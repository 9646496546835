import { useEffect, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const useResizeObserver = (ref: any) => {
    const [dimensions, setDimensions] = useState<any>();
    useEffect(() => {
        const observeTarget = ref.current;
        const resizeObserver = new ResizeObserver((entries: any) => {
            entries.forEach((entry: any) => {
                setDimensions(entry.contentRect);
            });
        });
        observeTarget && resizeObserver.observe(observeTarget);
        return () => {
            resizeObserver.unobserve(observeTarget);
        };
    }, [ref]);
    return dimensions;
};

export default useResizeObserver;
