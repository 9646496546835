import { ListPaginationInterface } from '@features/_shared/components/Paginator/enums/ListPaginationInterface';
import { HomeSetServices } from '@features/home/state/actions';
import {
    ServiceResetCategoryFilters,
    ServiceResetCreateUpdate,
    ServiceResetCreateUpdateCategory,
    ServiceResetExecutionHtml,
    ServiceResetExecutionLogs,
    ServiceResetExecutionPaginationAction,
    ServiceResetFilters,
    ServiceResetPaginationAction,
    ServiceResetPublishData,
    ServiceResetSelectedService,
    ServiceSetAllPublishService,
    ServiceSetCategories,
    ServiceSetExecutionHtml,
    ServiceSetExecutionLogs,
    ServiceSetExecutions,
    ServiceSetExecutionsPagination,
    ServiceSetExecutionStatus,
    ServiceSetFilters,
    ServiceSetOutputs,
    ServiceSetPaginationAction,
    ServiceSetPublishService,
    ServiceSetSelectedExecution,
    ServiceSetSelectedService,
    ServiceSetServices,
    ServiceSetValue,
    ServiceUpdateCategoryAvailableOptions,
} from '@features/service/store/actions';
import { ServiceState } from '@features/service/store/state';
import { StoreManager } from '@hypereact/state';
import { Slices } from '@store/state';
import KeycloakUtil from '@utils/keycloak.util';
import {
    OutputFile,
    RequestApprove,
    RequestBodyGenericStopProcess,
    RequestFile,
    RequestProjectPublish,
    RequestReject,
    RequestServiceCreate,
    RequestServiceExecutionCreate,
    RequestServiceFileCreate,
    RequestServicePublish,
    RequestServiceUpdate,
    RequestServiceUrlFileSelect,
    ResponseServiceDiffToProject,
    ResponseServiceExecution,
    ResponseServiceExecutionPaginated,
    ResponseServiceUrlFileSelect,
    ServiceExecutionStatusEnum,
    WebService,
    WebServiceSearchFilter,
    WebServiceSearchRequest,
} from '../../api/entities';
import { IProjectApiService, ProjectApiService } from '../services/projects/project.api.service';
import { IServiceApiService, ServiceApiService } from '../services/service/service.api.service';

export interface IServiceManagerProps {
    search(request: WebServiceSearchRequest): Promise<void>;
    create(requestServiceCreate: RequestServiceCreate): Promise<WebService>;
}

export class ServiceManager implements IServiceManagerProps {
    private static instance: ServiceManager;
    private serviceService: IServiceApiService;
    private projectService: IProjectApiService;

    private storeManager: StoreManager;
    private constructor() {
        this.serviceService = ServiceApiService.getInstance(process.env.REACT_APP_MICROSERVICE_DASHBOARD_API_BASEPATH!);
        this.projectService = ProjectApiService.getInstance(process.env.REACT_APP_MICROSERVICE_DASHBOARD_API_BASEPATH!);

        this.storeManager = StoreManager.getInstance();
    }

    static getInstance() {
        if (ServiceManager.instance == null) {
            ServiceManager.instance = new ServiceManager();
        }
        return ServiceManager.instance;
    }

    private getState(): ServiceState {
        return this.storeManager.getState(Slices.Service) as ServiceState;
    }

    async search(request: WebServiceSearchRequest): Promise<void> {
        const response = await this.serviceService.search(request);
        this.storeManager.dispatch(new ServiceSetPaginationAction(response.page?.page as number, response.page?.size as number, response.page?.total as number));
        this.storeManager.dispatch(new ServiceSetServices(response.services || []));
    }

    async homeSearch(): Promise<void> {
        const response = await this.serviceService.search({
            size: 5,
            page: 0,
            filter: { published: true },
            sort: { published_at: 'DESC' },
        });
        this.storeManager.dispatch(new HomeSetServices(response.services || []));
    }

    async create(request: RequestServiceCreate): Promise<WebService> {
        const response = await this.serviceService.create(request);
        await this.search({
            size: 10,
            page: 0,
            filter: {},
        });
        this.storeManager.dispatch(new ServiceResetCreateUpdate());
        return response;
    }

    setFilters(key: keyof WebServiceSearchFilter, value: string | boolean | Array<string>) {
        this.storeManager.dispatch(new ServiceSetFilters(key, value !== '' ? value : undefined));
    }

    resetCreateUpdate() {
        this.storeManager.dispatch(new ServiceResetCreateUpdate());
    }

    async pollServiceById(id: string): Promise<WebService> {
        const response = await this.serviceService.getServiceById(id);
        if (id === this.storeManager.getState(Slices.Service).service.selectedService.id) {
            this.storeManager.dispatch(new ServiceSetSelectedService(response));
        }
        return response;
    }

    updateAvailableOptions(value: Array<string>) {
        this.storeManager.dispatch(new ServiceUpdateCategoryAvailableOptions(value));
    }

    async getServiceById(id: string): Promise<WebService> {
        const response = await this.serviceService.getServiceById(id);
        this.storeManager.dispatch(new ServiceSetSelectedService(response));
        return response;
    }

    async launch(serviceId: string, execId: string): Promise<void> {
        await this.serviceService.launch(serviceId, execId as string, {
            timeout: 1200,
            jupyter_log_level: 'debug',
        });
        this.storeManager.dispatch(new ServiceSetExecutionStatus(ServiceExecutionStatusEnum.RUNNING));
    }

    async stop(serviceId: string, execId: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess): Promise<void> {
        await this.serviceService.stop(serviceId, execId, { force: false });
        this.storeManager.dispatch(new ServiceSetExecutionStatus(ServiceExecutionStatusEnum.FAILED));
    }

    async createExecution(serviceId: string, requestServiceExecutionCreate: RequestServiceExecutionCreate): Promise<ResponseServiceExecution> {
        const response = await this.serviceService.createExecution(serviceId, requestServiceExecutionCreate);
        this.storeManager.dispatch(new ServiceSetSelectedExecution(response));
        return response;
    }

    async pollExecution(serviceId: string, execId: string): Promise<ResponseServiceExecution> {
        const response = await this.serviceService.findExecution(serviceId, execId);
        if (response.id === this.storeManager.getState(Slices.Service).execution.selectedExecution?.id) {
            this.storeManager.dispatch(new ServiceSetSelectedExecution(response));
        }
        return response;
    }

    async findExecution(serviceId: string, execId: string): Promise<ResponseServiceExecution> {
        const response = await this.serviceService.findExecution(serviceId, execId);
        this.storeManager.dispatch(new ServiceSetSelectedExecution(response));
        return response;
    }

    async pollExecutionList(id: string, page?: number, size?: number): Promise<ResponseServiceExecutionPaginated> {
        const response = await this.serviceService.executions(id, page, size);
        if (id === this.storeManager.getState(Slices.Service).service.selectedService?.id) {
            response.serviceExecutions && this.storeManager.dispatch(new ServiceSetExecutions(response.serviceExecutions));
            response.serviceExecutions &&
                this.storeManager.dispatch(new ServiceSetExecutionsPagination(response.page?.page as number, response.page?.size as number, response.page?.total as number));
        }
        return response;
    }

    async getExecutionList(id: string, page?: number, size?: number): Promise<ResponseServiceExecutionPaginated> {
        const response = await this.serviceService.executions(id, page, size);
        response.serviceExecutions && this.storeManager.dispatch(new ServiceSetExecutions(response.serviceExecutions));
        response.serviceExecutions &&
            this.storeManager.dispatch(new ServiceSetExecutionsPagination(response.page?.page as number, response.page?.size as number, response.page?.total as number));
        return response;
    }

    async createInput(id: string, execId: string, requestServiceFileCreate: RequestServiceFileCreate): Promise<void> {
        await this.serviceService.createInput(id, execId, requestServiceFileCreate);
    }

    async selectExternalUrlInput(id: string, execId: string, requestServiceUrlFileSelect: RequestServiceUrlFileSelect): Promise<ResponseServiceUrlFileSelect> {
        const response = this.serviceService.selectExternalUrlInput(id, execId, requestServiceUrlFileSelect);
        return response;
    }

    resetFilters() {
        this.storeManager.dispatch(new ServiceResetFilters());
    }

    resetPagination() {
        this.storeManager.dispatch(new ServiceResetPaginationAction());
    }

    resetExecutionPagination() {
        this.storeManager.dispatch(new ServiceResetExecutionPaginationAction());
    }

    resetCategoryFilter() {
        this.storeManager.dispatch(new ServiceResetCategoryFilters());
    }

    resetSelectedService() {
        this.storeManager.dispatch(new ServiceResetSelectedService());
    }
    saveValue(key: keyof WebService, value: string | number | boolean | Array<string>): void {
        this.storeManager.dispatch(new ServiceSetValue(key, value));
    }

    resetCategoryCreate() {
        this.storeManager.dispatch(new ServiceResetCreateUpdateCategory());
    }

    async setPagination(page: number, size: number, total: number): Promise<void> {
        this.storeManager.dispatch(new ServiceSetPaginationAction(page, size, total));
        const serviceState = this.getState();
        const pagination: ListPaginationInterface = serviceState.service.pagination;
        const query = serviceState.service.query;
        const order = serviceState.service.order ? { [serviceState.service.order.by]: serviceState?.service.order.direction } : undefined;
        const filters: WebServiceSearchFilter = serviceState.service.filters;
        const serviceSearchRequest: WebServiceSearchRequest = { page: pagination.page, size: pagination.size, query: query, sort: order, filter: filters };
        await this.search(serviceSearchRequest);
    }

    async setExecutionsPagination(id: string, page: number, size: number, total: number): Promise<void> {
        this.storeManager.dispatch(new ServiceSetExecutionsPagination(page, size, total));
        await this.getExecutionList(id, page, size);
    }

    async update(serviceId: string, requestServiceUpdate: RequestServiceUpdate): Promise<void> {
        await this.serviceService.update(serviceId, requestServiceUpdate);
        const response = await this.serviceService.getServiceById(serviceId);
        this.storeManager.dispatch(new ServiceSetSelectedService(response));
    }

    async pollOutputList(serviceId: string, execId: string): Promise<void> {
        const response = await this.serviceService.outputList(serviceId, execId);
        const outputs = response.outputs && Object.values(response.outputs);
        if (execId === this.storeManager.getState(Slices.Service).execution.selectedExecution?.id) {
            response.outputs && this.storeManager.dispatch(new ServiceSetOutputs(outputs as Array<OutputFile>));
        }
    }

    async getOutputList(serviceId: string, execId: string): Promise<void> {
        const response = await this.serviceService.outputList(serviceId, execId);
        const outputs = response.outputs && Object.values(response.outputs);
        response.outputs && this.storeManager.dispatch(new ServiceSetOutputs(outputs as Array<OutputFile>));
    }

    async publishService(id: string, requestPublish: RequestProjectPublish): Promise<void> {
        await this.serviceService.publishService(id, requestPublish);
    }

    async pollLogs(serviceId: string, executionId: string): Promise<string> {
        const response = await this.serviceService.getLogs(serviceId, executionId);
        if (executionId === this.storeManager.getState(Slices.Service).execution.selectedExecution?.id) {
            this.storeManager.dispatch(new ServiceSetExecutionLogs(response));
        }
        return response;
    }

    async getLogs(serviceId: string, executionId: string): Promise<string> {
        const response = await this.serviceService.getLogs(serviceId, executionId);
        this.storeManager.dispatch(new ServiceSetExecutionLogs(response));
        return response;
    }

    async pollHtml(serviceId: string, executionId: string): Promise<string> {
        const response = await this.serviceService.getHtml(serviceId, executionId);
        if (executionId === this.storeManager.getState(Slices.Service).execution.selectedExecution?.id) {
            this.storeManager.dispatch(new ServiceSetExecutionHtml(response));
        }
        return response;
    }

    async getHtml(serviceId: string, executionId: string): Promise<string> {
        const response = await this.serviceService.getHtml(serviceId, executionId);
        this.storeManager.dispatch(new ServiceSetExecutionHtml(response));
        return response;
    }

    resetOutputAndLogs() {
        this.storeManager.dispatch(new ServiceResetExecutionHtml());
        this.storeManager.dispatch(new ServiceResetExecutionLogs());
    }

    async reject(id: string, requestReject: RequestReject): Promise<void> {
        const response = await this.serviceService.reject(id, requestReject);
        this.storeManager.dispatch(new ServiceSetSelectedService(response));
    }

    async approve(id: string, requestApprove: RequestApprove): Promise<void> {
        const response = await this.serviceService.approve(id, requestApprove);
        this.storeManager.dispatch(new ServiceSetSelectedService(response));
    }

    async downloadOutput(id: string, execId: string, requestFile: RequestFile): Promise<File> {
        return await this.serviceService.downloadOutput(id, execId, requestFile);
    }

    async downloadInput(id: string, execId: string, requestFile: RequestFile): Promise<File> {
        return await this.serviceService.downloadInput(id, execId, requestFile);
    }

    async refresh(id: string): Promise<void> {
        const response = await this.serviceService.refresh(id);
        this.storeManager.dispatch(new ServiceSetSelectedService(response));
        const exec = await this.serviceService.createExecution(id, {
            author: {
                name: KeycloakUtil.getName(),
                email: KeycloakUtil.getEmail(),
            },
        });
        this.storeManager.dispatch(new ServiceSetSelectedExecution(exec));
        //await this.serviceService.getServiceById(id);
        exec.id && (await this.launch(id, exec.id as string));
    }

    async diffToProject(id: string): Promise<ResponseServiceDiffToProject> {
        return await this.serviceService.diffToProject(id);
    }

    setPublishData(key: keyof RequestServicePublish, value: string) {
        this.storeManager.dispatch(new ServiceSetPublishService(key, value));
    }

    setAllPublishData(requestServicePublish: RequestServicePublish) {
        this.storeManager.dispatch(new ServiceSetAllPublishService(requestServicePublish));
    }

    resetPublishData() {
        this.storeManager.dispatch(new ServiceResetPublishData());
    }

    async updatePublicationData(id: string, requestServicePublish: RequestServicePublish) {
        const response = await this.serviceService.updatePublicationData(id, requestServicePublish);
        this.storeManager.dispatch(new ServiceSetSelectedService(response));
    }

    async delete(id: string): Promise<void> {
        await this.serviceService.delete(id);
    }

    async getServiceCategories(): Promise<void> {
        const response = await this.projectService.getProjectCategories();
        response.categories && this.storeManager.dispatch(new ServiceSetCategories(response.categories));
    }
}
