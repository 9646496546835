/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ResponseStatistics } from '../entities';
/**
 * StatisticsControllerApi - axios parameter creator
 * @export
 */
export const StatisticsControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistics: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/metrics/retrieve`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * StatisticsControllerApi - functional programming interface
 * @export
 */
export const StatisticsControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = StatisticsControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getStatistics(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseStatistics>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getStatistics(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * StatisticsControllerApi - factory interface
 * @export
 */
export const StatisticsControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StatisticsControllerApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatistics(options?: any): AxiosPromise<ResponseStatistics> {
            return localVarFp.getStatistics(options).then(request => request(axios, basePath));
        },
    };
};

/**
 * StatisticsControllerApi - interface
 * @export
 * @interface StatisticsControllerApi
 */
export interface StatisticsControllerApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsControllerApiInterface
     */
    getStatistics(options?: AxiosRequestConfig): AxiosPromise<ResponseStatistics>;
}

/**
 * StatisticsControllerApi - object-oriented interface
 * @export
 * @class StatisticsControllerApi
 * @extends {BaseAPI}
 */
export class StatisticsControllerApi extends BaseAPI implements StatisticsControllerApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StatisticsControllerApi
     */
    public getStatistics(options?: AxiosRequestConfig) {
        return StatisticsControllerApiFp(this.configuration)
            .getStatistics(options)
            .then(request => request(this.axios, this.basePath));
    }
}
