import { TextArea } from '@features/_shared/components/TextArea';
import { TextField } from '@features/_shared/components/TextField';
import { ServiceDataState } from '@features/service/store/state';
import { TranslatorManager } from '@managers/TranslatorManager';
import { FormManager } from '@managers/form.manager';
import { ServiceManager } from '@managers/service.manager';
import { FormHelperUtil } from '@utils/form.helper.util';
import React, { useContext, useEffect } from 'react';
import { IntlContext } from 'react-intl';
import { BaseProjectTemplate, Project, RequestServicePublish, WebService } from 'src/api/entities';
import { ProvisioningServiceData } from '../beans/provisioningServiceData';
import { ProvisioningPageEnum } from '../store/state';
import '../styles/provisioning.service.data.component.style.scss';
export interface IServiceDataComponentProps {
    project?: Project;
    handleDatasetChange?: (checked: boolean) => void;
    templates?: Array<BaseProjectTemplate>;
    currentPage?: ProvisioningPageEnum;
    service?: WebService;
    handlePublishDataChange?: (key: keyof RequestServicePublish, value: any) => void;
    isServiceNextClicked?: boolean;
    serviceState?: ServiceDataState;
}
export const ServiceDataComponent: React.FC<IServiceDataComponentProps> = ({
    project,
    service,
    templates,
    currentPage,
    handleDatasetChange,
    handlePublishDataChange,
    isServiceNextClicked,
    serviceState,
}) => {
    const translator = TranslatorManager.getInstance();
    const serviceManager = ServiceManager.getInstance();
    const intl = useContext(IntlContext);

    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator('provisioning.service', intl as any);

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        handlePublishDataChange?.(e.target.name as keyof RequestServicePublish, e.target.value);
        setValue(e.target.name, e.target.value);
        isServiceNextClicked && validateTitleAndSubtitle();
    };

    const validateTitleAndSubtitle = () => {
        const isTitle = getValues('minisite_title') ? true : false;
        const isSubtitle = getValues('subtitle') ? true : false;

        if (isTitle || isSubtitle) {
            if (isTitle) {
                clearErrors('minisite_title');
            }
            if (isSubtitle) {
                clearErrors('subtitle');
            }
        }
        if (!isTitle || !isSubtitle) {
            if (!isTitle) {
                setError('minisite_title', { type: 'isNotEmpty' });
            }
            if (!isSubtitle) {
                setError('subtitle', { type: 'isNotEmpty' });
            }
        }
        return (isTitle && isSubtitle) as boolean;
    };

    useEffect(() => {
        validateTitleAndSubtitle();

        serviceManager.setAllPublishData({
            minisite_title: service?.minisite_title,
            subtitle: service?.subtitle,
            logo: service?.logo,
            landscape: service?.landscape,
        });
    }, [isServiceNextClicked]);

    const handleOnFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let files = e.target.files;
        let fileReader = new FileReader();
        files && fileReader.readAsDataURL(files[0]);

        fileReader.onload = event => {
            handlePublishDataChange?.(e.target.name as keyof RequestServicePublish, event.target?.result as string);
        };
    };

    const triggerValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.name, e.target.value);
        isServiceNextClicked && validateTitleAndSubtitle();
    };

    const { errors, register, setValue, trigger, clearErrors, setError, getValues } = FormManager.getInstance().buildFormProxy<ProvisioningServiceData>(
        ProvisioningServiceData,
        () => {},
        {
            minisite_title: service?.minisite_title,
            subtitle: service?.subtitle,
        },
    );

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='row col-12 home-title-container '>
                        <h5 className='title-description col-12 mb-0'>{translator.get('provisioning.service.publication.description.part.one')}</h5>
                        {/* <h3 className='secondary-title col-12 mt-3 mb-3'>{translator.get('provisioning.service.publication.description.part.two')}</h3>
                        <h5 className='title-description col-12'>{translator.get('provisioning.service.publication.description.part.three')}</h5> */}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <p className='input-output-header-container mb-4'>{translator.get('provisioning.service.title.text')}</p>

                        <div className='form-group'>
                            <label htmlFor='name' className='active d-flex flex-row label-color align-items-center'>
                                {translator.get('provisioning.service.name.label')}
                            </label>
                            <TextField
                                type='text'
                                className='form-control'
                                defaultValue={service?.name}
                                placeholder={translator.get('provisioning.service.name.placeholder')}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='name' className='active d-flex flex-row label-color align-items-center'>
                                {translator.get('provisioning.version.label')}
                            </label>
                            <TextField
                                type='text'
                                className='form-control'
                                defaultValue={service?.version}
                                placeholder={translator.get('provisioning.version.placeholder')}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <div className='select-wrapper'>
                                <label htmlFor='technology' className='active d-flex flex-row label-color'>
                                    {translator.get('provisioning.technology.label')}
                                </label>
                                <select name='technology' className='select-container' disabled>
                                    <option value='' selected>
                                        {
                                            templates?.find((item: BaseProjectTemplate) => {
                                                return item.id == service?.technology;
                                            })?.name
                                        }
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='name' className='active d-flex flex-row label-color align-items-center'>
                                {translator.get('provisioning.creator.label')}
                            </label>
                            <TextField
                                type='text'
                                className='form-control'
                                defaultValue={service?.author?.name}
                                placeholder={translator.get('provisioning.creator.placeholder')}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='name' className='active d-flex flex-row label-color align-items-center'>
                                {translator.get('provisioning.abstract.label')}
                            </label>
                            <TextArea
                                className='form-control'
                                rows={5}
                                name='description'
                                value={service?.description}
                                placeholder={translator.get('provisioning.abstract.placeholder')}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='name' className='active d-flex flex-row label-color align-items-center'>
                                {translator.get('provisioning.keywords.label')}
                            </label>
                            <TextField
                                type='text'
                                className='form-control'
                                defaultValue={service?.keywords}
                                placeholder={translator.get('provisioning.keywords.placeholder')}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
                {/* <div className='row'>
                    <div className='col-12'>
                        <label htmlFor='metadata' className='active d-flex flex-row label-color align-items-center fw-bold'>
                            {translator.get('provisioning.additional.metadata.text')}
                        </label>
                        <table className='table table-container'>
                            <tr className='metadata-row-container mb-1'>
                                <th scope='col'>{translator.get('provisioning.metadata.text')}</th>

                                <td>
                                    <div className='form-group mb-0'>
                                        <TextField type='text' className='form-control' placeholder={translator.get('provisioning.metadata.value.placeholder')} disabled />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div> */}

                <div className='row'>
                    <div className='col-12'>
                        <p className='input-output-header-container mb-5'>{translator.get('provisioning.web.service.title.label')}</p>

                        <div className='form-group'>
                            <label htmlFor='title' className='active d-flex flex-row label-color align-items-center'>
                                {translator.get('provisioning.title.label')}
                                <p className='requiredSign'>*</p>
                            </label>
                            <TextField
                                type='title'
                                {...register('minisite_title', {
                                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation(e),
                                })}
                                error={errors.minisite_title}
                                errorMessage={errors.minisite_title && getMessageByFieldAndError('minisite_title', errors)}
                                placeholder={translator.get('provisioning.title.placeholder')}
                                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='subtitle' className='active d-flex flex-row label-color align-items-center'>
                                {translator.get('provisioning.subtitle.label')}
                                <p className='requiredSign'>*</p>
                            </label>
                            <TextField
                                type='title'
                                {...register('subtitle', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation(e) })}
                                error={errors.subtitle}
                                errorMessage={errors.subtitle && getMessageByFieldAndError('subtitle', errors)}
                                placeholder={translator.get('provisioning.subtitle.placeholder')}
                                onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='logo' className='active d-flex flex-row label-color align-items-center'>
                                {translator.get('provisioning.logo.label')}
                            </label>
                            <TextField
                                type='file'
                                {...register('logo')}
                                className='form-control'
                                accept='image/*'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnFileChange(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='backgroundImg' className='active d-flex flex-row label-color align-items-center'>
                                {translator.get('provisioning.backgroundImg.label')}
                            </label>
                            <TextField
                                type='file'
                                {...register('landscape')}
                                className='form-control'
                                accept='image/*'
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOnFileChange(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
