import { InputFile, InputState, OutputState } from '@features/project/state/state';
import { ListPaginationInterface } from '@features/_shared/components/Paginator/enums/ListPaginationInterface';
import { Order } from '@features/_shared/components/Paginator/interfaces/Order';
import { OutputFile, RequestServicePublish, ServiceExecution, WebService, WebServiceSearchFilter, WebServiceSearchFilterStatusEnum } from '../../../api';

export type ServiceInputState = Omit<InputState, 'selectedInput'>;
export type ServiceOutputState = Omit<OutputState, 'selectedOutput'>;

export interface ServiceDataState {
    services: Array<WebService>;
    selectedService?: WebService;
    createUpdate: WebService;
    filters: WebServiceSearchFilter;
    pagination: ListPaginationInterface;
    query?: string;
    order: Order | undefined;
    categoryAvailableOptions: string[];
    publishData: RequestServicePublish;
}

export interface ExecutionState {
    executions: Array<ServiceExecution>;
    selectedExecution?: ServiceExecution;
    pagination: ListPaginationInterface;
    log?: string;
    html?: string;
}

export interface ServiceState {
    service: ServiceDataState;
    execution: ExecutionState;
    input: ServiceInputState;
    output: ServiceOutputState;
}

export const initialServiceState: ServiceState = {
    service: {
        services: [],
        selectedService: undefined,
        createUpdate: {
            name: undefined,
            version: undefined,
            technology: undefined,
            description: undefined,
            keywords: undefined,
        },
        filters: {
            name: undefined,
            creatorName: undefined,
            status: WebServiceSearchFilterStatusEnum.ALL,
            categories: 'ALL',
            keywords: undefined,
        },
        pagination: {
            page: 0,
            size: 10,
        },
        order: undefined,
        categoryAvailableOptions: [],
        publishData: {},
    },
    execution: {
        executions: [],
        selectedExecution: undefined,
        pagination: {
            page: 0,
            size: 5,
        },
    },
    input: {
        inputs: [],
    },
    output: {
        outputs: [],
    },
};

export interface VersionControl {
    id?: string;
    name?: string;
    group?: string;
    url?: string;
    branch?: string;
    forkCount?: number;
    idOwner?: number;
    parentProject?: VersionControl | null;
}

//TODO replace these entities with BE entities

export interface ServiceMocked {
    id: string;
    idReferenceProject: string;
    name: string;
    version: string;
    technology: string;
    author: {
        id: string;
        firstName: string;
        lastName: string;
    };
    approver: {
        id?: string;
        firstName?: string;
        lastName?: string;
    };
    git: VersionControl;
    runtime: {
        repository?: string;
        image?: string;
        group?: string;
        tag?: string;
    };
    status: ServiceStatusEnum;
    description?: string;
    chat: {
        url?: string;
    };
    wiki: {
        url?: string;
    };
    inputs: Array<InputFile>;
    executions: Array<ServiceExecutionMock>;
    outcomes: {
        log: {
            createdAt?: string;
            updatedAt?: string;
            size?: number;
        };
        html: {
            createdAt?: string;
            updatedAt?: string;
            size?: number;
        };
    };
    outputs: Array<OutputFile>;
    statistics: any; //not defined yet
    warnings: any; //not defined yet
}

export interface ServiceExecutionMock {
    status: ExecutionStatusEnum;
    run?: number;
    dateOfStarted?: string;
    dateOfFinished?: string;
    executionTime?: string;
    author: {
        id?: string;
        firstName?: string;
        lastName?: string;
    };
    statistics?: any; //not defined yet
    warnings?: any; //not defined yet
}

export interface ServiceSearchFilter {
    name?: string;
    author?: string;
    status?: string;
    approved?: string;
}

export interface RequestServiceCreate {
    name: string;
    version: string;
    technology: string;
    author: {
        id: string;
        firstName: string;
        lastName: string;
    };
    description?: string;
}

export enum ServiceStatusEnum {
    Draft = 'draft',
    ToBePublished = 'to be published',
    Published = 'published',
}

export enum ServiceCategoryEnum {
    CategoryX = 'Category_X',
    CategoryY = 'Category_Y',
    CategoryA = 'Category_A',
}

export enum ExecutionStatusEnum {
    Running = 'running',
    Wait = 'wait',
    Stop = 'stop',
}
