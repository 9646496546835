import Banner from '@assets/images/banner.png';

import { ListPaginationInterface } from '@features/_shared/components/Paginator/enums/ListPaginationInterface';
import Table from '@features/_shared/components/Table/table.component';
import { User } from '@features/login/store/state';
import { ProvisioningOriginEnum } from '@features/provisioning/views/provisioning.view';
import { ReduxConnect } from '@hypereact/state';
import { TranslatorManager } from '@managers/TranslatorManager';
import { BackofficeManager } from '@managers/backoffice.manager';
import { HomepageManager } from '@managers/homepage.manages';
import { NavigationManager } from '@managers/navigation.manager';
import { ProjectManager } from '@managers/project.manager';
import { ProvisioningManager } from '@managers/provisioning.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { ServiceManager } from '@managers/service.manager';
import { Paths } from '@routes';
import { RootState } from '@store/state';
import { FormatUtil } from '@utils/formaters.util';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import '../../../_shared/theme/login.scss';
import { BaseProjectTemplate, Project, ResponseStatistics, WebService } from '../../../api/entities';
import '../styles/home.style.scss';

export interface IHomeViewProps {
    projects: Array<Project>;
    services: Array<WebService>;
    user: User;
    templates: Array<BaseProjectTemplate>;
    pagination: ListPaginationInterface;
    statistics: ResponseStatistics;
    configuration: { [key: string]: string | undefined };
}

export interface IHomeViewState {}

@ReduxConnect((state: RootState) => {
    return {
        projects: state.home.publishedProjects,
        services: state.home.publishedServices,
        templates: state.project.template,
        pagination: state.project.project.pagination,
        statistics: state.home.statistics,
        configuration: state.shared.configuration,
    };
})
export class HomeView extends React.Component<IHomeViewProps, IHomeViewState> {
    private projectManager: ProjectManager;
    private serviceManager: ServiceManager;
    private homepageManager: HomepageManager;
    private translator: TranslatorManager;
    private navigationManager: NavigationManager;
    private provisioningManager: ProvisioningManager;
    private queueManager: QueueManager;
    private backofficeManager: BackofficeManager;
    constructor(props: IHomeViewProps) {
        super(props);
        this.projectManager = ProjectManager.getInstance();
        this.serviceManager = ServiceManager.getInstance();
        this.homepageManager = HomepageManager.getInstance();
        this.backofficeManager = BackofficeManager.getInstance();
        this.translator = TranslatorManager.getInstance();
        this.provisioningManager = ProvisioningManager.getInstance();
        this.queueManager = QueueManager.getInstance();
        this.navigationManager = NavigationManager.getInstance();
        this.handleProjectTableRowClick = this.handleProjectTableRowClick.bind(this);
        this.handleServiceTableRowClick = this.handleServiceTableRowClick.bind(this);
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.handleOnHomepageLoad());
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.backofficeManager.getKeys({ startsWith: 'ui.homepage' }));
    }

    shouldComponentUpdate(nextProp: IHomeViewProps, nextState: IHomeViewState) {
        if (_.isEqual(nextProp, this.props) && _.isEqual(nextState, this.state)) {
            return false;
        } else {
            return true;
        }
    }

    async handleOnHomepageLoad() {
        await this.projectManager.homeSearch();
        await this.serviceManager.homeSearch();
        await this.homepageManager.getStatistics();
        await this.projectManager.getTemplateList();
    }

    async handleProjectTableRowClick(id: string) {
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(id));
        this.provisioningManager.setOrigin(ProvisioningOriginEnum.HOMEPAGE);
        this.navigationManager.navigateTo(`/project/${id}` as Paths);
    }

    async handleServiceTableRowClick(id: string) {
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(id));
        this.provisioningManager.setOrigin(ProvisioningOriginEnum.HOMEPAGE);
        this.navigationManager.navigateTo(`/service/${id}` as Paths);
    }

    render() {
        const { projects, services, templates } = this.props;
        const tableColumns = [
            this.translator.get('project.table.header.title.text'),
            this.translator.get('project.table.header.version.text'),
            this.translator.get('project.table.header.language.text'),
            this.translator.get('project.table.header.category.text'),
            this.translator.get('home.table.header.published.text'),
            '',
        ];

        const projectTableRows = projects?.map((item: Project, index) => {
            return {
                id: item.id as string,
                values: {
                    title: <div className='td-name-size'>{item.name}</div>,
                    version: <div className='td-version-size'>{item.version}</div>,
                    programmingLanguage: (
                        <div className='td-programmingLanguage-size'>
                            {
                                templates.find((template: BaseProjectTemplate) => {
                                    return template?.id == item.technology;
                                })?.name
                            }{' '}
                        </div>
                    ),
                    category: <div className='td-category-size-home'>{item.categories?.join(', ')}</div>,
                    published_at: (
                        <div className='td-published_at-size'>
                            {item?.project_published_at &&
                                moment
                                    .utc(item?.project_published_at as string)
                                    .local()
                                    .format('DD/MM/YYYY HH:mm')}{' '}
                        </div>
                    ),
                },
                collapsedRow: (
                    <>
                        <div className='description table-padding'>{FormatUtil.truncateText(item.description as string)}</div>
                        <div className='d-flex justify-content-space-between w-100 table-padding'>
                            <div className='creator-container'>
                                <div className='d-flex'>
                                    <div className='creator-label-container'>Creator: </div>
                                    <div className='creator-content-container'>{`${item.author?.name} (${item.author?.email})`}</div>
                                </div>
                            </div>
                        </div>
                    </>
                ),
            };
        });

        const serviceTableRows = services.map((service: WebService, index) => {
            return {
                id: service.id as string,
                values: {
                    title: <div className='td-name-size'>{service.name}</div>,
                    version: <div className='td-version-size'>{service.version}</div>,
                    programmingLanguage: (
                        <div className='td-programmingLanguage-size'>
                            {
                                this.props.templates.find((template: BaseProjectTemplate) => {
                                    return template?.id == service.technology;
                                })?.name
                            }
                        </div>
                    ),
                    category: <div className='td-category-size-home'> {service.categories ? String(service.categories) : '-'} </div>,
                    published_at: (
                        <div className='td-published_at-size'>
                            {service.published_at &&
                                moment
                                    .utc(service.published_at as string)
                                    .local()
                                    .format('DD/MM/YYYY HH:mm')}
                        </div>
                    ),
                },
                collapsedRow: (
                    <>
                        <div className='description table-padding'>{FormatUtil.truncateText(service.description as string)}</div>
                        <div className='d-flex justify-content-space-between w-100 table-padding'>
                            <div className='creator-container'>
                                <div className='d-flex'>
                                    <div className='creator-label-container'>Creator: </div>
                                    <div className='creator-content-container'>{`${service.author?.name} (${service.author?.email})`}</div>
                                </div>
                            </div>
                        </div>
                    </>
                ),
            };
        });

        return (
            <>
                <div className='container-fluid'>
                    <div className='row home-title-container'>
                        <div className='home-header-title row'>
                            <p className='home-header-title col-12'>{this.translator.get('home.view.title')}</p>
                        </div>
                        <h5 className='title-description'>{this.translator.get('home.view.title.description.part.one')}</h5>
                        <h5 className='title-description'>{this.translator.get('home.view.title.description.part.two')}</h5>
                        <h5 className='title-description mb-0'>{this.translator.get('home.view.title.description.part.three')}</h5>
                        <h5 className='title-description'>{this.translator.get('home.view.title.description.part.four')}</h5>
                    </div>
                    <div className='row metrics-main-container'>
                        <div className='start-page-top p-4'>
                            <div className='home-header-title row'>
                                <h3 className='title col-12'>{this.translator.get('home.view.metrics.title')}</h3>
                            </div>
                            <div className='metrics-container'>
                                {this.props.configuration['projects_count'] === 'true' && (
                                    <div className='metrics-box'>
                                        <div className='metrics-value'> {this.props.statistics.projectsCount} </div>
                                        <div className='metrics-text'> {this.translator.get('home.metrics.projects')} </div>
                                    </div>
                                )}
                                {this.props.configuration['published_projects_count'] === 'true' && (
                                    <div className='metrics-box'>
                                        <div className='metrics-value'> {this.props.statistics.publishedProjectsCount} </div>
                                        <div className='metrics-text'> {this.translator.get('home.metrics.projects.published')} </div>
                                    </div>
                                )}
                                {this.props.configuration['sessions_count'] === 'true' && (
                                    <div className='metrics-box'>
                                        <div className='metrics-value'> {this.props.statistics.sessionsCount} </div>
                                        <div className='metrics-text'> {this.translator.get('home.metrics.users')} </div>
                                    </div>
                                )}
                                {this.props.configuration['services_count'] === 'true' && (
                                    <div className='metrics-box'>
                                        <div className='metrics-value'> {this.props.statistics.servicesCount} </div>
                                        <div className='metrics-text'> {this.translator.get('home.metrics.services')} </div>
                                    </div>
                                )}
                                {this.props.configuration['published_services_count'] === 'true' && (
                                    <div className='metrics-box'>
                                        <div className='metrics-value'> {this.props.statistics.publishedServicesCount} </div>
                                        <div className='metrics-text'> {this.translator.get('home.metrics.services.published')} </div>
                                    </div>
                                )}
                                {this.props.configuration['launched_executions'] === 'true' && (
                                    <div className='metrics-box'>
                                        <div className='metrics-value'> {this.props.statistics.launchedExecutionsCount} </div>
                                        <div className='metrics-text'> {this.translator.get('home.metrics.executions')} </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='mt-4'>
                            <div className='home-header-title row'>
                                <h3 className='title col-12'>{this.translator.get('home.table.title')}</h3>
                                <h5 className='title-description col-12'>{this.translator.get('home.view.latest.published.description')}</h5>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p className='tableTitle p-2 mb-3 mt-4'>{this.translator.get('home.metrics.projects')}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='project-table-container size-tab'>
                                <Table columns={tableColumns} rows={projectTableRows} isStyledHeaders={true} onRowClick={this.handleProjectTableRowClick} keyLabel='project' />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <p className='tableTitle p-2 mb-3 mt-4'>{this.translator.get('home.metrics.services')}</p>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='project-table-container size-tab'>
                                <Table columns={tableColumns} rows={serviceTableRows} isStyledHeaders={true} onRowClick={this.handleServiceTableRowClick} keyLabel='service' />
                            </div>
                        </div>
                    </div>
                    <div className='row banner'>
                        <img src={Banner} className='banner-img'></img>
                    </div>
                </div>
            </>
        );
    }
}
