/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RequestBodyGenericStopProcess } from '../entities';
// @ts-ignore
import { RequestBodyStartHeadless } from '../entities';
// @ts-ignore
import { RequestBodyStartProject } from '../entities';
// @ts-ignore
import { ResponseCoreProjectIdeStart } from '../entities';
// @ts-ignore
import { ResponseCoreServiceExecutionLaunch } from '../entities';
/**
 * MockedApphiaControllerApi - axios parameter creator
 * @export
 */
export const MockedApphiaControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {string} projectId
         * @param {RequestBodyStartProject} requestBodyStartProject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mockProjectStart: async (projectId: string, requestBodyStartProject: RequestBodyStartProject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('mockProjectStart', 'projectId', projectId);
            // verify required parameter 'requestBodyStartProject' is not null or undefined
            assertParamExists('mockProjectStart', 'requestBodyStartProject', requestBodyStartProject);
            const localVarPath = `/mocked/ide/becode/{projectId}/start`.replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestBodyStartProject, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mockProjectStop: async (projectId: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('mockProjectStop', 'projectId', projectId);
            // verify required parameter 'requestBodyGenericStopProcess' is not null or undefined
            assertParamExists('mockProjectStop', 'requestBodyGenericStopProcess', requestBodyGenericStopProcess);
            const localVarPath = `/mocked/ide/becode/{projectId}/stop`.replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestBodyGenericStopProcess, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} sessionId
         * @param {RequestBodyStartHeadless} requestBodyStartHeadless
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mockServiceStart: async (sessionId: string, requestBodyStartHeadless: RequestBodyStartHeadless, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('mockServiceStart', 'sessionId', sessionId);
            // verify required parameter 'requestBodyStartHeadless' is not null or undefined
            assertParamExists('mockServiceStart', 'requestBodyStartHeadless', requestBodyStartHeadless);
            const localVarPath = `/mocked/headless/becode/{sessionId}/start`.replace(`{${'sessionId'}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestBodyStartHeadless, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} sessionId
         * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mockServiceStop: async (sessionId: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('mockServiceStop', 'sessionId', sessionId);
            // verify required parameter 'requestBodyGenericStopProcess' is not null or undefined
            assertParamExists('mockServiceStop', 'requestBodyGenericStopProcess', requestBodyGenericStopProcess);
            const localVarPath = `/mocked/headless/becode/{sessionId}/stop`.replace(`{${'sessionId'}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestBodyGenericStopProcess, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * MockedApphiaControllerApi - functional programming interface
 * @export
 */
export const MockedApphiaControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MockedApphiaControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} projectId
         * @param {RequestBodyStartProject} requestBodyStartProject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mockProjectStart(
            projectId: string,
            requestBodyStartProject: RequestBodyStartProject,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCoreProjectIdeStart>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mockProjectStart(projectId, requestBodyStartProject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} projectId
         * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mockProjectStop(
            projectId: string,
            requestBodyGenericStopProcess: RequestBodyGenericStopProcess,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mockProjectStop(projectId, requestBodyGenericStopProcess, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} sessionId
         * @param {RequestBodyStartHeadless} requestBodyStartHeadless
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mockServiceStart(
            sessionId: string,
            requestBodyStartHeadless: RequestBodyStartHeadless,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCoreServiceExecutionLaunch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mockServiceStart(sessionId, requestBodyStartHeadless, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} sessionId
         * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async mockServiceStop(
            sessionId: string,
            requestBodyGenericStopProcess: RequestBodyGenericStopProcess,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.mockServiceStop(sessionId, requestBodyGenericStopProcess, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * MockedApphiaControllerApi - factory interface
 * @export
 */
export const MockedApphiaControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MockedApphiaControllerApiFp(configuration);
    return {
        /**
         *
         * @param {string} projectId
         * @param {RequestBodyStartProject} requestBodyStartProject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mockProjectStart(projectId: string, requestBodyStartProject: RequestBodyStartProject, options?: any): AxiosPromise<ResponseCoreProjectIdeStart> {
            return localVarFp.mockProjectStart(projectId, requestBodyStartProject, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} projectId
         * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mockProjectStop(projectId: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options?: any): AxiosPromise<void> {
            return localVarFp.mockProjectStop(projectId, requestBodyGenericStopProcess, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} sessionId
         * @param {RequestBodyStartHeadless} requestBodyStartHeadless
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mockServiceStart(sessionId: string, requestBodyStartHeadless: RequestBodyStartHeadless, options?: any): AxiosPromise<ResponseCoreServiceExecutionLaunch> {
            return localVarFp.mockServiceStart(sessionId, requestBodyStartHeadless, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} sessionId
         * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mockServiceStop(sessionId: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options?: any): AxiosPromise<void> {
            return localVarFp.mockServiceStop(sessionId, requestBodyGenericStopProcess, options).then(request => request(axios, basePath));
        },
    };
};

/**
 * MockedApphiaControllerApi - interface
 * @export
 * @interface MockedApphiaControllerApi
 */
export interface MockedApphiaControllerApiInterface {
    /**
     *
     * @param {string} projectId
     * @param {RequestBodyStartProject} requestBodyStartProject
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MockedApphiaControllerApiInterface
     */
    mockProjectStart(projectId: string, requestBodyStartProject: RequestBodyStartProject, options?: AxiosRequestConfig): AxiosPromise<ResponseCoreProjectIdeStart>;

    /**
     *
     * @param {string} projectId
     * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MockedApphiaControllerApiInterface
     */
    mockProjectStop(projectId: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} sessionId
     * @param {RequestBodyStartHeadless} requestBodyStartHeadless
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MockedApphiaControllerApiInterface
     */
    mockServiceStart(sessionId: string, requestBodyStartHeadless: RequestBodyStartHeadless, options?: AxiosRequestConfig): AxiosPromise<ResponseCoreServiceExecutionLaunch>;

    /**
     *
     * @param {string} sessionId
     * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MockedApphiaControllerApiInterface
     */
    mockServiceStop(sessionId: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * MockedApphiaControllerApi - object-oriented interface
 * @export
 * @class MockedApphiaControllerApi
 * @extends {BaseAPI}
 */
export class MockedApphiaControllerApi extends BaseAPI implements MockedApphiaControllerApiInterface {
    /**
     *
     * @param {string} projectId
     * @param {RequestBodyStartProject} requestBodyStartProject
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MockedApphiaControllerApi
     */
    public mockProjectStart(projectId: string, requestBodyStartProject: RequestBodyStartProject, options?: AxiosRequestConfig) {
        return MockedApphiaControllerApiFp(this.configuration)
            .mockProjectStart(projectId, requestBodyStartProject, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} projectId
     * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MockedApphiaControllerApi
     */
    public mockProjectStop(projectId: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options?: AxiosRequestConfig) {
        return MockedApphiaControllerApiFp(this.configuration)
            .mockProjectStop(projectId, requestBodyGenericStopProcess, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} sessionId
     * @param {RequestBodyStartHeadless} requestBodyStartHeadless
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MockedApphiaControllerApi
     */
    public mockServiceStart(sessionId: string, requestBodyStartHeadless: RequestBodyStartHeadless, options?: AxiosRequestConfig) {
        return MockedApphiaControllerApiFp(this.configuration)
            .mockServiceStart(sessionId, requestBodyStartHeadless, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} sessionId
     * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MockedApphiaControllerApi
     */
    public mockServiceStop(sessionId: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options?: AxiosRequestConfig) {
        return MockedApphiaControllerApiFp(this.configuration)
            .mockServiceStop(sessionId, requestBodyGenericStopProcess, options)
            .then(request => request(this.axios, this.basePath));
    }
}
