import { ResponseStatistics } from '../../../api';
import { HomepageApiClients, IHomepageApiClient } from './homepage.api.client';

export interface IHomepageApiService {
    getStatistics(): Promise<ResponseStatistics>;
}

export class HomepageApiService implements IHomepageApiService {
    private static instance: HomepageApiService;
    private api: IHomepageApiClient;

    private constructor(basePath: string) {
        this.api = HomepageApiClients.getInstance(basePath);
    }

    static getInstance(basePath: string) {
        if (HomepageApiService.instance == null) {
            HomepageApiService.instance = new HomepageApiService(basePath);
        }
        return HomepageApiService.instance;
    }

    async getStatistics(): Promise<ResponseStatistics> {
        return (await this.api.statistics.getStatistics()).data;
    }
}
