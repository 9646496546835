import { ReactComponent as Close } from '@assets/svg/it-close.svg';
import { TextField } from '@components/TextField';
import { SelectMultipleComponent } from '@features/_shared/components/SelectMultiple/SelectMultiple.component';
import { TextArea } from '@features/_shared/components/TextArea';
import { StyledTooltip } from '@features/_shared/components/Tooltip/Tooltip';
import { TranslatorManager } from '@managers/TranslatorManager';
import { FormManager } from '@managers/form.manager';
import { ProjectManager } from '@managers/project.manager';
import { FormHelperUtil } from '@utils/form.helper.util';
import KeycloakUtil from '@utils/keycloak.util';
import { useContext, useEffect, useState } from 'react';
import { IntlContext } from 'react-intl';
import { BaseProjectTemplate, Project, User } from '../../../api/entities';
import { ProjectCloneFormData } from '../beans/projectCloneFormData';
import { ProjectCreateUpdate } from '../state/state';
import './styles/project.clone.style.scss';

export interface IProjectCloneComponentProps {
    user: User;
    project?: Project;
    onBlur?: (key: keyof ProjectCreateUpdate, value: string | number | boolean | Array<string>) => void;
    onClone?: () => void;
    isModalClosed?: boolean;
    templates?: Array<BaseProjectTemplate>;
    availableCategoryOptions?: string[];
    projectCreate: ProjectCreateUpdate;
}

export const ProjectCloneFormView: React.FC<IProjectCloneComponentProps> = ({ project, onBlur, onClone, isModalClosed, templates, availableCategoryOptions, projectCreate }) => {
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const intl = useContext(IntlContext);
    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator('project.clone', intl as any);
    const username = KeycloakUtil.getName();
    const projectManager = ProjectManager.getInstance();

    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        let error = await trigger(['name', 'version', 'description']);
        //needs to be called after trigger , otherwise the values will be reset
        const isCategoriesAndKeywordsValidated = validateCategoriesAndKeywords();
        error = error && isCategoriesAndKeywordsValidated;

        setIsSubmitted(true);
        if (error) {
            onClone?.();
            reset();
            setIsSubmitted(false);
        } else {
            e.preventDefault();
        }
    };

    const validateCategoriesAndKeywords = () => {
        const isCategories = projectCreate.categories && projectCreate.categories?.length > 0 ? true : false;
        const isKeywords = projectCreate.keywords && projectCreate.keywords.length > 0 ? true : false;

        if (isCategories || isKeywords) {
            if (isCategories) {
                clearErrors('categories');
            }
            if (isKeywords) {
                clearErrors('keyword');
            }
        }
        if (!isCategories || !isKeywords) {
            if (!isCategories) {
                setError('categories', { type: 'isNotEmpty' });
            }
            if (!isKeywords) {
                setError('keyword', { type: 'isNotEmpty' });
            }
        }
        return (isCategories && isKeywords) as boolean;
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        onBlur?.(e.target.name as keyof ProjectCreateUpdate, e.target.value);
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onBlur?.(e.target.name as keyof ProjectCreateUpdate, e.target.value);
    };

    const handleOnAddKeywordButtonClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        const currentKeyword = getValues('keyword');
        const noError = (await trigger('keyword')) && !projectCreate?.keywords?.find((item: string) => item.toLowerCase() === currentKeyword.toLowerCase());
        if (currentKeyword !== '' && noError) {
            const updatedKeywords = projectCreate.keywords ? [...(projectCreate.keywords as string[]), currentKeyword] : [currentKeyword];
            onBlur?.('keywords' as keyof ProjectCreateUpdate, updatedKeywords);
            clearErrors('keyword');
            setValue('keyword', undefined);
        } else if (projectCreate?.keywords?.find((item: string) => item.toLowerCase() === currentKeyword.toLowerCase())) {
            setError('keyword', { type: 'keywordlrdxst' });
        }
    };

    const handleOnKeywordRemoveClick = (keywordToBeRemoved: string) => {
        if (projectCreate.keywords) {
            const updatedKeywords: any[] = projectCreate.keywords.filter(currentKeyword => currentKeyword !== keywordToBeRemoved);
            onBlur?.('keywords' as keyof ProjectCreateUpdate, updatedKeywords);
        }
    };

    const triggerValidation = (key: string, value: string) => {
        setValue(key, value);
        (isSubmitted || key === 'keyword') && trigger(key);
    };

    const handleCategoryChange = async (newValues: Array<string>) => {
        onBlur?.('categories' as keyof ProjectCreateUpdate, newValues);
        if (newValues.length > 0) {
            clearErrors('categories');
        } else {
            setError('categories', { type: 'isNotEmpty' });
        }
    };

    const handleUnselectAll = async (e?: React.MouseEvent<HTMLButtonElement>) => {
        e && e.preventDefault();
        projectManager.resetCategoryCreate();
        await projectManager.getProjectCategories();
    };

    useEffect(() => {
        clearErrors(['name', 'version', 'description', 'keyword']);
        const fieldNames = ['name', 'version'];
        if (isModalClosed) {
            fieldNames.map((item: string) => setValue(item, ''));
            setValue('description', project?.description);
        }
        setIsSubmitted(false);
    }, [isModalClosed]);

    const { errors, register, trigger, setValue, reset, clearErrors, setError, getValues } = FormManager.getInstance().buildFormProxy<ProjectCloneFormData>(
        ProjectCloneFormData,
        onSubmit,
        {
            description: project?.description,
        },
    );
    const translator = TranslatorManager.getInstance();
    return (
        <div className='container-fluid mt-4'>
            <div className='row pb-1 mb-4'>
                <div className='col-12 col-sm-6'>
                    <h6 className='project-clone'>{translator.get('project.modal.clone.data.new.title')}</h6>
                </div>
                <div className='col-6 mb-md-4'>
                    <h6 className='data-project-clone'>{translator.get('project.modal.clone.data.old.title')}</h6>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-12 col-md-6 mb-4'>
                    <div className='form-group m-0'>
                        <label htmlFor='name' className='active d-flex flex-row label-color align-items-center'>
                            {translator.get('project.modal.clone.new.title.label')}
                            <p className='requiredSign'>*</p>
                            <StyledTooltip trigger='hover' position='right' labelKey={translator.get('project.modal.create.title.tooltip')}>
                                <div className='tooltip-icon-container'>?</div>
                            </StyledTooltip>
                        </label>
                        <TextField
                            type='text'
                            className='form-control'
                            maxLength={40}
                            placeholder={translator.get('project.modal.clone.new.title.placeholder')}
                            {...register('name', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('name', e.target.value) })}
                            error={errors.name}
                            errorMessage={errors.name && getMessageByFieldAndError('name', errors)}
                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                        />
                    </div>
                </div>
                <div className='col-12 col-md-5 mb-4 old-info-container'>
                    <label className='label'>{translator.get('project.modal.clone.old.title.label')}</label>
                    <p className='old-info-paragraph'>{project?.name}</p>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12 col-md-6 mb-4'>
                    <div className='form-group m-0'>
                        <label htmlFor='version' className='active d-flex flex-row label-color align-items-center'>
                            {translator.get('project.modal.clone.version.label')}
                            <p className='requiredSign'>*</p>
                            <StyledTooltip trigger='hover' position='right' labelKey={translator.get('project.modal.create.version.tooltip')}>
                                <div className='tooltip-icon-container'>?</div>
                            </StyledTooltip>
                        </label>
                        <TextField
                            type='text'
                            className='form-control'
                            maxLength={20}
                            placeholder={translator.get('project.modal.clone.new.version.placeholder')}
                            {...register('version', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('version', e.target.value) })}
                            error={errors.version}
                            errorMessage={errors.version && getMessageByFieldAndError('version', errors)}
                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                        />
                    </div>
                </div>
                <div className='col-12 col-md-5 mb-4 old-info-container'>
                    <label className='label'>{translator.get('project.modal.clone.version.label')}</label>
                    <p className='old-info-paragraph'>{project?.version}</p>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12 col-md-6 mb-4'>
                    <div className='form-group m-0'>
                        <div className='select-wrapper'>
                            <label htmlFor='technology' className='active d-flex flex-row label-color'>
                                {translator.get('project.modal.clone.language.label')}
                                <StyledTooltip trigger='hover' position='right' labelKey={translator.get('project.modal.create.technology.tooltip')}>
                                    <div className='tooltip-icon-container'>?</div>
                                </StyledTooltip>
                            </label>
                            <div>
                                <select name='technology' className='select-container' disabled>
                                    <option
                                        value={
                                            templates?.find((item: BaseProjectTemplate) => {
                                                return item.id == project?.technology;
                                            })?.id
                                        }
                                        selected>
                                        {
                                            templates?.find((item: BaseProjectTemplate) => {
                                                return item.id == project?.technology;
                                            })?.name
                                        }
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-5 mb-4 old-info-container'>
                    <label className='label'>{translator.get('project.modal.clone.language.label')}</label>
                    <p className='old-info-paragraph'>
                        {
                            templates?.find((item: BaseProjectTemplate) => {
                                return item.id == project?.technology;
                            })?.name
                        }
                    </p>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12 col-md-6 mb-4'>
                    <div className='form-group m-0'>
                        <label htmlFor='creator' className='active label-color'>
                            {translator.get('project.modal.clone.creator.label')}
                        </label>
                        <TextField
                            type='text'
                            className='form-control'
                            placeholder={translator.get('project.modal.clone.new.creator.placeholder')}
                            name='creator'
                            value={username}
                            readOnly
                        />
                    </div>
                </div>
                <div className='col col-12 col-md-5 old-info-container mb-4'>
                    <label className='label'>{translator.get('project.modal.clone.creator.label')}</label>
                    <p className='old-info-paragraph'>{project?.author?.name}</p>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12 col-md-6 mb-4 category-index'>
                    <div className='form-group'>
                        <div className='select-wrapper'>
                            <label htmlFor='categories' className='active d-flex flex-row align-items-center label-color'>
                                {translator.get('project.modal.clone.new.category.label')}
                                <p className='requiredSign'>*</p>
                                <StyledTooltip trigger='hover' position='right' labelKey={translator.get('project.modal.create.category.tooltip')}>
                                    <div className='tooltip-icon-container'>?</div>
                                </StyledTooltip>
                            </label>
                            <div>
                                <SelectMultipleComponent
                                    placeholder={translator.get('service.filterbar.category.placeholder')}
                                    options={availableCategoryOptions as string[]}
                                    value={projectCreate.categories}
                                    {...register('categories')}
                                    onChange={handleCategoryChange}
                                    availableValues={availableCategoryOptions?.filter(option => option !== 'ALL') as Array<string>}
                                    handleUnselectAll={handleUnselectAll}
                                />
                            </div>
                            {errors.categories && <small className='form-text error-message'>{translator.get(`form.project.create.category.${errors.categories.type}`)}</small>}
                        </div>
                    </div>
                </div>
                <div className='col col-12 col-md-5 old-info-container mb-4'>
                    <label className='label'>{translator.get('project.modal.clone.old.category.label')}</label>
                    <p className='multiple-select-form-control white-space-line'>{project?.categories ? project?.categories.join(', ') : '-'}</p>
                </div>
            </div>

            <div className='row mt-4 mb-4'>
                <div className='col-12 col-md-6 mb-4'>
                    <div className='form-group m-0'>
                        <label htmlFor='description' className='active d-flex flex-row align-items-center label-color'>
                            {translator.get('project.modal.clone.abstract.label')}
                            <p className='requiredSign'>*</p>
                            <StyledTooltip trigger='hover' position='right' labelKey={translator.get('project.modal.create.abstract.tooltip')}>
                                <div className='tooltip-icon-container'>?</div>
                            </StyledTooltip>
                        </label>
                        <TextArea
                            className='textarea-description-container'
                            //defaultValue={projectCreate.description}
                            {...register('description', {
                                onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => {
                                    handleOnBlur(e as any);
                                    triggerValidation('description', e.target.value);
                                },
                            })}
                            placeholder={translator.get('project.modal.clone.abstract.placeholder')}
                            onBlur={(e: React.FocusEvent<HTMLTextAreaElement, Element>) => handleOnBlur(e)}
                        />
                        {errors.description && <small className='form-text error-message'>{translator.get(`form.project.clone.description.${errors.description.type}`)}</small>}
                    </div>
                </div>
                <div className='col col-12 col-md-5 old-info-container margin-old'>
                    <label className='label margin-old-description'>{translator.get('project.modal.clone.abstract.label')}</label>
                    <div className='box mb-5'>
                        <p className='old-info-paragraph'> {`${project?.description}`}</p>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12 col-md-6 mb-4 mt-3'>
                    <div className='form-group'>
                        <label htmlFor='keywords' className='active d-flex flex-row align-items-center label-color'>
                            {translator.get('project.modal.clone.keywords.label')}
                            <p className='requiredSign'>*</p>
                            <StyledTooltip trigger='hover' position='right' labelKey={translator.get('project.modal.create.keywords.tooltip')}>
                                <div className='tooltip-icon-container'>?</div>
                            </StyledTooltip>
                        </label>
                        {projectCreate.keywords &&
                            projectCreate.keywords.map((keyword, index) => {
                                return (
                                    <div className='row' key={index}>
                                        <div className='col-12'>
                                            <div className='keyword-container pl-3'>
                                                {keyword}
                                                <button
                                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnKeywordRemoveClick(keyword)}
                                                    className='btn btn-primary text-white btn-remove'>
                                                    <div className='d-flex'>
                                                        <Close className='icon' fill='white' />
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        <TextField
                            type='text'
                            className='form-control'
                            placeholder='Keywords'
                            id='keyword'
                            {...register('keyword', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('keyword', e.target.value) })}
                            error={errors.keyword}
                            errorMessage={errors.keyword && getMessageByFieldAndError('keyword', errors)}
                        />

                        <div className='col-12 mb-5 mt-2'>
                            <button onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnAddKeywordButtonClick(e)} className='btn btn-primary text-white'>
                                {translator.get('project.modal.create.add.keyword.button.text')}
                            </button>
                        </div>
                    </div>
                </div>
                <div className='col-12 col-md-4 old-info-container'>
                    <label className='label'>{translator.get('project.modal.clone.keywords.label')}</label>
                    <p className='old-info-paragraph'>{project?.keywords ? project?.keywords.join(', ') : ' - '}</p>
                </div>
            </div>
            {/* <div className='row mb-3'>
                <div className='col-12 col-lg-6'>
                    <h6 className='additional-info'>{translator.get('project.modal.clone.additional.metadata.title')}</h6>
                    <div className='modal-additional-info-container d-flex justify-content-end'>
                        <button
                            className='btn btn-outline-secondary button-plus'
                            type='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#plus'
                            aria-expanded='true'
                            aria-controls='plus'>
                            <Plus className='plus-container icon' />
                            <Minus className='minus-container icon' />
                        </button>
                    </div>
                </div>
                <div className='col-6 mb-md-4 mb-4'>
                    <h6 className='old-additional-info'>{translator.get('project.modal.clone.additional.metadata.title')}</h6>
                </div>
            </div>
            <div id='plus' className='collapse' role='region' aria-labelledby='heading1c'>
                <div className='row'>
                    <div className='col-12 col-md-6'>
                        <div className='form-group'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <label htmlFor='metadata' className='active metadata label-color'>
                                        {translator.get('project.modal.clone.metadata.label')}
                                    </label>
                                </div>
                            </div>
                            <TextField type='text' className='form-control' id='metadata' placeholder='Keywords' name='metadata' />
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12 col-md-6 value-container'>
                        <div className='form-group'>
                            <label htmlFor='value' className='active label-color'>
                                {translator.get('project.modal.clone.value.label')}
                            </label>
                            <TextArea
                                className='text-value'
                                id='value'
                                name='metadataValue'
                                placeholder='Lorem ipsum'
                                onBlur={(e: React.FocusEvent<HTMLTextAreaElement, Element>) => handleOnBlur(e)}
                            />
                        </div>
                    </div>
                </div>
                <div className='row mt-3'>
                    <div className='col-12 col-md-6 value-container mb-4'>
                        <h6 className='metadata-list-header'>{translator.get('project.modal.clone.metadata.table.title')}</h6>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('project.modal.clone.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('project.modal.clone.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('project.modal.clone.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('project.modal.clone.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                    </div>
                    <div className='col col-12 col-md-6'>
                        <h6 className='metadata-list-header'>{translator.get('project.modal.clone.metadata.table.title')}</h6>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('project.modal.clone.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('project.modal.clone.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('project.modal.clone.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('project.modal.clone.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                    </div>
                </div>
            </div>*/}
            <div className='row'>
                <div className='col-12 mt-4 mb-3'>
                    <button className='btn btn-primary text-white w-100' onClick={(e: React.MouseEvent<HTMLButtonElement>) => onSubmit(e)}>
                        {translator.get('project.modal.clone.confirm.button.text')}
                    </button>
                </div>
            </div>
        </div>
    );
};
