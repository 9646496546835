import { ServiceControllerApi } from '../../../api/controllers/service-controller-api';

export interface IServiceApiClient {
    service: ServiceControllerApi;
}

export class ServiceApiClients {
    private static instance: IServiceApiClient;

    static getInstance(basePath: string): IServiceApiClient {
        if (ServiceApiClients.instance == null) {
            ServiceApiClients.instance = {
                service: new ServiceControllerApi(undefined, basePath),
            };
        }
        return ServiceApiClients.instance;
    }
}
