import { ProvisioningRequirementsData } from '@features/provisioning/beans/provisioningRequirementsData';
import {
    ProvisioningResetRequirements,
    ProvisioningResetSteps,
    ProvisioningSetActiveStep,
    ProvisioningSetCurrentPage,
    ProvisioningSetOrigin,
    ProvisioningSetRequirements,
    ProvisioningSetSteps,
} from '@features/provisioning/store/actions';
import { ProvisioningPageEnum } from '@features/provisioning/store/state';
import { ProvisioningOriginEnum } from '@features/provisioning/views/provisioning.view';
import { StoreManager } from '@hypereact/state';

export interface IProvisioningManagerProps {
    setActiveStep: (activeStep: string) => void;
}

export class ProvisioningManager implements IProvisioningManagerProps {
    private static instance: ProvisioningManager;
    private storeManager: StoreManager;

    private constructor() {
        this.storeManager = StoreManager.getInstance();
    }

    static getInstance() {
        if (ProvisioningManager.instance == null) {
            ProvisioningManager.instance = new ProvisioningManager();
        }
        return ProvisioningManager.instance;
    }
    setActiveStep(activeStep: string) {
        this.storeManager.dispatch(new ProvisioningSetActiveStep(activeStep));
    }
    setSteps(steps: Array<string>) {
        this.storeManager.dispatch(new ProvisioningSetSteps(steps));
    }

    resetSteps() {
        this.storeManager.dispatch(new ProvisioningResetSteps());
    }

    setCurrentPage(page: ProvisioningPageEnum) {
        this.storeManager.dispatch(new ProvisioningSetCurrentPage(page));
    }

    setOrigin(origin: ProvisioningOriginEnum) {
        this.storeManager.dispatch(new ProvisioningSetOrigin(origin));
    }

    setRequirements(key: keyof ProvisioningRequirementsData, value: boolean) {
        this.storeManager.dispatch(new ProvisioningSetRequirements(key, value));
    }

    resetRequirements() {
        this.storeManager.dispatch(new ProvisioningResetRequirements());
    }
}
