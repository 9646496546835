import { TranslatorManager } from '@managers/TranslatorManager';
import React from 'react';
import '../styles/provisioning.publication.component.style.scss';

export interface IProvisioningPublicationComponentProps {}

export const ProvisioningPublicationComponent: React.FC<IProvisioningPublicationComponentProps> = () => {
    const translator = TranslatorManager.getInstance();
    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12'>
                        <div className='d-flex flex-column'>
                            <p className='thank-you-container text-center'>{translator.get('provisioning.publication.thank.you.text')}</p>
                            <p className='request-received-title text-center'>{translator.get('provisioning.publication.request.received.title.text')}</p>
                            <div className='d-flex justify-content-center'>
                                <p className='request-received-paragraph text-center'>{translator.get('provisioning.publication.request.received.paragraph.text')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
