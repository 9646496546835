import { StatisticsControllerApi } from '../../../api';

export interface IHomepageApiClient {
    statistics: StatisticsControllerApi;
}

export class HomepageApiClients {
    private static instance: IHomepageApiClient;

    static getInstance(basePath: string): IHomepageApiClient {
        if (HomepageApiClients.instance == null) {
            HomepageApiClients.instance = {
                statistics: new StatisticsControllerApi(undefined, basePath),
            };
        }
        return HomepageApiClients.instance;
    }
}
