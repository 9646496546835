import { PersistentReduceableReducer } from '@hypereact/state';
import { LoginState } from './state';

export class LoginReducer extends PersistentReduceableReducer<LoginState> {
    dehydrate(state: LoginState): LoginState | null {
        return state;
    }

    rehydrate(state: LoginState, data: LoginState): LoginState {
        return {
            ...state,
            ...data,
        };
    }
}
