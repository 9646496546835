import { PersistentReduceableReducer } from '@hypereact/state';
import { ProjectState } from './state';

export class ProjectReducer extends PersistentReduceableReducer<ProjectState> {
    dehydrate(state: ProjectState): ProjectState | null {
        return state;
    }

    rehydrate(state: ProjectState, data: ProjectState): ProjectState {
        return {
            ...state,
            ...data,
        };
    }
}
