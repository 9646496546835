/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface ProjectSearchFilter
 */
export interface ProjectSearchFilter {
    /**
     *
     * @type {string}
     * @memberof ProjectSearchFilter
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectSearchFilter
     */
    status?: ProjectSearchFilterStatusEnum;
    /**
     *
     * @type {string}
     * @memberof ProjectSearchFilter
     */
    creatorName?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectSearchFilter
     */
    categories?: string;
    /**
     *
     * @type {string}
     * @memberof ProjectSearchFilter
     */
    keywords?: string;
    /**
     *
     * @type {boolean}
     * @memberof ProjectSearchFilter
     */
    published?: boolean;
}

export const ProjectSearchFilterStatusEnum = {
    ALL: 'ALL',
    READY: 'READY',
    RUNNING: 'RUNNING',
    STOPPED: 'STOPPED',
    LOADING: 'LOADING',
    STOPPING: 'STOPPING',
    TO_BE_ARCHIVED: 'TO_BE_ARCHIVED',
    TO_BE_DELETED: 'TO_BE_DELETED',
    ARCHIVED: 'ARCHIVED',
    DELETED: 'DELETED',
    FAILED: 'FAILED',
    FORKING: 'FORKING',
} as const;

export type ProjectSearchFilterStatusEnum = (typeof ProjectSearchFilterStatusEnum)[keyof typeof ProjectSearchFilterStatusEnum];
