import React from 'react';
import './style/loader.component.style.scss';

interface Props {
    isLoading: boolean;
}

const Loader: React.FC<Props> = ({ isLoading }) => {
    return isLoading ? <div className='loader'></div> : null;
};

export default Loader;
