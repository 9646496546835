import React from 'react';
import { PopupManager } from '../../../../_shared/managers/popup.manager';
import { ReduxConnect } from '@hypereact/state';
import { RootState } from '../../../../_shared/store/state';

interface PopupContainerProps {
    isVisible?: boolean;
}
@ReduxConnect((state: RootState) => {
    return {
        isVisible: state.shared.popup.isVisible,
    };
})
class Popup extends React.Component<PopupContainerProps> {
    private popupManager: PopupManager;

    constructor(props: any) {
        super(props);
        this.popupManager = PopupManager.getInstance();
    }

    render() {
        return this.props.isVisible ? this.popupManager.get() : null;
    }
}

export default Popup;
