import { IReduceableAction, ReduxAction } from '@hypereact/state';
import { Slices } from '@store/state';
import { ResponseStatistics, WebService } from 'src/api';
import { Project } from 'src/api/entities/project';
import { HomeState } from './state';

@ReduxAction('HOME_SET_PROJECTS', Slices.Home)
export class HomeSetProjects implements IReduceableAction<HomeState> {
    constructor(public projects: Array<Project>) {}
    reduce(state: HomeState) {
        state.publishedProjects = this.projects || [];
        return state;
    }
}

@ReduxAction('HOME_SET_SERVICES', Slices.Home)
export class HomeSetServices implements IReduceableAction<HomeState> {
    constructor(public services: Array<WebService>) {}
    reduce(state: HomeState) {
        state.publishedServices = this.services || [];
        return state;
    }
}

@ReduxAction('HOME_SET_STATISTICS', Slices.Home)
export class HomeSetStatistics implements IReduceableAction<HomeState> {
    constructor(public statistics: ResponseStatistics) {}
    reduce(state: HomeState) {
        state.statistics.launchedExecutionsCount = this.statistics.launchedExecutionsCount;
        state.statistics.projectsCount = this.statistics.projectsCount;
        state.statistics.publishedProjectsCount = this.statistics.publishedProjectsCount;
        state.statistics.publishedServicesCount = this.statistics.publishedServicesCount;
        state.statistics.servicesCount = this.statistics.servicesCount;
        state.statistics.sessionsCount = this.statistics.sessionsCount;
        return state;
    }
}
