/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 *
 * @export
 * @interface WebServiceSearchFilter
 */
export interface WebServiceSearchFilter {
    /**
     *
     * @type {string}
     * @memberof WebServiceSearchFilter
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof WebServiceSearchFilter
     */
    status?: WebServiceSearchFilterStatusEnum;
    /**
     *
     * @type {string}
     * @memberof WebServiceSearchFilter
     */
    creatorName?: string;
    /**
     *
     * @type {string}
     * @memberof WebServiceSearchFilter
     */
    categories?: string;
    /**
     *
     * @type {string}
     * @memberof WebServiceSearchFilter
     */
    keywords?: string;
    /**
     *
     * @type {boolean}
     * @memberof WebServiceSearchFilter
     */
    approved?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof WebServiceSearchFilter
     */
    published?: boolean;
}

export const WebServiceSearchFilterStatusEnum = {
    ALL: 'ALL',
    DRAFT: 'DRAFT',
    TO_BE_PUBLISHED: 'TO_BE_PUBLISHED',
    TO_BE_APPROVED: 'TO_BE_APPROVED',
    PUBLISHED: 'PUBLISHED',
    REJECTED: 'REJECTED',
    TO_BE_DELETED: 'TO_BE_DELETED',
    DELETED: 'DELETED',
} as const;

export type WebServiceSearchFilterStatusEnum = (typeof WebServiceSearchFilterStatusEnum)[keyof typeof WebServiceSearchFilterStatusEnum];
