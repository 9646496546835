import { IReduceableAction, ReduxAction } from '@hypereact/state';
import { Slices } from '@store/state';
import { OutputFile, ResponseServiceExecutionMinisite, ResponseServiceExecutionMinisiteStatusEnum } from '../../../api';
import { MinisiteState } from './state';

//TODO: update ResponseServiceExecutionMinisite with ServiceExecutionMinisite if possible
@ReduxAction('MINISITE_SET_SELECTED_EXECUTION', Slices.Minisite)
export class MinisiteSetSelectedExecution implements IReduceableAction<MinisiteState> {
    constructor(public execution: ResponseServiceExecutionMinisite) {}
    reduce(state: MinisiteState) {
        state.execution.selectedExecution = this.execution;
        return state;
    }
}

@ReduxAction('MINISITE_SET_OUTPUTS', Slices.Minisite)
export class MinisiteSetOutputs implements IReduceableAction<MinisiteState> {
    constructor(public outputs: Array<OutputFile>) {}
    reduce(state: MinisiteState) {
        state.output.outputs = this.outputs;
        return state;
    }
}

@ReduxAction('MINISITE_SET_EXECUTION_LOGS', Slices.Minisite)
export class MinisiteSetExecutionLogs implements IReduceableAction<MinisiteState> {
    constructor(public logs: string) {}
    reduce(state: MinisiteState) {
        state.execution.log = this.logs;
        return state;
    }
}

@ReduxAction('MINISITE_RESET_EXECUTION_LOGS', Slices.Service)
export class MinisiteResetExecutionLogs implements IReduceableAction<MinisiteState> {
    reduce(state: MinisiteState) {
        state.execution.log = undefined;
        return state;
    }
}

@ReduxAction('MINISITE_RESET_EXECUTION_HTML', Slices.Service)
export class MinisiteResetExecutionHtml implements IReduceableAction<MinisiteState> {
    reduce(state: MinisiteState) {
        state.execution.html = undefined;
        return state;
    }
}

@ReduxAction('MINISITE_SET_EMAIL', Slices.Minisite)
export class MinisiteSetEmail implements IReduceableAction<MinisiteState> {
    constructor(public email: string) {}
    reduce(state: MinisiteState) {
        state.execution.email = this.email;
        return state;
    }
}

@ReduxAction('MINISITE_RESET_EMAIL', Slices.Minisite)
export class MinisiteReSetEmail implements IReduceableAction<MinisiteState> {
    constructor() {}
    reduce(state: MinisiteState) {
        state.execution.email = undefined;
        return state;
    }
}

@ReduxAction('MINISITE_SET_STATUS', Slices.Minisite)
export class MinisiteSetStatus implements IReduceableAction<MinisiteState> {
    constructor(public status: ResponseServiceExecutionMinisiteStatusEnum) {}
    reduce(state: MinisiteState) {
        state.execution.selectedExecution!.status = this.status;
        return state;
    }
}
