import { TokenResponse } from '../../../api';

export enum UserRoleEnum {
    Admin = 'ADMIN',
    User = 'USER',
}
export interface LoginState {
    email?: string;
    role?: UserRoleEnum;
    userId?: string;
    isLogged: boolean;
    firstName?: string;
    lastName?: string;
    tokenResponse?: TokenResponse;
}

export const initialLoginState: LoginState = {
    email: undefined,
    role: undefined,
    userId: undefined,
    isLogged: false,
};

//TODO replace these entities with BE entities

export interface User {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    role: UserRoleEnum;
    password: string;
    tokenResponse?: TokenResponse;
}
