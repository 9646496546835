import { TextField } from '@features/_shared/components/TextField';
import { FormManager } from '@managers/form.manager';
import { MinisiteManager } from '@managers/minisite.manager';
import { NavigationManager } from '@managers/navigation.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { ServiceManager } from '@managers/service.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import KeycloakUtil from '@utils/keycloak.util';
import React, { useEffect, useState } from 'react';
import { ServiceExecution, WebService } from 'src/api';
import * as tus from 'tus-js-client';
import { PreviousUpload } from 'tus-js-client';
import { ServiceInputUploadData } from '../beans/ServiceInputUploadData';

export interface LocalInputUploadComponentProps {
    service: WebService;
    execution?: ServiceExecution;
    isClosed: boolean;
    onUpload(): void;
    filename: string;
    token?: string;
}

export const ServiceLocalInputUploadComponent: React.FC<LocalInputUploadComponentProps> = ({ service, execution, isClosed, onUpload, filename, token }) => {
    const minisiteManager = MinisiteManager.getInstance();
    const serviceManager = ServiceManager.getInstance();

    const [showInputField, setShowInputField] = useState<boolean>(true);
    const [fileName, setFileName] = useState<string>('');
    const [isUploadEnabled, setIsUploadEnabled] = useState<boolean>(false);

    const translator = TranslatorManager.getInstance();

    const { register, getValues, setValue } = FormManager.getInstance().buildFormProxy<ServiceInputUploadData>(ServiceInputUploadData, () => {});

    useEffect(() => {
        if (isClosed) {
            setShowInputField(true);
        }
    }, [isClosed, showInputField]);

    const handleSelectLocalInput = async () => {
        let retryCount = 0;
        var file = getValues('file')[0];
        const createUploadInstance = () => {
            return new tus.Upload(file, {
                endpoint: `${window.location.origin}/dashboard/api/${token ? 'minisite' : 'service'}/input`,
                retryDelays: [0, 3000, 5000, 10000, 20000],
                metadata:
                    NavigationManager.getInstance().getCurrentRoute() === '/provisioning/templates'
                        ? {
                              filename: file.name,
                              filetype: file.type,
                              slotname: filename,
                              //executionid: execution?.id as string,
                          }
                        : {
                              filename: file.name,
                              filetype: file.type,
                              slotname: filename,
                              executionid: execution?.id as string,
                          },
                chunkSize: 104857600, //100MB
                removeFingerprintOnSuccess: true,
                headers:
                    NavigationManager.getInstance().getCurrentRoute() === '/provisioning/templates'
                        ? {
                              slotname: filename,
                              serviceid: service.id as string,
                              slotsize: file.size as string,
                              template: NavigationManager.getInstance().getCurrentRoute() === '/provisioning/templates' ? file.name : '',
                              Authorization: KeycloakUtil.isLoggedIn() ? `Bearer ${KeycloakUtil.getToken()}` : '',
                          }
                        : {
                              slotname: filename,
                              serviceid: service.id as string,
                              executionid: execution?.id as string,
                              slotsize: file.size as string,
                              Authorization: KeycloakUtil.isLoggedIn() ? `Bearer ${KeycloakUtil.getToken()}` : '',
                          },
                onError: function (error) {
                    if (error.message.includes('401') && retryCount < 1) {
                        retryCount++;
                        upload.options.headers = {
                            ...upload.options.headers,
                            Authorization: `Bearer ${KeycloakUtil.getToken()}`,
                        };
                        upload.start();
                        retryCount = 0;
                    } else {
                        console.log('Failed because: ' + error);
                    }
                },
                onChunkComplete: async function () {
                    if (token) {
                        let [header, payload, signature] = token.split('.');
                        await minisiteManager.getExecutionByServiceByIdAndId(header, payload, signature);
                    } else {
                        NavigationManager.getInstance().getCurrentRoute() !== '/provisioning/templates' &&
                            (await serviceManager.findExecution(service.id as string, execution?.id as string));
                    }
                },
                onSuccess: function () {
                    console.log('Download %s from %s', upload.file, upload.url);
                },
            });
        };
        let upload = createUploadInstance();
        // Check if there are any previous uploads to continue.
        upload.findPreviousUploads().then(function (previousUploads) {
            // Found previous uploads so we select the first one.
            let prvUpload = previousUploads.find((item: any) => item.metadata.slotname === filename);
            if (previousUploads && previousUploads.length > 0 && prvUpload) {
                upload.resumeFromPreviousUpload(prvUpload as PreviousUpload);
            }
            // Start the upload
            upload.start();
        });
        onUpload?.();
        setShowInputField(false);
        setFileName('');
        setIsUploadEnabled(false);

        if (token) {
            let [header, payload, signature] = token.split('.');
            service.id && execution?.id && QueueManager.getInstance().queue(QueueKeyType.DEFAULT, minisiteManager.getExecutionByServiceByIdAndId(header, payload, signature));
        } else {
            NavigationManager.getInstance().getCurrentRoute() !== '/provisioning/templates' &&
                service.id &&
                execution?.id &&
                QueueManager.getInstance().queue(QueueKeyType.DEFAULT, ServiceManager.getInstance().findExecution(service.id, execution.id));
        }
    };

    const enableUpload = () => {
        getValues('file') ? setIsUploadEnabled(true) : setIsUploadEnabled(false);
    };

    return (
        <>
            {showInputField && (
                <div>
                    <div className='form-group'>
                        <TextField type='file' className='form-control' id='file' {...register('file', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => enableUpload() })} />
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button className='btn btn-primary text-white d-flex align-items-center' disabled={!isUploadEnabled} onClick={handleSelectLocalInput}>
                            {translator.get('service.modal.upload.input.upload.button')}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};
