import { TranslatorManager } from '@managers/TranslatorManager';
import React from 'react';
import { RowComponent } from './row.component';
import './styles/table.style.scss';
export interface Row<T = string | number | boolean | JSX.Element | Date | undefined> {
    id: string | number;
    values: {
        [key: string]: T;
    };
    collapsedRow?: JSX.Element;
}
export interface Props {
    columns: string[];
    rows: Row[] | any;
    keyLabel: string;
    selectedId?: string | number;
    isStyledHeaders?: boolean;
    isUsingPagination?: boolean;
    onRowClick?(id: string): void;
    handleRowExpansion?: (e: any) => void;
}
export const Table: React.FC<Props> = ({ columns, rows, isStyledHeaders, keyLabel, onRowClick, handleRowExpansion }) => {
    const handleRowClick = (id: string) => {
        onRowClick?.(id);
    };

    return (
        <>
            <table className='table table-container'>
                <thead>
                    <tr className={`table-container ${isStyledHeaders && 'styledHeaders'}`}>
                        {columns.map((column = '', index) => (
                            <th className='table-size-title table-padding' scope='col' key={index}>
                                {column}
                            </th>
                        ))}
                    </tr>
                </thead>

                {rows?.length && rows?.length > 0 ? (
                    rows?.map((row: Row, index: number) => <RowComponent row={row} index={index} onRowClick={handleRowClick} key={index} handleRowExpansion={handleRowExpansion} />)
                ) : (
                    <tr className='empty-row'>
                        <td colSpan={columns.length} key={0}>
                            <div className='d-flex justify-content-center'>
                                <h6 style={{ fontWeight: 'normal' }}>{TranslatorManager.getInstance().get(`table.${keyLabel}.empty.label`)}</h6>
                            </div>
                        </td>
                    </tr>
                )}
            </table>
        </>
    );
};
export default Table;
