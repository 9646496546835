import { RequestBehubIntegrationPublishDataset } from 'src/api/entities/request-behub-integration-publish-dataset';
import { ResponseBehubIntegrationPublishDataset } from 'src/api/entities/response-behub-integration-publish-dataset';
import { BehubIntegrationApiClients, IBehubIntegrationApiClient } from './behub-integration.api.client';

export interface IBehubIntegrationApiService {
    publishDataset(
        projectId: string,
        target: 'INPUT' | 'OUTPUT',
        fileName: string,
        requestBehubIntegrationPublishDataset: RequestBehubIntegrationPublishDataset,
    ): Promise<ResponseBehubIntegrationPublishDataset>;
}

export class BehubIntegrationApiService implements IBehubIntegrationApiService {
    private static instance: BehubIntegrationApiService;
    private api: IBehubIntegrationApiClient;

    private constructor(basePath: string) {
        this.api = BehubIntegrationApiClients.getInstance(basePath);
    }

    static getInstance(basePath: string) {
        if (BehubIntegrationApiService.instance == null) {
            BehubIntegrationApiService.instance = new BehubIntegrationApiService(basePath);
        }
        return BehubIntegrationApiService.instance;
    }

    async publishDataset(
        projectId: string,
        target: 'INPUT' | 'OUTPUT',
        fileName: string,
        requestBehubIntegrationPublishDataset: RequestBehubIntegrationPublishDataset,
    ): Promise<ResponseBehubIntegrationPublishDataset> {
        return (await this.api.behub.publishDataset(projectId, target, fileName, requestBehubIntegrationPublishDataset)).data;
    }
}
