import { MinisiteControllerApi } from '../../../api/controllers/minisite-controller-api';

export interface IMinisiteApiClient {
    minisite: MinisiteControllerApi;
}

export class MinisiteApiClients {
    private static instance: IMinisiteApiClient;

    static getInstance(basePath: string): IMinisiteApiClient {
        if (MinisiteApiClients.instance == null) {
            MinisiteApiClients.instance = {
                minisite: new MinisiteControllerApi(undefined, basePath),
            };
        }
        return MinisiteApiClients.instance;
    }
}
