import { ReactComponent as Invisible } from '@assets/svg/it-password-invisible.svg';
import { ReactComponent as Visible } from '@assets/svg/it-password-visible.svg';
import { ReactComponent as Search } from '@assets/svg/it-search.svg';
import React, { InputHTMLAttributes, useState } from 'react';
import './styles/textfield.components.style.scss';

export interface TextFieldProps extends InputHTMLAttributes<HTMLInputElement> {
    isSearchable?: boolean;
    fullWidth?: boolean;
    error?: { [x: string]: any };
    errorMessage?: string;
}

const TextField: React.FC<TextFieldProps> = React.forwardRef(({ isSearchable = false, type = 'text', error, name, errorMessage, ...props }, ref) => {
    const [showPassword, setShowPassword] = useState(false);
    const { className } = props;
    return (
        <>
            <div className='d-flex flex-row input-container'>
                <input {...props} ref={ref as React.MutableRefObject<HTMLInputElement>} name={name} className={`${className} form-control`} type={showPassword ? 'text' : type} />
                {isSearchable && type !== 'password' && <Search className='text-field-svg-container' fill='#009138' />}
                {type === 'password' && (
                    <span
                        onClick={() => {
                            setShowPassword((prev: boolean) => !prev);
                        }}
                        className='password-icon'
                        aria-hidden='true'>
                        {showPassword ? <Invisible className='password-icon-visible icon icon-sm' /> : <Visible className='password-icon-invisible icon icon-sm ' />}
                    </span>
                )}
            </div>
            {error && <small className='form-text error-message'>{errorMessage}</small>}
        </>
    );
});

export default TextField;
