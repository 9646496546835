import { ReactComponent as Chat } from '@assets/svg/it-comment.svg';
import { ProvisioningOriginEnum } from '@features/provisioning/views/provisioning.view';
import { TranslatorManager } from '@managers/TranslatorManager';
import { ReactComponent as Close } from '@assets/svg/it-close.svg';
import { NavigationManager } from '@managers/navigation.manager';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';
import { BaseProjectTemplate, Project, WebService } from '../../../../api/entities';

export interface HeaderProps {
    project?: Project;
    service?: WebService;
    templates: Array<BaseProjectTemplate>;
    handleClickClose?: () => void;
    origin?: ProvisioningOriginEnum;
}

export enum SaveInfoTypeEnum {
    ABSTRACT = 'ABSTRACT',
    KEYWORDS = 'KEYWORDS',
    METADATA = 'METADATA',
}

const Header: React.FC<HeaderProps> = ({ project, service, templates, handleClickClose, origin }) => {
    const translator = TranslatorManager.getInstance();
    const navigationManager = NavigationManager.getInstance();

    return (
        <div className={`container-fluid container-gray mb-3`}>
            {project &&
                (origin === ProvisioningOriginEnum.PROJECT || origin === ProvisioningOriginEnum.PROJECTDETAIL || navigationManager.getCurrentRoute().includes('/project')) && (
                    <>
                        <div className='row justify-content-between'>
                            <div className='col-7 d-flex flex-row align-item-center header-flex'>
                                <div className='d-flex flex-row align-items-center status-chip-position'>
                                    {KeycloakUtil.isLoggedIn() && (
                                        <div className='chip chip-simple bg-secondary m-1'>
                                            <span className='chip-label text-white mt-1'>{project?.status?.replace(/_/g, ' ')}</span>
                                        </div>
                                    )}
                                </div>
                                {KeycloakUtil.isLoggedIn() && KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) && (
                                    <p className='m-2 d-flex align-items-center'>{`${translator.get('header.entity.id')}: ${project?.id}`}</p>
                                )}{' '}
                            </div>
                            <div className='col-5 d-flex justify-content-end'>
                                <div className='row headerDetail align-items-center'>
                                    <div className='col d-flex justify-content-end'>
                                        <button className='btn btn-primary text-white d-flex align-items-center btn-close-padding-header' onClick={handleClickClose}>
                                            <h6 className='button-close-text'>{translator.get('service.detail.header.closeProject.text')}</h6>
                                            <Close className='close-container' fill='white' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row pt-3 justify-content-between'>
                            <div className='col-9 white-space-nowrap d-flex position-detail-element'>
                                <p className='m-0 created-by inline-text'>
                                    <div className='row headerDetail align-items-center'>
                                        <div className='col p-0 width-element-header'>
                                            {project?.project_published && <span style={{ color: '#009133' }}>Published - </span>}
                                            <p className='inline-text ms-1 text-header-detail'>{translator.get('project.table.header.creator.text')}:</p>
                                            <p className='inline-text ms-1 text-header'>{project?.author?.name} </p>
                                        </div>
                                        <div className='col p-0 width-element-header'>
                                            <p className='inline-text ms-1 text-header-detail'>{translator.get('service.table.header.language.text')}:</p>
                                            <p className='inline-text ms-1 text-header'>{templates?.find((item: BaseProjectTemplate) => item.id == project?.technology)?.name} </p>
                                        </div>
                                        <div className='col p-0 width-element-header'>
                                            <p className='inline-text ms-1 text-header-detail'>{translator.get('service.table.header.version.text')}:</p>
                                            <p className='inline-text ms-1 text-header'>{project?.version} </p>
                                        </div>
                                    </div>
                                </p>
                            </div>
                            {KeycloakUtil.isLoggedIn() && (
                                <div className='col-2 d-flex justify-content-end'>
                                    <a
                                        href={`https://meet.jit.si/Lifewatch_BECODE_${
                                            project?.id
                                        }#userInfo.displayName="${KeycloakUtil.getName()}"&config.prejoinPageEnabled=false`}
                                        target='_blank'
                                        className='startChatroom d-flex flex-row align-items-center focus--mouse'>
                                        <p className='d-none d-md-block m-0'> {translator.get('common.start.chat.button.text')}</p>
                                        <Chat className='chat-container' fill='#009133' />
                                    </a>
                                </div>
                            )}
                        </div>
                    </>
                )}
            {service &&
                (origin === ProvisioningOriginEnum.SERVICE || origin === ProvisioningOriginEnum.SERVICEDETAIL || navigationManager.getCurrentRoute().includes('/service')) && (
                    <>
                        <div className='row justify-content-between'>
                            <div className='col-7 d-flex flex-row align-item-center header-flex'>
                                <div className='row headerDetail align-items-center'>
                                    <div className='col status-chip-position'>
                                        {KeycloakUtil.isLoggedIn() && (
                                            <div className='chip chip-simple bg-secondary m-1'>
                                                <span className='chip-label text-white mt-1'>{service?.status?.replace(/_/g, ' ')}</span>
                                            </div>
                                        )}
                                    </div>
                                    {KeycloakUtil.isLoggedIn() && KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) && (
                                        <div className='col mt-2 mb-2 text-nowrap'>
                                            <span className='m-0'>{`${translator.get('header.entity.id')}: ${service?.id}`}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='col-5 d-flex justify-content-end'>
                                <div className='row headerDetail align-items-center'>
                                    <div className='col d-flex justify-content-end'>
                                        <button className='btn btn-primary text-white d-flex align-items-center btn-close-padding-header' onClick={handleClickClose}>
                                            <h6 className='button-close-text'>{translator.get('service.detail.header.closeProject.text')}</h6>
                                            <Close className='close-container' fill='white' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row pt-3 justify-content-between'>
                            <div className='col-9 white-space-nowrap d-flex position-detail-element'>
                                <p className='m-0 created-by inline-text'>
                                    <div className='row headerDetail align-items-center'>
                                        <div className='col p-0 width-element-header'>
                                            {project?.project_published && <span style={{ color: '#009133' }}>Published - </span>}
                                            <p className='inline-text ms-1 text-header-detail'>{translator.get('project.table.header.creator.text')}:</p>
                                            <p className='inline-text ms-1 text-header'>{project?.author?.name} </p>
                                        </div>
                                        <div className='col p-0 width-element-header'>
                                            <p className='inline-text ms-1 text-header-detail'>{translator.get('service.table.header.language.text')}:</p>
                                            <p className='inline-text ms-1 text-header'>{templates?.find((item: BaseProjectTemplate) => item.id == project?.technology)?.name} </p>
                                        </div>
                                        <div className='col p-0 width-element-header'>
                                            <p className='inline-text ms-1 text-header-detail'>{translator.get('service.table.header.version.text')}:</p>
                                            <p className='inline-text ms-1 text-header'>{project?.version} </p>
                                        </div>
                                    </div>
                                </p>
                            </div>
                            {KeycloakUtil.isLoggedIn() && (
                                <div className='col-2 d-flex justify-content-end'>
                                    <a
                                        href={`https://meet.jit.si/Lifewatch_BECODE_${
                                            service?.id_reference_project
                                        }#userInfo.displayName="${KeycloakUtil.getName()}"&config.prejoinPageEnabled=false`}
                                        target='_blank'
                                        className='startChatroom d-flex flex-row align-items-center focus--mouse'>
                                        <p className='d-none d-md-block m-0'> {translator.get('common.start.chat.button.text')}</p>
                                        <Chat className='chat-container' fill='#009133' />
                                    </a>
                                </div>
                            )}
                        </div>
                    </>
                )}
        </div>
    );
};

export default Header;
