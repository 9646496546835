import { PersistentReduceableReducer } from '@hypereact/state';
import { ProvisioningState } from './state';

export class ProvisioningReducer extends PersistentReduceableReducer<ProvisioningState> {
    dehydrate(state: ProvisioningState): ProvisioningState | null {
        return state;
    }

    rehydrate(state: ProvisioningState, data: ProvisioningState): ProvisioningState {
        return {
            ...state,
            ...data,
        };
    }
}
