import { IReduceableAction, ReduxAction } from '@hypereact/state';
import { Slices } from '@store/state';
import { TokenResponse } from 'src/api';
import { initialLoginState, LoginState } from './state';

@ReduxAction('LOGIN_SET_USER', Slices.Login)
export class LoginSetCredential implements IReduceableAction<LoginState> {
    constructor(public user: LoginState) {}
    reduce(state: LoginState) {
        state.email = this.user.email;
        state.role = this.user.role;
        state.userId = this.user.userId;
        state.firstName = this.user.firstName;
        state.lastName = this.user.lastName;
        state.isLogged = true;
        return state;
    }
}

@ReduxAction('SET_IS_LOGGED', Slices.Login)
export class SetIsLogged implements IReduceableAction<LoginState> {
    constructor(public isLogged: boolean) {}
    reduce(state: LoginState) {
        state.isLogged = this.isLogged;
        return state;
    }
}

@ReduxAction('LOGIN_RESET_USER', Slices.Login)
export class LoginResetCredential implements IReduceableAction<LoginState> {
    reduce(state: LoginState) {
        state = initialLoginState;
        return state;
    }
}

@ReduxAction('SET_POKE_USER', Slices.Login)
export class SetPokeUser implements IReduceableAction<LoginState> {
    constructor(public tokenResponse: TokenResponse) {}
    reduce(state: LoginState) {
        state.tokenResponse = this.tokenResponse;
        return state;
    }
}
