export class ProvisioningRequirementsData {
    dataValidation: boolean;
    temporaryFiles: boolean;
    diskSpaceUsage: boolean;
    executionTime: boolean;
    cpuUsage: boolean;
    easyToFind: boolean;
    retrivable: boolean;
    interoperable: boolean;
    reusable: boolean;
}
