import { ReactComponent as ArrowDown } from '@assets/svg/it-arrow-down-triangle.svg';
import { ReactComponent as ArrowUp } from '@assets/svg/it-arrow-up-triangle.svg';
import Breadcrumb from '@components/Breadcrumb/breadcrumb.component';
import { ListPaginationInterface } from '@features/_shared/components/Paginator/enums/ListPaginationInterface';
import Paginator from '@features/_shared/components/Paginator/paginator.components';
import { PopupType } from '@features/_shared/components/Popup/popup.item';
import { Table } from '@features/_shared/components/Table';
import { LoginState } from '@features/login/store/state';
import { ProjectState } from '@features/project/state/state';
import { ProvisioningPageEnum } from '@features/provisioning/store/state';
import { wizardPublicationDataset, wizardPublicationService } from '@features/provisioning/utils/constant';
import { ProvisioningOriginEnum } from '@features/provisioning/views/provisioning.view';
import FilterBarServiceView from '@features/service/components/service.filterbar.component';
import { ReduxConnect } from '@hypereact/state';
import { TranslatorManager } from '@managers/TranslatorManager';
import { NavigationManager } from '@managers/navigation.manager';
import { PopupManager } from '@managers/popup.manager';
import { ProjectManager } from '@managers/project.manager';
import { ProvisioningManager } from '@managers/provisioning.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { ServiceManager } from '@managers/service.manager';
import { Paths } from '@routes';
import { RootState } from '@store/state';
import { FormatUtil } from '@utils/formaters.util';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';
import { PollingUtil } from '@utils/polling.util';
import React from 'react';
import { BaseProjectTemplate, Project, WebService, WebServiceSearchFilter, WebServiceStatusEnum } from '../../../api';
import { ExecutionState } from '../store/state';
import '../styles/service.style.scss';
export interface ServiceViewProps {
    services: Array<WebService>;
    filters: WebServiceSearchFilter;
    service: WebService;
    templates: Array<BaseProjectTemplate>;
    user: LoginState;
    pagination: ListPaginationInterface;
    availableCategoryOptions: string[];
    execution: ExecutionState;
    selectedProject: Project;
    origin: ProvisioningOriginEnum;
    projectState: ProjectState;
}

export interface ServiceViewState {
    openedMenu: string | null;
}

@ReduxConnect((state: RootState) => {
    return {
        services: state.service.service.services,
        filters: state.service.service.filters,
        service: state.service.service.selectedService,
        execution: state.service.execution,
        templates: state.project.template,
        user: state.login,
        pagination: state.service.service.pagination,
        availableCategoryOptions: state.project.project.categoryAvailableOptions,
        selectedProject: state.project.project.selectedProject,
        origin: state.provisioning.origin,
    };
})
export class ServiceView extends React.Component<ServiceViewProps, ServiceViewState> {
    private serviceManager: ServiceManager;
    private translator: TranslatorManager;
    private navigationManager: NavigationManager;
    private provisioningManager: ProvisioningManager;
    private projectManager: ProjectManager;
    private popupManager: PopupManager;
    private queueManager: QueueManager;
    constructor(props: ServiceViewProps) {
        super(props);
        this.serviceManager = ServiceManager.getInstance();
        this.projectManager = ProjectManager.getInstance();
        this.translator = TranslatorManager.getInstance();
        this.popupManager = PopupManager.getInstance();
        this.queueManager = QueueManager.getInstance();
        this.navigationManager = NavigationManager.getInstance();
        this.handleTableRowClick = this.handleTableRowClick.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.handleStartClick = this.handleStartClick.bind(this);
        this.handleOnPaginationChange = this.handleOnPaginationChange.bind(this);
        this.handlePublicationClick = this.handlePublicationClick.bind(this);
        this.provisioningManager = ProvisioningManager.getInstance();
        this.handleClickOutsideDropdown = this.handleClickOutsideDropdown.bind(this);
        this.handleDropdownClose = this.handleDropdownClose.bind(this);

        this.state = {
            openedMenu: null,
        };
    }

    async pollServices(page: number, size: number) {
        const validateServices = () => this.navigationManager.getCurrentRoute() === '/service';
        await PollingUtil.register(
            'services',
            async () =>
                await this.serviceManager.search({
                    size: size,
                    page: page,
                    filter: {
                        published: KeycloakUtil.isLoggedIn() ? undefined : true,
                        ...this.props.filters,
                    },
                }),
            validateServices,
            10000,
        );
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.handleOnServiceDashboardLoad());
        await this.serviceManager.getServiceCategories();
        await this.queueManager.queue(QueueKeyType.POLLER, this.pollServices(this.props.pagination.page, this.props.pagination.size));
        document.addEventListener('click', this.handleClickOutsideDropdown);
    }

    componentWillUnmount() {
        PollingUtil.unregister('services');
        this.serviceManager.resetFilters();
        this.serviceManager.resetPagination();
        document.removeEventListener('click', this.handleClickOutsideDropdown);
    }

    handleClickOutsideDropdown(event: any) {
        if (!event.target.closest('.publish-container')) {
            this.setState({
                openedMenu: null,
            });
        }
    }

    handleDropdownClose(id: string) {
        this.state.openedMenu === `actionMenu${id}` ? this.setState({ openedMenu: null }) : this.setState({ openedMenu: `actionMenu${id}` });
    }

    async handleOnServiceDashboardLoad() {
        await this.projectManager.getProjectCategories();
        await this.projectManager.getTemplateList();
        await this.serviceManager.search({
            size: this.props.pagination.size,
            page: this.props.pagination.page,
            filter: {
                published: KeycloakUtil.isLoggedIn() ? undefined : true,
                ...this.props.filters,
            },
        });
    }

    async handleTableRowClick(id: string) {
        const response = await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(id));
        this.provisioningManager.setOrigin(ProvisioningOriginEnum.SERVICE);
        response.id_reference_project && (await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(response.id_reference_project)));
        this.serviceManager.saveValue('keywords' as keyof WebService, this.props.service?.keywords as any);
        this.serviceManager.saveValue('categories' as keyof WebService, this.props.service?.categories as any);
        this.navigationManager.navigateTo(`/service/${id}` as Paths);
    }

    handleFilterChange() {
        PollingUtil.unregister('services');
        this.pollServices(0, 10);
    }

    async handleClearClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation();
        if (this.props.filters.status) {
            await this.queueManager.queue(
                QueueKeyType.DEFAULT,
                this.serviceManager.search({
                    size: this.props.pagination.size,
                    page: this.props.pagination.page,
                    filter: {
                        published: KeycloakUtil.isLoggedIn() ? undefined : true,
                        ...this.props.filters,
                    },
                }),
            );
        } else {
            await this.queueManager.queue(
                QueueKeyType.DEFAULT,
                this.serviceManager.search({
                    size: this.props.pagination.size,
                    page: this.props.pagination.page,
                    filter: {
                        published: KeycloakUtil.isLoggedIn() ? undefined : true,
                        name: this.props.filters.name,
                        creatorName: this.props.filters.creatorName,
                    },
                }),
            );
        }
    }

    async handlePublicationClick(e: React.MouseEvent<HTMLHyperlinkElementUtils>, id: string, wizardPublication: string[], currentPage: ProvisioningPageEnum) {
        e.stopPropagation();
        const serviceCommitsDiff = await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.diffToProject(id as string));
        if (serviceCommitsDiff.commits && serviceCommitsDiff.commits > 0) {
            this.popupManager.show({
                type: PopupType.Info,
                title: this.translator.get('service.popup.diff.title'),
                description: this.translator.get('service.popup.diff.description'),
                maxWidth: 'lg',
                stylingProp: 'popup-display',
                ctas: {
                    primary: {
                        label: this.translator.get('service.popup.refresh.button'),
                        className: 'popup-accept-button',
                        cta: async () => {
                            this.popupManager.hide();
                            await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.refresh(id as string));
                            await this.serviceManager.search({
                                size: this.props.pagination.size,
                                page: this.props.pagination.page,
                                filter: {
                                    published: KeycloakUtil.isLoggedIn() ? undefined : true,
                                    ...this.props.filters,
                                },
                            });
                        },
                    },
                },
            });
        } else if (serviceCommitsDiff.inputs && serviceCommitsDiff.inputs > 0) {
            this.popupManager.show({
                type: PopupType.Info,
                title: this.translator.get('service.popup.diff.input.title'),
                description: this.translator.get('service.popup.diff.input.description'),
                maxWidth: 'lg',
                stylingProp: 'popup-display',
                ctas: {
                    primary: {
                        label: this.translator.get('service.popup.refresh.button'),
                        className: 'popup-accept-button',
                        cta: async () => {
                            this.popupManager.hide();
                            await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.refresh(id as string));
                            await this.serviceManager.search({
                                size: this.props.pagination.size,
                                page: this.props.pagination.page,
                                filter: {
                                    published: KeycloakUtil.isLoggedIn() ? undefined : true,
                                    ...this.props.filters,
                                },
                            });
                        },
                    },
                },
            });
        } else {
            await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(id));
            await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(this.props.service.id_reference_project as string));
            this.provisioningManager.setSteps(wizardPublication);
            this.provisioningManager.setCurrentPage(currentPage);
            this.provisioningManager.setOrigin(ProvisioningOriginEnum.SERVICE);
            let index =
                currentPage === ProvisioningPageEnum.SERVICE
                    ? this.props.selectedProject.provisioningPageIndex?.serviceIndex
                    : this.props.selectedProject.provisioningPageIndex?.datasetIndex;
            this.provisioningManager.setActiveStep(wizardPublication[index ? index : 0]);

            this.navigationManager.navigateTo(wizardPublication[index ? index : 0] as Paths);
        }
    }

    async handleProjectButtonClick(e: any) {
        e.stopPropagation();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(e.target.id));
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(this.props.service.id_reference_project as string));
        this.navigationManager.navigateTo(`/project/${this.props.service.id_reference_project}` as Paths);
    }

    async handleStartClick(e: any, serviceId: string) {
        e.stopPropagation();
        const serviceCommitsDiff = await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.diffToProject(serviceId as string));
        if (serviceCommitsDiff.commits && serviceCommitsDiff.commits > 0) {
            this.popupManager.show({
                type: PopupType.Info,
                title: this.translator.get('service.popup.diff.title'),
                description: this.translator.get('service.popup.diff.description'),
                maxWidth: 'lg',
                stylingProp: 'popup-display',
                ctas: {
                    primary: {
                        label: this.translator.get('service.popup.refresh.button'),
                        className: 'popup-accept-button',
                        cta: async () => {
                            this.popupManager.hide();
                            await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.refresh(serviceId));
                        },
                    },
                },
            });
        } else if (serviceCommitsDiff.inputs && serviceCommitsDiff.inputs > 0) {
            this.popupManager.show({
                type: PopupType.Info,
                title: this.translator.get('service.popup.diff.input.title'),
                description: this.translator.get('service.popup.diff.input.description'),
                maxWidth: 'lg',
                stylingProp: 'popup-display',
                ctas: {
                    primary: {
                        label: this.translator.get('service.popup.refresh.button'),
                        className: 'popup-accept-button',
                        cta: async () => {
                            this.popupManager.hide();
                            await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.refresh(serviceId));
                        },
                    },
                },
            });
        } else {
            const response = await this.queueManager.queue(
                QueueKeyType.DEFAULT,
                this.serviceManager.createExecution(serviceId, {
                    author: {
                        name: KeycloakUtil.getName(),
                        email: KeycloakUtil.getEmail(),
                    },
                }),
            );
            await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(serviceId));
            await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.launch(serviceId, response.id as string));
            this.navigationManager.navigateTo(`/service/${serviceId}` as Paths);
        }
    }

    async handleOnPaginationChange(page: number, size: number, total: number) {
        PollingUtil.unregister('services');
        this.pollServices(page, size);
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.setPagination(page, size, total));
    }

    async handleDownloadRepoClick(e: React.MouseEvent<HTMLHyperlinkElementUtils>, projectId: string, zipname: string) {
        e.stopPropagation();
        const response = await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.downloadRepo(projectId, zipname));
        const href = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${zipname}.zip`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    async handleDeleteService(e: React.MouseEvent<HTMLHyperlinkElementUtils>, serviceId: string) {
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.delete(serviceId));
        await this.queueManager.queue(
            QueueKeyType.DEFAULT,
            this.serviceManager.search({
                size: this.props.pagination.size,
                page: this.props.pagination.page,
                filter: {
                    published: KeycloakUtil.isLoggedIn() ? undefined : true,
                    ...this.props.filters,
                },
            }),
        );
        //await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(serviceId));
    }

    handleDeleteClick(e: React.MouseEvent<HTMLHyperlinkElementUtils>, serviceId: string) {
        e.stopPropagation();
        this.popupManager.show({
            type: PopupType.Info,
            title: this.translator.get('service.popup.delete.service.title'),
            description: this.translator.get('service.popup.delete.service.description'),
            maxWidth: 'lg',
            stylingProp: 'popup-display',
            ctas: {
                primary: {
                    label: this.translator.get('service.popup.delete.service.button'),
                    className: 'popup-accept-button',
                    cta: () => this.handleDeleteService(e, serviceId),
                },
                secondary: {
                    label: this.translator.get('common.button.cancel.label'),
                    className: 'popup-accept-button',
                    cta: () => this.popupManager.hide(),
                },
            },
        });
    }

    render() {
        const isLogged = KeycloakUtil.isLoggedIn();
        const { services, filters } = this.props;
        const { page, size, total } = this.props.pagination;

        const tableColumns = [
            this.translator.get('service.table.header.title.text'),
            this.translator.get('service.table.header.version.text'),
            this.translator.get('service.table.header.language.text'),
            this.translator.get('service.table.header.category.text'),
            this.translator.get('service.table.header.status.text'),
            '',
        ];
        const tableRows = services.map((item: WebService, index) => {
            return {
                id: item.id as string,
                values: {
                    title: item.name,
                    version: item.version,
                    programmingLanguage: this.props.templates.find((template: BaseProjectTemplate) => {
                        return template?.id == item.technology;
                    })?.name,
                    category: <div className='td-category-size'>{item.categories ? String(item.categories) : '-'}</div>,
                    status: (
                        <div className='chip chip-simple bg-secondary m-1'>
                            <span className='chip-label text-white mt-1 chip-status-container'>{item?.status?.replace(/_/g, ' ')}</span>
                        </div>
                    ),
                },
                collapsedRow: (
                    <>
                        <div className='description table-padding'>{FormatUtil.truncateText(item.description as string)}</div>
                        <div className='d-flex justify-content-space-between w-100 table-padding'>
                            <div className='creator-container'>
                                <div className='d-flex'>
                                    <div className='creator-label-container'>Creator: </div>
                                    <div className='creator-content-container'>{`${item.author?.name} (${item.author?.email})`}</div>
                                </div>
                            </div>
                            {
                                <div className='action-container'>
                                    {/* {KeycloakUtil.isLoggedIn() && (
                                        <button
                                            id={item?.id}
                                            disabled={item?.status === WebServiceStatusEnum.DRAFT}
                                            className='btn btn-outline-primary table-button d-flex align-items-center action-item'
                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handleStartClick(e, item?.id as string)}>
                                            {this.translator.get('common.start.button.text')} <Play className='table-icon-button ms-2' />
                                        </button>
                                    )} */}
                                    <div className='btn-group'>
                                        {((!item.published && (item.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin]))) ||
                                            item.published) && (
                                            <button
                                                className='btn btn-outline-primary publish-container d-flex flex-row'
                                                type='button'
                                                data-bs-toggle='dropdown'
                                                aria-controls='dropdown'
                                                aria-haspopup='true'
                                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                    e.stopPropagation();
                                                    this.handleDropdownClose(item.id as string);
                                                }}
                                                aria-expanded='false'>
                                                {this.translator.get('common.button.action.label')}
                                                <ArrowDown className='arrow-down icon' fill='#009138' />
                                                <ArrowUp className='arrow-up icon' fill='#009138' />
                                            </button>
                                        )}
                                        <div className={`dropdown-menu ${`actionMenu${item.id}` === this.state.openedMenu && 'show'}`} id={`actionMenu${item.id}`}>
                                            <div className='link-list-wrapper'>
                                                <ul className='link-list'>
                                                    {(item.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) && (
                                                        <li>
                                                            <a
                                                                className='dropdown-item list-item'
                                                                onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) =>
                                                                    item.id &&
                                                                    this.handlePublicationClick(e, item.id as string, wizardPublicationDataset, ProvisioningPageEnum.DATASET)
                                                                }>
                                                                <span>{this.translator.get('service.table.action.pubblication.dataset.text')}</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {(item.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) &&
                                                        !item.published &&
                                                        item.status === WebServiceStatusEnum.TO_BE_PUBLISHED && (
                                                            <li>
                                                                <a
                                                                    className='dropdown-item list-item'
                                                                    onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) =>
                                                                        item.id &&
                                                                        this.handlePublicationClick(e, item.id as string, wizardPublicationService, ProvisioningPageEnum.SERVICE)
                                                                    }>
                                                                    <span>{this.translator.get('service.table.action.pubblication.service.text')}</span>
                                                                </a>
                                                            </li>
                                                        )}
                                                    {item.published && (
                                                        <li>
                                                            <a
                                                                className='dropdown-item list-item'
                                                                id={item.id}
                                                                onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) =>
                                                                    this.handleDownloadRepoClick(e, item.id_reference_project as string, item.name as string)
                                                                }>
                                                                <span>{this.translator.get('service.table.action.download.project.text')}</span>
                                                            </a>
                                                        </li>
                                                    )}
                                                    {(item.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) &&
                                                        item.status !== WebServiceStatusEnum.TO_BE_APPROVED && (
                                                            <li>
                                                                <a
                                                                    className='dropdown-item list-item'
                                                                    id={item.id}
                                                                    onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) => this.handleDeleteClick(e, item.id as string)}>
                                                                    <span>{this.translator.get('service.table.action.delete.service.text')}</span>
                                                                </a>
                                                            </li>
                                                        )}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                ),
            };
        });

        return (
            <div className='container-fluid'>
                <div>
                    <div className='row header-title-container pb-0'>
                        <p className='header-title col-12 mb-4'>{this.translator.get('service.view.title')}</p>
                        <Breadcrumb secondaryPages={[this.translator.get('service.breadcrumb.secondary.item')]} />
                    </div>
                    <div className='row home-title-container '>
                        <h5 className='title-description col-12'>{this.translator.get('service.title.description.part.one')}</h5>
                        <h5 className='title-description col-12'>{this.translator.get('service.title.description.part.two')}</h5>
                    </div>
                </div>
                <div className='row mb-5 mb-md-0 mt-3'>
                    <div className='col p-0'>
                        <FilterBarServiceView
                            filters={filters}
                            onFilterChange={this.handleFilterChange}
                            clearFilters={this.handleClearClick}
                            availableCategoryOptions={this.props.availableCategoryOptions}
                        />
                    </div>
                </div>
                <div className='row mb-3'>
                    <div className='col'>
                        <p className='tableTitle p-2 m-0'>{this.translator.get('service.table.title')}</p>
                    </div>
                </div>
                <div className='row g-0'>
                    <div className='col'>
                        <div className='service-table-container'>
                            <div className='size-tab'>
                                <Table columns={tableColumns} rows={tableRows} isStyledHeaders={true} onRowClick={this.handleTableRowClick} keyLabel='service' />
                            </div>
                            {<Paginator onChange={this.handleOnPaginationChange} page={page} size={size} total={total as number} />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
