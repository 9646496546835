/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RequestConfigurationGet } from '../entities';
// @ts-ignore
import { ResponseConfiguration } from '../entities';
/**
 * KeyConfigurationControllerApi - axios parameter creator
 * @export
 */
export const KeyConfigurationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get filtered configuration keys from tenant configmap based on the value the key starts with
         * @param {RequestConfigurationGet} requestConfigurationGet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys: async (requestConfigurationGet: RequestConfigurationGet, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestConfigurationGet' is not null or undefined
            assertParamExists('getKeys', 'requestConfigurationGet', requestConfigurationGet);
            const localVarPath = `/config/keys`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestConfigurationGet, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * KeyConfigurationControllerApi - functional programming interface
 * @export
 */
export const KeyConfigurationControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = KeyConfigurationControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Get filtered configuration keys from tenant configmap based on the value the key starts with
         * @param {RequestConfigurationGet} requestConfigurationGet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getKeys(
            requestConfigurationGet: RequestConfigurationGet,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseConfiguration>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getKeys(requestConfigurationGet, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * KeyConfigurationControllerApi - factory interface
 * @export
 */
export const KeyConfigurationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KeyConfigurationControllerApiFp(configuration);
    return {
        /**
         * Get filtered configuration keys from tenant configmap based on the value the key starts with
         * @param {RequestConfigurationGet} requestConfigurationGet
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getKeys(requestConfigurationGet: RequestConfigurationGet, options?: any): AxiosPromise<ResponseConfiguration> {
            return localVarFp.getKeys(requestConfigurationGet, options).then(request => request(axios, basePath));
        },
    };
};

/**
 * KeyConfigurationControllerApi - interface
 * @export
 * @interface KeyConfigurationControllerApi
 */
export interface KeyConfigurationControllerApiInterface {
    /**
     * Get filtered configuration keys from tenant configmap based on the value the key starts with
     * @param {RequestConfigurationGet} requestConfigurationGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeyConfigurationControllerApiInterface
     */
    getKeys(requestConfigurationGet: RequestConfigurationGet, options?: AxiosRequestConfig): AxiosPromise<ResponseConfiguration>;
}

/**
 * KeyConfigurationControllerApi - object-oriented interface
 * @export
 * @class KeyConfigurationControllerApi
 * @extends {BaseAPI}
 */
export class KeyConfigurationControllerApi extends BaseAPI implements KeyConfigurationControllerApiInterface {
    /**
     * Get filtered configuration keys from tenant configmap based on the value the key starts with
     * @param {RequestConfigurationGet} requestConfigurationGet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeyConfigurationControllerApi
     */
    public getKeys(requestConfigurationGet: RequestConfigurationGet, options?: AxiosRequestConfig) {
        return KeyConfigurationControllerApiFp(this.configuration)
            .getKeys(requestConfigurationGet, options)
            .then(request => request(this.axios, this.basePath));
    }
}
