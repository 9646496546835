import { ResponseStatistics, WebService } from 'src/api';
import { Project } from 'src/api/entities/project';

export interface HomeState {
    publishedProjects: Array<Project>;
    publishedServices: Array<WebService>;
    statistics: ResponseStatistics;
}

export const initialHomeState: HomeState = {
    publishedProjects: [],
    publishedServices: [],
    //TODO: update with actual values
    statistics: {
        launchedExecutionsCount: 0,
        projectsCount: 0,
        publishedProjectsCount: 0,
        publishedServicesCount: 0,
        servicesCount: 0,
        sessionsCount: 0,
    },
};

export interface IMetrics {
    projects: number;
    publishedProjects: number;
    services: number;
    publishedServices: number;
    loggedInUsers: number;
    executions: number;
}
