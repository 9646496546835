import { PersistentReduceableReducer } from '@hypereact/state';
import { HomeState } from './state';

export class HomeReducer extends PersistentReduceableReducer<HomeState> {
    dehydrate(state: HomeState): HomeState | null {
        return state;
    }

    rehydrate(state: HomeState, data: HomeState): HomeState {
        return {
            ...state,
            ...data,
        };
    }
}
