import { InputFile } from '@features/project/state/state';
import { IReduceableAction, ReduxAction } from '@hypereact/state';
import { Slices } from '@store/state';
import { OutputFile, Project, RequestServicePublish, ServiceExecution, ServiceExecutionStatusEnum, WebService, WebServiceSearchFilter } from '../../../api';
import { ServiceState, initialServiceState } from './state';

@ReduxAction('SERVICE_SET_SERVICES', Slices.Service)
export class ServiceSetServices implements IReduceableAction<ServiceState> {
    constructor(public services: Array<WebService>) {}
    reduce(state: ServiceState) {
        state.service.services = this.services;
        return state;
    }
}

@ReduxAction('SERVICE_SET_SELECTED_SERVICE', Slices.Service)
export class ServiceSetSelectedService implements IReduceableAction<ServiceState> {
    constructor(public service: WebService) {}
    reduce(state: ServiceState) {
        state.service.selectedService = this.service;
        return state;
    }
}
@ReduxAction('SERVICE_RESET_SELECTED_SERVICE', Slices.Service)
export class ServiceResetSelectedService implements IReduceableAction<ServiceState> {
    reduce(state: ServiceState) {
        state.service.selectedService = initialServiceState.service.selectedService;
        return state;
    }
}

@ReduxAction('SERVICE_SET_FILTERS', Slices.Service)
export class ServiceSetFilters implements IReduceableAction<ServiceState> {
    constructor(public key: keyof WebServiceSearchFilter, public value: string | boolean | Array<string> | undefined) {}
    reduce(state: ServiceState) {
        state.service.filters[this.key] = this.value as any;
        return state;
    }
}

@ReduxAction('SERVICE_RESET_FILTERS', Slices.Service)
export class ServiceResetFilters implements IReduceableAction<ServiceState> {
    reduce(state: ServiceState) {
        state.service.filters = initialServiceState.service.filters;
        state.service.categoryAvailableOptions = initialServiceState.service.categoryAvailableOptions;
        return state;
    }
}

@ReduxAction('SERVICE_RESET_CATEGORY_FILTERS', Slices.Service)
export class ServiceResetCategoryFilters implements IReduceableAction<ServiceState> {
    reduce(state: ServiceState) {
        state.service.filters.categories = initialServiceState.service.filters.categories;
        state.service.categoryAvailableOptions = initialServiceState.service.categoryAvailableOptions;
        return state;
    }
}

// @ReduxAction('SERVICE_UPDATE_CATEGORY_OPTIONS', Slices.Service)
// export class ServiceUpdateCategoryAvailableOptions implements IReduceableAction<ServiceState> {
//     constructor(public value: any) {}
//     reduce(state: ServiceState) {
//         this.value && (state.service.categoryAvailableOptions = state.service.categoryAvailableOptions.filter(prevItem => !this.value.split(',').includes(prevItem)));
//         return state;
//     }
// }

@ReduxAction('SERVICE_SET_EXECUTIONS', Slices.Service)
export class ServiceSetExecutions implements IReduceableAction<ServiceState> {
    constructor(public executions: Array<ServiceExecution>) {}
    reduce(state: ServiceState) {
        state.execution.executions = this.executions;
        return state;
    }
}

@ReduxAction('SERVICE_SET_SELECTED_EXECUTION', Slices.Service)
export class ServiceSetSelectedExecution implements IReduceableAction<ServiceState> {
    constructor(public execution: ServiceExecution) {}
    reduce(state: ServiceState) {
        state.execution.selectedExecution = this.execution;
        return state;
    }
}

@ReduxAction('SERVICE_RESET_SELECTED_EXECUTION', Slices.Service)
export class ServiceResetSelectedExecution implements IReduceableAction<ServiceState> {
    reduce(state: ServiceState) {
        state.execution.selectedExecution = initialServiceState.execution.selectedExecution;
        return state;
    }
}

@ReduxAction('SERVICE_EXECUTION_SET_STATUS', Slices.Service)
export class ServiceSetExecutionStatus implements IReduceableAction<ServiceState> {
    constructor(public status: ServiceExecutionStatusEnum) {}
    reduce(state: ServiceState) {
        state.execution.selectedExecution!.status = this.status;
        return state;
    }
}

@ReduxAction('SERVICE_SET_OUTPUTS', Slices.Service)
export class ServiceSetOutputs implements IReduceableAction<ServiceState> {
    constructor(public outputs: Array<OutputFile>) {}
    reduce(state: ServiceState) {
        state.output.outputs = this.outputs;
        return state;
    }
}

@ReduxAction('SERVICE_SET_INPUTS', Slices.Service)
export class ServiceSetInputs implements IReduceableAction<ServiceState> {
    constructor(public inputs: Array<InputFile>) {}
    reduce(state: ServiceState) {
        state.input.inputs = this.inputs;
        return state;
    }
}

@ReduxAction('SERVICE_RESET_CREATE_UPDATE', Slices.Service)
export class ServiceResetCreateUpdate implements IReduceableAction<ServiceState> {
    reduce(state: ServiceState) {
        state.service.createUpdate = initialServiceState.service.createUpdate;
        return state;
    }
}

@ReduxAction('SERVICE_SET_CREATE_UPDATE', Slices.Service)
export class ServiceSetCreateUpdate implements IReduceableAction<ServiceState> {
    constructor(public initialCreateUpdateState: Project) {}
    reduce(state: ServiceState) {
        state.service.createUpdate.keywords = this.initialCreateUpdateState.keywords;
        return state;
    }
}

@ReduxAction('SERVICE_SET_EXECUTION_LOGS', Slices.Service)
export class ServiceSetExecutionLogs implements IReduceableAction<ServiceState> {
    constructor(public logs: string) {}
    reduce(state: ServiceState) {
        state.execution.log = this.logs;
        return state;
    }
}

@ReduxAction('SERVICE_UPDATE_CATEGORY_OPTIONS', Slices.Service)
export class ServiceUpdateCategoryAvailableOptions implements IReduceableAction<ServiceState> {
    constructor(public value: any) {}
    reduce(state: ServiceState) {
        if (!Array.isArray(this.value)) {
            this.value && (state.service.categoryAvailableOptions = state.service.categoryAvailableOptions.filter(prevItem => !this.value.split(',').includes(prevItem)));
        } else {
            this.value && (state.service.categoryAvailableOptions = state.service.categoryAvailableOptions.filter(prevItem => !String(this.value).split(',').includes(prevItem)));
        }
        return state;
    }
}

@ReduxAction('SERVICE_SET_EXECUTION_HTML', Slices.Service)
export class ServiceSetExecutionHtml implements IReduceableAction<ServiceState> {
    constructor(public html: string) {}
    reduce(state: ServiceState) {
        state.execution.html = this.html;
        return state;
    }
}

@ReduxAction('SERVICE_SET_EXECUTION_LOGS', Slices.Service)
export class ServiceResetExecutionLogs implements IReduceableAction<ServiceState> {
    reduce(state: ServiceState) {
        state.execution.log = undefined;
        return state;
    }
}

@ReduxAction('SERVICE_SET_EXECUTION_HTML', Slices.Service)
export class ServiceResetExecutionHtml implements IReduceableAction<ServiceState> {
    reduce(state: ServiceState) {
        state.execution.html = undefined;
        return state;
    }
}

@ReduxAction('SERVICE_SET_VALUE', Slices.Service)
export class ServiceSetValue implements IReduceableAction<ServiceState> {
    constructor(public key: keyof WebService, public value: any) {}
    reduce(state: ServiceState) {
        state.service.createUpdate[this.key] = this.value;
        return state;
    }
}

@ReduxAction('SERVICE_SET_PAGINATION', Slices.Service)
export class ServiceSetPaginationAction implements IReduceableAction<ServiceState> {
    constructor(public page: number, public size: number, public total: number) {}

    reduce(service: ServiceState) {
        service.service.pagination.page = this.page;
        service.service.pagination.size = this.size;
        service.service.pagination.total = this.total;

        return service;
    }
}

@ReduxAction('SERVICE_RESET_PAGINATION', Slices.Service)
export class ServiceResetPaginationAction implements IReduceableAction<ServiceState> {
    reduce(service: ServiceState) {
        service.service.pagination.page = initialServiceState.service.pagination.page;
        service.service.pagination.size = initialServiceState.service.pagination.size;
        service.service.pagination.total = initialServiceState.service.pagination.total;
        return service;
    }
}

@ReduxAction('SERVICE_RESET_EXECUTION_PAGINATION', Slices.Service)
export class ServiceResetExecutionPaginationAction implements IReduceableAction<ServiceState> {
    reduce(service: ServiceState) {
        service.execution.pagination.page = initialServiceState.execution.pagination.page;
        service.execution.pagination.size = initialServiceState.execution.pagination.size;
        service.execution.pagination.total = initialServiceState.execution.pagination.total;

        return service;
    }
}

@ReduxAction('SERVICE_SET_EXECUTIONS_PAGINATION', Slices.Service)
export class ServiceSetExecutionsPagination implements IReduceableAction<ServiceState> {
    constructor(public page: number, public size: number, public total: number) {}
    reduce(service: ServiceState) {
        service.execution.pagination.page = this.page;
        service.execution.pagination.size = this.size;
        service.execution.pagination.total = this.total;
        return service;
    }
}

@ReduxAction('SERVICE_RESET_CREATE_UPDATE_CATEGORY', Slices.Service)
export class ServiceResetCreateUpdateCategory implements IReduceableAction<ServiceState> {
    reduce(state: ServiceState) {
        state.service.createUpdate.categories = initialServiceState.service.createUpdate.categories;
        state.service.categoryAvailableOptions = initialServiceState.service.categoryAvailableOptions;
        return state;
    }
}

@ReduxAction('SERVICE_SET_PUBLISH_SERVICE', Slices.Service)
export class ServiceSetPublishService implements IReduceableAction<ServiceState> {
    constructor(public key: keyof RequestServicePublish, public value: any) {}
    reduce(state: ServiceState) {
        state.service.publishData[this.key] = this.value;
        return state;
    }
}

@ReduxAction('SERVICE_SET_ALL_PUBLISH_SERVICE', Slices.Service)
export class ServiceSetAllPublishService implements IReduceableAction<ServiceState> {
    constructor(public data: RequestServicePublish) {}
    reduce(state: ServiceState) {
        state.service.publishData = this.data;
        return state;
    }
}

@ReduxAction('SERVICE_RESET_PUBLISH_DATA', Slices.Service)
export class ServiceResetPublishData implements IReduceableAction<ServiceState> {
    reduce(state: ServiceState) {
        state.service.publishData = initialServiceState.service.publishData;
        return state;
    }
}

@ReduxAction('SERVICE_SET_CATEGORIES', Slices.Service)
export class ServiceSetCategories implements IReduceableAction<ServiceState> {
    constructor(public categories: Array<string>) {}
    reduce(state: ServiceState) {
        state.service.categoryAvailableOptions = this.categories;
        return state;
    }
}
