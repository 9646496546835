import { NavigationManager } from '@managers/navigation.manager';
import React from 'react';
import './styles/breadcrumb.styles.scss';

export interface Props {
    mainPage?: string;
    secondaryPages: string[];
    isMainPageLink?: boolean;
    onItemClick?(): void;
}
export const Breadcrumb: React.FC<Props> = ({ mainPage = 'Home', secondaryPages, isMainPageLink = true, onItemClick }) => {
    const navigationManager = NavigationManager.getInstance();
    const onBreadCrumbItemClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        e.preventDefault();
        onItemClick ? onItemClick?.() : navigationManager.navigateTo('/home');
    };
    return (
        <>
            <nav aria-label='breadcrumb'>
                <ol className='breadcrumb justify-content-center'>
                    <li className='breadcrumb-item d-flex align-items-center'>
                        {isMainPageLink ? (
                            <span className='breadcrumb-main-item breadcrumb-item-cursor' onClick={e => onBreadCrumbItemClick(e)}>
                                {mainPage}
                            </span>
                        ) : (
                            <span className='breadcrumb-main-item'>{mainPage}</span>
                        )}
                    </li>
                    {secondaryPages &&
                        secondaryPages.map(page => (
                            <>
                                <li className='breadcrumb-item d-flex'>
                                    <span className='breadcrumb-secondary-item'>{page}</span>
                                </li>
                            </>
                        ))}
                </ol>
            </nav>
        </>
    );
};
export default Breadcrumb;
