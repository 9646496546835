import { PersistentReduceableReducer } from '@hypereact/state';
import { ServiceState } from './state';

export class ServiceReducer extends PersistentReduceableReducer<ServiceState> {
    dehydrate(state: ServiceState): ServiceState | null {
        return state;
    }

    rehydrate(state: ServiceState, data: ServiceState): ServiceState {
        return {
            ...state,
            ...data,
        };
    }
}
