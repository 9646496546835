import { TranslatorManager } from '@managers/TranslatorManager';
import { NavigationManager } from '@managers/navigation.manager';
import { PollingUtil } from '@utils/polling.util';
import React, { useEffect } from 'react';
import './styles/backoffice.view.scss';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';

export const BackofficeView: React.FC = () => {
    const translatorManager = TranslatorManager.getInstance();

    useEffect(() => {
        !KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) && NavigationManager.getInstance().navigateTo('/home');
        let iframe = document.querySelector('#backoffice-iframe');
        const validateBackoffice = () => NavigationManager.getInstance().getCurrentRoute() === '/administration';
        PollingUtil.register(
            'backoffice',
            async () => {
                (iframe as any).style.height = (iframe as any)!.contentDocument.body.scrollHeight + 'px';
                (iframe as any).style.width = (iframe as any).contentDocument.body.scrollWidth + 'px';
            },
            validateBackoffice,
            200,
        );
        return () => PollingUtil.unregister('backoffice');
    });
    return (
        <div className='container-fluid' id='test'>
            <div className='row header-title-container'>
                <div className='start-page-top'>
                    <h3 className='header-title'>{translatorManager.get('backoffice.view.title')}</h3>
                </div>
            </div>
            <div className='bacoffice-iframe-container'>
                <iframe
                    id='backoffice-iframe'
                    scrolling='no'
                    src={`${window.location.origin}/backoffice/ui`}
                    style={{ overflow: 'hidden', width: '1px', minWidth: '100%', border: 'none' }}
                    width='100%'
                    height='fit-content'
                    title='backoffice'
                    className='row iframe'
                />
            </div>
        </div>
    );
};
