/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BaseResponse } from '../entities';
// @ts-ignore
import { RequestApprove } from '../entities';
// @ts-ignore
import { RequestBodyGenericStopProcess } from '../entities';
// @ts-ignore
import { RequestFile } from '../entities';
// @ts-ignore
import { RequestGenericStart } from '../entities';
// @ts-ignore
import { RequestReject } from '../entities';
// @ts-ignore
import { RequestServiceBEHUBFileSelect } from '../entities';
// @ts-ignore
import { RequestServiceCreate } from '../entities';
// @ts-ignore
import { RequestServiceExecutionCreate } from '../entities';
// @ts-ignore
import { RequestServiceFileCreate } from '../entities';
// @ts-ignore
import { RequestServicePublish } from '../entities';
// @ts-ignore
import { RequestServiceUpdate } from '../entities';
// @ts-ignore
import { RequestServiceUrlFileSelect } from '../entities';
// @ts-ignore
import { ResponseCoreServiceExecutionLaunch } from '../entities';
// @ts-ignore
import { ResponseServiceBEHUBFileSelect } from '../entities';
// @ts-ignore
import { ResponseServiceDiffToProject } from '../entities';
// @ts-ignore
import { ResponseServiceExecution } from '../entities';
// @ts-ignore
import { ResponseServiceExecutionPaginated } from '../entities';
// @ts-ignore
import { ResponseServiceFileCreate } from '../entities';
// @ts-ignore
import { ResponseServiceGet } from '../entities';
// @ts-ignore
import { ResponseServiceListInput } from '../entities';
// @ts-ignore
import { ResponseServiceListOutput } from '../entities';
// @ts-ignore
import { ResponseServiceSearch } from '../entities';
// @ts-ignore
import { ResponseServiceUpdate } from '../entities';
// @ts-ignore
import { ResponseServiceUrlFileSelect } from '../entities';
// @ts-ignore
import { WebService } from '../entities';
// @ts-ignore
import { WebServiceSearchRequest } from '../entities';
/**
 * ServiceControllerApi - axios parameter creator
 * @export
 */
export const ServiceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a service
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('_delete', 'id', id);
            const localVarPath = `/service/delete/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Approve service
         * @param {string} id
         * @param {RequestApprove} requestApprove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approve: async (id: string, requestApprove: RequestApprove, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('approve', 'id', id);
            // verify required parameter 'requestApprove' is not null or undefined
            assertParamExists('approve', 'requestApprove', requestApprove);
            const localVarPath = `/service/{id}/approve`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestApprove, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new service from project identifier.
         * @param {RequestServiceCreate} requestServiceCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create: async (requestServiceCreate: RequestServiceCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestServiceCreate' is not null or undefined
            assertParamExists('create', 'requestServiceCreate', requestServiceCreate);
            const localVarPath = `/service/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestServiceCreate, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates an new execution on given service identifier
         * @param {string} id
         * @param {RequestServiceExecutionCreate} requestServiceExecutionCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExecution: async (id: string, requestServiceExecutionCreate: RequestServiceExecutionCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createExecution', 'id', id);
            // verify required parameter 'requestServiceExecutionCreate' is not null or undefined
            assertParamExists('createExecution', 'requestServiceExecutionCreate', requestServiceExecutionCreate);
            const localVarPath = `/service/{id}/execution/create`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestServiceExecutionCreate, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a definition of an input for the service
         * @param {string} id
         * @param {string} execId
         * @param {RequestServiceFileCreate} requestServiceFileCreate
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createInput: async (id: string, execId: string, requestServiceFileCreate: RequestServiceFileCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createInput', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('createInput', 'execId', execId);
            // verify required parameter 'requestServiceFileCreate' is not null or undefined
            assertParamExists('createInput', 'requestServiceFileCreate', requestServiceFileCreate);
            const localVarPath = `/service/{id}/execution/{execId}/input`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestServiceFileCreate, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if the service is aligned on relative project
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diffToProject: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('diffToProject', 'id', id);
            const localVarPath = `/service/{id}/diff`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Downloads the output notebook as html by service and execution id.
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadHtml: async (id: string, execId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadHtml', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('downloadHtml', 'execId', execId);
            const localVarPath = `/service/{id}/execution/{execId}/outcome/html/download`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {string} id
         * @param {string} execId
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInput: async (id: string, execId: string, requestFile: RequestFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadInput', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('downloadInput', 'execId', execId);
            // verify required parameter 'requestFile' is not null or undefined
            assertParamExists('downloadInput', 'requestFile', requestFile);
            const localVarPath = `/service/{id}/execution/{execId}/input/download`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestFile, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Downloads the jupyter log files included the build of binder
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadLog: async (id: string, execId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadLog', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('downloadLog', 'execId', execId);
            const localVarPath = `/service/{id}/execution/{execId}/outcome/log/download`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Downloads the log file as html
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        downloadLogTEST: async (id: string, execId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadLogTEST', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('downloadLogTEST', 'execId', execId);
            const localVarPath = `/service/{id}/execution/{execId}/outcome/log/downloadTEST`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Downloads specific output file specified. The required field is the name.
         * @param {string} id
         * @param {string} execId
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOutput: async (id: string, execId: string, requestFile: RequestFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadOutput', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('downloadOutput', 'execId', execId);
            // verify required parameter 'requestFile' is not null or undefined
            assertParamExists('downloadOutput', 'requestFile', requestFile);
            const localVarPath = `/service/{id}/execution/{execId}/output/download`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestFile, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtains updated list of all execution details
         * @param {string} id
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executions: async (id: string, page?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('executions', 'id', id);
            const localVarPath = `/service/{id}/executions`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Detail of execution information by service and execution id.
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExecution: async (id: string, execId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('findExecution', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('findExecution', 'execId', execId);
            const localVarPath = `/service/{id}/execution/{execId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtains detailed information about the service by identifier
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get', 'id', id);
            const localVarPath = `/service/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtains information about all inputs of the specific instance and execution
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inputList: async (id: string, execId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inputList', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('inputList', 'execId', execId);
            const localVarPath = `/service/{id}/execution/{execId}/input`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Launch an execution by service and execution identifier. It creates an headless environment and execute the notebook.
         * @param {string} id
         * @param {string} execId
         * @param {RequestGenericStart} requestGenericStart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launch: async (id: string, execId: string, requestGenericStart: RequestGenericStart, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('launch', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('launch', 'execId', execId);
            // verify required parameter 'requestGenericStart' is not null or undefined
            assertParamExists('launch', 'requestGenericStart', requestGenericStart);
            const localVarPath = `/service/{id}/execution/{execId}/launch`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestGenericStart, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves information of all output files of the specific instance and information (generated from script and system)
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outputList: async (id: string, execId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('outputList', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('outputList', 'execId', execId);
            const localVarPath = `/service/{id}/execution/{execId}/output`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Publish service
         * @param {string} id
         * @param {RequestServicePublish} requestServicePublish
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish: async (id: string, requestServicePublish: RequestServicePublish, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publish', 'id', id);
            // verify required parameter 'requestServicePublish' is not null or undefined
            assertParamExists('publish', 'requestServicePublish', requestServicePublish);
            const localVarPath = `/service/{id}/publish`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestServicePublish, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Rebuilds service
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('refresh', 'id', id);
            const localVarPath = `/service/refresh/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Reject service
         * @param {string} id
         * @param {RequestReject} requestReject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reject: async (id: string, requestReject: RequestReject, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('reject', 'id', id);
            // verify required parameter 'requestReject' is not null or undefined
            assertParamExists('reject', 'requestReject', requestReject);
            const localVarPath = `/service/{id}/reject`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestReject, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * retrieve a list of web services matching sorting, filtering and pagination constraints
         * @param {WebServiceSearchRequest} webServiceSearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (webServiceSearchRequest: WebServiceSearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'webServiceSearchRequest' is not null or undefined
            assertParamExists('search', 'webServiceSearchRequest', webServiceSearchRequest);
            const localVarPath = `/service/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(webServiceSearchRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks and selection a specific input from BEHUB
         * @param {string} id
         * @param {string} inputName
         * @param {RequestServiceBEHUBFileSelect} requestServiceBEHUBFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectBehubInput: async (
            id: string,
            inputName: string,
            requestServiceBEHUBFileSelect: RequestServiceBEHUBFileSelect,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('selectBehubInput', 'id', id);
            // verify required parameter 'inputName' is not null or undefined
            assertParamExists('selectBehubInput', 'inputName', inputName);
            // verify required parameter 'requestServiceBEHUBFileSelect' is not null or undefined
            assertParamExists('selectBehubInput', 'requestServiceBEHUBFileSelect', requestServiceBEHUBFileSelect);
            const localVarPath = `/service/{id}/select/behub`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'inputName'}}`, encodeURIComponent(String(inputName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestServiceBEHUBFileSelect, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks and selection a specific input from BEHUB
         * @param {string} id
         * @param {string} execId
         * @param {string} inputName
         * @param {RequestServiceBEHUBFileSelect} requestServiceBEHUBFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectBehubInput1: async (
            id: string,
            execId: string,
            inputName: string,
            requestServiceBEHUBFileSelect: RequestServiceBEHUBFileSelect,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('selectBehubInput1', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('selectBehubInput1', 'execId', execId);
            // verify required parameter 'inputName' is not null or undefined
            assertParamExists('selectBehubInput1', 'inputName', inputName);
            // verify required parameter 'requestServiceBEHUBFileSelect' is not null or undefined
            assertParamExists('selectBehubInput1', 'requestServiceBEHUBFileSelect', requestServiceBEHUBFileSelect);
            const localVarPath = `/service/{id}/input/{inputName}/select/behub`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)))
                .replace(`{${'inputName'}}`, encodeURIComponent(String(inputName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestServiceBEHUBFileSelect, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks and selection a specific input from external URL
         * @param {string} id
         * @param {RequestServiceUrlFileSelect} requestServiceUrlFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectExternalUrlInput: async (id: string, requestServiceUrlFileSelect: RequestServiceUrlFileSelect, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('selectExternalUrlInput', 'id', id);
            // verify required parameter 'requestServiceUrlFileSelect' is not null or undefined
            assertParamExists('selectExternalUrlInput', 'requestServiceUrlFileSelect', requestServiceUrlFileSelect);
            const localVarPath = `/service/{id}/input/select/url`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestServiceUrlFileSelect, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks and selection a specific input from external URL
         * @param {string} id
         * @param {string} execId
         * @param {RequestServiceUrlFileSelect} requestServiceUrlFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectExternalUrlInput1: async (
            id: string,
            execId: string,
            requestServiceUrlFileSelect: RequestServiceUrlFileSelect,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('selectExternalUrlInput1', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('selectExternalUrlInput1', 'execId', execId);
            // verify required parameter 'requestServiceUrlFileSelect' is not null or undefined
            assertParamExists('selectExternalUrlInput1', 'requestServiceUrlFileSelect', requestServiceUrlFileSelect);
            const localVarPath = `/service/{id}/execution/{execId}/input/select/url`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestServiceUrlFileSelect, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stops an execution by service and execution identifier. It destroy the execution of notebook and deletes it from the session.
         * @param {string} id
         * @param {string} execId
         * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stop: async (id: string, execId: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stop', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('stop', 'execId', execId);
            // verify required parameter 'requestBodyGenericStopProcess' is not null or undefined
            assertParamExists('stop', 'requestBodyGenericStopProcess', requestBodyGenericStopProcess);
            const localVarPath = `/service/{id}/execution/{execId}/stop`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestBodyGenericStopProcess, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify the specific service fields. Only the specified field on the request will be updated. The required field are name and email of author.
         * @param {string} id
         * @param {RequestServiceUpdate} requestServiceUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update: async (id: string, requestServiceUpdate: RequestServiceUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update', 'id', id);
            // verify required parameter 'requestServiceUpdate' is not null or undefined
            assertParamExists('update', 'requestServiceUpdate', requestServiceUpdate);
            const localVarPath = `/service/{id}/update`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestServiceUpdate, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify the specific service fields. Only the specified field on the request will be updated
         * @param {string} id
         * @param {RequestServicePublish} requestServicePublish
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublicationData: async (id: string, requestServicePublish: RequestServicePublish, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updatePublicationData', 'id', id);
            // verify required parameter 'requestServicePublish' is not null or undefined
            assertParamExists('updatePublicationData', 'requestServicePublish', requestServicePublish);
            const localVarPath = `/service/{id}/update/publication`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestServicePublish, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput12: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput13: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput14: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput15: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput16: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput17: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput18: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput19: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput20: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput21: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInputPatch: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInputPatch1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/service/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ServiceControllerApi - functional programming interface
 * @export
 */
export const ServiceControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ServiceControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Delete a service
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async _delete(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator._delete(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Approve service
         * @param {string} id
         * @param {RequestApprove} requestApprove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async approve(id: string, requestApprove: RequestApprove, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.approve(id, requestApprove, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates new service from project identifier.
         * @param {RequestServiceCreate} requestServiceCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create(requestServiceCreate: RequestServiceCreate, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create(requestServiceCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates an new execution on given service identifier
         * @param {string} id
         * @param {RequestServiceExecutionCreate} requestServiceExecutionCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExecution(
            id: string,
            requestServiceExecutionCreate: RequestServiceExecutionCreate,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceExecution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExecution(id, requestServiceExecutionCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a definition of an input for the service
         * @param {string} id
         * @param {string} execId
         * @param {RequestServiceFileCreate} requestServiceFileCreate
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createInput(
            id: string,
            execId: string,
            requestServiceFileCreate: RequestServiceFileCreate,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceFileCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInput(id, execId, requestServiceFileCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check if the service is aligned on relative project
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async diffToProject(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceDiffToProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.diffToProject(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Downloads the output notebook as html by service and execution id.
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadHtml(id: string, execId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadHtml(id, execId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {string} id
         * @param {string} execId
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadInput(
            id: string,
            execId: string,
            requestFile: RequestFile,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadInput(id, execId, requestFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Downloads the jupyter log files included the build of binder
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadLog(id: string, execId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadLog(id, execId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Downloads the log file as html
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async downloadLogTEST(id: string, execId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadLogTEST(id, execId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Downloads specific output file specified. The required field is the name.
         * @param {string} id
         * @param {string} execId
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadOutput(
            id: string,
            execId: string,
            requestFile: RequestFile,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadOutput(id, execId, requestFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtains updated list of all execution details
         * @param {string} id
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async executions(
            id: string,
            page?: number,
            size?: number,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceExecutionPaginated>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.executions(id, page, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Detail of execution information by service and execution id.
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async findExecution(
            id: string,
            execId: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceExecution>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.findExecution(id, execId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtains detailed information about the service by identifier
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtains information about all inputs of the specific instance and execution
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inputList(id: string, execId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceListInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inputList(id, execId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Launch an execution by service and execution identifier. It creates an headless environment and execute the notebook.
         * @param {string} id
         * @param {string} execId
         * @param {RequestGenericStart} requestGenericStart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async launch(
            id: string,
            execId: string,
            requestGenericStart: RequestGenericStart,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseCoreServiceExecutionLaunch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.launch(id, execId, requestGenericStart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves information of all output files of the specific instance and information (generated from script and system)
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outputList(id: string, execId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceListOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outputList(id, execId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Publish service
         * @param {string} id
         * @param {RequestServicePublish} requestServicePublish
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publish(
            id: string,
            requestServicePublish: RequestServicePublish,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publish(id, requestServicePublish, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Rebuilds service
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refresh(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refresh(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Reject service
         * @param {string} id
         * @param {RequestReject} requestReject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reject(id: string, requestReject: RequestReject, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reject(id, requestReject, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * retrieve a list of web services matching sorting, filtering and pagination constraints
         * @param {WebServiceSearchRequest} webServiceSearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(
            webServiceSearchRequest: WebServiceSearchRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceSearch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(webServiceSearchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checks and selection a specific input from BEHUB
         * @param {string} id
         * @param {string} inputName
         * @param {RequestServiceBEHUBFileSelect} requestServiceBEHUBFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectBehubInput(
            id: string,
            inputName: string,
            requestServiceBEHUBFileSelect: RequestServiceBEHUBFileSelect,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceBEHUBFileSelect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectBehubInput(id, inputName, requestServiceBEHUBFileSelect, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checks and selection a specific input from BEHUB
         * @param {string} id
         * @param {string} execId
         * @param {string} inputName
         * @param {RequestServiceBEHUBFileSelect} requestServiceBEHUBFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectBehubInput1(
            id: string,
            execId: string,
            inputName: string,
            requestServiceBEHUBFileSelect: RequestServiceBEHUBFileSelect,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceBEHUBFileSelect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectBehubInput1(id, execId, inputName, requestServiceBEHUBFileSelect, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checks and selection a specific input from external URL
         * @param {string} id
         * @param {RequestServiceUrlFileSelect} requestServiceUrlFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectExternalUrlInput(
            id: string,
            requestServiceUrlFileSelect: RequestServiceUrlFileSelect,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceUrlFileSelect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectExternalUrlInput(id, requestServiceUrlFileSelect, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checks and selection a specific input from external URL
         * @param {string} id
         * @param {string} execId
         * @param {RequestServiceUrlFileSelect} requestServiceUrlFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectExternalUrlInput1(
            id: string,
            execId: string,
            requestServiceUrlFileSelect: RequestServiceUrlFileSelect,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceUrlFileSelect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectExternalUrlInput1(id, execId, requestServiceUrlFileSelect, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stops an execution by service and execution identifier. It destroy the execution of notebook and deletes it from the session.
         * @param {string} id
         * @param {string} execId
         * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stop(
            id: string,
            execId: string,
            requestBodyGenericStopProcess: RequestBodyGenericStopProcess,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stop(id, execId, requestBodyGenericStopProcess, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify the specific service fields. Only the specified field on the request will be updated. The required field are name and email of author.
         * @param {string} id
         * @param {RequestServiceUpdate} requestServiceUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update(
            id: string,
            requestServiceUpdate: RequestServiceUpdate,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update(id, requestServiceUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify the specific service fields. Only the specified field on the request will be updated
         * @param {string} id
         * @param {RequestServicePublish} requestServicePublish
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updatePublicationData(
            id: string,
            requestServicePublish: RequestServicePublish,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WebService>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updatePublicationData(id, requestServicePublish, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput12(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput12(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput13(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput13(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput14(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput14(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput15(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput15(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput16(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput16(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput17(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput17(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput18(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput18(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput19(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput19(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput20(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput20(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput21(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput21(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInputPatch(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInputPatch(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInputPatch1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInputPatch1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ServiceControllerApi - factory interface
 * @export
 */
export const ServiceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ServiceControllerApiFp(configuration);
    return {
        /**
         * Delete a service
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        _delete(id: string, options?: any): AxiosPromise<void> {
            return localVarFp._delete(id, options).then(request => request(axios, basePath));
        },
        /**
         * Approve service
         * @param {string} id
         * @param {RequestApprove} requestApprove
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approve(id: string, requestApprove: RequestApprove, options?: any): AxiosPromise<WebService> {
            return localVarFp.approve(id, requestApprove, options).then(request => request(axios, basePath));
        },
        /**
         * Creates new service from project identifier.
         * @param {RequestServiceCreate} requestServiceCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create(requestServiceCreate: RequestServiceCreate, options?: any): AxiosPromise<WebService> {
            return localVarFp.create(requestServiceCreate, options).then(request => request(axios, basePath));
        },
        /**
         * Creates an new execution on given service identifier
         * @param {string} id
         * @param {RequestServiceExecutionCreate} requestServiceExecutionCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExecution(id: string, requestServiceExecutionCreate: RequestServiceExecutionCreate, options?: any): AxiosPromise<ResponseServiceExecution> {
            return localVarFp.createExecution(id, requestServiceExecutionCreate, options).then(request => request(axios, basePath));
        },
        /**
         * Creates a definition of an input for the service
         * @param {string} id
         * @param {string} execId
         * @param {RequestServiceFileCreate} requestServiceFileCreate
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createInput(id: string, execId: string, requestServiceFileCreate: RequestServiceFileCreate, options?: any): AxiosPromise<ResponseServiceFileCreate> {
            return localVarFp.createInput(id, execId, requestServiceFileCreate, options).then(request => request(axios, basePath));
        },
        /**
         * Check if the service is aligned on relative project
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        diffToProject(id: string, options?: any): AxiosPromise<ResponseServiceDiffToProject> {
            return localVarFp.diffToProject(id, options).then(request => request(axios, basePath));
        },
        /**
         * Downloads the output notebook as html by service and execution id.
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadHtml(id: string, execId: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadHtml(id, execId, options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {string} id
         * @param {string} execId
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInput(id: string, execId: string, requestFile: RequestFile, options?: any): AxiosPromise<File> {
            return localVarFp.downloadInput(id, execId, requestFile, options).then(request => request(axios, basePath));
        },
        /**
         * Downloads the jupyter log files included the build of binder
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadLog(id: string, execId: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadLog(id, execId, options).then(request => request(axios, basePath));
        },
        /**
         * Downloads the log file as html
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        downloadLogTEST(id: string, execId: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadLogTEST(id, execId, options).then(request => request(axios, basePath));
        },
        /**
         * Downloads specific output file specified. The required field is the name.
         * @param {string} id
         * @param {string} execId
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOutput(id: string, execId: string, requestFile: RequestFile, options?: any): AxiosPromise<File> {
            return localVarFp.downloadOutput(id, execId, requestFile, options).then(request => request(axios, basePath));
        },
        /**
         * Obtains updated list of all execution details
         * @param {string} id
         * @param {number} [page]
         * @param {number} [size]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        executions(id: string, page?: number, size?: number, options?: any): AxiosPromise<ResponseServiceExecutionPaginated> {
            return localVarFp.executions(id, page, size, options).then(request => request(axios, basePath));
        },
        /**
         * Detail of execution information by service and execution id.
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findExecution(id: string, execId: string, options?: any): AxiosPromise<ResponseServiceExecution> {
            return localVarFp.findExecution(id, execId, options).then(request => request(axios, basePath));
        },
        /**
         * Obtains detailed information about the service by identifier
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get(id: string, options?: any): AxiosPromise<ResponseServiceGet> {
            return localVarFp.get(id, options).then(request => request(axios, basePath));
        },
        /**
         * Obtains information about all inputs of the specific instance and execution
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inputList(id: string, execId: string, options?: any): AxiosPromise<ResponseServiceListInput> {
            return localVarFp.inputList(id, execId, options).then(request => request(axios, basePath));
        },
        /**
         * Launch an execution by service and execution identifier. It creates an headless environment and execute the notebook.
         * @param {string} id
         * @param {string} execId
         * @param {RequestGenericStart} requestGenericStart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launch(id: string, execId: string, requestGenericStart: RequestGenericStart, options?: any): AxiosPromise<ResponseCoreServiceExecutionLaunch> {
            return localVarFp.launch(id, execId, requestGenericStart, options).then(request => request(axios, basePath));
        },
        /**
         * Retrieves information of all output files of the specific instance and information (generated from script and system)
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outputList(id: string, execId: string, options?: any): AxiosPromise<ResponseServiceListOutput> {
            return localVarFp.outputList(id, execId, options).then(request => request(axios, basePath));
        },
        /**
         * Publish service
         * @param {string} id
         * @param {RequestServicePublish} requestServicePublish
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publish(id: string, requestServicePublish: RequestServicePublish, options?: any): AxiosPromise<WebService> {
            return localVarFp.publish(id, requestServicePublish, options).then(request => request(axios, basePath));
        },
        /**
         * Rebuilds service
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refresh(id: string, options?: any): AxiosPromise<WebService> {
            return localVarFp.refresh(id, options).then(request => request(axios, basePath));
        },
        /**
         * Reject service
         * @param {string} id
         * @param {RequestReject} requestReject
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reject(id: string, requestReject: RequestReject, options?: any): AxiosPromise<WebService> {
            return localVarFp.reject(id, requestReject, options).then(request => request(axios, basePath));
        },
        /**
         * retrieve a list of web services matching sorting, filtering and pagination constraints
         * @param {WebServiceSearchRequest} webServiceSearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(webServiceSearchRequest: WebServiceSearchRequest, options?: any): AxiosPromise<ResponseServiceSearch> {
            return localVarFp.search(webServiceSearchRequest, options).then(request => request(axios, basePath));
        },
        /**
         * Checks and selection a specific input from BEHUB
         * @param {string} id
         * @param {string} inputName
         * @param {RequestServiceBEHUBFileSelect} requestServiceBEHUBFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectBehubInput(id: string, inputName: string, requestServiceBEHUBFileSelect: RequestServiceBEHUBFileSelect, options?: any): AxiosPromise<ResponseServiceBEHUBFileSelect> {
            return localVarFp.selectBehubInput(id, inputName, requestServiceBEHUBFileSelect, options).then(request => request(axios, basePath));
        },
        /**
         * Checks and selection a specific input from BEHUB
         * @param {string} id
         * @param {string} execId
         * @param {string} inputName
         * @param {RequestServiceBEHUBFileSelect} requestServiceBEHUBFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectBehubInput1(
            id: string,
            execId: string,
            inputName: string,
            requestServiceBEHUBFileSelect: RequestServiceBEHUBFileSelect,
            options?: any,
        ): AxiosPromise<ResponseServiceBEHUBFileSelect> {
            return localVarFp.selectBehubInput1(id, execId, inputName, requestServiceBEHUBFileSelect, options).then(request => request(axios, basePath));
        },
        /**
         * Checks and selection a specific input from external URL
         * @param {string} id
         * @param {RequestServiceUrlFileSelect} requestServiceUrlFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectExternalUrlInput(id: string, requestServiceUrlFileSelect: RequestServiceUrlFileSelect, options?: any): AxiosPromise<ResponseServiceUrlFileSelect> {
            return localVarFp.selectExternalUrlInput(id, requestServiceUrlFileSelect, options).then(request => request(axios, basePath));
        },
        /**
         * Checks and selection a specific input from external URL
         * @param {string} id
         * @param {string} execId
         * @param {RequestServiceUrlFileSelect} requestServiceUrlFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectExternalUrlInput1(id: string, execId: string, requestServiceUrlFileSelect: RequestServiceUrlFileSelect, options?: any): AxiosPromise<ResponseServiceUrlFileSelect> {
            return localVarFp.selectExternalUrlInput1(id, execId, requestServiceUrlFileSelect, options).then(request => request(axios, basePath));
        },
        /**
         * Stops an execution by service and execution identifier. It destroy the execution of notebook and deletes it from the session.
         * @param {string} id
         * @param {string} execId
         * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stop(id: string, execId: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.stop(id, execId, requestBodyGenericStopProcess, options).then(request => request(axios, basePath));
        },
        /**
         * Modify the specific service fields. Only the specified field on the request will be updated. The required field are name and email of author.
         * @param {string} id
         * @param {RequestServiceUpdate} requestServiceUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update(id: string, requestServiceUpdate: RequestServiceUpdate, options?: any): AxiosPromise<ResponseServiceUpdate> {
            return localVarFp.update(id, requestServiceUpdate, options).then(request => request(axios, basePath));
        },
        /**
         * Modify the specific service fields. Only the specified field on the request will be updated
         * @param {string} id
         * @param {RequestServicePublish} requestServicePublish
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePublicationData(id: string, requestServicePublish: RequestServicePublish, options?: any): AxiosPromise<WebService> {
            return localVarFp.updatePublicationData(id, requestServicePublish, options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput12(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput12(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput13(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput13(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput14(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput14(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput15(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput15(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput16(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput16(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput17(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput17(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput18(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput18(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput19(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput19(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput20(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput20(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput21(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput21(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInputPatch(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInputPatch(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInputPatch1(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInputPatch1(options).then(request => request(axios, basePath));
        },
    };
};

/**
 * ServiceControllerApi - interface
 * @export
 * @interface ServiceControllerApi
 */
export interface ServiceControllerApiInterface {
    /**
     * Delete a service
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    _delete(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Approve service
     * @param {string} id
     * @param {RequestApprove} requestApprove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    approve(id: string, requestApprove: RequestApprove, options?: AxiosRequestConfig): AxiosPromise<WebService>;

    /**
     * Creates new service from project identifier.
     * @param {RequestServiceCreate} requestServiceCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    create(requestServiceCreate: RequestServiceCreate, options?: AxiosRequestConfig): AxiosPromise<WebService>;

    /**
     * Creates an new execution on given service identifier
     * @param {string} id
     * @param {RequestServiceExecutionCreate} requestServiceExecutionCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    createExecution(id: string, requestServiceExecutionCreate: RequestServiceExecutionCreate, options?: AxiosRequestConfig): AxiosPromise<ResponseServiceExecution>;

    /**
     * Creates a definition of an input for the service
     * @param {string} id
     * @param {string} execId
     * @param {RequestServiceFileCreate} requestServiceFileCreate
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    createInput(id: string, execId: string, requestServiceFileCreate: RequestServiceFileCreate, options?: AxiosRequestConfig): AxiosPromise<ResponseServiceFileCreate>;

    /**
     * Check if the service is aligned on relative project
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    diffToProject(id: string, options?: AxiosRequestConfig): AxiosPromise<ResponseServiceDiffToProject>;

    /**
     * Downloads the output notebook as html by service and execution id.
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    downloadHtml(id: string, execId: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {string} id
     * @param {string} execId
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    downloadInput(id: string, execId: string, requestFile: RequestFile, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * Downloads the jupyter log files included the build of binder
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    downloadLog(id: string, execId: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Downloads the log file as html
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    downloadLogTEST(id: string, execId: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Downloads specific output file specified. The required field is the name.
     * @param {string} id
     * @param {string} execId
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    downloadOutput(id: string, execId: string, requestFile: RequestFile, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * Obtains updated list of all execution details
     * @param {string} id
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    executions(id: string, page?: number, size?: number, options?: AxiosRequestConfig): AxiosPromise<ResponseServiceExecutionPaginated>;

    /**
     * Detail of execution information by service and execution id.
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    findExecution(id: string, execId: string, options?: AxiosRequestConfig): AxiosPromise<ResponseServiceExecution>;

    /**
     * Obtains detailed information about the service by identifier
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    get(id: string, options?: AxiosRequestConfig): AxiosPromise<ResponseServiceGet>;

    /**
     * Obtains information about all inputs of the specific instance and execution
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    inputList(id: string, execId: string, options?: AxiosRequestConfig): AxiosPromise<ResponseServiceListInput>;

    /**
     * Launch an execution by service and execution identifier. It creates an headless environment and execute the notebook.
     * @param {string} id
     * @param {string} execId
     * @param {RequestGenericStart} requestGenericStart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    launch(id: string, execId: string, requestGenericStart: RequestGenericStart, options?: AxiosRequestConfig): AxiosPromise<ResponseCoreServiceExecutionLaunch>;

    /**
     * Retrieves information of all output files of the specific instance and information (generated from script and system)
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    outputList(id: string, execId: string, options?: AxiosRequestConfig): AxiosPromise<ResponseServiceListOutput>;

    /**
     * Publish service
     * @param {string} id
     * @param {RequestServicePublish} requestServicePublish
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    publish(id: string, requestServicePublish: RequestServicePublish, options?: AxiosRequestConfig): AxiosPromise<WebService>;

    /**
     * Rebuilds service
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    refresh(id: string, options?: AxiosRequestConfig): AxiosPromise<WebService>;

    /**
     * Reject service
     * @param {string} id
     * @param {RequestReject} requestReject
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    reject(id: string, requestReject: RequestReject, options?: AxiosRequestConfig): AxiosPromise<WebService>;

    /**
     * retrieve a list of web services matching sorting, filtering and pagination constraints
     * @param {WebServiceSearchRequest} webServiceSearchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    search(webServiceSearchRequest: WebServiceSearchRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseServiceSearch>;

    /**
     * Checks and selection a specific input from BEHUB
     * @param {string} id
     * @param {string} inputName
     * @param {RequestServiceBEHUBFileSelect} requestServiceBEHUBFileSelect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    selectBehubInput(
        id: string,
        inputName: string,
        requestServiceBEHUBFileSelect: RequestServiceBEHUBFileSelect,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseServiceBEHUBFileSelect>;

    /**
     * Checks and selection a specific input from BEHUB
     * @param {string} id
     * @param {string} execId
     * @param {string} inputName
     * @param {RequestServiceBEHUBFileSelect} requestServiceBEHUBFileSelect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    selectBehubInput1(
        id: string,
        execId: string,
        inputName: string,
        requestServiceBEHUBFileSelect: RequestServiceBEHUBFileSelect,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseServiceBEHUBFileSelect>;

    /**
     * Checks and selection a specific input from external URL
     * @param {string} id
     * @param {RequestServiceUrlFileSelect} requestServiceUrlFileSelect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    selectExternalUrlInput(id: string, requestServiceUrlFileSelect: RequestServiceUrlFileSelect, options?: AxiosRequestConfig): AxiosPromise<ResponseServiceUrlFileSelect>;

    /**
     * Checks and selection a specific input from external URL
     * @param {string} id
     * @param {string} execId
     * @param {RequestServiceUrlFileSelect} requestServiceUrlFileSelect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    selectExternalUrlInput1(
        id: string,
        execId: string,
        requestServiceUrlFileSelect: RequestServiceUrlFileSelect,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseServiceUrlFileSelect>;

    /**
     * Stops an execution by service and execution identifier. It destroy the execution of notebook and deletes it from the session.
     * @param {string} id
     * @param {string} execId
     * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    stop(id: string, execId: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options?: AxiosRequestConfig): AxiosPromise<BaseResponse>;

    /**
     * Modify the specific service fields. Only the specified field on the request will be updated. The required field are name and email of author.
     * @param {string} id
     * @param {RequestServiceUpdate} requestServiceUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    update(id: string, requestServiceUpdate: RequestServiceUpdate, options?: AxiosRequestConfig): AxiosPromise<ResponseServiceUpdate>;

    /**
     * Modify the specific service fields. Only the specified field on the request will be updated
     * @param {string} id
     * @param {RequestServicePublish} requestServicePublish
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    updatePublicationData(id: string, requestServicePublish: RequestServicePublish, options?: AxiosRequestConfig): AxiosPromise<WebService>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    uploadInput12(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    uploadInput13(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    uploadInput14(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    uploadInput15(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    uploadInput16(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    uploadInput17(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    uploadInput18(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    uploadInput19(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    uploadInput20(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    uploadInput21(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    uploadInputPatch(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApiInterface
     */
    uploadInputPatch1(options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * ServiceControllerApi - object-oriented interface
 * @export
 * @class ServiceControllerApi
 * @extends {BaseAPI}
 */
export class ServiceControllerApi extends BaseAPI implements ServiceControllerApiInterface {
    /**
     * Delete a service
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public _delete(id: string, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            ._delete(id, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Approve service
     * @param {string} id
     * @param {RequestApprove} requestApprove
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public approve(id: string, requestApprove: RequestApprove, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .approve(id, requestApprove, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Creates new service from project identifier.
     * @param {RequestServiceCreate} requestServiceCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public create(requestServiceCreate: RequestServiceCreate, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .create(requestServiceCreate, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Creates an new execution on given service identifier
     * @param {string} id
     * @param {RequestServiceExecutionCreate} requestServiceExecutionCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public createExecution(id: string, requestServiceExecutionCreate: RequestServiceExecutionCreate, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .createExecution(id, requestServiceExecutionCreate, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Creates a definition of an input for the service
     * @param {string} id
     * @param {string} execId
     * @param {RequestServiceFileCreate} requestServiceFileCreate
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public createInput(id: string, execId: string, requestServiceFileCreate: RequestServiceFileCreate, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .createInput(id, execId, requestServiceFileCreate, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Check if the service is aligned on relative project
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public diffToProject(id: string, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .diffToProject(id, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Downloads the output notebook as html by service and execution id.
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public downloadHtml(id: string, execId: string, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .downloadHtml(id, execId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {string} id
     * @param {string} execId
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public downloadInput(id: string, execId: string, requestFile: RequestFile, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .downloadInput(id, execId, requestFile, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Downloads the jupyter log files included the build of binder
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public downloadLog(id: string, execId: string, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .downloadLog(id, execId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Downloads the log file as html
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public downloadLogTEST(id: string, execId: string, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .downloadLogTEST(id, execId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Downloads specific output file specified. The required field is the name.
     * @param {string} id
     * @param {string} execId
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public downloadOutput(id: string, execId: string, requestFile: RequestFile, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .downloadOutput(id, execId, requestFile, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Obtains updated list of all execution details
     * @param {string} id
     * @param {number} [page]
     * @param {number} [size]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public executions(id: string, page?: number, size?: number, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .executions(id, page, size, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Detail of execution information by service and execution id.
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public findExecution(id: string, execId: string, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .findExecution(id, execId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Obtains detailed information about the service by identifier
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public get(id: string, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .get(id, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Obtains information about all inputs of the specific instance and execution
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public inputList(id: string, execId: string, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .inputList(id, execId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Launch an execution by service and execution identifier. It creates an headless environment and execute the notebook.
     * @param {string} id
     * @param {string} execId
     * @param {RequestGenericStart} requestGenericStart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public launch(id: string, execId: string, requestGenericStart: RequestGenericStart, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .launch(id, execId, requestGenericStart, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Retrieves information of all output files of the specific instance and information (generated from script and system)
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public outputList(id: string, execId: string, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .outputList(id, execId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Publish service
     * @param {string} id
     * @param {RequestServicePublish} requestServicePublish
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public publish(id: string, requestServicePublish: RequestServicePublish, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .publish(id, requestServicePublish, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Rebuilds service
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public refresh(id: string, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .refresh(id, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Reject service
     * @param {string} id
     * @param {RequestReject} requestReject
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public reject(id: string, requestReject: RequestReject, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .reject(id, requestReject, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * retrieve a list of web services matching sorting, filtering and pagination constraints
     * @param {WebServiceSearchRequest} webServiceSearchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public search(webServiceSearchRequest: WebServiceSearchRequest, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .search(webServiceSearchRequest, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Checks and selection a specific input from BEHUB
     * @param {string} id
     * @param {string} inputName
     * @param {RequestServiceBEHUBFileSelect} requestServiceBEHUBFileSelect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public selectBehubInput(id: string, inputName: string, requestServiceBEHUBFileSelect: RequestServiceBEHUBFileSelect, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .selectBehubInput(id, inputName, requestServiceBEHUBFileSelect, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Checks and selection a specific input from BEHUB
     * @param {string} id
     * @param {string} execId
     * @param {string} inputName
     * @param {RequestServiceBEHUBFileSelect} requestServiceBEHUBFileSelect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public selectBehubInput1(id: string, execId: string, inputName: string, requestServiceBEHUBFileSelect: RequestServiceBEHUBFileSelect, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .selectBehubInput1(id, execId, inputName, requestServiceBEHUBFileSelect, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Checks and selection a specific input from external URL
     * @param {string} id
     * @param {RequestServiceUrlFileSelect} requestServiceUrlFileSelect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public selectExternalUrlInput(id: string, requestServiceUrlFileSelect: RequestServiceUrlFileSelect, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .selectExternalUrlInput(id, requestServiceUrlFileSelect, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Checks and selection a specific input from external URL
     * @param {string} id
     * @param {string} execId
     * @param {RequestServiceUrlFileSelect} requestServiceUrlFileSelect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public selectExternalUrlInput1(id: string, execId: string, requestServiceUrlFileSelect: RequestServiceUrlFileSelect, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .selectExternalUrlInput1(id, execId, requestServiceUrlFileSelect, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Stops an execution by service and execution identifier. It destroy the execution of notebook and deletes it from the session.
     * @param {string} id
     * @param {string} execId
     * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public stop(id: string, execId: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .stop(id, execId, requestBodyGenericStopProcess, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Modify the specific service fields. Only the specified field on the request will be updated. The required field are name and email of author.
     * @param {string} id
     * @param {RequestServiceUpdate} requestServiceUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public update(id: string, requestServiceUpdate: RequestServiceUpdate, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .update(id, requestServiceUpdate, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Modify the specific service fields. Only the specified field on the request will be updated
     * @param {string} id
     * @param {RequestServicePublish} requestServicePublish
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public updatePublicationData(id: string, requestServicePublish: RequestServicePublish, options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .updatePublicationData(id, requestServicePublish, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public uploadInput12(options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .uploadInput12(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public uploadInput13(options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .uploadInput13(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public uploadInput14(options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .uploadInput14(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public uploadInput15(options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .uploadInput15(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public uploadInput16(options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .uploadInput16(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public uploadInput17(options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .uploadInput17(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public uploadInput18(options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .uploadInput18(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public uploadInput19(options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .uploadInput19(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public uploadInput20(options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .uploadInput20(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public uploadInput21(options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .uploadInput21(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public uploadInputPatch(options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .uploadInputPatch(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ServiceControllerApi
     */
    public uploadInputPatch1(options?: AxiosRequestConfig) {
        return ServiceControllerApiFp(this.configuration)
            .uploadInputPatch1(options)
            .then(request => request(this.axios, this.basePath));
    }
}
