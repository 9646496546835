import { BackofficeView } from '@features/backoffice/views/backoffice.view';
import { FaqsView } from '@features/faqs/views/faqs.view';
import { HomeView } from '@features/home/views/home.view';
import { LoginView } from '@features/login/views/login.view';
import { MiniSiteView } from '@features/minisite/views/minisite.view';
import { ProjectDetailView } from '@features/project/views/project.detail.view';
import { ProjectView } from '@features/project/views/project.view';
import { ServiceDataComponent } from '@features/provisioning/components/provisiong.service.data.component';
import { ProvisioningDatasetComponent } from '@features/provisioning/components/provisioning.dataset.component';
import { ProvisioningMetadataComponent } from '@features/provisioning/components/provisioning.metadata.component';
import { ProjectDataComponent } from '@features/provisioning/components/provisioning.project.data.component';
import { ProvisioningPublicationComponent } from '@features/provisioning/components/provisioning.publication.component';
import { ProvisioningRequirementsComponent } from '@features/provisioning/components/provisioning.requirements.component';
import { ProvisioningTemplatesComponent } from '@features/provisioning/components/provisioning.templates.component';
import { ProvisioningView } from '@features/provisioning/views/provisioning.view';
import { ServiceDetailExecutionView } from '@features/service/views/service.detail.execution.view';
import { ServiceDetailView } from '@features/service/views/service.detail.view';
import { ServiceView } from '@features/service/views/service.view';
import { Route } from 'react-router-dom';

export const PUBLIC_ROUTES = [] as const;

export const PRIVATE_ROUTES = [
    { id: 'home', path: '/home', component: HomeView },
    { id: 'service', path: '/service', component: ServiceView },
    { id: 'project', path: '/project', component: ProjectView },
    { id: 'projectDetail', path: '/project/*', component: ProjectDetailView },
    { id: 'serviceDetail', path: '/service/:id', component: ServiceDetailView },
    { id: 'wizardCheckbox', path: '/provisioningCheckbox', component: ProvisioningRequirementsComponent },
    { id: 'ProvisioningView', path: '/provisioning/*', component: ProvisioningView },
    { id: 'ServiceDetailExecutionView', path: '/service/:serviceId/execution/:execId', component: ServiceDetailExecutionView },
    { id: 'miniSiteView', path: '/minisite/:jwtPayload/:jwtSignature/:jwtHeader', component: MiniSiteView },
    { id: 'faqs', path: '/faqs', component: FaqsView },
    { id: 'administration', path: '/administration', component: BackofficeView },
] as const;

const routes = [...PUBLIC_ROUTES, ...PRIVATE_ROUTES] as const;

const GuardRoute: React.FC<any> = ({ path, component }) => {
    return <Route exact path={path} component={component} />;
};

export const PublicRoutes = () => (
    <>
        {PUBLIC_ROUTES.map(({ path, component }) => (
            <Route key={path} path={path} component={component} />
        ))}
    </>
);

export const PrivateRoutes = () => {
    return (
        <>
            {PRIVATE_ROUTES.map(({ path, component }) => (
                <GuardRoute key={path} path={path} component={component} />
            ))}
        </>
    );
};

export type Paths = (typeof routes)[number]['path'];

export const ProvisioningRoutes = [
    { path: '/serviceData', component: ServiceDataComponent },
    { path: '/dataset', component: ProvisioningDatasetComponent },
    { path: '/metadata', component: ProvisioningMetadataComponent },
    { path: '/projectData', component: ProjectDataComponent },
    { path: '/publication', component: ProvisioningPublicationComponent },
    { path: '/requirements', component: ProvisioningRequirementsComponent },
    { path: '/templates', component: ProvisioningTemplatesComponent },
];
