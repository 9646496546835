/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { InputFile } from './input-file';
// May contain unused imports in some cases
// @ts-ignore
import { Integrations } from './integrations';
// May contain unused imports in some cases
// @ts-ignore
import { Runtime } from './runtime';
// May contain unused imports in some cases
// @ts-ignore
import { User } from './user';
// May contain unused imports in some cases
// @ts-ignore
import { VersionControl } from './version-control';

/**
 *
 * @export
 * @interface WebService
 */
export interface WebService {
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    version?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    technology?: string;
    /**
     *
     * @type {User}
     * @memberof WebService
     */
    author?: User;
    /**
     *
     * @type {User}
     * @memberof WebService
     */
    approver?: User;
    /**
     *
     * @type {boolean}
     * @memberof WebService
     */
    published?: boolean;
    /**
     *
     * @type {VersionControl}
     * @memberof WebService
     */
    git?: VersionControl;
    /**
     *
     * @type {Runtime}
     * @memberof WebService
     */
    runtime?: Runtime;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    status?: WebServiceStatusEnum;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    description?: string;
    /**
     *
     * @type {Array<string>}
     * @memberof WebService
     */
    categories?: Array<string>;
    /**
     *
     * @type {Integrations}
     * @memberof WebService
     */
    integrations?: Integrations;
    /**
     *
     * @type {Array<string>}
     * @memberof WebService
     */
    keywords?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    community?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    helpdesk?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    training?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    title?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    subtitle?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    logo?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    landscape?: string;
    /**
     *
     * @type {{ [key: string]: InputFile | undefined; }}
     * @memberof WebService
     */
    inputs?: { [key: string]: InputFile | undefined };
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    image?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    id_reference_project?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    published_at?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    approved_at?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    rejected_at?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    rejected_reason?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    chat_url?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    wiki_url?: string;
    /**
     *
     * @type {string}
     * @memberof WebService
     */
    minisite_title?: string;
}

export const WebServiceStatusEnum = {
    ALL: 'ALL',
    DRAFT: 'DRAFT',
    TO_BE_PUBLISHED: 'TO_BE_PUBLISHED',
    TO_BE_APPROVED: 'TO_BE_APPROVED',
    PUBLISHED: 'PUBLISHED',
    REJECTED: 'REJECTED',
    TO_BE_DELETED: 'TO_BE_DELETED',
    DELETED: 'DELETED',
} as const;

export type WebServiceStatusEnum = (typeof WebServiceStatusEnum)[keyof typeof WebServiceStatusEnum];
