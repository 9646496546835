import { createIntl, createIntlCache, IntlShape } from '@formatjs/intl';
import React from 'react';
import { ITranslatorManager } from './FacadeTranslatorManager';

const cache = createIntlCache();

export class LanguageManager implements ITranslatorManager {
    private static instance: LanguageManager;
    private intl: IntlShape<string>;

    public static getInstance(messages?: Record<string, string>) {
        if (LanguageManager.instance == null) {
            LanguageManager.instance = new LanguageManager(messages);
        }
        return LanguageManager.instance;
    }

    constructor(messages?: Record<string, string>) {
        this.intl = createIntl(
            {
                locale: 'en-EN',
                messages,
            },
            cache,
        );
    }

    get(id: string, values?: Record<string, string>) {
        return this.intl.formatMessage(
            { id },
            {
                ...(values as any),
                b: str => React.createElement('b', null, str),
                li: str => React.createElement('li', null, str),
                ul: str => React.createElement('ul', null, str),
                i: str => React.createElement('i', null, str),
            },
        );
    }

    static async loadLocales() {
        const paths = ['_shared', 'login', 'project', 'provisioning', 'service', 'home', 'minisite', 'faqs', 'backoffice'];
        return await paths.reduce(async (previousPromise, path) => {
            const acc = await previousPromise;
            const locale = (await import(`@features/${path}/locales/en.json`)).default;
            return {
                ...acc,
                ...locale,
            };
        }, Promise.resolve<any>({}));
    }
}
