import { ReactComponent as ArrowRight } from '@assets/svg/it-arrow-right.svg';
import { ReactComponent as Error } from '@assets/svg/it-close-circle.svg';

import { ReactComponent as Download } from '@assets/svg/it-download.svg';
import { ReactComponent as LinkIcon } from '@assets/svg/link-solid.svg';
import Table from '@features/_shared/components/Table/table.component';
import { TextField } from '@features/_shared/components/TextField';
import { LoginState } from '@features/login/store/state';
import { ServiceBehubInputUploadComponent } from '@features/service/components/service.behub.input.upload.component';
import { ServiceLocalInputUploadComponent } from '@features/service/components/service.local.input.upload.component';
import { TranslatorManager } from '@managers/TranslatorManager';
import { FormManager } from '@managers/form.manager';
import { MinisiteManager } from '@managers/minisite.manager';
import { ServiceManager } from '@managers/service.manager';
import { FormHelperUtil } from '@utils/form.helper.util';
import { FormatUtil } from '@utils/formaters.util';
import DOMPurify from 'dompurify';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IntlContext } from 'react-intl';
import { InputFile, InputFileStatusEnum, InputFileTypeEnum, OutputFile, ServiceExecution, WebService } from '../../../api';
import { ResponseServiceExecutionMinisite, ResponseServiceExecutionMinisiteStatusEnum } from '../../../api/entities/response-service-execution-minisite';
import { MinisiteEmailFormData } from '../beans/MinisiteEmailFromData';
import { ExecutionMinisiteState, OutputState } from '../store/state';
import './styles/minisite.scss';

export interface IMiniSiteBodyProps {
    service?: WebService;
    execution?: ExecutionMinisiteState;
    user: LoginState;
    selectedExecution: ResponseServiceExecutionMinisite | undefined;
    output: OutputState;
    handleLaunch: (token: string) => void;
    onBlur?: (value: string) => void;
    handleViewDataset: (token: string, fileName: string, type: InputFileTypeEnum, template?: string) => void;
    handleUploadFromURL: (token: string, fileUrl: string, fileName: string) => {};
    handleUploadFromLocal: () => void;
    handleUseDataset: (token: string, fileName: string) => void;
    handleGetLogs: (token: string) => void;
}

export const MiniSiteBody: React.FC<IMiniSiteBodyProps> = ({
    service,
    execution,
    output,
    handleLaunch,
    onBlur,
    selectedExecution,
    handleViewDataset,
    user,
    handleUploadFromURL,
    handleUploadFromLocal,
    handleUseDataset,
    handleGetLogs,
}) => {
    const translator = TranslatorManager.getInstance();
    const serviceManager = ServiceManager.getInstance();
    const minisiteManager = MinisiteManager.getInstance();
    const intl = useContext(IntlContext);
    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator('minisite.email', intl as any);
    const [filename, setFileName] = useState<string>();
    const [isInputModalClosed, setIsInputModalClosed] = useState<boolean>(false);
    const [isLogShown, setIsLogShown] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const logButtonRef = useRef<HTMLButtonElement>(null);
    let logsRef = useRef<HTMLDivElement>(null);

    const handleLogsScroll = () => logsRef.current && logsRef.current.scrollTo({ top: logsRef.current.scrollHeight, behavior: 'smooth' });

    useEffect(() => {
        handleLogsScroll();
    }, [execution]);

    const { errors, register, trigger, setValue } = FormManager.getInstance().buildFormProxy<MinisiteEmailFormData>(MinisiteEmailFormData, () => {});
    const outputCols = [
        translator.get('service.execution.detail.table.header.name.text'),
        translator.get('service.execution.detail.output.table.header.date.text'),
        translator.get('service.execution.detail.output.table.header.size.text'),
        translator.get('common.table.header.actions.text'),
    ];

    const outputRows =
        output.outputs &&
        output.outputs.map((item: OutputFile | undefined) => {
            return {
                id: item?.filename,
                values: {
                    name: item?.filename,
                    date: moment(new Date(item?.updated_at as string)).format('DD/MM/YYYY HH:mm'),
                    size: item?.size ? FormatUtil.formatBytes(item.size) : '0 Bytes',
                    actions: (
                        <button className='btn ' onClick={() => handleDownloadOutput(item?.filename as string, selectedExecution?.data as string)}>
                            {<Download className='icon' fill='#009138' />}
                        </button>
                    ),
                },
            };
        });

    const handleDownloadOutput = async (fileName: string, token: string) => {
        let [header, payload, signature] = token.split('.');
        const response = await minisiteManager.downloadOutput(header, payload, signature, { name: fileName });
        const href = response && URL.createObjectURL(new Blob([response], { type: 'application/octet-stream' }));
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    };

    const handleLaunchClick = async (token: string) => {
        setIsSubmitted(true);
        const isValidated = await trigger();
        isValidated && token && handleLaunch(token);
    };

    const triggerValidation = async (key: string, value: string) => {
        setValue(key, value);
        isSubmitted && (await trigger(key));
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        onBlur?.(e.target.value);
    };

    const handleOnInput = (e: React.FormEvent<HTMLInputElement>) => {
        onBlur?.(e.currentTarget.value);
    };

    const getLogs = (e: React.MouseEvent<HTMLButtonElement>, token: string) => {
        if (!isLogShown) {
            e.stopPropagation();
            setIsLogShown(true);
            handleGetLogs(token);
            let current: any = logButtonRef.current;
            current.click();
        } else {
            setIsLogShown(false);
        }
    };

    const getExpirationDate = (date: string, days: number) => {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return moment.utc(result).format('DD/MM/YYYY');
    };

    const isButtonDisable = () => {
        let emailRegex = new RegExp('^[_A-Za-z0-9-]+(\\.[_A-Za-z0-9-]+)*@[A-Za-z0-9]+(\\.[A-Za-z0-9]+)*(\\.[A-Za-z]{2,})$');
        const isEmailNotValidated = !execution?.email || execution?.email === '' || !emailRegex.test(execution?.email);

        const inputsWait = selectedExecution?.inputs && Object.values(selectedExecution?.inputs).find((item: InputFile | undefined) => item?.status === InputFileStatusEnum.WAIT);
        const isButtonDisabled = isEmailNotValidated || (inputsWait ? true : false) || selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.RUNNING;
        return isButtonDisabled;
    };

    const isEmailAccordionEnabled = () => {
        if (selectedExecution?.inputs && Object.keys(selectedExecution.inputs).length > 0) {
            return Object.values(selectedExecution?.inputs).find((item: InputFile | undefined) => item?.status === InputFileStatusEnum.WAIT) ? false : true;
        } else {
            return true;
        }
    };

    const getIconBasedOnStatusAndSize = (status: InputFileStatusEnum, fileName: string, size: number, total: number) => {
        let percentage = (((size as number) / (total as number)) * 100).toFixed(2);

        if (status === InputFileStatusEnum.READY && size > 0) {
            return <p className='p-3'>{fileName}</p>;
        } else if (status === InputFileStatusEnum.PROGRESS && percentage && Number(percentage) > 0) {
            return (
                <div className='minisite-loader-container'>
                    <div className='input-loader'></div>
                    <div className='loader-percentage'>{percentage + '%'}</div>
                </div>
            );
        } else if ((status === InputFileStatusEnum.WAIT && size === 0) || status === InputFileStatusEnum.ERROR) {
            return null;
        }
    };

    return (
        <React.Fragment>
            <div>
                <div className='container'>
                    {/* Accordion Input */}
                    <div className='accordion accordion-background-active border-bottom-0' id='accordion'>
                        <div className='row mb-3'>
                            <div className='col'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            disabled={selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.FAILED}
                                            className={`${
                                                selectedExecution?.status !== ResponseServiceExecutionMinisiteStatusEnum.FAILED ? '' : 'accordion-button-disabled'
                                            } accordion-button border-minisite collapsed`}
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#input'
                                            aria-expanded='false'
                                            aria-controls='input'>
                                            {translator.get('minisite.input.accordion.header.text')}
                                        </button>
                                    </h2>
                                    <div
                                        id='input'
                                        className='collapse input-accordion-body border-minisite bg-white'
                                        role='region'
                                        aria-labelledby='input'
                                        data-bs-parent='#accordion'>
                                        <div className='accordion-body'>
                                            <p>{translator.get('minisite.input.description')}</p>
                                            <hr></hr>
                                            {selectedExecution?.inputs && Object.keys(selectedExecution?.inputs).length > 0 ? (
                                                Object.values(selectedExecution?.inputs).map((input, index) => {
                                                    return (
                                                        <>
                                                            <div className='row d-flex p-3'>
                                                                <div className='col-md-3'>
                                                                    <div className=' d-flex p-3 flex-nowrap'>
                                                                        <div className='input-label-container'>{`Input ${index + 1}`}</div>
                                                                        <a
                                                                            className='btn-view-dataset'
                                                                            onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
                                                                                e.preventDefault();
                                                                                input?.template
                                                                                    ? handleViewDataset(
                                                                                          selectedExecution?.data as string,
                                                                                          input?.filename as string,
                                                                                          input?.type as InputFileTypeEnum,
                                                                                          input?.template?.filename as string,
                                                                                      )
                                                                                    : handleViewDataset(
                                                                                          selectedExecution?.data as string,
                                                                                          input?.url as string,
                                                                                          input?.type as InputFileTypeEnum,
                                                                                      );
                                                                            }}>
                                                                            <div className='d-flex'>
                                                                                <div style={{ marginRight: '5px' }}>
                                                                                    {input?.template
                                                                                        ? translator.get('minisite.input.view.template')
                                                                                        : translator.get('minisite.input.view.input')}
                                                                                </div>
                                                                                <ArrowRight width={15} fill='#009138' />
                                                                            </div>
                                                                        </a>
                                                                    </div>

                                                                    {getIconBasedOnStatusAndSize(
                                                                        input?.status as InputFileStatusEnum,
                                                                        input?.filename as string,
                                                                        input?.size as number,
                                                                        input?.total as number,
                                                                    )}
                                                                </div>
                                                                {!input?.persistent && (
                                                                    <div className='col-md-9 d-flex flex-column align-items-end'>
                                                                        {!input?.template && (
                                                                            <div className='mb-3' style={{ minWidth: 190 }}>
                                                                                <button
                                                                                    className='btn btn-primary button-input-minisite'
                                                                                    onClick={() => handleUseDataset(selectedExecution?.data as string, input?.filename as string)}
                                                                                    disabled={
                                                                                        selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.COMPLETED ||
                                                                                        selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.RUNNING
                                                                                    }>
                                                                                    {translator.get('minisite.input.download.project.inputs')}
                                                                                </button>
                                                                            </div>
                                                                        )}
                                                                        <div className='mb-1' style={{ minWidth: 190 }}>
                                                                            <button
                                                                                className='btn btn-primary button-input-minisite'
                                                                                onClick={() => setFileName(input?.filename as string)}
                                                                                style={{ color: '#ffffff' }}
                                                                                data-bs-toggle='modal'
                                                                                data-bs-target='#localUploadMinisiteModal'
                                                                                disabled={
                                                                                    selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.COMPLETED ||
                                                                                    selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.RUNNING
                                                                                }>
                                                                                {translator.get('minisite.input.upload.local.inputs')}
                                                                            </button>
                                                                        </div>
                                                                        <div style={{ minWidth: 190 }}>
                                                                            <button
                                                                                className='btn btn-primary button-input-minisite'
                                                                                onClick={() => {
                                                                                    setFileName(input?.filename as string);
                                                                                    setIsInputModalClosed(false);
                                                                                }}
                                                                                style={{ color: '#ffffff' }}
                                                                                data-bs-toggle='modal'
                                                                                data-bs-target='#dataPortalMinisiteUploadModal'
                                                                                disabled={
                                                                                    selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.COMPLETED ||
                                                                                    selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.RUNNING
                                                                                }>
                                                                                {translator.get('minisite.input.upload.data.portal.inputs')}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </>
                                                    );
                                                })
                                            ) : (
                                                <div> {translator.get('minisite.input.upload.no.inputs.text')}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Accordion Email */}
                        <div className='row mb-3'>
                            <div className='col'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            disabled={!isEmailAccordionEnabled() || selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.FAILED}
                                            className={`accordion-button border-minisite collapsed ${
                                                isEmailAccordionEnabled() && selectedExecution?.status !== ResponseServiceExecutionMinisiteStatusEnum.FAILED
                                                    ? ''
                                                    : 'accordion-button-disabled'
                                            }`}
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#email-accordion'
                                            aria-expanded='false'
                                            aria-controls='email'>
                                            {translator.get('minisite.email.accordion.header.text')}
                                        </button>
                                    </h2>
                                    <div
                                        id='email-accordion'
                                        className='collapse input-accordion-body border-minisite bg-white'
                                        role='region'
                                        aria-labelledby='email-accordion'
                                        data-bs-parent='#accordion'>
                                        <div className='accordion-body'>
                                            <p>{translator.get('minisite.email.description.text')}</p>
                                            <div className='col-5'>
                                                <TextField
                                                    type='text'
                                                    placeholder='Email'
                                                    {...register('email', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('email', e.target.value) })}
                                                    error={errors.email}
                                                    errorMessage={errors.email && getMessageByFieldAndError('email', errors)}
                                                    onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                                                    onInput={(e: React.FormEvent<HTMLInputElement>) => handleOnInput(e)}
                                                    readOnly={
                                                        selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.COMPLETED ||
                                                        selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.RUNNING
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='container'>
                            <div className='row mb-3'>
                                <div
                                    className={`d-flex run-service-container ${
                                        selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.RUNNING ? 'justify-content-between' : 'justify-content-center'
                                    }`}>
                                    {selectedExecution?.status !== ResponseServiceExecutionMinisiteStatusEnum.COMPLETED &&
                                        selectedExecution?.status !== ResponseServiceExecutionMinisiteStatusEnum.FAILED && (
                                            <button
                                                className='btn btn-primary pr-4'
                                                disabled={isButtonDisable()}
                                                style={{ color: 'white' }}
                                                onClick={() => handleLaunchClick(selectedExecution?.data as string)}>
                                                {translator.get('minisite.service.run.btn.text')}
                                            </button>
                                        )}
                                    {selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.RUNNING && (
                                        <>
                                            <div className='d-flex'>
                                                <div className='d-flex'>
                                                    <div className=' minisite-loader'></div>
                                                </div>
                                                <div className='d-flex btn-text-container'>{translator.get('minisite.service.running.label')}</div>
                                            </div>
                                        </>
                                    )}
                                    {selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.FAILED && (
                                        <>
                                            <div className='d-flex align-items-center'>
                                                <Error className='icon' fill='red' />
                                                <div className='d-flex btn-failed-text-container'>{translator.get('minisite.service.failed.label')}</div>
                                            </div>
                                        </>
                                    )}
                                    {selectedExecution?.status !== ResponseServiceExecutionMinisiteStatusEnum.WAIT && (
                                        <button
                                            className='btn btn-outline-primary btn-view-dataset'
                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                getLogs(e, selectedExecution?.data as string);
                                            }}>
                                            {translator.get('minisite.service.running.view.logs')}
                                        </button>
                                    )}
                                </div>
                            </div>
                            {(selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.RUNNING ||
                                selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.COMPLETED) && (
                                <div className='row mb-3'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <div className='mail-notification-sent'> {translator.get('minisite.email.sent.notification.text')}</div>
                                        <button
                                            className='btn btn-primary'
                                            //disabled={!(selectedExecution?.inputs && Object.keys(selectedExecution.inputs).length > 0)}
                                            style={{ color: 'white' }}
                                            onClick={() => {
                                                navigator.clipboard.writeText(selectedExecution?.minisiteUrl as string);
                                            }}>
                                            <div className='d-flex'>
                                                <div className='d-flex btn-text-container'>{translator.get('minisite.email.sent.copy.link.btn.text')}</div>
                                                <LinkIcon fill='white' width={14} className='ml-2' />
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            )}

                            {selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.FAILED && (
                                <div className='row mb-3'>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <div className='failed-status-description'> {translator.get('minisite.service.failed.description.text')}</div>
                                    </div>
                                </div>
                            )}

                            <div className={`${isLogShown ? '' : 'hide-accordion'} 'row mb-3'`}>
                                <div className='col'>
                                    <div className='accordion accordion-background-active border bg-white' id='accordion'>
                                        <div className='accordion-item'>
                                            <h2 className='accordion-header ' id='heading1c'>
                                                <button
                                                    ref={logButtonRef}
                                                    id='log-accordion'
                                                    className='accordion-button border collapsed'
                                                    type='button'
                                                    data-bs-toggle='collapse'
                                                    data-bs-target='#log'
                                                    aria-expanded='false'
                                                    aria-controls='log'
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        handleLogsScroll();
                                                    }}>
                                                    {translator.get('execution.detail.log.accordion.header.button.text')}
                                                </button>
                                            </h2>
                                            <div id='log' className='collapse' role='region' aria-labelledby='log'>
                                                <div className='accordion-body' style={{ maxHeight: 400, overflowY: 'auto', overflowX: 'hidden' }} ref={logsRef}>
                                                    {execution?.log ? (
                                                        <div
                                                            className='content'
                                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(execution?.log?.replace(/(?:\n)/g, '<br>')) }}></div>
                                                    ) : (
                                                        <div className='content'>{translator.get('minisite.logs.not.found')}</div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* Accordion Output */}
                        <div className='row mb-3'>
                            <div className='col'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            disabled={selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.WAIT}
                                            className={`accordion-button border-minisite collapsed ${
                                                selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.WAIT ? 'accordion-button-disabled' : ''
                                            }`}
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#output'
                                            aria-expanded='false'
                                            aria-controls='output'
                                            data-bs-parent='#accordion'>
                                            {translator.get('minisite.output.accordion.header.text')}
                                        </button>
                                    </h2>
                                    <div id='output' className='collapse input-accordion-body border-minisite bg-white' role='region' aria-labelledby='output'>
                                        <div className='accordion-body'>
                                            {(selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.RUNNING ||
                                                selectedExecution?.status === ResponseServiceExecutionMinisiteStatusEnum.COMPLETED) && (
                                                <p className='font-weight-bold'>{`${translator.get('minisite.output.accordion.available.until')} ${getExpirationDate(
                                                    selectedExecution?.created_at as string,
                                                    3,
                                                )}`}</p>
                                            )}
                                            <Table columns={outputCols} rows={outputRows} keyLabel='output' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className='modal fade'
                    role='dialog'
                    id='dataPortalMinisiteUploadModal'
                    aria-labelledby='dataPortalMinisiteUploadModalTitle'
                    data-bs-backdrop='static'
                    data-bs-keyboard='false'>
                    <div className='modal-dialog modal-xl' role='document'>
                        <div className='modal-content' style={{ height: 800 }}>
                            <div className='modal-header'>
                                <h2 className='modal-title h5 no_toc' id='dataPortalMinisiteUploadModalTitle'>
                                    {translator.get('minisite.upload.url.text')}
                                </h2>
                                <button
                                    className='btn-close'
                                    type='button'
                                    data-bs-dismiss='modal'
                                    aria-label='Close modal'
                                    id='dataPortalMinisiteUploadModalClose'
                                    onClick={() => setIsInputModalClosed(true)}
                                />
                            </div>
                            <div className='modal-body mt-4'>
                                <ServiceBehubInputUploadComponent
                                    user={user as LoginState}
                                    service={service as WebService}
                                    execution={selectedExecution as ServiceExecution}
                                    isClosed={isInputModalClosed}
                                    onMinisiteUpload={handleUploadFromURL}
                                    filename={filename as string}
                                    token={selectedExecution?.data}
                                />
                            </div>
                            <div className='modal-footer'></div>
                        </div>
                    </div>
                </div>

                <div
                    className='modal fade'
                    role='dialog'
                    id='localUploadMinisiteModal'
                    aria-labelledby='localUploadMinisiteModalTitle'
                    data-bs-backdrop='static'
                    data-bs-keyboard='false'>
                    <div className='modal-dialog' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h2 className='modal-title h5 no_toc' id='localUploadMinisiteModalTitle'>
                                    {translator.get('minisite.upload.local.text')}
                                </h2>
                                <button
                                    className='btn-close'
                                    type='button'
                                    data-bs-dismiss='modal'
                                    aria-label='Close modal'
                                    id='localUploadMinisiteModalClose'
                                    onClick={() => setIsInputModalClosed(true)}
                                />
                            </div>
                            <div className='modal-body mt-4'>
                                <ServiceLocalInputUploadComponent
                                    service={service as WebService}
                                    execution={selectedExecution as ServiceExecution}
                                    isClosed={isInputModalClosed}
                                    onUpload={handleUploadFromLocal}
                                    filename={filename as string}
                                    token={selectedExecution?.data}
                                />
                            </div>
                            <div className='modal-footer'></div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
