import { InputFile } from '@features/project/state/state';
import { IReduceableAction, ReduxAction } from '@hypereact/state';
import { Slices } from '@store/state';
import { ProvisioningRequirementsData } from '../beans/provisioningRequirementsData';
import { ProvisioningOriginEnum } from '../views/provisioning.view';
import { ProvisioningPageEnum, ProvisioningProjectDataState, ProvisioningState, initialProvisioningState } from './state';

@ReduxAction('PROVISIONING_SET_VALUE', Slices.Provisioning)
export class ProvisioningSetValue implements IReduceableAction<ProvisioningState> {
    constructor(public activeStep: keyof Omit<ProvisioningState, 'activeStep'>, public key: keyof ProvisioningProjectDataState, public value: string | boolean) {}
    reduce(state: ProvisioningState) {
        state[this.activeStep][this.key] = this.value;
        return state;
    }
}

@ReduxAction('PROVISIONING_SET_INPUTS', Slices.Provisioning)
export class ProvisioningSetInputs implements IReduceableAction<ProvisioningState> {
    constructor(public inputs: Array<InputFile>) {}
    reduce(state: ProvisioningState) {
        state.input.inputs = this.inputs;
        return state;
    }
}

@ReduxAction('PROVISIONING_SET_SELECTED_INPUT', Slices.Provisioning)
export class ProvisioningSetSelectedInput implements IReduceableAction<ProvisioningState> {
    constructor(public input: InputFile) {}
    reduce(state: ProvisioningState) {
        state.input.selectedInput = this.input;
        return state;
    }
}

@ReduxAction('PROVISIONING_SET_ACTIVE_STEP', Slices.Provisioning)
export class ProvisioningSetActiveStep implements IReduceableAction<ProvisioningState> {
    constructor(public activeStep: string) {}
    reduce(state: ProvisioningState) {
        state.activeStep = this.activeStep;
        return state;
    }
}
@ReduxAction('PROVISIONING_SET_STEPS', Slices.Provisioning)
export class ProvisioningSetSteps implements IReduceableAction<ProvisioningState> {
    constructor(public steps: Array<string>) {}
    reduce(state: ProvisioningState) {
        state.steps = this.steps;
        return state;
    }
}

@ReduxAction('PROVISIONING_RESET_STEPS', Slices.Provisioning)
export class ProvisioningResetSteps implements IReduceableAction<ProvisioningState> {
    reduce(state: ProvisioningState) {
        state.steps = [];
        state.activeStep = '';
        return state;
    }
}

@ReduxAction('PROVISIONING_RESET_PROJECT_DATA', Slices.Provisioning)
export class ProvisioningResetProjectData implements IReduceableAction<ProvisioningState> {
    reduce(state: ProvisioningState) {
        state.projectData = initialProvisioningState.projectData;
        return state;
    }
}

@ReduxAction('PROVISIONING_SET_CURRENT_PAGE', Slices.Provisioning)
export class ProvisioningSetCurrentPage implements IReduceableAction<ProvisioningState> {
    constructor(public currentPage: ProvisioningPageEnum) {}
    reduce(state: ProvisioningState) {
        state.currentPage = this.currentPage;
        return state;
    }
}

@ReduxAction('PROVISIONING_SET_ORIGIN', Slices.Provisioning)
export class ProvisioningSetOrigin implements IReduceableAction<ProvisioningState> {
    constructor(public origin: ProvisioningOriginEnum) {}
    reduce(state: ProvisioningState) {
        state.origin = this.origin;
        return state;
    }
}

@ReduxAction('PROVISIONING_SET_REQUIREMENTS', Slices.Provisioning)
export class ProvisioningSetRequirements implements IReduceableAction<ProvisioningState> {
    constructor(public key: keyof ProvisioningRequirementsData, public value: boolean) {}
    reduce(state: ProvisioningState) {
        state.requirements[this.key] = this.value;
        return state;
    }
}

@ReduxAction('PROVISIONING_RESET_REQUIREMENTS', Slices.Provisioning)
export class ProvisioningResetRequirements implements IReduceableAction<ProvisioningState> {
    reduce(state: ProvisioningState) {
        state.requirements = initialProvisioningState.requirements;
        return state;
    }
}
