import { IsNotEmpty, Matches, ValidateIf } from 'class-validator';

export class ProjectDetailInfoData {
    @Matches(/^(?!\s)[^!@#$%^&*()_+={}[\]|\\:;"'<,>.?/~`].*/)
    @IsNotEmpty()
    description: string;

    @Matches(/^(?!\s)[^!@#$%^&*()_+={}[\]|\\:;"'<,>.?/~`].*/)
    @IsNotEmpty()
    keyword: Array<string>;

    @ValidateIf(form => form.training)
    @Matches(/^((http|https|ftp|ftps):\/\/)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/)
    training: string;

    @ValidateIf(form => form.community)
    @Matches(/^((http|https|ftp|ftps):\/\/)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/)
    community: string;

    @ValidateIf(form => form.helpdesk)
    @Matches(/^((http|https|ftp|ftps):\/\/)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/)
    helpdesk: string;
}
