import { PopupType } from '@features/_shared/components/Popup/popup.item';
import { PopupManager } from './popup.manager';
import { TranslatorManager } from './TranslatorManager';

export interface IErrorManager {
    notifyUser(error: string): void;
}

export class ErrorManager implements IErrorManager {
    private static instance: ErrorManager;
    private popupManager: PopupManager;
    private translator: TranslatorManager;

    private constructor() {
        this.popupManager = PopupManager.getInstance();
        this.translator = TranslatorManager.getInstance();
    }

    static getInstance() {
        if (ErrorManager.instance == null) {
            ErrorManager.instance = new ErrorManager();
        }
        return ErrorManager.instance;
    }

    notifyUser(error: any): void {
        const name = (error && error.response?.data?.error?.code) || 'unknown';
        const message = (error && (error.response?.data?.error?.message || error.message)) || 'unknown';
        //const endpoint = error.response.config.url;
        if (error.response?.status !== 403) {
            this.popupManager.show({
                type: PopupType.Error,
                title: name !== 'unknown' ? this.translator.get(`error.popup.${name}.title`) : this.translator.get(`error.popup.generic.title`),
                description:
                    name === 'externalerror'
                        ? message
                        : name !== 'unknown'
                        ? this.translator.get(`error.popup.${name}.description`)
                        : this.translator.get(`error.popup.generic.description`),
                maxWidth: 'lg',
                stylingProp: 'popup-display',
                ctas: {
                    primary: {
                        label: this.translator.get('popup.menu.ok.title'),
                        className: 'popup-accept-button',
                    },
                },
            });
        } else {
            this.popupManager.show({
                type: PopupType.Error,
                title: this.translator.get(`error.popup.permissiondnd.title`),
                description: this.translator.get(`error.popup.permissiondnd.description`),
                maxWidth: 'lg',
                stylingProp: 'popup-display',
                ctas: {
                    primary: {
                        label: this.translator.get('popup.menu.ok.title'),
                        className: 'popup-accept-button',
                    },
                },
            });
        }
    }
}
