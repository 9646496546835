import { SetIsLogged } from '@features/login/store/actions';
import { StoreManager } from '@hypereact/state';
import keycloak from '../keyclock/keyclock';

const _kc = keycloak;

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: () => void, isAlreadyLogged: boolean) => {
    _kc.init({
        onLoad: 'check-sso',
    })
        .then((authenticated: any) => {
            if (!authenticated) {
                console.log('user is not authenticated..!');
            }
            onAuthenticatedCallback();
        })
        .catch(console.error);
};

const doLoginWithParameters = (redirectUri: string) => _kc.login({ redirectUri });

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback: any) => _kc.updateToken(5).then(successCallback).catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;
const getName = () => _kc.tokenParsed?.name;
const getEmail = () => _kc.tokenParsed?.email;
const getFirstName = () => _kc.tokenParsed?.given_name;
const getLastName = () => _kc.tokenParsed?.family_name;
const getGitlabUsername = () => _kc.tokenParsed?.gitlab_username;
const getGitlabToken = () => _kc.tokenParsed?.gitlab_token;
const getGitlabUserId = () => _kc.tokenParsed?.gitlab_userid;

const hasRole = (roles: any[]) => roles.some((role: any) => _kc.hasResourceRole(role));

const handleLogout = () => {
    StoreManager.getInstance().dispatch(new SetIsLogged(false));
    keycloak.logout({ redirectUri: `${window.location.origin}/dashboard/ui/home` });
};

const handleLogin = () => {
    StoreManager.getInstance().dispatch(new SetIsLogged(true));
    keycloak.login();
};

const KeycloakUtil = {
    initKeycloak,
    doLogin,
    doLogout,
    isLoggedIn,
    getToken,
    updateToken,
    getUsername,
    hasRole,
    doLoginWithParameters,
    getName,
    getEmail,
    getGitlabUsername,
    getGitlabToken,
    getGitlabUserId,
    handleLogout,
    handleLogin,
    getFirstName,
    getLastName,
};

export enum KeycloakRoleEnum {
    admin = 'DATALABS_ADMIN',
    researcher = 'DATALABS_RESEARCHER',
    reviewer = 'DATALABS_REVIEWER',
    guest = 'GUEST',
    project_creator = 'PROJECT_CREATOR',
}

export default KeycloakUtil;
