import { RequestServiceExecutionMinisiteCreate } from '../../../api/entities/request-service-execution-minisite-create';
import { ResponseServiceExecutionMinisite } from '../../../api/entities/response-service-execution-minisite';
import { RequestFile, RequestGenericStart, RequestServiceUrlFileSelect, ResponseServiceListOutput, ResponseServiceUrlFileSelect } from '../../../api/entities';
import { IMinisiteApiClient, MinisiteApiClients } from './minisite.api.client';

export interface IMinisiteApiService {
    create(id: string, requestServiceExecutionCreate: RequestServiceExecutionMinisiteCreate): Promise<ResponseServiceExecutionMinisite>;
    get(jwtHeader: string, jwtPayload: string, jwtSignature: string): Promise<ResponseServiceExecutionMinisite>;
    launch(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestGenericStart: RequestGenericStart): Promise<ResponseServiceExecutionMinisite>;
    selectExternalUrlInput(
        jwtHeader: string,
        jwtPayload: string,
        jwtSignature: string,
        requestServiceUrlFileSelect: RequestServiceUrlFileSelect,
    ): Promise<ResponseServiceUrlFileSelect>;
    outputList(jwtHeader: string, jwtPayload: string, jwtSignature: string): Promise<ResponseServiceListOutput>;
    getLogs(jwtHeader: string, jwtPayload: string, jwtSignature: string): Promise<string>;
    downloadOutput(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile): Promise<File>;
    downloadInput(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile): Promise<File>;
    useDataset(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile): Promise<ResponseServiceExecutionMinisite>;
}

export class MinisiteApiService implements IMinisiteApiService {
    private static instance: MinisiteApiService;
    private api: IMinisiteApiClient;

    private constructor(basePath: string) {
        this.api = MinisiteApiClients.getInstance(basePath);
    }

    static getInstance(basePath: string) {
        if (MinisiteApiService.instance == null) {
            MinisiteApiService.instance = new MinisiteApiService(basePath);
        }
        return MinisiteApiService.instance;
    }

    async create(id: string, requestServiceExecutionCreate: RequestServiceExecutionMinisiteCreate): Promise<ResponseServiceExecutionMinisite> {
        return (await this.api.minisite.createExecutionMinisite(id, requestServiceExecutionCreate)).data;
    }

    async get(jwtHeader: string, jwtPayload: string, jwtSignature: string): Promise<ResponseServiceExecutionMinisite> {
        return (await this.api.minisite.get2(jwtHeader, jwtPayload, jwtSignature)).data;
    }

    async launch(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestGenericStart: RequestGenericStart): Promise<ResponseServiceExecutionMinisite> {
        return (await this.api.minisite.launch1(jwtHeader, jwtPayload, jwtSignature, requestGenericStart)).data;
    }

    async outputList(jwtHeader: string, jwtPayload: string, jwtSignature: string): Promise<ResponseServiceListOutput> {
        return (await this.api.minisite.outputs(jwtHeader, jwtPayload, jwtSignature)).data;
    }

    async selectExternalUrlInput(
        jwtHeader: string,
        jwtPayload: string,
        jwtSignature: string,
        requestServiceUrlFileSelect: RequestServiceUrlFileSelect,
    ): Promise<ResponseServiceUrlFileSelect> {
        return (await this.api.minisite.selectExternalUrlInput3(jwtHeader, jwtPayload, jwtSignature, requestServiceUrlFileSelect)).data;
    }

    async getLogs(jwtHeader: string, jwtPayload: string, jwtSignature: string): Promise<string> {
        return (await this.api.minisite.downloadLog1(jwtHeader, jwtPayload, jwtSignature)).data;
    }

    async downloadOutput(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile): Promise<File> {
        return (await this.api.minisite.downloadOutput2(jwtHeader, jwtPayload, jwtSignature, requestFile, { responseType: 'blob' })).data;
    }

    async downloadInput(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile): Promise<File> {
        return (await this.api.minisite.downloadInput2(jwtHeader, jwtPayload, jwtSignature, requestFile, { responseType: 'blob' })).data;
    }

    async useDataset(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile): Promise<ResponseServiceExecutionMinisite> {
        return (await this.api.minisite.useDataset(jwtHeader, jwtPayload, jwtSignature, requestFile)).data;
    }
}
