import { ReduxConnect } from '@hypereact/state';
import { ProvisioningManager } from '@managers/provisioning.manager';
import { RootState } from '@store/state';
import React from 'react';

import Breadcrumb from '@components/Breadcrumb/breadcrumb.component';
import Header from '@features/_shared/components/Header/header.component';
import { PopupType } from '@features/_shared/components/Popup/popup.item';
import { ServiceDataState } from '@features/service/store/state';
import { TranslatorManager } from '@managers/TranslatorManager';
import { NavigationManager } from '@managers/navigation.manager';
import { PopupManager } from '@managers/popup.manager';
import { ProjectManager } from '@managers/project.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { ServiceManager } from '@managers/service.manager';
import { Paths, ProvisioningRoutes } from '@routes';
import KeycloakUtil from '@utils/keycloak.util';
import moment from 'moment';
import { Route } from 'react-router-dom';
import { BaseProjectTemplate, InputFile, InputFileTypeEnum, Project, RequestProjectPublish, WebService } from '../../../api';
import { RequestServicePublish } from '../../../api/entities/request-service-publish';
import { ProvisioningRequirementsData } from '../beans/provisioningRequirementsData';
import { ProvisioningPageEnum } from '../store/state';
import '../styles/provisioning.view.style.scss';
import { wizardPublicationProject } from '../utils/constant';

export interface IProvisioningViewProps {
    activeStep: string;
    selectedProject: Project;
    selectedService?: WebService;
    serviceState: ServiceDataState;
    steps: Array<string>;
    templates?: Array<BaseProjectTemplate>;
    currentPage: ProvisioningPageEnum;
    origin: ProvisioningOriginEnum;
    requirements: ProvisioningRequirementsData;
}

export interface IProvisioningViewStateType {
    isHelperSelectedProject: boolean;
    isServiceNextClicked: boolean;
    isRequirementsNextClicked: boolean;
}

export enum ProvisioningOriginEnum {
    PROJECT = 'project',
    SERVICE = 'service',
    PROJECTDETAIL = 'projectdetail',
    SERVICEDETAIL = 'servicedetail',
    HOMEPAGE = 'homepage',
}

@ReduxConnect((state: RootState) => {
    return {
        activeStep: state.provisioning.activeStep,
        selectedProject: state.project.project.selectedProject,
        selectedService: state.service.service.selectedService,
        serviceState: state.service.service,
        steps: state.provisioning.steps,
        templates: state.project.template,
        currentPage: state.provisioning.currentPage,
        origin: state.provisioning.origin,
        requirements: state.provisioning.requirements,
    };
})
export class ProvisioningView extends React.Component<IProvisioningViewProps, IProvisioningViewStateType> {
    private provisioningManager: ProvisioningManager;
    private translator: TranslatorManager;
    private navigationManager: NavigationManager;
    private projectManager: ProjectManager;
    private serviceManager: ServiceManager;
    private queueManager: QueueManager;
    private popupManager: PopupManager;
    constructor(props: IProvisioningViewProps) {
        super(props);
        this.provisioningManager = ProvisioningManager.getInstance();
        this.translator = TranslatorManager.getInstance();
        this.navigationManager = NavigationManager.getInstance();
        this.projectManager = ProjectManager.getInstance();
        this.serviceManager = ServiceManager.getInstance();
        this.queueManager = QueueManager.getInstance();
        this.popupManager = PopupManager.getInstance();
        this.handlePageChangeClick = this.handlePageChangeClick.bind(this);
        this.handleFinalizeClick = this.handleFinalizeClick.bind(this);
        this.handleGoToDashboard = this.handleGoToDashboard.bind(this);
        this.handleGoToDataset = this.handleGoToDataset.bind(this);
        this.handleDatasetChange = this.handleDatasetChange.bind(this);
        this.handleUploadFromLocal = this.handleUploadFromLocal.bind(this);
        this.handlePublishDataChange = this.handlePublishDataChange.bind(this);
        this.handleHeaderCloseClick = this.handleHeaderCloseClick.bind(this);

        this.state = {
            isHelperSelectedProject: false,
            isServiceNextClicked: false,
            isRequirementsNextClicked: false,
        };
    }

    componentWillUnmount() {
        this.serviceManager.resetPublishData();
        this.provisioningManager.resetRequirements();
    }

    private updatePageIndexBasedOnCurrentPage(index: number) {
        const { currentPage, selectedProject } = this.props;
        switch (currentPage) {
            case ProvisioningPageEnum.PROJECT:
                this.projectManager.updateProvisioning(selectedProject.id as string, { projectIndex: index });
                break;
            case ProvisioningPageEnum.SERVICE:
                this.projectManager.updateProvisioning(selectedProject.id as string, { serviceIndex: index });
                break;
            case ProvisioningPageEnum.DATASET:
                this.projectManager.updateProvisioning(selectedProject.id as string, { datasetIndex: index });
                break;
            case ProvisioningPageEnum.SCRIPTS:
                this.projectManager.updateProvisioning(selectedProject.id as string, { scriptIndex: index });
                break;
        }
    }

    validateTitleAndSubtitle = () => {
        const currentStep = this.navigationManager.getCurrentRoute();
        if (currentStep === '/provisioning/serviceData') {
            const isTitle = this.props.serviceState?.publishData.minisite_title ? true : false;
            const isSubtitle = this.props.serviceState?.publishData.subtitle ? true : false;

            return (isTitle && isSubtitle) as boolean;
        }

        return true;
    };

    areAllTemplatesUploaded = () => {
        const result =
            this.props.selectedService?.inputs &&
            Object.values(this.props.selectedService.inputs)
                .filter(item => item?.type === InputFileTypeEnum.LOCAL)
                .find((input: InputFile | undefined) => {
                    return !input?.template;
                });
        return result ? false : true;
    };

    validateRequirements = () => {
        const currentStep = this.navigationManager.getCurrentRoute();
        if (currentStep === '/provisioning/requirements') {
            const serviceKeys = ['easyToFind', 'retrivable', 'interoperable', 'reusable'];
            const isServiceOrScripts = this.props.currentPage === ProvisioningPageEnum.SERVICE || this.props.currentPage === ProvisioningPageEnum.SCRIPTS;

            const isValidated = isServiceOrScripts
                ? Object.values(this.props.requirements).every(requirement => requirement === true)
                : Object.keys(this.props.requirements)
                      .filter(key => !serviceKeys.includes(key))
                      .every(requirementKey => this.props.requirements[requirementKey as keyof ProvisioningRequirementsData] === true);
            return isValidated as boolean;
        }

        return true;
    };

    async handlePageChangeClick(e: React.MouseEvent<HTMLButtonElement>, isNext?: boolean) {
        e.stopPropagation();
        const { steps, currentPage, origin, selectedService, serviceState } = this.props;
        const currentStep = this.navigationManager.getCurrentRoute();
        const areTeplatesUploaded = this.areAllTemplatesUploaded();

        if (currentStep === '/provisioning/requirements' && !this.validateRequirements() && isNext) {
            this.setState({ isRequirementsNextClicked: true });
        } else if (
            (origin === ProvisioningOriginEnum.SERVICE || origin === ProvisioningOriginEnum.SERVICEDETAIL) &&
            currentPage === ProvisioningPageEnum.SERVICE &&
            !this.validateTitleAndSubtitle() &&
            currentStep === '/provisioning/serviceData'
        ) {
            this.setState({ isServiceNextClicked: true });
            this.setState({ isRequirementsNextClicked: false });
        } else if (currentStep === '/provisioning/templates' && isNext && !areTeplatesUploaded) {
            this.popupManager.show({
                type: PopupType.Info,
                title: this.translator.get('provisioning.popup.template.title'),
                description: this.translator.get('provisioning.popup.template.description'),
                maxWidth: 'lg',
                stylingProp: 'popup-display',
                ctas: {
                    primary: {
                        label: this.translator.get('popup.menu.ok.title'),
                        className: 'popup-accept-button',
                        cta: () => this.popupManager.hide(),
                    },
                },
            });
        } else {
            this.setState({ isServiceNextClicked: false });
            this.setState({ isRequirementsNextClicked: false });

            const currentStep = this.navigationManager.getCurrentRoute();
            const nextStepIndex = isNext ? steps.indexOf(currentStep) + 1 : steps.indexOf(currentStep) - 1;

            if (currentStep === '/provisioning/serviceData') {
                const serviceId = selectedService?.id;
                const servicePublishRequest: RequestServicePublish = {
                    minisite_title: serviceState?.publishData.minisite_title,
                    subtitle: serviceState.publishData?.subtitle,
                    logo: serviceState.publishData?.logo,
                    landscape: serviceState.publishData?.landscape,
                };
                await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.updatePublicationData(serviceId as string, servicePublishRequest));
            }

            this.updatePageIndexBasedOnCurrentPage(nextStepIndex);

            this.navigationManager.navigateTo(steps[nextStepIndex] as Paths);
        }
    }

    async handleFinalizeClick() {
        const { steps, currentPage, origin } = this.props;
        const currentStep = this.navigationManager.getCurrentRoute();
        const nextStepIndex = steps.indexOf(currentStep) + 1;

        if (
            currentPage &&
            (origin === ProvisioningOriginEnum.PROJECT || origin === ProvisioningOriginEnum.PROJECTDETAIL) &&
            currentPage === ProvisioningPageEnum.PROJECT &&
            !this.validateRequirements()
        ) {
            this.setState({ isRequirementsNextClicked: true });
        } else {
            this.updatePageIndexBasedOnCurrentPage(nextStepIndex);

            if (currentPage && (origin === ProvisioningOriginEnum.PROJECT || origin === ProvisioningOriginEnum.PROJECTDETAIL) && currentPage === ProvisioningPageEnum.PROJECT) {
                const projectId = this.props.selectedProject.id;
                const projectPublishRequest: RequestProjectPublish = {
                    published_at: moment().toISOString(),
                };
                await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.publishProject(projectId as string, projectPublishRequest));
            }

            if (currentPage && (origin === ProvisioningOriginEnum.PROJECT || origin === ProvisioningOriginEnum.PROJECTDETAIL) && currentPage === ProvisioningPageEnum.SCRIPTS) {
                const projectId = this.props.selectedProject.id;
                const scriptPublishRequest: RequestProjectPublish = {
                    published_at: moment().toISOString(),
                };
                await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.publishScript(projectId as string, scriptPublishRequest));
            }
            if (currentPage && currentPage === ProvisioningPageEnum.SERVICE) {
                const { selectedService, serviceState } = this.props;
                const serviceId = selectedService?.id;
                const servicePublishRequest: RequestServicePublish = {
                    published_at: moment().toISOString(),
                    user: {
                        name: KeycloakUtil.getName(),
                        email: KeycloakUtil.getEmail(),
                    },
                    minisite_title: serviceState.publishData?.minisite_title,
                    subtitle: serviceState.publishData?.subtitle,
                    logo: serviceState.publishData?.logo,
                    landscape: serviceState.publishData?.landscape,
                };
                await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.publishService(serviceId as string, servicePublishRequest));
            }
            this.navigationManager.navigateTo(steps[nextStepIndex] as Paths);
        }
    }

    handleGoToDataset(e: React.MouseEvent<HTMLButtonElement>) {
        const { steps } = this.props;
        e.stopPropagation();
        this.provisioningManager.setActiveStep(steps[0]);
        this.navigationManager.navigateTo(`/provisioning/dataset` as Paths);
    }

    handleGoToDashboard(e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation();
        const { origin } = this.props;
        origin === ProvisioningOriginEnum.PROJECT || origin === ProvisioningOriginEnum.PROJECTDETAIL
            ? this.navigationManager.navigateTo('/project')
            : this.navigationManager.navigateTo('/service');
    }

    handleDatasetChange(checked: boolean) {
        this.provisioningManager.setSteps(checked ? wizardPublicationProject : wizardPublicationProject.filter((item: string) => item !== '/provisioning/dataset'));
        this.setState({
            isHelperSelectedProject: checked,
        });
    }

    handlePublishDataChange(key: keyof RequestServicePublish, value: any) {
        this.serviceManager.setPublishData(key, value);
    }

    buildTitleBasedOnStep(step: string) {
        switch (step) {
            case '/provisioning/projectData':
                return this.translator.get('provisioning.step.projectData.title');
            case '/provisioning/dataset':
                return this.translator.get('provisioning.step.dataset.title');
            case '/provisioning/metadata':
                return this.translator.get('provisioning.step.metadata.title');
            case '/provisioning/publication':
                return this.translator.get('provisioning.step.publication.title');
            case '/provisioning/requirements':
                return this.translator.get('provisioning.step.requirements.title');
            case '/provisioning/serviceData':
                return this.translator.get('provisioning.step.serviceData.title');
            case '/provisioning/serviceCreation':
                return this.translator.get('provisioning.step.serviceCreation.title');
            case '/provisioning/templates':
                return this.translator.get('provisioning.step.templates.title');
        }
    }

    handleHeaderCloseClick() {
        switch (this.props.origin) {
            case ProvisioningOriginEnum.PROJECT:
                this.navigationManager.navigateTo('/project');
                break;
            case ProvisioningOriginEnum.SERVICE:
                this.navigationManager.navigateTo('/service');
                break;
            case ProvisioningOriginEnum.PROJECTDETAIL:
                this.navigationManager.navigateTo(`/project/${this.props.selectedProject.id}` as Paths);
                break;
            case ProvisioningOriginEnum.SERVICEDETAIL:
                this.provisioningManager.setOrigin(ProvisioningOriginEnum.SERVICE);
                this.navigationManager.navigateTo(`/service/${this.props.selectedService?.id}` as Paths);
                break;
            case ProvisioningOriginEnum.HOMEPAGE:
                this.navigationManager.navigateTo('/home');
                break;
        }
    }

    async handleUploadFromLocal() {
        document.getElementById('localUploadModalClose')?.click();
    }

    getBreadcrumbProvisioningLabel() {
        const { currentPage } = this.props;
        if (currentPage === ProvisioningPageEnum.PROJECT) {
            return this.translator.get('provisioning.detail.project.breadcrumb.secondary.item');
        } else if (currentPage === ProvisioningPageEnum.SERVICE) {
            return this.translator.get('provisioning.detail.service.breadcrumb.secondary.item');
        } else if (currentPage === ProvisioningPageEnum.DATASET) {
            return this.translator.get('provisioning.detail.dataset.breadcrumb.secondary.item');
        } else if (currentPage === ProvisioningPageEnum.SCRIPTS) {
            return this.translator.get('provisioning.detail.scripts.breadcrumb.secondary.item');
        } else {
            return this.translator.get('provisioning.detail.breadcrumb.secondary.item');
        }
    }

    render() {
        const { steps, selectedProject, selectedService, templates, currentPage, origin, requirements } = this.props;
        const activeStep = this.navigationManager.getCurrentRoute();

        return (
            <>
                <div>
                    <div className='header-title-container pb-0'>
                        <p className='header-title col-12 mb-4 mt-0'>
                            {origin === ProvisioningOriginEnum.PROJECT || origin === ProvisioningOriginEnum.PROJECTDETAIL ? selectedProject?.name : selectedService?.name}
                        </p>
                        <Breadcrumb secondaryPages={[this.getBreadcrumbProvisioningLabel()]} />
                    </div>
                    <Header
                        project={selectedProject}
                        service={selectedService}
                        templates={templates as BaseProjectTemplate[]}
                        origin={origin}
                        handleClickClose={this.handleHeaderCloseClick}
                    />
                    {activeStep == '/provisioning/requirements' && (
                        <div className='mt-4'>
                            <div className='col-12 d-flex justify-content-center'>
                                <p className='col-md-auto helper-container'>
                                    {this.translator.get(this.state.isHelperSelectedProject ? 'provisioning.project.helper.text' : 'provisioning.script.helper.text')}
                                </p>
                            </div>
                        </div>
                    )}
                    <div className='mb-4'>
                        <div className='col-12'>
                            <div className='steppers d-flex justify-content-center'>
                                {this.props.currentPage !== ProvisioningPageEnum.DATASET && (
                                    <ul className='steppers-header'>
                                        {steps.map((step: string, index: number) => (
                                            <>
                                                <li
                                                    key={index}
                                                    className={`
                                                ${index < steps.indexOf(activeStep) && 'confirmed no-line d-none d-lg-flex'} 
                                                ${index <= steps.indexOf(activeStep) && 'active no-line'} 
                                                ${index > steps.indexOf(activeStep) && 'd-none d-lg-flex'}
                                                d-flex flex-xs-row flex-lg-column justify-content-center step-container align-items-center
                                                `}>
                                                    <span className={`steppers-number ${index <= steps.indexOf(activeStep) && 'active no-line'} m-0 text-center`}>{index + 1}</span>
                                                    <p className='ps-xs-3 ps-lg-0 mb-0 text-center text-nowrap'>{this.buildTitleBasedOnStep(step)}</p>
                                                </li>
                                                {index < steps.length - 1 && (
                                                    <hr className={`stepper-divider ${index < steps.indexOf(activeStep) && 'stepper-divider-confirmed'}`} />
                                                )}
                                            </>
                                        ))}
                                        <li className='steppers-index' aria-hidden='true'>
                                            {steps.map((step, index) => (
                                                <span className={`${step === activeStep && 'active'}`}>{index + 1}</span>
                                            ))}
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='mb-4 step-component-container'>
                        <div className='col-12 '>
                            {ProvisioningRoutes.map(({ path, component }) => {
                                const Component = component;
                                let componentProps = {};
                                if (selectedProject && (origin === ProvisioningOriginEnum.PROJECT || origin === ProvisioningOriginEnum.PROJECTDETAIL)) {
                                    const { inputs, outputs } = selectedProject;
                                    componentProps = {
                                        ...(path === '/dataset' && {
                                            inputs: inputs,
                                            outputs: outputs,
                                            currentPage: currentPage,
                                            origin: origin,
                                            steps: steps,
                                            selectedProject: selectedProject,
                                        }),
                                        ...((path === '/projectData' || path === '/metadata') && {
                                            project: selectedProject,
                                            handleDatasetChange: this.handleDatasetChange,
                                            enableDatasetPublication: true,
                                            templates: templates,
                                            currentPage: currentPage,
                                            origin: origin,
                                            onMetadatactionSuccess: this.handleFinalizeClick,
                                        }),
                                        ...(path === '/requirements' && {
                                            projectId: selectedProject.id,
                                            currentPage: currentPage,
                                            origin: origin,
                                            requirements: requirements,
                                            isRequirementsNextClicked: this.state.isRequirementsNextClicked,
                                        }),
                                    };
                                }
                                if (selectedProject && selectedService && (origin === ProvisioningOriginEnum.SERVICE || origin === ProvisioningOriginEnum.SERVICEDETAIL)) {
                                    const { inputs, outputs } = selectedProject;
                                    componentProps = {
                                        ...(path === '/dataset' && {
                                            inputs: inputs,
                                            currentPage: currentPage,
                                            origin: origin,
                                            outputs: outputs,
                                            steps: steps,
                                            selectedProject: selectedProject,
                                        }),
                                        ...((path === '/serviceData' || path === '/requirements' || path === '/templates' || path === '/metadata') && {
                                            project: selectedProject,
                                            service: selectedService,
                                            onUpload: this.handleUploadFromLocal,
                                            handleDatasetChange: this.handleDatasetChange,
                                            templates: templates,
                                            handlePublishDataChange: this.handlePublishDataChange,
                                            isServiceNextClicked: this.state.isServiceNextClicked,
                                            isRequirementsNextClicked: this.state.isRequirementsNextClicked,
                                            serviceState: this.props.serviceState,
                                            origin: origin,
                                            requirements: requirements,
                                            currentPage: currentPage,
                                            onMetadatactionSuccess: this.handleFinalizeClick,
                                        }),
                                    };
                                }

                                return (
                                    <Route path={`/provisioning${path}`}>
                                        <Component {...componentProps} />
                                    </Route>
                                );
                            })}
                        </div>
                    </div>
                    <div className='button-row mb-4'>
                        <div className='col-12 col-sm-6 col-md-6 col-lg-6 position-button-left'>
                            {steps.indexOf(activeStep) !== 0 && steps.indexOf(activeStep) !== steps.length - 1 && (
                                <button
                                    className='btn btn-outline-secondary width-provisioning-button'
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handlePageChangeClick(e)}>
                                    {this.translator.get('provisioning.back.button.text')}
                                </button>
                            )}
                            {steps.length === 3 && selectedProject && steps.indexOf(activeStep) === steps.length - 1 && (
                                <button
                                    className='btn btn-outline-secondary width-provisioning-final-button'
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handleGoToDataset(e)}>
                                    {this.translator.get('provisioning.gotodataset.button.text')}
                                </button>
                            )}
                        </div>
                        <div
                            className={
                                (steps.length === 3 && selectedProject && steps.indexOf(activeStep) === steps.length - 1) ||
                                (steps.indexOf(activeStep) !== 0 && steps.indexOf(activeStep) !== steps.length - 1)
                                    ? 'col-12 col-sm-6 col-md-6 col-lg-6 position-button-right'
                                    : 'col-12 position-button-next'
                            }>
                            {' '}
                            {((this.props.currentPage === ProvisioningPageEnum.PROJECT && steps.indexOf(activeStep) !== 2) || steps.indexOf(activeStep) !== steps.length - 2) &&
                                steps.indexOf(activeStep) !== steps.length - 1 && (
                                    <button
                                        className='btn btn-primary text-white width-provisioning-button'
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handlePageChangeClick(e, true)}>
                                        {this.translator.get('provisioning.next.button.text')}
                                    </button>
                                )}
                            {steps.indexOf(activeStep) === steps.length - 2 && currentPage !== ProvisioningPageEnum.SERVICE && currentPage !== ProvisioningPageEnum.SCRIPTS && (
                                <button
                                    className='btn btn-primary text-white width-provisioning-button'
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handleFinalizeClick()}>
                                    {this.translator.get('provisioning.finalize.button.text')}
                                </button>
                            )}
                            {steps.indexOf(activeStep) === steps.length - 1 && (
                                <button
                                    className='btn btn-primary text-white width-provisioning-final-button'
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handleGoToDashboard(e)}>
                                    {this.translator.get('provisioning.publication.gotodashboard.button')}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
