import { NavigationManager } from '@managers/navigation.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';
import { useEffect, useState } from 'react';
import '../styles/linksList.style.scss';

export interface ILinksListProps {
    isIDEOpen: boolean;
    route: string;
}

const LinkList: React.FC<ILinksListProps> = ({ isIDEOpen, route }) => {
    const translator = TranslatorManager.getInstance();
    const navigator = NavigationManager.getInstance();
    const [currentRoute, setCurrentRoute] = useState<string>(NavigationManager.getInstance().getCurrentRoute());

    const handleRouteChange = (path: any) => {
        navigator.navigateTo(path);
        setCurrentRoute(path);
    };

    useEffect(() => {
        setCurrentRoute(navigator.getCurrentRoute());
    }, [route]);

    return (
        <>
            <div className='container-fluid navigation-container'>
                <div className='container-fluid d-flex'>
                    <div className='row'>
                        <div className='col-12 link-container'>
                            <a className={`link ${navigator.getCurrentRoute().includes('/home') ? 'active' : ''} `} onClick={() => handleRouteChange('/home')}>
                                {translator.get('tabbar.home.tab.text')}
                            </a>
                        </div>
                        <div className='col-12 link-container'>
                            <a className={`link ${navigator.getCurrentRoute().includes('/project') ? 'active' : ''} `} onClick={() => handleRouteChange('/project')}>
                                {translator.get('tabbar.projects.tab.text')}
                            </a>
                        </div>
                        <div className='col-12 link-container'>
                            <a className={`link ${navigator.getCurrentRoute().includes('/service') ? 'active' : ''} `} onClick={() => handleRouteChange('/service')}>
                                {translator.get('tabbar.services.tab.text')}
                            </a>
                        </div>
                        <div className='col-12 link-container'>
                            <a href='https://helpdesk.lifewatchitaly.eu' target='_blank' rel='noreferrer' className='link'>
                                {translator.get('linkbar.helpdesk.link.text')}
                            </a>
                        </div>
                        <div className='col-12 link-container'>
                            <a
                                href={`${
                                    route?.includes('/project') && !isIDEOpen
                                        ? 'https://training.lifewatchitaly.eu/en/resources-2/?resource=/course/view.php?id=84'
                                        : route?.includes('/service')
                                        ? 'https://training.lifewatchitaly.eu/en/resources-2/?resource=/course/view.php?id=86'
                                        : route?.includes('/project') && isIDEOpen
                                        ? 'https://training.lifewatchitaly.eu/en/resources-2/?resource=/course/view.php?id=85'
                                        : 'https://training.lifewatchitaly.eu/resources/?resource=/course/view.php?id=156'
                                }`}
                                target='_blank'
                                rel='noreferrer'
                                className='link'>
                                {translator.get('linkbar.training.link.text')}
                            </a>
                        </div>
                        <div className='col-12 link-container'>
                            <a className={`link ${currentRoute.includes('/faqs') ? 'active' : ''} `} onClick={() => handleRouteChange('/faqs')}>
                                {translator.get('tabbar.faqs.tab.text')}
                            </a>
                        </div>
                        {KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) && (
                            <div>
                                <hr />
                                <div className='col-12 link-container'>
                                    <a className={`link ${currentRoute.includes('/administration') ? 'active' : ''} `} onClick={() => handleRouteChange('/administration')}>
                                        {translator.get('tabbar.administration.tab.text')}
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default LinkList;
