import { InputState } from '@features/project/state/state';
import { ProvisioningRequirementsData } from '../beans/provisioningRequirementsData';
import { ProvisioningOriginEnum } from '../views/provisioning.view';

export interface ProvisioningProjectDataState {
    serviceName?: string;
    version?: string;
    applicant?: string;
    description?: string;
}

export interface ProvisioningState {
    projectData: ProvisioningProjectDataState;
    metadata: any; //ProvisioningMetadataState (not defined yet)
    input: InputState;
    requirements: ProvisioningRequirementsData; //ProvisioningRequirementsState (not defined yet)
    activeStep: string;
    steps: Array<string>;
    currentPage: ProvisioningPageEnum | undefined;
    origin: ProvisioningOriginEnum | undefined;
}

export enum ProvisioningPageEnum {
    PROJECT = 'PROJECT',
    SERVICE = 'SERVICE',
    DATASET = 'DATASET',
    SCRIPTS = 'SCRIPTS',
}

export const initialProvisioningState: ProvisioningState = {
    projectData: {
        serviceName: undefined,
        version: undefined,
        applicant: undefined,
        description: undefined,
    },
    metadata: undefined,
    input: {
        inputs: [],
        selectedInput: undefined,
    },
    requirements: {
        dataValidation: false,
        temporaryFiles: false,
        diskSpaceUsage: false,
        executionTime: false,
        cpuUsage: false,
        easyToFind: false,
        retrivable: false,
        interoperable: false,
        reusable: false,
    },
    activeStep: '',
    steps: [],
    currentPage: undefined,
    origin: undefined,
};
