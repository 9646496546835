import { IsNotEmpty } from 'class-validator';

export class ProvisioningServiceData {
    @IsNotEmpty()
    minisite_title: string;

    @IsNotEmpty()
    subtitle: string;

    logo: string;

    backgroundImg: string;
}
