import { Table } from '@features/_shared/components/Table';
import { TranslatorManager } from '@managers/TranslatorManager';
import { FormatUtil } from '@utils/formaters.util';
import { useEffect, useState } from 'react';
import { InputFile, InputFileStatusEnum, InputFileTypeEnum, OutputFile, WebService } from '../../../api/entities';
import { ProvisioningPageEnum } from '../store/state';
import '../styles/provisioning.dataset.component.style.scss';
import { ProvisioningOriginEnum } from '../views/provisioning.view';
import { ServiceLocalInputUploadComponent } from '../../service/components/service.local.input.upload.component';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { ServiceManager } from '@managers/service.manager';
import { NavigationManager } from '@managers/navigation.manager';
import { PollingUtil } from '@utils/polling.util';
import { ReactComponent as Error } from '@assets/svg/it-close-circle.svg';
import { ReactComponent as CheckCircle } from '../../../_shared/assets/svg/it-check-circle.svg';
import { ReactComponent as WarningCircle } from '@assets/svg/it-warning-circle.svg';

export interface IProvisioningTemplatesComponentProps {
    isPublicatingProject?: boolean;
    inputs?: InputFile[];
    outputs?: OutputFile[];
    currentPage?: ProvisioningPageEnum;
    origin?: ProvisioningOriginEnum;
    steps?: Array<string>;
    service?: WebService;
}

export const ProvisioningTemplatesComponent: React.FC<IProvisioningTemplatesComponentProps> = ({ isPublicatingProject, service }) => {
    const translator = TranslatorManager.getInstance();
    const queueManager = QueueManager.getInstance();
    const serviceManager = ServiceManager.getInstance();
    const navigationManager = NavigationManager.getInstance();

    const [fileName, setFileName] = useState<string>('');
    const [isInputModalClosed, setIsInputModalClosed] = useState<boolean>(false);
    const [isUploadProgress, setIsUploadProgress] = useState<boolean>(false);

    const inputCols = [
        translator.get('provisioning.table.header.name.text'),
        translator.get('provisioning.input.table.header.source.text'),
        translator.get('provisioning.table.header.size.text'),
        translator.get('provisioning.table.header.action.text'),
        translator.get('provisioning.table.header.template.text'),
    ];

    const pollTemplates = async () => {
        const validateTemplates = () => navigationManager.getCurrentRoute() === '/provisioning/templates';
        await PollingUtil.register('templates', async () => await serviceManager.getServiceById(service?.id as string), validateTemplates, 10000);
    };

    useEffect(() => {
        queueManager.queue(QueueKeyType.POLLER, pollTemplates());
    }, []);

    const handleUploadFromLocal = async () => {
        document.getElementById('localUploadModalClose')?.click();
        await queueManager.queue(QueueKeyType.DEFAULT, serviceManager.getServiceById(service?.id as string));
    };

    const getIconBasedOnStatusAndSize = (status: InputFileStatusEnum, size: number, total: number) => {
        let percentage = (((size as number) / (total as number)) * 100).toFixed(2);

        if (status === InputFileStatusEnum.READY && size > 0) {
            return <CheckCircle className='icon icon-primary' />;
        } else if (status === InputFileStatusEnum.WAIT && size === 0) {
            return <WarningCircle className='icon icon-secondary' />;
        } else if (status === InputFileStatusEnum.PROGRESS || Number(percentage) > 0 || (status === InputFileStatusEnum.READY && size === 0) || isUploadProgress) {
            return (
                <div className='d-flex align-items-center'>
                    <div className='input-loader'></div>
                    <div className='project-loader-percentage'>{percentage + '%'}</div>
                </div>
            );
        } else if (status === InputFileStatusEnum.ERROR) {
            return <Error className='icon icon-secondary' />;
        }
    };

    const inputRows =
        service?.inputs && Object.keys(service.inputs).length > 0
            ? Object.values(service.inputs).map((inputItem: InputFile | undefined, index) => {
                  return {
                      id: inputItem?.filename,
                      values: {
                          name: inputItem?.filename,
                          source: inputItem?.type,
                          size: inputItem?.size ? FormatUtil.formatBytes(inputItem.size) : '0 Bytes',
                          action: (
                              <>
                                  <div className='d-flex justify-content-start provisioning-action-container'>
                                      {inputItem?.type === InputFileTypeEnum.LOCAL && (
                                          <button
                                              onClick={() => {
                                                  setIsUploadProgress(true);
                                                  setIsInputModalClosed(true);
                                                  setFileName(inputItem?.filename as string);
                                              }}
                                              className='btn btn-primary btnInput'
                                              style={{ color: '#ffffff' }}
                                              data-bs-toggle='modal'
                                              data-bs-target='#localUploadModal'>
                                              UPLOAD
                                          </button>
                                      )}
                                  </div>
                              </>
                          ),
                          template:
                              inputItem?.type === InputFileTypeEnum.LOCAL ? (
                                  <div className='published-column'>
                                      {!inputItem?.template ? (
                                          <WarningCircle className='icon icon-secondary' />
                                      ) : (
                                          <div className='status-icon'>
                                              {getIconBasedOnStatusAndSize(
                                                  inputItem?.template?.status as InputFileStatusEnum,
                                                  inputItem?.template?.size ? inputItem?.template?.size : 0,
                                                  inputItem?.template?.total as number,
                                              )}
                                          </div>
                                      )}
                                  </div>
                              ) : (
                                  <></>
                              ),
                      },
                  };
              })
            : [];

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='row col-12  home-title-container '>
                        <h5 className='title-description col-12 mb-0'>{translator.get('provisioning.template.publication.description.part.one')}</h5>
                        {/* <h3 className='secondary-title col-12 mt-3 mb-3'>{translator.get('provisioning.template.publication.description.part.two')}</h3>
                        <h5 className='title-description col-12'>{translator.get('provisioning.template.publication.description.part.three')}</h5> */}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <p className='input-output-header-container'>{translator.get('provisioning.input.header.text')}</p>
                    </div>
                    <div className='col-12'>
                        <div className='project-table-container size-tab'>
                            <Table columns={inputCols as string[]} rows={inputRows || []} keyLabel='input' />
                        </div>
                    </div>
                </div>
                {isPublicatingProject && (
                    <div className='row'>
                        <div className='col-12'>
                            <div className='form-check'>
                                <input id='datasets' type='checkbox' className='focus--mouse' />
                                <label htmlFor='datasets'>{translator.get('provisioning.dataset.checkbox.text')}</label>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className='modal fade' role='dialog' id='localUploadModal' aria-labelledby='localUploadModalTitle' data-bs-backdrop='static' data-bs-keyboard='false'>
                <div className='modal-dialog' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='modal-title h5 no_toc' id='localUploadModalTitle'>
                                Upload From Local
                            </h2>
                            <button
                                className='btn-close'
                                type='button'
                                data-bs-dismiss='modal'
                                aria-label='Close modal'
                                id='localUploadModalClose'
                                onClick={() => setIsInputModalClosed(false)}
                            />
                        </div>
                        <div className='modal-body mt-4'>
                            <ServiceLocalInputUploadComponent service={service as WebService} isClosed={isInputModalClosed} onUpload={handleUploadFromLocal} filename={fileName} />
                        </div>
                        <div className='modal-footer'></div>
                    </div>
                </div>
            </div>
        </>
    );
};
