import React, { ReactElement } from 'react';
import { StoreManager } from '@hypereact/state';
import { NotificationManager } from './notification.manager';
import PopupItem, { PopupItemProps } from '@features/_shared/components/Popup/popup.item';
import { ShowPopupAction, HidePopupAction } from '@features/_shared/store/actions';

type Popup = ReactElement | null;

export class PopupManager extends NotificationManager {
    private static instance: PopupManager;
    private storeManager: StoreManager;
    private popup: Popup = null;

    static getInstance() {
        if (PopupManager.instance == null) {
            PopupManager.instance = new PopupManager();
        }
        return PopupManager.instance;
    }

    private constructor() {
        super();
        this.storeManager = StoreManager.getInstance();
    }

    show(props: PopupItemProps) {
        this.popup && this.hide();
        this.popup = React.createElement(PopupItem, props);
        this.storeManager.dispatch(new ShowPopupAction());
    }

    hide() {
        this.popup = null;
        this.storeManager.dispatch(new HidePopupAction());
    }

    get() {
        return this.popup;
    }
}
