import { TranslatorManager } from '@managers/TranslatorManager';
import { ReactComponent as Logout } from '@assets/svg/logout.svg';
import keycloak from '../../../../../_shared/keyclock/keyclock';

const LogoutButton: React.FC = () => {
    const translator = TranslatorManager.getInstance();
    return (
        <button className='dropdown-item list-item text-primary d-flex align-items-center' onClick={() => keycloak.logout()}>
            {translator.get('logout.button.text')}
            <Logout className='icon ms-2' />
        </button>
    );
};
export default LogoutButton;
