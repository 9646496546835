import { ReactComponent as Error } from '@assets/svg/it-close-circle.svg';
import { ReactComponent as Download } from '@assets/svg/it-download.svg';
import { ReactComponent as WarningCircle } from '@assets/svg/it-warning-circle.svg';

import Breadcrumb from '@components/Breadcrumb/breadcrumb.component';
import { Table } from '@features/_shared/components/Table';
import { LoginState } from '@features/login/store/state';
import { Statistics } from '@features/project/components/project.detail.component';
import { ProvisioningOriginEnum } from '@features/provisioning/views/provisioning.view';
import { ServiceDetailHeader } from '@features/service/components/service.detail.header.component';
import { ReduxConnect, StoreManager } from '@hypereact/state';
import { TranslatorManager } from '@managers/TranslatorManager';
import { BackofficeManager } from '@managers/backoffice.manager';
import { NavigationManager } from '@managers/navigation.manager';
import { ProjectManager } from '@managers/project.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { ServiceManager } from '@managers/service.manager';
import { RootState } from '@store/state';
import { FormatUtil } from '@utils/formaters.util';
import { PollingUtil } from '@utils/polling.util';
import DOMPurify from 'dompurify';
import _ from 'lodash';
import moment from 'moment';
import React, { createRef } from 'react';
import { ReactComponent as CheckCircle } from '../../../_shared/assets/svg/it-check-circle.svg';
import {
    BaseProjectTemplate,
    CellInBoundMetrics,
    InBoundMetrics,
    InBoundWarnings,
    InputFile,
    InputFileStatusEnum,
    OutputFile,
    Project,
    ServiceExecution,
    ServiceExecutionStatusEnum,
    WebService,
    WebServiceStatusEnum,
} from '../../../api';
import { ServiceBehubInputUploadComponent } from '../components/service.behub.input.upload.component';
import { ServiceLocalInputUploadComponent } from '../components/service.local.input.upload.component';
import '../styles/service.detail.execution.view.style.scss';

export interface IServiceDetailExecutionViewProps {
    project?: Project;
    user?: LoginState;
    service?: WebService;
    execution?: ServiceExecution;
    templates?: Array<BaseProjectTemplate>;
    outputs?: Array<OutputFile>;
    html: string;
    logs: string;
    createUpdate: WebService;
    origin: ProvisioningOriginEnum;
    configuration: { [key: string]: string };
}

export interface IServiceDetailExecutionViewState {
    isInputModalClosed: boolean;
    filename: string;
    outputHTML?: string;
    shadowRoot: ShadowRoot | null;
}

@ReduxConnect((state: RootState) => {
    return {
        project: state.project.project.selectedProject,
        user: state.login,
        service: state.service.service.selectedService,
        execution: state.service.execution.selectedExecution,
        outputs: state.service.output.outputs,
        templates: state.project.template,
        html: state.service.execution.html,
        logs: state.service.execution.log,
        createUpdate: state.service.service.createUpdate,
        origin: state.provisioning.origin,
        configuration: state.shared.configuration,
    };
})
export class ServiceDetailExecutionView extends React.Component<IServiceDetailExecutionViewProps, IServiceDetailExecutionViewState> {
    private translator: TranslatorManager;
    private serviceManager: ServiceManager;
    private navigationManager: NavigationManager;
    private projectManager: ProjectManager;
    private queueManager: QueueManager;
    private backofficeManger: BackofficeManager;
    private ref: any;
    private logsRef: any;
    private htmlRef: any;
    private warningsRef: any;

    constructor(props: IServiceDetailExecutionViewProps) {
        super(props);
        this.translator = TranslatorManager.getInstance();
        this.serviceManager = ServiceManager.getInstance();
        this.projectManager = ProjectManager.getInstance();
        this.navigationManager = NavigationManager.getInstance();
        this.queueManager = QueueManager.getInstance();
        this.backofficeManger = BackofficeManager.getInstance();
        this.handleUploadFromURL = this.handleUploadFromURL.bind(this);
        this.handleStartExecution = this.handleStartExecution.bind(this);
        this.handleStopExecution = this.handleStopExecution.bind(this);
        this.handleDownloadOutput = this.handleDownloadOutput.bind(this);
        this.ref = createRef();
        this.logsRef = createRef();
        this.htmlRef = createRef();
        this.warningsRef = createRef();

        this.state = {
            isInputModalClosed: false,
            filename: '',
            outputHTML: '',
            shadowRoot: null,
        };
    }

    scrollToBottom = () => {
        this.ref && this.ref.scrollTo({ top: this.ref.offsetTop + this.ref.offsetHeight, behavior: 'smooth' });
    };

    handleLogsScroll = () => this.logsRef.current.scrollTo({ top: this.logsRef.current.scrollHeight, behavior: 'smooth' });

    handleHtmlScroll = () => this.htmlRef.current.scrollTo({ top: this.htmlRef.current.scrollHeight, behavior: 'smooth' });

    handleWarningsScroll = () => this.warningsRef.current.scrollTo({ top: this.warningsRef.current.scrollHeight, behavior: 'smooth' });

    updateShadowContent() {
        const { html } = this.props;
        const { shadowRoot } = this.state;
        if (shadowRoot) {
            const htmlContent = document.createElement('div');
            htmlContent.className = 'content';
            htmlContent.innerHTML = html;

            while (shadowRoot.firstChild) {
                shadowRoot.firstChild.remove();
            }

            shadowRoot.appendChild(htmlContent);
        }
    }

    async handleExecutionDetailViewPoll() {
        const { service, execution } = this.props;
        const validateExecutionOutput = () => this.navigationManager.getCurrentRoute().includes(`/execution`);
        const validateExecutionOutputHTML = () => this.navigationManager.getCurrentRoute().includes('/execution');
        const validateExecutionLog = () => this.navigationManager.getCurrentRoute().includes('/execution');
        const validateExecution = () => this.navigationManager.getCurrentRoute().includes('/execution');
        PollingUtil.register(
            'servicedetailexecoutput',
            async () => service?.id && execution?.id && (await this.serviceManager.pollOutputList(service?.id, execution?.id)),
            validateExecutionOutput,
            10000,
            service?.id! + execution?.id!,
        );
        PollingUtil.register(
            'servicedetailexechtml',
            async () => service?.id && execution?.id && (await this.serviceManager.pollHtml(service?.id, execution?.id)),
            validateExecutionOutputHTML,
            10000,
            service?.id! + execution?.id!,
        );
        PollingUtil.register(
            'servicedetailexeclog',
            async () => service?.id && execution?.id && (await this.serviceManager.pollLogs(service?.id, execution?.id)),
            validateExecutionLog,
            10000,
            service?.id! + execution?.id!,
        );
        PollingUtil.register(
            'servicedetailexecdata',
            async () => service?.id && execution?.id && (await this.serviceManager.pollExecution(service?.id, execution?.id)),
            validateExecution,
            10000,
            service?.id! + execution?.id!,
        );
    }

    async handleExecutionDetailViewOnLoad() {
        const str = window.location.href;
        const start = '/service/';
        const end = '/execution';
        const parts = str.split(start);
        const substrings = parts[1].split(end);
        let serviceId = substrings[0];
        let executionId = window.location.href.split('execution/')[1];
        const response = await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(serviceId));
        response?.id && (await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.findExecution(response?.id, executionId)));
        response?.id && (await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getTemplateList()));
        response?.id && (await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getOutputList(response?.id, executionId)));
        response?.id && (await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getHtml(response?.id, executionId)));
        response?.id && (await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getLogs(response?.id, executionId)));
    }

    async componentDidMount() {
        this.ref && this.scrollToBottom();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.backofficeManger.getKeys({ startsWith: 'ui.service' }));
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.handleExecutionDetailViewOnLoad());
        await this.queueManager.queue(QueueKeyType.POLLER, this.handleExecutionDetailViewPoll());
        const shadow = this.htmlRef.current?.attachShadow({ mode: 'open' });
        this.setState({ shadowRoot: shadow });
        this.updateShadowContent();
        //this.htmlRef && this.htmlRef.current && this.handleHtmlShadow();
    }

    shouldComponentUpdate(nextProp: IServiceDetailExecutionViewProps, nextState: IServiceDetailExecutionViewState) {
        const id = StoreManager.getInstance().getState('service').execution?.selectedExecution?.id;
        if ((_.isEqual(nextProp, this.props) && _.isEqual(nextState, this.state)) || id != window.location.href.split('/execution/')[1]) {
            return false;
        } else {
            return true;
        }
    }

    componentDidUpdate(prevProps: IServiceDetailExecutionViewProps) {
        this.scrollToBottom();
        this.handleLogsScroll();
        this.handleHtmlScroll();
        this.handleWarningsScroll();
        if (prevProps.html !== this.props.html && this.state.shadowRoot) {
            this.updateShadowContent();
        }
    }

    async componentWillUnmount() {
        PollingUtil.unregister('servicedetailexecoutput');
        PollingUtil.unregister('servicedetailexechtml');
        PollingUtil.unregister('servicedetailexeclog');
        PollingUtil.unregister('servicedetailexecdata');
        this.serviceManager.resetOutputAndLogs();
        this.serviceManager.resetExecutionPagination();
    }

    async handleUploadFromURL(id: string, execId: string, fileUrl: string, fileName: string) {
        document.getElementById('dataPortalUploadModalClose')?.click();
        await this.serviceManager.selectExternalUrlInput(id, execId, {
            file_url: fileUrl,
            file_name: fileName,
        });
    }

    handleUploadFromLocal() {
        document.getElementById('localUploadModalClose')?.click();
    }

    async handleStartExecution() {
        const { service, execution } = this.props;
        service?.id && execution?.id && (await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.launch(service?.id, execution?.id)));
        service?.id && execution?.id && (await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getOutputList(service?.id, execution?.id)));
        service?.id && execution?.id && (await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getHtml(service?.id, execution?.id)));
        service?.id && execution?.id && (await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getLogs(service?.id, execution?.id)));
    }

    handleStopExecution() {
        const { service, execution } = this.props;
        service?.id && execution?.id && this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.stop(service?.id, execution?.id, { force: true }));
    }

    async handleDownloadOutput(fileName: string) {
        const response = await this.queueManager.queue(
            QueueKeyType.DEFAULT,
            this.serviceManager.downloadOutput(this.props.service?.id as string, this.props.execution?.id as string, { name: fileName }),
        );
        const href = response && URL.createObjectURL(new Blob([response], { type: 'application/octet-stream' }));
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    async handleDownloadInput(fileName: string, template: string) {
        const response = await this.queueManager.queue(
            QueueKeyType.DEFAULT,
            this.serviceManager.downloadInput(this.props.service?.id as string, this.props.execution?.id as string, { name: fileName, template: template }),
        );
        const href = response && URL.createObjectURL(new Blob([response], { type: 'application/octet-stream' }));
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', template); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    getIconBasedOnStatusAndSize(status: InputFileStatusEnum, size: number, total: number) {
        let percentage = (((size as number) / (total as number)) * 100).toFixed(2);

        if (status === InputFileStatusEnum.READY && size > 0) {
            return <CheckCircle className='icon icon-primary' />;
        } else if (status === InputFileStatusEnum.WAIT && size === 0) {
            return <WarningCircle className='icon icon-secondary' />;
        } else if (status === InputFileStatusEnum.PROGRESS || Number(percentage) > 0 || (status === InputFileStatusEnum.READY && size === 0)) {
            return (
                <div className='d-flex align-items-center'>
                    <div className='input-loader'></div>
                    <div className='project-loader-percentage'>{percentage + '%'}</div>
                </div>
            );
        } else if (status === InputFileStatusEnum.ERROR) {
            return <Error className='icon icon-secondary' />;
        }
    }

    render() {
        const { execution } = this.props;

        const inputCols =
            execution?.run !== 1 && this.props.service?.status === WebServiceStatusEnum.PUBLISHED
                ? [
                      this.translator.get('service.execution.detail.table.header.name.text'),
                      this.translator.get('service.execution.detail.table.header.status.text'),
                      this.translator.get('service.execution.detail.table.header.template.text'),
                      this.translator.get('common.table.header.actions.text'),
                  ]
                : [
                      this.translator.get('service.execution.detail.table.header.name.text'),
                      this.translator.get('service.execution.detail.table.header.status.text'),
                      this.translator.get('common.table.header.actions.text'),
                  ];

        const inputRows =
            execution?.inputs &&
            Object.values(execution?.inputs)
                .filter((item: InputFile | undefined) => !item?.persistent)
                .map((input: InputFile | undefined) => {
                    return execution.run !== 1 && this.props.service?.status === WebServiceStatusEnum.PUBLISHED
                        ? {
                              id: input?.filename,
                              values: {
                                  name: <div className='d-flex'>{input?.filename}</div>,
                                  status: (
                                      <div className='status-icon' style={{ minWidth: 50, marginLeft: 10 }}>
                                          {this.getIconBasedOnStatusAndSize(input?.status as InputFileStatusEnum, input?.size ? input?.size : 0, input?.total as number)}
                                      </div>
                                  ),
                                  templates: (
                                      <button
                                          className='btn'
                                          disabled={!input?.template}
                                          onClick={() => this.handleDownloadInput(input?.filename as string, input?.template?.filename as string)}>
                                          {<Download className='icon' fill={input?.template ? '#009138' : '#666666'} />}
                                      </button>
                                  ),
                                  action: (
                                      <div className='input-excution-buttons-container'>
                                          <>
                                              <button
                                                  onClick={() =>
                                                      this.setState({
                                                          filename: input?.filename as string,
                                                      })
                                                  }
                                                  disabled={execution?.run === 1 || execution?.status !== ServiceExecutionStatusEnum.WAIT}
                                                  className='btn btn-primary btnInput'
                                                  style={{ color: '#ffffff', minWidth: 120 }}
                                                  data-bs-toggle='modal'
                                                  data-bs-target='#localUploadModal'>
                                                  {this.translator.get('project.detail.accordion.input.local.button.text')}
                                              </button>
                                              <button
                                                  onClick={() =>
                                                      this.setState({
                                                          filename: input?.filename as string,
                                                          isInputModalClosed: false,
                                                      })
                                                  }
                                                  className='btn btn-primary btnInput'
                                                  style={{ color: '#ffffff', minWidth: 120 }}
                                                  disabled={execution?.run === 1 || execution?.status !== ServiceExecutionStatusEnum.WAIT}
                                                  data-bs-toggle='modal'
                                                  data-bs-target='#dataPortalUploadModal'>
                                                  {this.translator.get('project.detail.accordion.input.data.portal.button.text')}
                                              </button>
                                          </>
                                      </div>
                                  ),
                              },
                          }
                        : {
                              id: input?.filename,
                              values: {
                                  name: <div className='d-flex'>{input?.filename}</div>,
                                  status: (
                                      <div className='status-icon' style={{ minWidth: 50, marginLeft: 10 }}>
                                          {this.getIconBasedOnStatusAndSize(input?.status as InputFileStatusEnum, input?.size ? input?.size : 0, input?.total as number)}
                                      </div>
                                  ),
                                  action: (
                                      <div className='input-excution-buttons-container'>
                                          <>
                                              <button
                                                  onClick={() =>
                                                      this.setState({
                                                          filename: input?.filename as string,
                                                      })
                                                  }
                                                  disabled={execution?.run === 1 || execution?.status !== ServiceExecutionStatusEnum.WAIT}
                                                  className='btn btn-primary btnInput'
                                                  style={{ color: '#ffffff', minWidth: 120 }}
                                                  data-bs-toggle='modal'
                                                  data-bs-target='#localUploadModal'>
                                                  {this.translator.get('project.detail.accordion.input.local.button.text')}
                                              </button>
                                              <button
                                                  onClick={() =>
                                                      this.setState({
                                                          filename: input?.filename as string,
                                                          isInputModalClosed: false,
                                                      })
                                                  }
                                                  className='btn btn-primary btnInput'
                                                  style={{ color: '#ffffff', minWidth: 120 }}
                                                  disabled={execution?.run === 1 || execution?.status !== ServiceExecutionStatusEnum.WAIT}
                                                  data-bs-toggle='modal'
                                                  data-bs-target='#dataPortalUploadModal'>
                                                  {this.translator.get('project.detail.accordion.input.data.portal.button.text')}
                                              </button>
                                          </>
                                      </div>
                                  ),
                              },
                          };
                });

        const outputCols = [
            this.translator.get('service.execution.detail.table.header.name.text'),
            this.translator.get('service.execution.detail.output.table.header.date.text'),
            this.translator.get('service.execution.detail.output.table.header.size.text'),
            this.translator.get('common.table.header.actions.text'),
        ];

        const outputRows =
            this.props.execution?.outputs &&
            Object.values(this.props.execution?.outputs).map((item: OutputFile | undefined) => {
                return {
                    id: item?.filename,
                    values: {
                        name: item?.filename,
                        date: moment
                            .utc(item?.created_at as string)
                            .local()
                            .format('DD/MM/YYYY HH:mm'),
                        size: item?.size ? FormatUtil.formatBytes(item.size) : '0 Bytes',
                        actions: (
                            <button className='btn ' onClick={() => this.handleDownloadOutput(item?.filename as string)}>
                                {<Download className='icon' fill='#009138' />}
                            </button>
                        ),
                    },
                };
            });

        const statisticsCellColumns = [
            this.translator.get('project.detail.statistics.cells.table.cell.id.text'),
            this.props.configuration['cells_execution_time'.replace('service', '')] === 'true' && this.translator.get('project.detail.statistics.cells.table.execution.time.text'),
            this.props.configuration['cells_code'.replace('service', '')] === 'true' && this.translator.get('project.detail.statistics.cells.table.code.text'),
            this.props.configuration['cells_message'.replace('service', '')] === 'true' && this.translator.get('project.detail.statistics.cells.table.message.text'),
        ];

        const statisticsCellRows =
            execution?.metrics?.cells && Object.keys(execution?.metrics.cells).length > 0
                ? Object.values(execution?.metrics.cells).map((item: CellInBoundMetrics | undefined, index) => {
                      return {
                          id: item?.cell_index,
                          values: {
                              cell: item?.cell_index,
                              execution_time:
                                  this.props.configuration['cells_execution_time'] === 'true'
                                      ? item?.execution_time && moment.utc(moment.duration(item?.execution_time).asMilliseconds()).format('H[h] m[m] s[s]')
                                      : ' ',
                              code: this.props.configuration['cells_code'] === 'true' ? item?.code : ' ',
                              message: this.props.configuration['cells_message'] === 'true' ? item?.message : ' ',
                          },
                      };
                  })
                : [];

        const statisticsColumns = [
            this.translator.get('project.detail.statistics.table.header.title.text'),
            this.translator.get('project.detail.statistics.table.header.value.text'),
        ];
        const statisticsLabel: Statistics = {
            cpu_avg: this.translator.get('project.detail.statistics.table.cpu.average.text'),
            cpu_max: this.translator.get('project.detail.statistics.table.cpu.max.text'),
            memory_avg: this.translator.get('project.detail.statistics.table.memory.average.text'),
            memory_max: this.translator.get('project.detail.statistics.table.memory.max.text'),
            storage_file_count: this.translator.get('project.detail.statistics.table.storage.total.file.text'),
            storage_total_size: this.translator.get('project.detail.statistics.table.storage.total.size.text'),
            execution_time: this.translator.get('project.detail.statistics.table.execution.time.text'),
        };

        const isStatisticTableField = (key: string) => {
            return key !== 'cells' && key !== 'timestamp' && key !== 'started' && key !== 'finished' && key !== 'id' && key !== 'instanceId' && key !== 'executionId';
        };

        const getStatisticValue = (key: string, value: string) => {
            if (key.includes('cpu')) {
                return FormatUtil.roundNumberAfterComma(Number(value), 2) + ' %';
            } else if (key.includes('memory') || key === 'storage_total_size') {
                return FormatUtil.formatBytes(Number(value));
            } else if (key === 'execution_time') {
                return moment.utc(moment.duration(value).asMilliseconds()).format('H[h] m[m] s[s]');
            } else {
                return value;
            }
        };

        const formatWarningValues = (key: string | undefined, value: number | undefined) => {
            switch (key) {
                case 'CPU':
                    return `${value} %`;
                case 'MEMORY':
                    return FormatUtil.formatBytes(Number(value));
                case 'STORAGE':
                    return FormatUtil.formatBytes(Number(value));
            }
        };

        const statisticsRows =
            execution?.metrics && Object.keys(execution?.metrics).length > 0
                ? Object.keys(execution?.metrics)
                      .filter(metricKey => isStatisticTableField(metricKey))
                      .map((item: string, index) => {
                          if (this.props.configuration[item.replace('service_', '')] && this.props.configuration[item.replace('service_', '')] === 'true') {
                              return {
                                  id: index,
                                  values: {
                                      title: statisticsLabel[item as keyof Statistics],
                                      value: execution?.metrics && getStatisticValue(item, execution?.metrics[item as keyof InBoundMetrics] as string),
                                  },
                              };
                          }
                      })
                : [];

        const warningColumns = [
            this.translator.get('project.detail.warning.table.header.warning.text'),
            this.translator.get('project.detail.warning.table.header.type.text'),
            this.translator.get('project.detail.warning.table.header.timestamp.text'),
        ];

        const warningRows =
            execution?.warnings && Object.keys(execution?.warnings).length > 0
                ? execution?.warnings.map((item: InBoundWarnings | undefined, index) => {
                      return {
                          id: index,
                          values: {
                              warning: formatWarningValues(item?.type, item?.value),
                              type: item?.type,
                              timestamp: moment
                                  .utc(item?.timestamp as string)
                                  .local()
                                  .format('DD/MM/YYYY HH:mm'),
                          },
                      };
                  })
                : [];

        return (
            <>
                <div className='header-title-container pb-0'>
                    <p className='header-title col-12 mb-4 mt-0'>{this.props.service?.name}</p>
                    <Breadcrumb secondaryPages={[this.translator.get('service.detail.execution.breadcrumb.secondary.item')]} />
                </div>
                <ServiceDetailHeader
                    project={this.props.project}
                    service={this.props.service}
                    execution={this.props.execution}
                    templates={this.props.templates}
                    onStart={this.handleStartExecution}
                    onStop={this.handleStopExecution}
                    origin={this.props.origin}
                />
                <div className='container-fluid'>
                    <div className='col-12  d-flex flex-row justify-content-end align-items-center'>
                        <div className='me-3'>
                            {execution?.status === ServiceExecutionStatusEnum.RUNNING && (
                                <button className='btn p-2 btn-primary text-white inline-text button-execution-container white-space-nowrap' onClick={this.handleStopExecution}>
                                    {this.translator.get('service.detail.header.stop.execution.button.text')}
                                </button>
                            )}
                            {execution?.status === ServiceExecutionStatusEnum.WAIT && (
                                <button
                                    className='btn p-2 btn-primary text-white inline-text button-execution-container white-space-nowrap'
                                    onClick={this.handleStartExecution}
                                    disabled={execution.inputs && Object.values(execution.inputs).find(item => item?.status !== InputFileStatusEnum.READY) ? true : false}>
                                    {this.translator.get('service.detail.header.launch.execution.button.text')}
                                </button>
                            )}
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col-xs-12 col-md-6 mt-3'>
                            <div className='accordion accordion-background-active accordion-item-container bg-white h-100' id='accordion'>
                                <div className='accordion-item accordion-display-container accordion-item-container h-100'>
                                    <h2 className='accordion-header '>
                                        <button
                                            className='accordion-button collapsed border'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#input'
                                            aria-expanded='true'
                                            aria-controls='input'>
                                            {this.translator.get('service.execution.detail.input.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div id='input' className='accordion-collapse collapse show output-accordion-body' role='region' aria-labelledby='input'>
                                        <div className='accordion-body'>
                                            <Table columns={inputCols} rows={inputRows} keyLabel='input' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xs-12 col-md-6 mt-3'>
                            <div className='accordion accordion-background-active accordion-item-container bg-white h-100' id='accordion'>
                                <div className='accordion-item-container h-100 accordion-display-container'>
                                    <h2 className='accordion-header '>
                                        <button
                                            className='accordion-button collapsed border'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#output'
                                            aria-expanded='true'
                                            aria-controls='output'>
                                            {this.translator.get('service.execution.detail.output.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div
                                        id='output'
                                        className='accordion-collapse collapse show output-accordion-body'
                                        role='region'
                                        aria-labelledby='output'
                                        ref={ref => (this.ref = ref)}>
                                        <div className='accordion-body'>
                                            <Table columns={outputCols} rows={outputRows} keyLabel='output' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='accordion accordion-background-active border bg-white' id='accordion'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            className='accordion-button border collapsed'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#log'
                                            aria-expanded='false'
                                            aria-controls='log'
                                            onClick={e => {
                                                e.preventDefault();
                                                this.handleLogsScroll();
                                            }}>
                                            {this.translator.get('execution.detail.log.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div id='log' className='collapse' role='region' aria-labelledby='log'>
                                        <div className='accordion-body log-size' ref={this.logsRef}>
                                            <div className='content' dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(this.props.logs?.replace(/(?:\n)/g, '<br>')) }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='accordion accordion-background-active border bg-white' id='accordion'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            className='accordion-button border collapsed'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#html'
                                            aria-expanded='false'
                                            aria-controls='html'
                                            onClick={e => {
                                                e.preventDefault();
                                                this.handleHtmlScroll();
                                            }}>
                                            {this.translator.get('execution.detail.html.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div id='html' className='collapse size-tab' role='region' aria-labelledby='html'>
                                        <div className='accordion-body' style={{ maxHeight: 400 }} ref={this.htmlRef}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-12'>
                            <div className='accordion accordion-background-active border bg-white' id='accordion'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            className='accordion-button border collapsed'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#Statistics'
                                            aria-expanded='false'
                                            aria-controls='Statistics'>
                                            {this.translator.get('service.execution.detail.statistics.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div id='Statistics' className='collapse size-tab' role='region' aria-labelledby='Statistics'>
                                        <div className='accordion-body'>
                                            <Table columns={statisticsColumns} rows={statisticsRows} keyLabel='statistics' />
                                            {(this.props.configuration['cells_execution_time'] === 'true' ||
                                                this.props.configuration['cells_code'] === 'true' ||
                                                this.props.configuration['cells_message'] === 'true') && (
                                                <div className='mt-4'>
                                                    <h5>{this.translator.get('project.detail.statistics.table.cells.text')}</h5>

                                                    <Table columns={statisticsCellColumns as string[]} rows={statisticsCellRows} keyLabel='statistics' />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'></div>
                    <div className='col-12 mb-3'>
                        <div className='accordion accordion-background-active border bg-white' id='accordion'>
                            <div className='accordion-item'>
                                <h2 className='accordion-header ' id='heading1c'>
                                    <button
                                        className='accordion-button border collapsed'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#Warnings'
                                        aria-expanded='false'
                                        aria-controls='Warnings'
                                        onClick={e => {
                                            e.preventDefault();
                                            this.handleWarningsScroll();
                                        }}>
                                        {this.translator.get('project.detail.warnings.accordion.header.button.text')}
                                    </button>
                                </h2>

                                <div id='Warnings' className='collapse size-tab' role='region' aria-labelledby='heading1c'>
                                    <div className='accordion-body' style={{ maxHeight: 400, overflowY: 'auto' }} ref={this.warningsRef}>
                                        <Table columns={warningColumns} rows={warningRows} keyLabel='warning' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className='modal fade'
                    role='dialog'
                    id='dataPortalUploadModal'
                    aria-labelledby='dataPortalUploadModalTitle'
                    data-bs-backdrop='static'
                    data-bs-keyboard='false'>
                    <div className='modal-dialog modal-xl' role='document'>
                        <div className='modal-content' style={{ height: 800 }}>
                            <div className='modal-header'>
                                <h2 className='modal-title h5 no_toc' id='dataPortalUploadModalTitle'>
                                    Upload From URL
                                </h2>
                                <button
                                    className='btn-close'
                                    type='button'
                                    data-bs-dismiss='modal'
                                    aria-label='Close modal'
                                    id='dataPortalUploadModalClose'
                                    onClick={() =>
                                        this.setState({
                                            isInputModalClosed: true,
                                        })
                                    }
                                />
                            </div>
                            <div className='modal-body mt-4'>
                                <ServiceBehubInputUploadComponent
                                    user={this.props.user as LoginState}
                                    service={this.props.service as WebService}
                                    execution={this.props.execution as ServiceExecution}
                                    isClosed={this.state.isInputModalClosed}
                                    onUpload={this.handleUploadFromURL}
                                    filename={this.state.filename}
                                />
                            </div>
                            <div className='modal-footer'></div>
                        </div>
                    </div>
                </div>

                <div className='modal fade' role='dialog' id='localUploadModal' aria-labelledby='localUploadModalTitle' data-bs-backdrop='static' data-bs-keyboard='false'>
                    <div className='modal-dialog' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h2 className='modal-title h5 no_toc' id='localUploadModalTitle'>
                                    Upload From Local
                                </h2>
                                <button
                                    className='btn-close'
                                    type='button'
                                    data-bs-dismiss='modal'
                                    aria-label='Close modal'
                                    id='localUploadModalClose'
                                    onClick={() =>
                                        this.setState({
                                            isInputModalClosed: true,
                                        })
                                    }
                                />
                            </div>
                            <div className='modal-body mt-4'>
                                <ServiceLocalInputUploadComponent
                                    service={this.props.service as WebService}
                                    execution={this.props.execution as ServiceExecution}
                                    isClosed={this.state.isInputModalClosed}
                                    onUpload={this.handleUploadFromLocal}
                                    filename={this.state.filename}
                                />
                            </div>
                            <div className='modal-footer'></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
