/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { Behub } from './behub';
// May contain unused imports in some cases
// @ts-ignore
import { Template } from './template';
// May contain unused imports in some cases
// @ts-ignore
import { User } from './user';

/**
 *
 * @export
 * @interface InputFile
 */
export interface InputFile {
    /**
     *
     * @type {string}
     * @memberof InputFile
     */
    filename?: string;
    /**
     *
     * @type {string}
     * @memberof InputFile
     */
    type?: InputFileTypeEnum;
    /**
     *
     * @type {number}
     * @memberof InputFile
     */
    size?: number;
    /**
     *
     * @type {string}
     * @memberof InputFile
     */
    language?: string;
    /**
     *
     * @type {string}
     * @memberof InputFile
     */
    format?: string;
    /**
     *
     * @type {number}
     * @memberof InputFile
     */
    total?: number;
    /**
     *
     * @type {boolean}
     * @memberof InputFile
     */
    persistent?: boolean;
    /**
     *
     * @type {string}
     * @memberof InputFile
     */
    status?: InputFileStatusEnum;
    /**
     *
     * @type {string}
     * @memberof InputFile
     */
    url?: string;
    /**
     *
     * @type {Behub}
     * @memberof InputFile
     */
    behub?: Behub;
    /**
     *
     * @type {Template}
     * @memberof InputFile
     */
    template?: Template;
    /**
     *
     * @type {string}
     * @memberof InputFile
     */
    created_at?: string;
    /**
     *
     * @type {User}
     * @memberof InputFile
     */
    created_by?: User;
}

export const InputFileTypeEnum = {
    LOCAL: 'LOCAL',
    BEHUB: 'BEHUB',
    URL: 'URL',
} as const;

export type InputFileTypeEnum = (typeof InputFileTypeEnum)[keyof typeof InputFileTypeEnum];
export const InputFileStatusEnum = {
    READY: 'READY',
    PROGRESS: 'PROGRESS',
    ERROR: 'ERROR',
    WAIT: 'WAIT',
} as const;

export type InputFileStatusEnum = (typeof InputFileStatusEnum)[keyof typeof InputFileStatusEnum];
