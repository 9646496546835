import { IReduceableAction, ReduxAction } from '@hypereact/state';
import { Slices } from '@store/state';
import { BaseProjectTemplate, OutputFile, Project, ProjectNode, ProjectSearchFilter, ProjectStatusEnum, ResponseBehubIntegrationPublishDataset } from '../../../api/entities';
import { initialProjectState, InputFile, MemberCreate, ProjectCreateUpdate, ProjectState, ProjectUser } from './state';

@ReduxAction('PROJECT_SET_PROJECTS', Slices.Project)
export class ProjectSetProjects implements IReduceableAction<ProjectState> {
    constructor(public projects: Array<Project>) {}
    reduce(state: ProjectState) {
        state.project.projects = this.projects || [];
        return state;
    }
}

@ReduxAction('PROJECT_SET_SELECTED_PROJECT', Slices.Project)
export class ProjectSetSelectedProject implements IReduceableAction<ProjectState> {
    constructor(public project: Project) {}
    reduce(state: ProjectState) {
        state.project.selectedProject = this.project;
        return state;
    }
}

@ReduxAction('PROJECT_RESET_SELECTED_PROJECT', Slices.Project)
export class ProjectResetSelectedProject implements IReduceableAction<ProjectState> {
    reduce(state: ProjectState) {
        state.project.selectedProject = initialProjectState.project.selectedProject;
        return state;
    }
}

@ReduxAction('PROJECT_SET_FILTERS', Slices.Project)
export class ProjectSetFilters implements IReduceableAction<ProjectState> {
    constructor(public key: keyof ProjectSearchFilter, public value: string | Array<string> | undefined) {}
    reduce(state: ProjectState) {
        state.project.filters[this.key] = this.value as any;
        //return state;
        return { ...state, project: { ...state.project, filters: { ...state.project.filters, [this.key]: this.value } } };
    }
}

@ReduxAction('PROJECT_RESET_FILTERS', Slices.Project)
export class ProjectResetFilters implements IReduceableAction<ProjectState> {
    reduce(state: ProjectState) {
        state.project.filters = initialProjectState.project.filters;
        state.project.categoryAvailableOptions = initialProjectState.project.categoryAvailableOptions;
        return state;
    }
}

@ReduxAction('PROJECT_RESET_CATEGORY_FILTERS', Slices.Project)
export class ProjectResetCategoryFilters implements IReduceableAction<ProjectState> {
    reduce(state: ProjectState) {
        state.project.filters.categories = initialProjectState.project.filters.categories;
        state.project.categoryAvailableOptions = initialProjectState.project.categoryAvailableOptions;
        return state;
    }
}

@ReduxAction('PROJECT_UPDATE_CATEGORY_OPTIONS', Slices.Project)
export class ProjectUpdateCategoryAvailableOptions implements IReduceableAction<ProjectState> {
    constructor(public value: any) {}
    reduce(state: ProjectState) {
        if (!Array.isArray(this.value)) {
            this.value && (state.project.categoryAvailableOptions = state.project.categoryAvailableOptions.filter(prevItem => !this.value.split(',').includes(prevItem)));
        } else {
            this.value && (state.project.categoryAvailableOptions = state.project.categoryAvailableOptions.filter(prevItem => !String(this.value).split(',').includes(prevItem)));
        }
        return state;
    }
}

@ReduxAction('PROJECT_RESET_CREATE_UPDATE_CATEGORY', Slices.Project)
export class ProjectResetCreateUpdateCategory implements IReduceableAction<ProjectState> {
    reduce(state: ProjectState) {
        state.project.createUpdate.categories = initialProjectState.project.createUpdate.categories;
        state.project.categoryAvailableOptions = initialProjectState.project.categoryAvailableOptions;
        return state;
    }
}

@ReduxAction('PROJECT_SET_VALUE', Slices.Project)
export class ProjectSetValue implements IReduceableAction<ProjectState> {
    constructor(public key: keyof ProjectCreateUpdate, public value: any) {}
    reduce(state: ProjectState) {
        state.project.createUpdate[this.key] = this.value;
        return state;
    }
}

@ReduxAction('PROJECT_SET_NEW_MEMBER_VALUE', Slices.Project)
export class ProjectSetNewMemberValue implements IReduceableAction<ProjectState> {
    constructor(public key: keyof MemberCreate, public value: any) {}
    reduce(state: ProjectState) {
        state.project.newCollaborator[this.key] = this.value;
        return state;
    }
}

@ReduxAction('PROJECT_RESET_CREATE_UPDATE', Slices.Project)
export class ProjectResetCreateUpdate implements IReduceableAction<ProjectState> {
    reduce(state: ProjectState) {
        state.project.createUpdate = initialProjectState.project.createUpdate;
        //state.project.categoryAvailableOptions = initialProjectState.project.categoryAvailableOptions;
        return state;
    }
}

@ReduxAction('PROJECT_RESET_NEW_COLLABORATOR', Slices.Project)
export class ProjectResetNewCollaborator implements IReduceableAction<ProjectState> {
    reduce(state: ProjectState) {
        state.project.newCollaborator = initialProjectState.project.newCollaborator;
        return state;
    }
}

@ReduxAction('PROJECT_SET_COLLABORATORS', Slices.Project)
export class ProjectSetCollaborators implements IReduceableAction<ProjectState> {
    constructor(public collaborators: Array<ProjectUser>) {}
    reduce(state: ProjectState) {
        state.team.collaborators = this.collaborators;
        return state;
    }
}

@ReduxAction('PROJECT_SET_OUTPUTS', Slices.Project)
export class ProjectSetOutputs implements IReduceableAction<ProjectState> {
    constructor(public outputs: Array<OutputFile> | undefined) {}
    reduce(state: ProjectState) {
        state.output.outputs = this.outputs ?? [];
        return state;
    }
}

@ReduxAction('PROJECT_RESET_OUTPUTS', Slices.Project)
export class ProjectResetOutputs implements IReduceableAction<ProjectState> {
    reduce(state: ProjectState) {
        state.output.outputs = initialProjectState.output.outputs;
        return state;
    }
}

@ReduxAction('PROJECT_SET_SELECTED_OUTPUT', Slices.Project)
export class ProjectSetSelectedOutuput implements IReduceableAction<ProjectState> {
    constructor(public output: OutputFile) {}
    reduce(state: ProjectState) {
        state.output.selectedOutput = this.output;
        return state;
    }
}

@ReduxAction('PROJECT_RESET_SELECTED_OUTPUT', Slices.Project)
export class ProjectResetSelectedOutuput implements IReduceableAction<ProjectState> {
    reduce(state: ProjectState) {
        state.output.selectedOutput = initialProjectState.output.selectedOutput;
        return state;
    }
}

@ReduxAction('PROJECT_SET_BINDER_URL', Slices.Project)
export class ProjectSetBinderURL implements IReduceableAction<ProjectState> {
    constructor(public binderUrl: string) {}
    reduce(state: ProjectState) {
        state.project.selectedProject && (state.project.selectedProject.binderUrl = this.binderUrl);
        return state;
    }
}

@ReduxAction('PROJECT_RESET_BINDER_URL', Slices.Project)
export class ProjectResetBinderURL implements IReduceableAction<ProjectState> {
    reduce(state: ProjectState) {
        state.project.selectedProject && (state.project.selectedProject.binderUrl = undefined);
        return state;
    }
}

@ReduxAction('PROJECT_SET_IS_IDE_OPENED', Slices.Project)
export class ProjectSetIsIDEOpened implements IReduceableAction<ProjectState> {
    constructor(public isOpen: boolean) {}
    reduce(state: ProjectState) {
        state.project.isIDEOpen = this.isOpen;
        return state;
    }
}

@ReduxAction('PROJECT_SET_TEMPLATES', Slices.Project)
export class ProjectSetTemplates implements IReduceableAction<ProjectState> {
    constructor(public templates: Array<BaseProjectTemplate>) {}
    reduce(state: ProjectState) {
        state.template = this.templates;
        return state;
    }
}

@ReduxAction('PROJECT_SET_NEW_PROJECT', Slices.Project)
export class ProjectSetNewProject implements IReduceableAction<ProjectState> {
    constructor(public newProject: Project) {}
    reduce(state: ProjectState) {
        state.project.projects.push(this.newProject);
        return state;
    }
}

@ReduxAction('PROJECT_SET_INPUTS', Slices.Project)
export class ProjectSetInputs implements IReduceableAction<ProjectState> {
    constructor(public inputs: Array<InputFile>) {}
    reduce(state: ProjectState) {
        //state.project.projects.find((item:Project)=> item.id === this.projectId)!.inputs = this.inputs
        state.project.selectedProject && ((state.project.selectedProject.inputs as any) = this.inputs);
        return state;
    }
}

@ReduxAction('PROJECT_SET_PROVENANCE', Slices.Project)
export class ProjectSetProvenance implements IReduceableAction<ProjectState> {
    constructor(public provenance: ProjectNode) {}
    reduce(state: ProjectState) {
        //state.project.projects.find((item:Project)=> item.id === this.projectId)!.inputs = this.inputs
        state.project.provenance = this.provenance;
        return state;
    }
}

@ReduxAction('PROJECT_SET_PAGINATION', Slices.Project)
export class ProjectSetPaginationAction implements IReduceableAction<ProjectState> {
    constructor(public page: number, public size: number, public total: number) {}

    reduce(project: ProjectState) {
        project.project.pagination.page = this.page;
        project.project.pagination.size = this.size;
        project.project.pagination.total = this.total;

        return project;
    }
}

@ReduxAction('PROJECT_RESET_PAGINATION', Slices.Project)
export class ProjectResetPaginationAction implements IReduceableAction<ProjectState> {
    reduce(project: ProjectState) {
        project.project.pagination.page = initialProjectState.project.pagination.page;
        project.project.pagination.size = initialProjectState.project.pagination.size;
        project.project.pagination.total = initialProjectState.project.pagination.total;
        return project;
    }
}

@ReduxAction('PROJECT_SET_CATEGORIES', Slices.Project)
export class ProjectSetCategories implements IReduceableAction<ProjectState> {
    constructor(public categories: Array<string>) {}
    reduce(state: ProjectState) {
        state.project.categoryAvailableOptions = this.categories;
        return state;
    }
}

@ReduxAction('PROJECT_SET_PROJECT_STATUS', Slices.Project)
export class ProjectSetStatus implements IReduceableAction<ProjectState> {
    constructor(public status: ProjectStatusEnum) {}
    reduce(state: ProjectState) {
        state.project.selectedProject!.status = this.status;
        return state;
    }
}

@ReduxAction('PROJECT_UPDATE_INPUT_STATUS', Slices.Project)
export class ProjectUpdateInputsStatus implements IReduceableAction<ProjectState> {
    constructor(public fileName: string, public status: ResponseBehubIntegrationPublishDataset) {}
    reduce(state: ProjectState) {
        state.project.selectedProject!.inputs![this.fileName]!.behub = {
            file_id: this.status.itemUUID,
            file_url: this.status.submissionUrl,
        };
        return state;
    }
}

@ReduxAction('PROJECT_UPDATE_OUTPUT_STATUS', Slices.Project)
export class ProjectUpdateOutputsStatus implements IReduceableAction<ProjectState> {
    constructor(public fileName: string, public status: ResponseBehubIntegrationPublishDataset) {}
    reduce(state: ProjectState) {
        state.project.selectedProject!.outputs![this.fileName]!.behub = {
            file_id: this.status.itemUUID,
            file_url: this.status.submissionUrl,
        };
        return state;
    }
}
