/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BaseResponse } from '../entities';
// @ts-ignore
import { ProjectNode } from '../entities';
// @ts-ignore
import { ProjectSearchRequest } from '../entities';
// @ts-ignore
import { RequestBodyGenericStopProcess } from '../entities';
// @ts-ignore
import { RequestFile } from '../entities';
// @ts-ignore
import { RequestGenericStart } from '../entities';
// @ts-ignore
import { RequestProjectAddUser } from '../entities';
// @ts-ignore
import { RequestProjectBEHUBFileSelect } from '../entities';
// @ts-ignore
import { RequestProjectCheck } from '../entities';
// @ts-ignore
import { RequestProjectClone } from '../entities';
// @ts-ignore
import { RequestProjectCreate } from '../entities';
// @ts-ignore
import { RequestProjectFileCreate } from '../entities';
// @ts-ignore
import { RequestProjectPublish } from '../entities';
// @ts-ignore
import { RequestProjectRemoveUser } from '../entities';
// @ts-ignore
import { RequestProjectTemplateCreate } from '../entities';
// @ts-ignore
import { RequestProjectUpdate } from '../entities';
// @ts-ignore
import { RequestProjectUrlFileSelect } from '../entities';
// @ts-ignore
import { RequestScriptPublish } from '../entities';
// @ts-ignore
import { RequestUpdateProvisioning } from '../entities';
// @ts-ignore
import { ResponseProjectAddUser } from '../entities';
// @ts-ignore
import { ResponseProjectBEHUBFileSelect } from '../entities';
// @ts-ignore
import { ResponseProjectCategories } from '../entities';
// @ts-ignore
import { ResponseProjectCheck } from '../entities';
// @ts-ignore
import { ResponseProjectClone } from '../entities';
// @ts-ignore
import { ResponseProjectCreate } from '../entities';
// @ts-ignore
import { ResponseProjectFileCreate } from '../entities';
// @ts-ignore
import { ResponseProjectGet } from '../entities';
// @ts-ignore
import { ResponseProjectListInput } from '../entities';
// @ts-ignore
import { ResponseProjectListInputRemove } from '../entities';
// @ts-ignore
import { ResponseProjectListOutput } from '../entities';
// @ts-ignore
import { ResponseProjectListUser } from '../entities';
// @ts-ignore
import { ResponseProjectPublish } from '../entities';
// @ts-ignore
import { ResponseProjectSearch } from '../entities';
// @ts-ignore
import { ResponseProjectTemplate } from '../entities';
// @ts-ignore
import { ResponseProjectTemplateCreate } from '../entities';
// @ts-ignore
import { ResponseProjectUpdate } from '../entities';
// @ts-ignore
import { ResponseProjectUrlFileSelect } from '../entities';
// @ts-ignore
import { ResponseScriptPublish } from '../entities';
// @ts-ignore
import { ResponseUpdateProvisioning } from '../entities';
/**
 * ProjectControllerApi - axios parameter creator
 * @export
 */
export const ProjectControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Adds user to the project
         * @param {string} id
         * @param {RequestProjectAddUser} requestProjectAddUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser1: async (id: string, requestProjectAddUser: RequestProjectAddUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('addUser1', 'id', id);
            // verify required parameter 'requestProjectAddUser' is not null or undefined
            assertParamExists('addUser1', 'requestProjectAddUser', requestProjectAddUser);
            const localVarPath = `/project/{id}/team/add`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestProjectAddUser, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Archive a project
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('archive', 'id', id);
            const localVarPath = `/project/archive/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks the existence of a specific project by name and version
         * @param {RequestProjectCheck} requestProjectCheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        check: async (requestProjectCheck: RequestProjectCheck, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestProjectCheck' is not null or undefined
            assertParamExists('check', 'requestProjectCheck', requestProjectCheck);
            const localVarPath = `/project/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestProjectCheck, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Clones project by another one specifying the identifier of source project.
         * @param {RequestProjectClone} requestProjectClone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clone: async (requestProjectClone: RequestProjectClone, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestProjectClone' is not null or undefined
            assertParamExists('clone', 'requestProjectClone', requestProjectClone);
            const localVarPath = `/project/clone`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestProjectClone, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a definition of an input for the project
         * @param {string} id
         * @param {RequestProjectFileCreate} requestProjectFileCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInput1: async (id: string, requestProjectFileCreate: RequestProjectFileCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createInput1', 'id', id);
            // verify required parameter 'requestProjectFileCreate' is not null or undefined
            assertParamExists('createInput1', 'requestProjectFileCreate', requestProjectFileCreate);
            const localVarPath = `/project/{id}/input/create`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestProjectFileCreate, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates new project from specific template. The template id comes from templates endpoint and categories comes from categories endpoint.
         * @param {RequestProjectCreate} requestProjectCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (requestProjectCreate: RequestProjectCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestProjectCreate' is not null or undefined
            assertParamExists('createProject', 'requestProjectCreate', requestProjectCreate);
            const localVarPath = `/project/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestProjectCreate, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates template as model for new projects
         * @param {RequestProjectTemplateCreate} requestProjectTemplateCreate
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createTemplate: async (requestProjectTemplateCreate: RequestProjectTemplateCreate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestProjectTemplateCreate' is not null or undefined
            assertParamExists('createTemplate', 'requestProjectTemplateCreate', requestProjectTemplateCreate);
            const localVarPath = `/project/template/create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestProjectTemplateCreate, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a project
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('delete1', 'id', id);
            const localVarPath = `/project/delete/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Downloads output notebook as html by project identifier
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadHtml1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadHtml1', 'id', id);
            const localVarPath = `/project/{id}/execution/outcome/html/download`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download a specific input
         * @param {string} id
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInput1: async (id: string, requestFile: RequestFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadInput1', 'id', id);
            // verify required parameter 'requestFile' is not null or undefined
            assertParamExists('downloadInput1', 'requestFile', requestFile);
            const localVarPath = `/project/{id}/input/download`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestFile, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download a specific project output files, included the build and environment logs.
         * @param {string} id
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOutput1: async (id: string, requestFile: RequestFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadOutput1', 'id', id);
            // verify required parameter 'requestFile' is not null or undefined
            assertParamExists('downloadOutput1', 'requestFile', requestFile);
            const localVarPath = `/project/{id}/output/download`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestFile, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Downloads repository as zip
         * @param {string} projectId
         * @param {string} zipname
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRepo: async (projectId: string, zipname: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('downloadRepo', 'projectId', projectId);
            // verify required parameter 'zipname' is not null or undefined
            assertParamExists('downloadRepo', 'zipname', zipname);
            const localVarPath = `/project/download/{projectId}/{zipname}`
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'zipname'}}`, encodeURIComponent(String(zipname)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve the project details by identifier
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('get1', 'id', id);
            const localVarPath = `/project/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all project categories used for creating the projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all project templates which can be used to create a project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get projects based on provenance
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByParentId: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectsByParentId', 'id', id);
            const localVarPath = `/project/getbyparent/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtains list of inputs
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inputList1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('inputList1', 'id', id);
            const localVarPath = `/project/{id}/inputs`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves and return the list of output files, generated from script and system
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outputList1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('outputList1', 'id', id);
            const localVarPath = `/project/{id}/output`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Publish project
         * @param {string} id
         * @param {RequestProjectPublish} requestProjectPublish
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishProject: async (id: string, requestProjectPublish: RequestProjectPublish, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publishProject', 'id', id);
            // verify required parameter 'requestProjectPublish' is not null or undefined
            assertParamExists('publishProject', 'requestProjectPublish', requestProjectPublish);
            const localVarPath = `/project/{id}/publish/project`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestProjectPublish, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Publish script
         * @param {string} id
         * @param {RequestScriptPublish} requestScriptPublish
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishScript: async (id: string, requestScriptPublish: RequestScriptPublish, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('publishScript', 'id', id);
            // verify required parameter 'requestScriptPublish' is not null or undefined
            assertParamExists('publishScript', 'requestScriptPublish', requestScriptPublish);
            const localVarPath = `/project/{id}/publish/script`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestScriptPublish, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Remove file from input
         * @param {string} id
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeInput: async (id: string, requestFile: RequestFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeInput', 'id', id);
            // verify required parameter 'requestFile' is not null or undefined
            assertParamExists('removeInput', 'requestFile', requestFile);
            const localVarPath = `/project/{id}/input/remove`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestFile, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Removes user to the project
         * @param {string} id
         * @param {RequestProjectRemoveUser} requestProjectRemoveUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUser: async (id: string, requestProjectRemoveUser: RequestProjectRemoveUser, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('removeUser', 'id', id);
            // verify required parameter 'requestProjectRemoveUser' is not null or undefined
            assertParamExists('removeUser', 'requestProjectRemoveUser', requestProjectRemoveUser);
            const localVarPath = `/project/{id}/team/remove`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestProjectRemoveUser, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * retrieve a list of projects matching sorting, filtering and pagination constraints
         * @param {ProjectSearchRequest} projectSearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search1: async (projectSearchRequest: ProjectSearchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectSearchRequest' is not null or undefined
            assertParamExists('search1', 'projectSearchRequest', projectSearchRequest);
            const localVarPath = `/project/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(projectSearchRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks and selection a specific input from BEHUB
         * @param {string} id
         * @param {string} inputName
         * @param {RequestProjectBEHUBFileSelect} requestProjectBEHUBFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectBehubInput2: async (
            id: string,
            inputName: string,
            requestProjectBEHUBFileSelect: RequestProjectBEHUBFileSelect,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('selectBehubInput2', 'id', id);
            // verify required parameter 'inputName' is not null or undefined
            assertParamExists('selectBehubInput2', 'inputName', inputName);
            // verify required parameter 'requestProjectBEHUBFileSelect' is not null or undefined
            assertParamExists('selectBehubInput2', 'requestProjectBEHUBFileSelect', requestProjectBEHUBFileSelect);
            const localVarPath = `/project/{id}/input/{inputName}/select/behub`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'inputName'}}`, encodeURIComponent(String(inputName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestProjectBEHUBFileSelect, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks and selection a specific input from external URL
         * @param {string} id
         * @param {RequestProjectUrlFileSelect} requestProjectUrlFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectExternalUrlInput2: async (id: string, requestProjectUrlFileSelect: RequestProjectUrlFileSelect, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('selectExternalUrlInput2', 'id', id);
            // verify required parameter 'requestProjectUrlFileSelect' is not null or undefined
            assertParamExists('selectExternalUrlInput2', 'requestProjectUrlFileSelect', requestProjectUrlFileSelect);
            const localVarPath = `/project/{id}/input/select/url`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestProjectUrlFileSelect, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Start a project by identifier. It builds the repository and create an ide environment
         * @param {string} id
         * @param {RequestGenericStart} requestGenericStart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        start: async (id: string, requestGenericStart: RequestGenericStart, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('start', 'id', id);
            // verify required parameter 'requestGenericStart' is not null or undefined
            assertParamExists('start', 'requestGenericStart', requestGenericStart);
            const localVarPath = `/project/{id}/start`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestGenericStart, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Stop a project by identifier. It removes the ide environment from session manager and close it
         * @param {string} id
         * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stop1: async (id: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('stop1', 'id', id);
            // verify required parameter 'requestBodyGenericStopProcess' is not null or undefined
            assertParamExists('stop1', 'requestBodyGenericStopProcess', requestBodyGenericStopProcess);
            const localVarPath = `/project/{id}/stop`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestBodyGenericStopProcess, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtains project data with detailed information
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        team: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('team', 'id', id);
            const localVarPath = `/project/{id}/team`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Modify the specific project fields. Only the specified field on the request will be updated.
         * @param {string} id
         * @param {RequestProjectUpdate} requestProjectUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1: async (id: string, requestProjectUpdate: RequestProjectUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('update1', 'id', id);
            // verify required parameter 'requestProjectUpdate' is not null or undefined
            assertParamExists('update1', 'requestProjectUpdate', requestProjectUpdate);
            const localVarPath = `/project/{id}/update`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestProjectUpdate, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update provisioning information
         * @param {string} id
         * @param {RequestUpdateProvisioning} requestUpdateProvisioning
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProvisioning: async (id: string, requestUpdateProvisioning: RequestUpdateProvisioning, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProvisioning', 'id', id);
            // verify required parameter 'requestUpdateProvisioning' is not null or undefined
            assertParamExists('updateProvisioning', 'requestUpdateProvisioning', requestUpdateProvisioning);
            const localVarPath = `/project/{id}/update/provisioning`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestUpdateProvisioning, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput22: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput23: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput24: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput25: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput26: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput27: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput28: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput29: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput30: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput31: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInputPatch2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ProjectControllerApi - functional programming interface
 * @export
 */
export const ProjectControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Adds user to the project
         * @param {string} id
         * @param {RequestProjectAddUser} requestProjectAddUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUser1(
            id: string,
            requestProjectAddUser: RequestProjectAddUser,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectAddUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUser1(id, requestProjectAddUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Archive a project
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async archive(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.archive(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checks the existence of a specific project by name and version
         * @param {RequestProjectCheck} requestProjectCheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async check(
            requestProjectCheck: RequestProjectCheck,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectCheck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.check(requestProjectCheck, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Clones project by another one specifying the identifier of source project.
         * @param {RequestProjectClone} requestProjectClone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clone(
            requestProjectClone: RequestProjectClone,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectClone>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clone(requestProjectClone, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a definition of an input for the project
         * @param {string} id
         * @param {RequestProjectFileCreate} requestProjectFileCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInput1(
            id: string,
            requestProjectFileCreate: RequestProjectFileCreate,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectFileCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInput1(id, requestProjectFileCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates new project from specific template. The template id comes from templates endpoint and categories comes from categories endpoint.
         * @param {RequestProjectCreate} requestProjectCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(
            requestProjectCreate: RequestProjectCreate,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(requestProjectCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates template as model for new projects
         * @param {RequestProjectTemplateCreate} requestProjectTemplateCreate
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createTemplate(
            requestProjectTemplateCreate: RequestProjectTemplateCreate,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectTemplateCreate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTemplate(requestProjectTemplateCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a project
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async delete1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.delete1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Downloads output notebook as html by project identifier
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadHtml1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadHtml1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download a specific input
         * @param {string} id
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadInput1(id: string, requestFile: RequestFile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadInput1(id, requestFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download a specific project output files, included the build and environment logs.
         * @param {string} id
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadOutput1(id: string, requestFile: RequestFile, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadOutput1(id, requestFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Downloads repository as zip
         * @param {string} projectId
         * @param {string} zipname
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadRepo(projectId: string, zipname: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadRepo(projectId, zipname, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve the project details by identifier
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectGet>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve all project categories used for creating the projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectCategories>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve all project templates which can be used to create a project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectTemplate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get projects based on provenance
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsByParentId(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectNode>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsByParentId(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtains list of inputs
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async inputList1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectListInput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.inputList1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves and return the list of output files, generated from script and system
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outputList1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectListOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outputList1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Publish project
         * @param {string} id
         * @param {RequestProjectPublish} requestProjectPublish
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishProject(
            id: string,
            requestProjectPublish: RequestProjectPublish,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectPublish>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishProject(id, requestProjectPublish, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Publish script
         * @param {string} id
         * @param {RequestScriptPublish} requestScriptPublish
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishScript(
            id: string,
            requestScriptPublish: RequestScriptPublish,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseScriptPublish>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishScript(id, requestScriptPublish, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Remove file from input
         * @param {string} id
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeInput(
            id: string,
            requestFile: RequestFile,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectListInputRemove>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeInput(id, requestFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Removes user to the project
         * @param {string} id
         * @param {RequestProjectRemoveUser} requestProjectRemoveUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUser(
            id: string,
            requestProjectRemoveUser: RequestProjectRemoveUser,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectListUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUser(id, requestProjectRemoveUser, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * retrieve a list of projects matching sorting, filtering and pagination constraints
         * @param {ProjectSearchRequest} projectSearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search1(
            projectSearchRequest: ProjectSearchRequest,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectSearch>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search1(projectSearchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checks and selection a specific input from BEHUB
         * @param {string} id
         * @param {string} inputName
         * @param {RequestProjectBEHUBFileSelect} requestProjectBEHUBFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectBehubInput2(
            id: string,
            inputName: string,
            requestProjectBEHUBFileSelect: RequestProjectBEHUBFileSelect,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectBEHUBFileSelect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectBehubInput2(id, inputName, requestProjectBEHUBFileSelect, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checks and selection a specific input from external URL
         * @param {string} id
         * @param {RequestProjectUrlFileSelect} requestProjectUrlFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectExternalUrlInput2(
            id: string,
            requestProjectUrlFileSelect: RequestProjectUrlFileSelect,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectUrlFileSelect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectExternalUrlInput2(id, requestProjectUrlFileSelect, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Start a project by identifier. It builds the repository and create an ide environment
         * @param {string} id
         * @param {RequestGenericStart} requestGenericStart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async start(
            id: string,
            requestGenericStart: RequestGenericStart,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.start(id, requestGenericStart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Stop a project by identifier. It removes the ide environment from session manager and close it
         * @param {string} id
         * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async stop1(
            id: string,
            requestBodyGenericStopProcess: RequestBodyGenericStopProcess,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.stop1(id, requestBodyGenericStopProcess, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtains project data with detailed information
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async team(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectListUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.team(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Modify the specific project fields. Only the specified field on the request will be updated.
         * @param {string} id
         * @param {RequestProjectUpdate} requestProjectUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async update1(
            id: string,
            requestProjectUpdate: RequestProjectUpdate,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseProjectUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.update1(id, requestProjectUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update provisioning information
         * @param {string} id
         * @param {RequestUpdateProvisioning} requestUpdateProvisioning
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProvisioning(
            id: string,
            requestUpdateProvisioning: RequestUpdateProvisioning,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseUpdateProvisioning>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProvisioning(id, requestUpdateProvisioning, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput22(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput22(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput23(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput23(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput24(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput24(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput25(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput25(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput26(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput26(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput27(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput27(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput28(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput28(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput29(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput29(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput30(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput30(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput31(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput31(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInputPatch2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInputPatch2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ProjectControllerApi - factory interface
 * @export
 */
export const ProjectControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectControllerApiFp(configuration);
    return {
        /**
         * Adds user to the project
         * @param {string} id
         * @param {RequestProjectAddUser} requestProjectAddUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser1(id: string, requestProjectAddUser: RequestProjectAddUser, options?: any): AxiosPromise<ResponseProjectAddUser> {
            return localVarFp.addUser1(id, requestProjectAddUser, options).then(request => request(axios, basePath));
        },
        /**
         * Archive a project
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        archive(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.archive(id, options).then(request => request(axios, basePath));
        },
        /**
         * Checks the existence of a specific project by name and version
         * @param {RequestProjectCheck} requestProjectCheck
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        check(requestProjectCheck: RequestProjectCheck, options?: any): AxiosPromise<ResponseProjectCheck> {
            return localVarFp.check(requestProjectCheck, options).then(request => request(axios, basePath));
        },
        /**
         * Clones project by another one specifying the identifier of source project.
         * @param {RequestProjectClone} requestProjectClone
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clone(requestProjectClone: RequestProjectClone, options?: any): AxiosPromise<ResponseProjectClone> {
            return localVarFp.clone(requestProjectClone, options).then(request => request(axios, basePath));
        },
        /**
         * Creates a definition of an input for the project
         * @param {string} id
         * @param {RequestProjectFileCreate} requestProjectFileCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInput1(id: string, requestProjectFileCreate: RequestProjectFileCreate, options?: any): AxiosPromise<ResponseProjectFileCreate> {
            return localVarFp.createInput1(id, requestProjectFileCreate, options).then(request => request(axios, basePath));
        },
        /**
         * Creates new project from specific template. The template id comes from templates endpoint and categories comes from categories endpoint.
         * @param {RequestProjectCreate} requestProjectCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(requestProjectCreate: RequestProjectCreate, options?: any): AxiosPromise<ResponseProjectCreate> {
            return localVarFp.createProject(requestProjectCreate, options).then(request => request(axios, basePath));
        },
        /**
         * Creates template as model for new projects
         * @param {RequestProjectTemplateCreate} requestProjectTemplateCreate
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createTemplate(requestProjectTemplateCreate: RequestProjectTemplateCreate, options?: any): AxiosPromise<ResponseProjectTemplateCreate> {
            return localVarFp.createTemplate(requestProjectTemplateCreate, options).then(request => request(axios, basePath));
        },
        /**
         * Delete a project
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        delete1(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.delete1(id, options).then(request => request(axios, basePath));
        },
        /**
         * Downloads output notebook as html by project identifier
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadHtml1(id: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadHtml1(id, options).then(request => request(axios, basePath));
        },
        /**
         * Download a specific input
         * @param {string} id
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInput1(id: string, requestFile: RequestFile, options?: any): AxiosPromise<File> {
            return localVarFp.downloadInput1(id, requestFile, options).then(request => request(axios, basePath));
        },
        /**
         * Download a specific project output files, included the build and environment logs.
         * @param {string} id
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOutput1(id: string, requestFile: RequestFile, options?: any): AxiosPromise<File> {
            return localVarFp.downloadOutput1(id, requestFile, options).then(request => request(axios, basePath));
        },
        /**
         * Downloads repository as zip
         * @param {string} projectId
         * @param {string} zipname
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadRepo(projectId: string, zipname: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadRepo(projectId, zipname, options).then(request => request(axios, basePath));
        },
        /**
         * Retrieve the project details by identifier
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get1(id: string, options?: any): AxiosPromise<ResponseProjectGet> {
            return localVarFp.get1(id, options).then(request => request(axios, basePath));
        },
        /**
         * Retrieve all project categories used for creating the projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectCategories(options?: any): AxiosPromise<ResponseProjectCategories> {
            return localVarFp.getProjectCategories(options).then(request => request(axios, basePath));
        },
        /**
         * Retrieve all project templates which can be used to create a project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectTemplates(options?: any): AxiosPromise<ResponseProjectTemplate> {
            return localVarFp.getProjectTemplates(options).then(request => request(axios, basePath));
        },
        /**
         * Get projects based on provenance
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsByParentId(id: string, options?: any): AxiosPromise<ProjectNode> {
            return localVarFp.getProjectsByParentId(id, options).then(request => request(axios, basePath));
        },
        /**
         * Obtains list of inputs
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inputList1(id: string, options?: any): AxiosPromise<ResponseProjectListInput> {
            return localVarFp.inputList1(id, options).then(request => request(axios, basePath));
        },
        /**
         * Retrieves and return the list of output files, generated from script and system
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outputList1(id: string, options?: any): AxiosPromise<ResponseProjectListOutput> {
            return localVarFp.outputList1(id, options).then(request => request(axios, basePath));
        },
        /**
         * Publish project
         * @param {string} id
         * @param {RequestProjectPublish} requestProjectPublish
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishProject(id: string, requestProjectPublish: RequestProjectPublish, options?: any): AxiosPromise<ResponseProjectPublish> {
            return localVarFp.publishProject(id, requestProjectPublish, options).then(request => request(axios, basePath));
        },
        /**
         * Publish script
         * @param {string} id
         * @param {RequestScriptPublish} requestScriptPublish
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishScript(id: string, requestScriptPublish: RequestScriptPublish, options?: any): AxiosPromise<ResponseScriptPublish> {
            return localVarFp.publishScript(id, requestScriptPublish, options).then(request => request(axios, basePath));
        },
        /**
         * Remove file from input
         * @param {string} id
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeInput(id: string, requestFile: RequestFile, options?: any): AxiosPromise<ResponseProjectListInputRemove> {
            return localVarFp.removeInput(id, requestFile, options).then(request => request(axios, basePath));
        },
        /**
         * Removes user to the project
         * @param {string} id
         * @param {RequestProjectRemoveUser} requestProjectRemoveUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUser(id: string, requestProjectRemoveUser: RequestProjectRemoveUser, options?: any): AxiosPromise<ResponseProjectListUser> {
            return localVarFp.removeUser(id, requestProjectRemoveUser, options).then(request => request(axios, basePath));
        },
        /**
         * retrieve a list of projects matching sorting, filtering and pagination constraints
         * @param {ProjectSearchRequest} projectSearchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search1(projectSearchRequest: ProjectSearchRequest, options?: any): AxiosPromise<ResponseProjectSearch> {
            return localVarFp.search1(projectSearchRequest, options).then(request => request(axios, basePath));
        },
        /**
         * Checks and selection a specific input from BEHUB
         * @param {string} id
         * @param {string} inputName
         * @param {RequestProjectBEHUBFileSelect} requestProjectBEHUBFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectBehubInput2(
            id: string,
            inputName: string,
            requestProjectBEHUBFileSelect: RequestProjectBEHUBFileSelect,
            options?: any,
        ): AxiosPromise<ResponseProjectBEHUBFileSelect> {
            return localVarFp.selectBehubInput2(id, inputName, requestProjectBEHUBFileSelect, options).then(request => request(axios, basePath));
        },
        /**
         * Checks and selection a specific input from external URL
         * @param {string} id
         * @param {RequestProjectUrlFileSelect} requestProjectUrlFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectExternalUrlInput2(id: string, requestProjectUrlFileSelect: RequestProjectUrlFileSelect, options?: any): AxiosPromise<ResponseProjectUrlFileSelect> {
            return localVarFp.selectExternalUrlInput2(id, requestProjectUrlFileSelect, options).then(request => request(axios, basePath));
        },
        /**
         * Start a project by identifier. It builds the repository and create an ide environment
         * @param {string} id
         * @param {RequestGenericStart} requestGenericStart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        start(id: string, requestGenericStart: RequestGenericStart, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.start(id, requestGenericStart, options).then(request => request(axios, basePath));
        },
        /**
         * Stop a project by identifier. It removes the ide environment from session manager and close it
         * @param {string} id
         * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        stop1(id: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options?: any): AxiosPromise<BaseResponse> {
            return localVarFp.stop1(id, requestBodyGenericStopProcess, options).then(request => request(axios, basePath));
        },
        /**
         * Obtains project data with detailed information
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        team(id: string, options?: any): AxiosPromise<ResponseProjectListUser> {
            return localVarFp.team(id, options).then(request => request(axios, basePath));
        },
        /**
         * Modify the specific project fields. Only the specified field on the request will be updated.
         * @param {string} id
         * @param {RequestProjectUpdate} requestProjectUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        update1(id: string, requestProjectUpdate: RequestProjectUpdate, options?: any): AxiosPromise<ResponseProjectUpdate> {
            return localVarFp.update1(id, requestProjectUpdate, options).then(request => request(axios, basePath));
        },
        /**
         * Update provisioning information
         * @param {string} id
         * @param {RequestUpdateProvisioning} requestUpdateProvisioning
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProvisioning(id: string, requestUpdateProvisioning: RequestUpdateProvisioning, options?: any): AxiosPromise<ResponseUpdateProvisioning> {
            return localVarFp.updateProvisioning(id, requestUpdateProvisioning, options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput22(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput22(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput23(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput23(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput24(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput24(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput25(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput25(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput26(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput26(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput27(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput27(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput28(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput28(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput29(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput29(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput30(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput30(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput31(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput31(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInputPatch2(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInputPatch2(options).then(request => request(axios, basePath));
        },
    };
};

/**
 * ProjectControllerApi - interface
 * @export
 * @interface ProjectControllerApi
 */
export interface ProjectControllerApiInterface {
    /**
     * Adds user to the project
     * @param {string} id
     * @param {RequestProjectAddUser} requestProjectAddUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    addUser1(id: string, requestProjectAddUser: RequestProjectAddUser, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectAddUser>;

    /**
     * Archive a project
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    archive(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Checks the existence of a specific project by name and version
     * @param {RequestProjectCheck} requestProjectCheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    check(requestProjectCheck: RequestProjectCheck, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectCheck>;

    /**
     * Clones project by another one specifying the identifier of source project.
     * @param {RequestProjectClone} requestProjectClone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    clone(requestProjectClone: RequestProjectClone, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectClone>;

    /**
     * Creates a definition of an input for the project
     * @param {string} id
     * @param {RequestProjectFileCreate} requestProjectFileCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    createInput1(id: string, requestProjectFileCreate: RequestProjectFileCreate, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectFileCreate>;

    /**
     * Creates new project from specific template. The template id comes from templates endpoint and categories comes from categories endpoint.
     * @param {RequestProjectCreate} requestProjectCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    createProject(requestProjectCreate: RequestProjectCreate, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectCreate>;

    /**
     * Creates template as model for new projects
     * @param {RequestProjectTemplateCreate} requestProjectTemplateCreate
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    createTemplate(requestProjectTemplateCreate: RequestProjectTemplateCreate, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectTemplateCreate>;

    /**
     * Delete a project
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    delete1(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Downloads output notebook as html by project identifier
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    downloadHtml1(id: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Download a specific input
     * @param {string} id
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    downloadInput1(id: string, requestFile: RequestFile, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * Download a specific project output files, included the build and environment logs.
     * @param {string} id
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    downloadOutput1(id: string, requestFile: RequestFile, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * Downloads repository as zip
     * @param {string} projectId
     * @param {string} zipname
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    downloadRepo(projectId: string, zipname: string, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * Retrieve the project details by identifier
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    get1(id: string, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectGet>;

    /**
     * Retrieve all project categories used for creating the projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    getProjectCategories(options?: AxiosRequestConfig): AxiosPromise<ResponseProjectCategories>;

    /**
     * Retrieve all project templates which can be used to create a project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    getProjectTemplates(options?: AxiosRequestConfig): AxiosPromise<ResponseProjectTemplate>;

    /**
     * Get projects based on provenance
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    getProjectsByParentId(id: string, options?: AxiosRequestConfig): AxiosPromise<ProjectNode>;

    /**
     * Obtains list of inputs
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    inputList1(id: string, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectListInput>;

    /**
     * Retrieves and return the list of output files, generated from script and system
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    outputList1(id: string, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectListOutput>;

    /**
     * Publish project
     * @param {string} id
     * @param {RequestProjectPublish} requestProjectPublish
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    publishProject(id: string, requestProjectPublish: RequestProjectPublish, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectPublish>;

    /**
     * Publish script
     * @param {string} id
     * @param {RequestScriptPublish} requestScriptPublish
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    publishScript(id: string, requestScriptPublish: RequestScriptPublish, options?: AxiosRequestConfig): AxiosPromise<ResponseScriptPublish>;

    /**
     * Remove file from input
     * @param {string} id
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    removeInput(id: string, requestFile: RequestFile, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectListInputRemove>;

    /**
     * Removes user to the project
     * @param {string} id
     * @param {RequestProjectRemoveUser} requestProjectRemoveUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    removeUser(id: string, requestProjectRemoveUser: RequestProjectRemoveUser, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectListUser>;

    /**
     * retrieve a list of projects matching sorting, filtering and pagination constraints
     * @param {ProjectSearchRequest} projectSearchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    search1(projectSearchRequest: ProjectSearchRequest, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectSearch>;

    /**
     * Checks and selection a specific input from BEHUB
     * @param {string} id
     * @param {string} inputName
     * @param {RequestProjectBEHUBFileSelect} requestProjectBEHUBFileSelect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    selectBehubInput2(
        id: string,
        inputName: string,
        requestProjectBEHUBFileSelect: RequestProjectBEHUBFileSelect,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseProjectBEHUBFileSelect>;

    /**
     * Checks and selection a specific input from external URL
     * @param {string} id
     * @param {RequestProjectUrlFileSelect} requestProjectUrlFileSelect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    selectExternalUrlInput2(id: string, requestProjectUrlFileSelect: RequestProjectUrlFileSelect, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectUrlFileSelect>;

    /**
     * Start a project by identifier. It builds the repository and create an ide environment
     * @param {string} id
     * @param {RequestGenericStart} requestGenericStart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    start(id: string, requestGenericStart: RequestGenericStart, options?: AxiosRequestConfig): AxiosPromise<BaseResponse>;

    /**
     * Stop a project by identifier. It removes the ide environment from session manager and close it
     * @param {string} id
     * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    stop1(id: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options?: AxiosRequestConfig): AxiosPromise<BaseResponse>;

    /**
     * Obtains project data with detailed information
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    team(id: string, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectListUser>;

    /**
     * Modify the specific project fields. Only the specified field on the request will be updated.
     * @param {string} id
     * @param {RequestProjectUpdate} requestProjectUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    update1(id: string, requestProjectUpdate: RequestProjectUpdate, options?: AxiosRequestConfig): AxiosPromise<ResponseProjectUpdate>;

    /**
     * Update provisioning information
     * @param {string} id
     * @param {RequestUpdateProvisioning} requestUpdateProvisioning
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    updateProvisioning(id: string, requestUpdateProvisioning: RequestUpdateProvisioning, options?: AxiosRequestConfig): AxiosPromise<ResponseUpdateProvisioning>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    uploadInput22(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    uploadInput23(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    uploadInput24(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    uploadInput25(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    uploadInput26(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    uploadInput27(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    uploadInput28(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    uploadInput29(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    uploadInput30(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    uploadInput31(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApiInterface
     */
    uploadInputPatch2(options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * ProjectControllerApi - object-oriented interface
 * @export
 * @class ProjectControllerApi
 * @extends {BaseAPI}
 */
export class ProjectControllerApi extends BaseAPI implements ProjectControllerApiInterface {
    /**
     * Adds user to the project
     * @param {string} id
     * @param {RequestProjectAddUser} requestProjectAddUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public addUser1(id: string, requestProjectAddUser: RequestProjectAddUser, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .addUser1(id, requestProjectAddUser, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Archive a project
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public archive(id: string, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .archive(id, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Checks the existence of a specific project by name and version
     * @param {RequestProjectCheck} requestProjectCheck
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public check(requestProjectCheck: RequestProjectCheck, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .check(requestProjectCheck, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Clones project by another one specifying the identifier of source project.
     * @param {RequestProjectClone} requestProjectClone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public clone(requestProjectClone: RequestProjectClone, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .clone(requestProjectClone, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Creates a definition of an input for the project
     * @param {string} id
     * @param {RequestProjectFileCreate} requestProjectFileCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public createInput1(id: string, requestProjectFileCreate: RequestProjectFileCreate, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .createInput1(id, requestProjectFileCreate, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Creates new project from specific template. The template id comes from templates endpoint and categories comes from categories endpoint.
     * @param {RequestProjectCreate} requestProjectCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public createProject(requestProjectCreate: RequestProjectCreate, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .createProject(requestProjectCreate, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Creates template as model for new projects
     * @param {RequestProjectTemplateCreate} requestProjectTemplateCreate
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public createTemplate(requestProjectTemplateCreate: RequestProjectTemplateCreate, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .createTemplate(requestProjectTemplateCreate, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Delete a project
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public delete1(id: string, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .delete1(id, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Downloads output notebook as html by project identifier
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public downloadHtml1(id: string, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .downloadHtml1(id, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Download a specific input
     * @param {string} id
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public downloadInput1(id: string, requestFile: RequestFile, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .downloadInput1(id, requestFile, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Download a specific project output files, included the build and environment logs.
     * @param {string} id
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public downloadOutput1(id: string, requestFile: RequestFile, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .downloadOutput1(id, requestFile, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Downloads repository as zip
     * @param {string} projectId
     * @param {string} zipname
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public downloadRepo(projectId: string, zipname: string, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .downloadRepo(projectId, zipname, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Retrieve the project details by identifier
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public get1(id: string, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .get1(id, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all project categories used for creating the projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public getProjectCategories(options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .getProjectCategories(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all project templates which can be used to create a project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public getProjectTemplates(options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .getProjectTemplates(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Get projects based on provenance
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public getProjectsByParentId(id: string, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .getProjectsByParentId(id, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Obtains list of inputs
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public inputList1(id: string, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .inputList1(id, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Retrieves and return the list of output files, generated from script and system
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public outputList1(id: string, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .outputList1(id, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Publish project
     * @param {string} id
     * @param {RequestProjectPublish} requestProjectPublish
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public publishProject(id: string, requestProjectPublish: RequestProjectPublish, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .publishProject(id, requestProjectPublish, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Publish script
     * @param {string} id
     * @param {RequestScriptPublish} requestScriptPublish
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public publishScript(id: string, requestScriptPublish: RequestScriptPublish, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .publishScript(id, requestScriptPublish, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Remove file from input
     * @param {string} id
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public removeInput(id: string, requestFile: RequestFile, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .removeInput(id, requestFile, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Removes user to the project
     * @param {string} id
     * @param {RequestProjectRemoveUser} requestProjectRemoveUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public removeUser(id: string, requestProjectRemoveUser: RequestProjectRemoveUser, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .removeUser(id, requestProjectRemoveUser, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * retrieve a list of projects matching sorting, filtering and pagination constraints
     * @param {ProjectSearchRequest} projectSearchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public search1(projectSearchRequest: ProjectSearchRequest, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .search1(projectSearchRequest, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Checks and selection a specific input from BEHUB
     * @param {string} id
     * @param {string} inputName
     * @param {RequestProjectBEHUBFileSelect} requestProjectBEHUBFileSelect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public selectBehubInput2(id: string, inputName: string, requestProjectBEHUBFileSelect: RequestProjectBEHUBFileSelect, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .selectBehubInput2(id, inputName, requestProjectBEHUBFileSelect, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Checks and selection a specific input from external URL
     * @param {string} id
     * @param {RequestProjectUrlFileSelect} requestProjectUrlFileSelect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public selectExternalUrlInput2(id: string, requestProjectUrlFileSelect: RequestProjectUrlFileSelect, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .selectExternalUrlInput2(id, requestProjectUrlFileSelect, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Start a project by identifier. It builds the repository and create an ide environment
     * @param {string} id
     * @param {RequestGenericStart} requestGenericStart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public start(id: string, requestGenericStart: RequestGenericStart, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .start(id, requestGenericStart, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Stop a project by identifier. It removes the ide environment from session manager and close it
     * @param {string} id
     * @param {RequestBodyGenericStopProcess} requestBodyGenericStopProcess
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public stop1(id: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .stop1(id, requestBodyGenericStopProcess, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Obtains project data with detailed information
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public team(id: string, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .team(id, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Modify the specific project fields. Only the specified field on the request will be updated.
     * @param {string} id
     * @param {RequestProjectUpdate} requestProjectUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public update1(id: string, requestProjectUpdate: RequestProjectUpdate, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .update1(id, requestProjectUpdate, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Update provisioning information
     * @param {string} id
     * @param {RequestUpdateProvisioning} requestUpdateProvisioning
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public updateProvisioning(id: string, requestUpdateProvisioning: RequestUpdateProvisioning, options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .updateProvisioning(id, requestUpdateProvisioning, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public uploadInput22(options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .uploadInput22(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public uploadInput23(options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .uploadInput23(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public uploadInput24(options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .uploadInput24(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public uploadInput25(options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .uploadInput25(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public uploadInput26(options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .uploadInput26(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public uploadInput27(options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .uploadInput27(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public uploadInput28(options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .uploadInput28(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public uploadInput29(options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .uploadInput29(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public uploadInput30(options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .uploadInput30(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public uploadInput31(options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .uploadInput31(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectControllerApi
     */
    public uploadInputPatch2(options?: AxiosRequestConfig) {
        return ProjectControllerApiFp(this.configuration)
            .uploadInputPatch2(options)
            .then(request => request(this.axios, this.basePath));
    }
}
