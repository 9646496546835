/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { Behub } from './behub';
// May contain unused imports in some cases
// @ts-ignore
import { Template } from './template';
// May contain unused imports in some cases
// @ts-ignore
import { User } from './user';

/**
 *
 * @export
 * @interface ResponseServiceFileCreate
 */
export interface ResponseServiceFileCreate {
    /**
     *
     * @type {string}
     * @memberof ResponseServiceFileCreate
     */
    filename?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseServiceFileCreate
     */
    type?: ResponseServiceFileCreateTypeEnum;
    /**
     *
     * @type {number}
     * @memberof ResponseServiceFileCreate
     */
    size?: number;
    /**
     *
     * @type {string}
     * @memberof ResponseServiceFileCreate
     */
    language?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseServiceFileCreate
     */
    format?: string;
    /**
     *
     * @type {number}
     * @memberof ResponseServiceFileCreate
     */
    total?: number;
    /**
     *
     * @type {boolean}
     * @memberof ResponseServiceFileCreate
     */
    persistent?: boolean;
    /**
     *
     * @type {string}
     * @memberof ResponseServiceFileCreate
     */
    status?: ResponseServiceFileCreateStatusEnum;
    /**
     *
     * @type {string}
     * @memberof ResponseServiceFileCreate
     */
    url?: string;
    /**
     *
     * @type {Behub}
     * @memberof ResponseServiceFileCreate
     */
    behub?: Behub;
    /**
     *
     * @type {Template}
     * @memberof ResponseServiceFileCreate
     */
    template?: Template;
    /**
     *
     * @type {string}
     * @memberof ResponseServiceFileCreate
     */
    created_at?: string;
    /**
     *
     * @type {User}
     * @memberof ResponseServiceFileCreate
     */
    created_by?: User;
}

export const ResponseServiceFileCreateTypeEnum = {
    LOCAL: 'LOCAL',
    BEHUB: 'BEHUB',
    URL: 'URL',
} as const;

export type ResponseServiceFileCreateTypeEnum = (typeof ResponseServiceFileCreateTypeEnum)[keyof typeof ResponseServiceFileCreateTypeEnum];
export const ResponseServiceFileCreateStatusEnum = {
    READY: 'READY',
    PROGRESS: 'PROGRESS',
    ERROR: 'ERROR',
    WAIT: 'WAIT',
} as const;

export type ResponseServiceFileCreateStatusEnum = (typeof ResponseServiceFileCreateStatusEnum)[keyof typeof ResponseServiceFileCreateStatusEnum];
