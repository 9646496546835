import { TextArea } from '@features/_shared/components/TextArea';
import { TextField } from '@features/_shared/components/TextField';
import { TranslatorManager } from '@managers/TranslatorManager';
import { useEffect, useRef } from 'react';
import { BaseProjectTemplate, Project } from 'src/api';
import { ProvisioningPageEnum } from '../store/state';
import '../styles/provisioning.project.data.component.style.scss';
export interface IProjectDataProps {
    enableDatasetPublication?: boolean;
    project?: Project;
    handleDatasetChange?: (checked: boolean) => void;
    templates?: Array<BaseProjectTemplate>;
    currentPage?: ProvisioningPageEnum;
}
export const ProjectDataComponent: React.FC<IProjectDataProps> = ({ enableDatasetPublication, project, handleDatasetChange, templates, currentPage }) => {
    const translator = TranslatorManager.getInstance();
    const checkedRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        checkedRef.current && handleDatasetChange?.(checkedRef.current.checked);
        return;
    }, [checkedRef]);

    return (
        <>
            <div className='container-fluid'>
                {enableDatasetPublication && (
                    <div className='row mb-3'>
                        <div className=' row col-12 home-title-container '>
                            <h5 className='title-description col-12 mb-0'>
                                {translator.get(
                                    `${
                                        currentPage === ProvisioningPageEnum.PROJECT
                                            ? 'provisioning.project.publication.description.part.one'
                                            : 'provisioning.script.publication.description.part.one'
                                    }`,
                                )}
                            </h5>

                            <h3 className='secondary-title col-12 mt-3 mb-3'>
                                {translator.get(
                                    `${
                                        currentPage === ProvisioningPageEnum.PROJECT
                                            ? 'provisioning.project.publication.description.part.two'
                                            : 'provisioning.script.publication.description.part.two'
                                    }`,
                                )}
                            </h3>
                            <h5 className='title-description col-12'>
                                {translator.get(
                                    `${
                                        currentPage === ProvisioningPageEnum.PROJECT
                                            ? 'provisioning.project.publication.description.part.three'
                                            : 'provisioning.script.publication.description.part.three'
                                    }`,
                                )}
                            </h5>
                        </div>
                    </div>
                )}
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='name' className='active d-flex flex-row label-color align-items-center'>
                                {translator.get('provisioning.project.name.label')}
                            </label>
                            <TextField
                                type='text'
                                className='form-control'
                                defaultValue={project?.name}
                                placeholder={translator.get('provisioning.project.name.placeholder')}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='name' className='active d-flex flex-row label-color align-items-center'>
                                {translator.get('provisioning.version.label')}
                            </label>
                            <TextField
                                type='text'
                                className='form-control'
                                defaultValue={project?.version}
                                placeholder={translator.get('provisioning.version.placeholder')}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <div className='select-wrapper'>
                                <label htmlFor='technology' className='active d-flex flex-row label-color'>
                                    {translator.get('provisioning.technology.label')}
                                </label>
                                <select name='technology' className='select-container' disabled>
                                    <option value='' selected>
                                        {
                                            templates?.find((item: BaseProjectTemplate) => {
                                                return item.id == project?.technology;
                                            })?.name
                                        }
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='name' className='active d-flex flex-row label-color align-items-center'>
                                {translator.get('provisioning.creator.label')}
                            </label>
                            <TextField
                                type='text'
                                className='form-control'
                                defaultValue={`${project?.author?.name}`}
                                placeholder={translator.get('provisioning.creator.placeholder')}
                                readOnly
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='name' className='active d-flex flex-row label-color align-items-center'>
                                {translator.get('provisioning.abstract.label')}
                            </label>
                            <TextArea
                                className='form-control'
                                rows={5}
                                defaultValue={`${project?.description}`}
                                name='description'
                                readOnly
                                placeholder={translator.get('provisioning.abstract.placeholder')}
                            />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='form-group'>
                            <label htmlFor='keywords' className='active d-flex flex-row label-color align-items-center'>
                                {translator.get('provisioning.keywords.label')}
                            </label>
                            <TextField
                                type='text'
                                className='form-control'
                                defaultValue={`${project?.keywords ? project?.keywords.join(', ') : ''}`}
                                placeholder={translator.get('provisioning.keywords.placeholder')}
                                readOnly
                            />
                        </div>
                    </div>
                </div>

                {/* <div className='row'>
                    <div className='col-12'>
                        <p className='additional-metadata-text-container'>{translator.get('provisioning.additional.metadata.text')}</p>
                        <table className='table table-container'>
                            <tr className='metadata-row-container mb-1'>
                                <th scope='col'>{translator.get('provisioning.metadata.text')}</th>
                                <td>
                                    <div className='form-group mb-0'>
                                        <TextField type='text' className='form-control' placeholder={translator.get('provisioning.metadata.value.placeholder')} disabled />
                                    </div>
                                </td>
                            </tr>
                            <tr className='metadata-row-container mb-1'>
                                <th scope='col'>{translator.get('provisioning.metadata.text')}</th>
                                <td>
                                    <div className='form-group mb-0'>
                                        <TextField type='text' className='form-control' placeholder={translator.get('provisioning.metadata.value.placeholder')} disabled />
                                    </div>
                                </td>
                            </tr>
                            <tr className='metadata-row-container mb-1'>
                                <th scope='col'>{translator.get('provisioning.metadata.text')}</th>
                                <td>
                                    <div className='form-group mb-0'>
                                        <TextField type='text' className='form-control' placeholder={translator.get('provisioning.metadata.value.placeholder')} disabled />
                                    </div>
                                </td>
                            </tr>
                            <tr className='metadata-row-container mb-1'>
                                <th scope='col'>{translator.get('provisioning.metadata.text')}</th>
                                <td>
                                    <div className='form-group mb-0'>
                                        <TextField type='text' className='form-control' placeholder={translator.get('provisioning.metadata.value.placeholder')} disabled />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div> */}
            </div>
        </>
    );
};
