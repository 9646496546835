import { OverlayTrigger } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import './styles/tooltip.style.scss';

type Trigger = 'hover' | 'focus' | 'click';

type Position = 'top' | 'bottom' | 'left' | 'right';

export interface Props {
    labelKey: string;
    children: JSX.Element;
    position?: Position;
    trigger?: Trigger;
}

/**
 * Tooltip
 *
 * It shows the Tooltip on input children
 *
 * @property {string} labelKey - content to show in the tooltip
 * @property {JSX.Element} children - content on which to show the tooltip
 * @property {Position} position - tooltip's position
 * @property {Trigger} trigger - action triggering the tooltip
 * @property {Colors} color - color of the tooltip and of the arrow
 *
 * @returns JSX.Element
 */

export const StyledTooltip: React.FC<Props> = ({ labelKey, children, position = 'top', trigger }) => {
    return (
        <OverlayTrigger
            trigger={trigger}
            placement={position}
            overlay={
                <Tooltip id={`tooltip-${position}`}>
                    <>{labelKey}</>
                </Tooltip>
            }>
            {children}
        </OverlayTrigger>
    );
};
