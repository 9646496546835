import { GitIntegrationControllerApi } from '../../../api/controllers/git-integration-controller-api';

export interface IGitIntegrationApiClient {
    git: GitIntegrationControllerApi;
}

export class GitIntegrationApiClients {
    private static instance: IGitIntegrationApiClient;

    static getInstance(basePath: string): IGitIntegrationApiClient {
        if (GitIntegrationApiClients.instance == null) {
            GitIntegrationApiClients.instance = {
                git: new GitIntegrationControllerApi(undefined, basePath),
            };
        }
        return GitIntegrationApiClients.instance;
    }
}
