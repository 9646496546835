import { NavigationManager } from '@managers/navigation.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';
import { useEffect, useState } from 'react';
import '../styles/linkbar.style.scss';

export interface ILinkBarProps {
    isIDEOpen: boolean;
    route?: string;
}

const LinkBar: React.FC<ILinkBarProps> = ({ isIDEOpen, route }) => {
    const translator = TranslatorManager.getInstance();
    const navigator = NavigationManager.getInstance();

    const handleRouteChange = (path: any) => {
        navigator.navigateTo(path);
    };

    useEffect(() => {}, [route]);

    return (
        <>
            <div className=' d-flex align-items-center'>
                <div className='d-flex flex-row justify-content-between align-items-center'>
                    <div className='container-fluid p-0'>
                        <div>
                            <div className='col-xs-12 col-lg-6 d-flex align-items-center '>
                                <a className='link' href='https://helpdesk.lifewatchitaly.eu' target='_blank' rel='noreferrer'>
                                    {translator.get('linkbar.helpdesk.link.text')}
                                </a>
                            </div>
                            <div className='col-xs-12 col-lg-6 d-flex align-items-center '>
                                <a
                                    className='link'
                                    href={`${
                                        route?.includes('/project') && !isIDEOpen
                                            ? 'https://training.lifewatchitaly.eu/en/resources-2/?resource=/course/view.php?id=84'
                                            : route?.includes('/service')
                                            ? 'https://training.lifewatchitaly.eu/en/resources-2/?resource=/course/view.php?id=86'
                                            : route?.includes('/project') && isIDEOpen
                                            ? 'https://training.lifewatchitaly.eu/en/resources-2/?resource=/course/view.php?id=85'
                                            : 'https://training.lifewatchitaly.eu/resources/?resource=/course/view.php?id=156'
                                    }`}
                                    target='_blank'
                                    rel='noreferrer'>
                                    {translator.get('linkbar.training.link.text')}
                                </a>
                            </div>
                            <div className='col-xs-12 col-lg-6 d-flex align-items-center '>
                                <a className='link' onClick={() => handleRouteChange('/faqs')}>
                                    {translator.get('tabbar.faqs.tab.text')}
                                </a>
                            </div>
                            {KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) && (
                                <div className='col-xs-12 col-lg-6 d-flex align-items-center '>
                                    <a className='link' onClick={() => handleRouteChange('/administration')}>
                                        {translator.get('tabbar.administration.tab.text')}
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LinkBar;
