import { LoginState } from '@features/login/store/state';
import { ReduxConnect, StoreManager } from '@hypereact/state';
import { MinisiteManager } from '@managers/minisite.manager';
import { NavigationManager } from '@managers/navigation.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { ServiceManager } from '@managers/service.manager';
import { RootState } from '@store/state';
import { PollingUtil } from '@utils/polling.util';
import React from 'react';
import { ResponseServiceExecutionMinisiteStatusEnum } from '../../../../src/api';
import { InputFileTypeEnum } from '../../../../src/api/entities/input-file';
import { WebService } from '../../../api/entities/web-service';
import { MiniSiteBody } from '../components/minisite.body.component';
import { MiniSiteHeader } from '../components/minisite.header.component';
import { MinisiteSetStatus } from '../store/actions';
import { ExecutionMinisiteState, OutputState } from '../store/state';

export interface IMiniSiteProps {
    execution: ExecutionMinisiteState;
    output: OutputState;
    user: LoginState;
    service: WebService;
    email: string;
}

@ReduxConnect((state: RootState) => {
    return {
        execution: state.minisite.execution,
        output: state.minisite.output,
        user: state.login,
        service: state.service.service.selectedService,
        email: state.minisite.execution.email,
    };
})
export class MiniSiteView extends React.Component<IMiniSiteProps> {
    private minisiteManager: MinisiteManager;
    private queueManager: QueueManager;
    private navigationManager: NavigationManager;
    private serviceManager: ServiceManager;
    private storeManager: StoreManager;

    constructor(props: IMiniSiteProps) {
        super(props);
        this.queueManager = QueueManager.getInstance();
        this.minisiteManager = MinisiteManager.getInstance();
        this.navigationManager = NavigationManager.getInstance();
        this.serviceManager = ServiceManager.getInstance();
        this.storeManager = StoreManager.getInstance();

        this.handleUploadFromURL = this.handleUploadFromURL.bind(this);
        this.handleOnBlur = this.handleOnBlur.bind(this);
        this.handleExecutionLaunch = this.handleExecutionLaunch.bind(this);
        this.handleInputDowload = this.handleInputDowload.bind(this);
        this.handleViewDataset = this.handleViewDataset.bind(this);
        this.handleUseDataset = this.handleUseDataset.bind(this);
        this.handleGetLogs = this.handleGetLogs.bind(this);
    }

    async pollMinisite(header: string, payload: string, signature: string) {
        const validateMinisite = () => this.navigationManager.getCurrentRoute().includes('/minisite');
        PollingUtil.register('minisitedata', async () => await this.minisiteManager.pollExecutionByServiceByIdAndId(header, payload, signature), validateMinisite, 5000, payload);
        PollingUtil.register('minisitelog', async () => await this.minisiteManager.pollLogs(header, payload, signature), validateMinisite, 10000, payload);
        PollingUtil.register('minisiteoutput', async () => await this.minisiteManager.pollOutputList(header, payload, signature), validateMinisite, 10000, payload);
    }

    async componentDidMount() {
        this.minisiteManager.setEmail('');
        const token = window.location.href.split('/minisite/')[1];
        let [payload, signature, header] = token.split('/');
        const payloadBuffer = Buffer.from(payload, 'base64');
        const payloadJson = JSON.parse(payloadBuffer.toString());
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(payloadJson.serviceId));
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.minisiteManager.getExecutionByServiceByIdAndId(header, payload, signature));
        await this.queueManager.queue(QueueKeyType.POLLER, this.pollMinisite(header, payload, signature));
    }

    componentWillUnmount() {
        PollingUtil.unregister('minisitedata');
        PollingUtil.unregister('minisitelog');
        PollingUtil.unregister('minisiteoutput');
    }

    async handleExecutionLaunch(token: string) {
        let [header, payload, signature] = token.split('.');
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.minisiteManager.launch(header, payload, signature, this.props.email));
        this.storeManager.dispatch(new MinisiteSetStatus(ResponseServiceExecutionMinisiteStatusEnum.RUNNING));
    }

    async handleUploadFromURL(token: string, fileUrl: string, fileName: string) {
        let [header, payload, signature] = token.split('.');
        document.getElementById('dataPortalMinisiteUploadModalClose')?.click();
        await this.minisiteManager.selectExternalUrlInput(header, payload, signature, {
            file_url: fileUrl,
            file_name: fileName,
        });
    }

    handleUploadFromLocal() {
        document.getElementById('localUploadMinisiteModalClose')?.click();
    }

    handleInputDowload = async (token: string, fileName: string, template?: string) => {
        let [header, payload, signature] = token.split('.');
        const response: any = await this.queueManager.queue(
            QueueKeyType.DEFAULT,
            this.minisiteManager.downloadInput(header, payload, signature, { name: fileName, template: template }),
        );
        const href = URL.createObjectURL(new Blob([response], { type: 'application/octet-stream' }));
        const link = document.createElement('a');
        link.href = href;
        template ? link.setAttribute('download', template as string) : link.setAttribute('download', fileName as string); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    };

    openInNewTab = (url: string) => {
        window.open(url, '_blank', 'noreferrer');
    };

    handleViewDataset = async (token: string, fileName: string, type: InputFileTypeEnum, template?: string) => {
        switch (type) {
            case InputFileTypeEnum.LOCAL: {
                await this.queueManager.queue(QueueKeyType.DEFAULT, this.handleInputDowload(token, fileName, template));
                break;
            }
            case InputFileTypeEnum.BEHUB: {
                //fileName in this case will be the url
                this.openInNewTab(fileName);
                break;
            }
            case InputFileTypeEnum.URL: {
                //fileName in this case will be the url
                this.openInNewTab(fileName);
                break;
            }
        }
    };

    handleUseDataset = async (token: string, fileName: string) => {
        let [header, payload, signature] = token.split('.');

        this.minisiteManager.useDataset(header, payload, signature, { name: fileName });
    };

    handleOnBlur(value: string) {
        this.minisiteManager.setEmail(value);
    }

    handleGetLogs(token: string) {
        let [header, payload, signature] = token.split('.');
        this.minisiteManager.getLogs(header, payload, signature);
    }

    render() {
        const { execution, output, user, service } = this.props;
        return (
            <React.Fragment>
                <MiniSiteHeader service={service} />

                <MiniSiteBody
                    execution={execution}
                    selectedExecution={execution.selectedExecution}
                    service={service}
                    output={output}
                    user={user}
                    handleLaunch={this.handleExecutionLaunch}
                    onBlur={this.handleOnBlur}
                    handleViewDataset={this.handleViewDataset}
                    handleUploadFromURL={this.handleUploadFromURL}
                    handleUploadFromLocal={this.handleUploadFromLocal}
                    handleUseDataset={this.handleUseDataset}
                    handleGetLogs={this.handleGetLogs}
                />
            </React.Fragment>
        );
    }
}
