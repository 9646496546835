import { BehubIntegrationControllerApi } from '../../../api/controllers/behub-integration-controller-api';

export interface IBehubIntegrationApiClient {
    behub: BehubIntegrationControllerApi;
}

export class BehubIntegrationApiClients {
    private static instance: IBehubIntegrationApiClient;

    static getInstance(basePath: string): IBehubIntegrationApiClient {
        if (BehubIntegrationApiClients.instance == null) {
            BehubIntegrationApiClients.instance = {
                behub: new BehubIntegrationControllerApi(undefined, basePath),
            };
        }
        return BehubIntegrationApiClients.instance;
    }
}
