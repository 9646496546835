/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ResponseMetadata } from '../entities';
// @ts-ignore
import { ResponseServiceMetadata } from '../entities';
/**
 * ProvisioningControllerApi - axios parameter creator
 * @export
 */
export const ProvisioningControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create service metadata on belinda
         * @param {string} id
         * @param {string} execId
         * @param {string} metadataId
         * @param {string} formatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceMetadata: async (id: string, execId: string, metadataId: string, formatId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createServiceMetadata', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('createServiceMetadata', 'execId', execId);
            // verify required parameter 'metadataId' is not null or undefined
            assertParamExists('createServiceMetadata', 'metadataId', metadataId);
            // verify required parameter 'formatId' is not null or undefined
            assertParamExists('createServiceMetadata', 'formatId', formatId);
            const localVarPath = `/provisioning/services/{id}/executions/{execId}/create/metadata/{metadataId}/{formatId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)))
                .replace(`{${'metadataId'}}`, encodeURIComponent(String(metadataId)))
                .replace(`{${'formatId'}}`, encodeURIComponent(String(formatId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download file
         * @param {string} id
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProjectFile: async (id: string, fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadProjectFile', 'id', id);
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('downloadProjectFile', 'fileId', fileId);
            const localVarPath = `/provisioning/projects/{id}/dataportal/download/{fileId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'fileId'}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download file
         * @param {string} id
         * @param {string} execId
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadServiceFile: async (id: string, execId: string, fileId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('downloadServiceFile', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('downloadServiceFile', 'execId', execId);
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('downloadServiceFile', 'fileId', fileId);
            const localVarPath = `/provisioning/services/{id}/executions/{execId}/dataportal/download/{fileId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)))
                .replace(`{${'fileId'}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update all service metadata
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceMetadata: async (id: string, execId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateServiceMetadata', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('updateServiceMetadata', 'execId', execId);
            const localVarPath = `/provisioning/services/{id}/executions/{execId}/metadata`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * ProvisioningControllerApi - functional programming interface
 * @export
 */
export const ProvisioningControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = ProvisioningControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Create service metadata on belinda
         * @param {string} id
         * @param {string} execId
         * @param {string} metadataId
         * @param {string} formatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createServiceMetadata(
            id: string,
            execId: string,
            metadataId: string,
            formatId: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceMetadata>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createServiceMetadata(id, execId, metadataId, formatId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download file
         * @param {string} id
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadProjectFile(id: string, fileId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadProjectFile(id, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download file
         * @param {string} id
         * @param {string} execId
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadServiceFile(
            id: string,
            execId: string,
            fileId: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadServiceFile(id, execId, fileId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * update all service metadata
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateServiceMetadata(
            id: string,
            execId: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseMetadata>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceMetadata(id, execId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * ProvisioningControllerApi - factory interface
 * @export
 */
export const ProvisioningControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProvisioningControllerApiFp(configuration);
    return {
        /**
         * Create service metadata on belinda
         * @param {string} id
         * @param {string} execId
         * @param {string} metadataId
         * @param {string} formatId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createServiceMetadata(id: string, execId: string, metadataId: string, formatId: string, options?: any): AxiosPromise<ResponseServiceMetadata> {
            return localVarFp.createServiceMetadata(id, execId, metadataId, formatId, options).then(request => request(axios, basePath));
        },
        /**
         * Download file
         * @param {string} id
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadProjectFile(id: string, fileId: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadProjectFile(id, fileId, options).then(request => request(axios, basePath));
        },
        /**
         * Download file
         * @param {string} id
         * @param {string} execId
         * @param {string} fileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadServiceFile(id: string, execId: string, fileId: string, options?: any): AxiosPromise<File> {
            return localVarFp.downloadServiceFile(id, execId, fileId, options).then(request => request(axios, basePath));
        },
        /**
         * update all service metadata
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateServiceMetadata(id: string, execId: string, options?: any): AxiosPromise<ResponseMetadata> {
            return localVarFp.updateServiceMetadata(id, execId, options).then(request => request(axios, basePath));
        },
    };
};

/**
 * ProvisioningControllerApi - interface
 * @export
 * @interface ProvisioningControllerApi
 */
export interface ProvisioningControllerApiInterface {
    /**
     * Create service metadata on belinda
     * @param {string} id
     * @param {string} execId
     * @param {string} metadataId
     * @param {string} formatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvisioningControllerApiInterface
     */
    createServiceMetadata(id: string, execId: string, metadataId: string, formatId: string, options?: AxiosRequestConfig): AxiosPromise<ResponseServiceMetadata>;

    /**
     * Download file
     * @param {string} id
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvisioningControllerApiInterface
     */
    downloadProjectFile(id: string, fileId: string, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * Download file
     * @param {string} id
     * @param {string} execId
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvisioningControllerApiInterface
     */
    downloadServiceFile(id: string, execId: string, fileId: string, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * update all service metadata
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvisioningControllerApiInterface
     */
    updateServiceMetadata(id: string, execId: string, options?: AxiosRequestConfig): AxiosPromise<ResponseMetadata>;
}

/**
 * ProvisioningControllerApi - object-oriented interface
 * @export
 * @class ProvisioningControllerApi
 * @extends {BaseAPI}
 */
export class ProvisioningControllerApi extends BaseAPI implements ProvisioningControllerApiInterface {
    /**
     * Create service metadata on belinda
     * @param {string} id
     * @param {string} execId
     * @param {string} metadataId
     * @param {string} formatId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvisioningControllerApi
     */
    public createServiceMetadata(id: string, execId: string, metadataId: string, formatId: string, options?: AxiosRequestConfig) {
        return ProvisioningControllerApiFp(this.configuration)
            .createServiceMetadata(id, execId, metadataId, formatId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Download file
     * @param {string} id
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvisioningControllerApi
     */
    public downloadProjectFile(id: string, fileId: string, options?: AxiosRequestConfig) {
        return ProvisioningControllerApiFp(this.configuration)
            .downloadProjectFile(id, fileId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Download file
     * @param {string} id
     * @param {string} execId
     * @param {string} fileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvisioningControllerApi
     */
    public downloadServiceFile(id: string, execId: string, fileId: string, options?: AxiosRequestConfig) {
        return ProvisioningControllerApiFp(this.configuration)
            .downloadServiceFile(id, execId, fileId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * update all service metadata
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProvisioningControllerApi
     */
    public updateServiceMetadata(id: string, execId: string, options?: AxiosRequestConfig) {
        return ProvisioningControllerApiFp(this.configuration)
            .updateServiceMetadata(id, execId, options)
            .then(request => request(this.axios, this.basePath));
    }
}
