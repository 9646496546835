export class PollingUtil {
    static timeouts: Map<string, number> = new Map();
    static timeoutUuids: Map<string, string> = new Map();

    static register = async (key: string, fn: Function, validate: Function, interval: number, uuid: string = '') => {
        const executeFn = async (init: boolean) => {
            if (PollingUtil.timeouts.has(key) || init) {
                const result = await fn();
                if ((PollingUtil.timeouts.has(key) && PollingUtil.timeoutUuids.get(key) === uuid) || init) {
                    if (validate(result)) {
                        PollingUtil.timeouts.set(key, setTimeout(executeFn, interval));
                        PollingUtil.timeoutUuids.set(key, uuid);
                    }
                }
            }
        };
        executeFn(true);
    };

    static unregister = (key: string) => {
        if (PollingUtil.timeouts.has(key)) {
            clearTimeout(PollingUtil.timeouts.get(key));
            PollingUtil.timeouts.delete(key);
            PollingUtil.timeoutUuids.delete(key);
        }
    };
}
