import { ListPaginationInterface } from '@features/_shared/components/Paginator/enums/ListPaginationInterface';
import { Order } from '@features/_shared/components/Paginator/interfaces/Order';
import { BaseProjectTemplate, InBoundMetrics, OutputFile, Project, ProjectNode, ProjectSearchFilter, ProjectSearchFilterStatusEnum } from '../../../api/entities';
export type ProjectCreateUpdate = Partial<Project>;
export interface MemberCreate {
    collaboratorName: string;
    collaboratorEmail: string;
}

export interface ProjectDataState {
    projects: Array<Project>;
    selectedProject?: Project & { binderUrl?: string };
    createUpdate: ProjectCreateUpdate;
    filters: ProjectSearchFilter;
    isIDEOpen: boolean;
    newCollaborator: MemberCreate;
    pagination: ListPaginationInterface;
    query?: string;
    order: Order | undefined;
    categoryAvailableOptions: string[];
    provenance?: ProjectNode;
}

export interface TeamState {
    collaborators: Array<ProjectUser>;
}

export interface InputState {
    inputs: Array<InputFile>;
    selectedInput?: InputFile;
}

export interface OutputState {
    outputs: Array<OutputFile> | undefined;
    selectedOutput?: OutputFile;
}

export interface ProjectState {
    project: ProjectDataState;
    team: TeamState;
    input: InputState;
    output: OutputState;
    template: Array<BaseProjectTemplate>;
    categories: Array<string>;
    statistics: InBoundMetrics;
}

export const initialProjectState: ProjectState = {
    project: {
        projects: [],
        selectedProject: undefined,
        newCollaborator: { collaboratorEmail: '', collaboratorName: '' },
        isIDEOpen: false,
        createUpdate: {
            name: undefined,
            version: undefined,
            technology: undefined,
            description: undefined,
            categories: undefined,
        },
        filters: {
            name: undefined,
            creatorName: undefined,
            status: ProjectSearchFilterStatusEnum.ALL,
            categories: 'ALL',
            keywords: undefined,
        },
        pagination: {
            page: 0,
            size: 10,
        },
        order: undefined,
        categoryAvailableOptions: [],
        provenance: undefined,
    },
    team: {
        collaborators: [],
    },
    input: {
        inputs: [],
        selectedInput: undefined,
    },
    output: {
        outputs: [],
        selectedOutput: undefined,
    },
    template: [],
    categories: [],
    statistics: {
        cpu_avg: 0,

        cpu_max: 0,

        memory_avg: 0,

        memory_max: 0,

        storage_file_count: 0,

        storage_total_size: 0,
    },
};

// TODO: replace these entities with BE entities
// export interface ProjectSearchFilter {
//     name?: string;
//     author?: string;
//     status?: string;
// }

export interface ProjectMock {
    id: string;
    name: string;
    version: string;
    technology: string;
    author: {
        id: string;
        firstName: string;
        lastName: string;
    };
    collaborators?: Array<ProjectUser>;
    git: {
        project?: string;
        group?: string;
        url?: string;
        branch?: string;
    };
    status: ProjectStatusEnum;
    description?: string;
    chat: {
        url?: string;
    };
    wiki: {
        url?: string;
    };
    execution: {
        status: ProjectExecutionStatusEnum;
        binderUrl?: string;
        author?: {
            id: string;
            firstName: string;
            lastName: string;
        };
    };
    inputs: Array<InputFile>;
    outputs: Array<OutputFile>;
    statistics: any; //not defined yet
    warnings: any; //not defined yet
}

export interface InputFile {
    fileName: string;
    id: string;
    type: InputTypeEnum;
    size: number;
    createdAt: string;
    author: {
        id: string;
        firstName: string;
        lastName: string;
    };
    local: {
        status?: InputStatusEnum;
    };
    behub: {
        doi?: string;
        fileId?: string;
        fileURL?: string;
        fileName?: string;
        status: InputStatusEnum;
    };
}

// export interface OutputFile {
//     fileName: string;
//     id: string;
//     createdAt: string;
//     updatedAt: string;
//     size: number;
// }

export interface ProjectUser {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
}

export interface Template {
    id: string;
    type: string;
    version: string;
    author: {
        id: string;
        firstName: string;
        lastName: string;
    };
    git: {
        project: string;
        group: string;
        url: string;
    };
}

export interface RequestProjectCreate {
    name: string;
    version: string;
    technology: string;
    author: {
        id: string;
        firstName: string;
        lastName: string;
    };
    description?: string;
}

export interface RequestProjectClone {
    sourceProjectId: string;
    name: string;
    version: string;
    author: {
        id: string;
        firstName: string;
        lastName: string;
    };
    description?: string;
}

export enum ProjectStatusEnum {
    Offline = 'Offline',
    Online = 'Online',
}

// export enum ProjectCategoryEnum {
//     CategoryX = 'Category_X',
//     CategoryY = 'Category_Y',
//     CategoryA = 'Category_A',
// }

export enum InputTypeEnum {
    Local = 'local',
    BeHub = 'behub',
}

export enum InputStatusEnum {
    Wait = 'wait',
    Ready = 'ready',
    Progress = 'progress',
    Error = 'error',
}

export enum ProjectExecutionStatusEnum {
    Wait = 'wait',
    Running = 'running',
    Stop = 'stop',
}
