import { TranslatorManager } from '@managers/TranslatorManager';
import { FormManager } from '@managers/form.manager';
import { NavigationManager } from '@managers/navigation.manager';
import { ProvisioningManager } from '@managers/provisioning.manager';
import { Paths } from '@routes';
import { useEffect } from 'react';
import { ReactComponent as Arrow } from '../../../_shared/assets/svg/it-arrow-right.svg';
import { WebService } from '../../../api/entities';
import { ProvisioningRequirementsData } from '../beans/provisioningRequirementsData';
import { ProvisioningPageEnum } from '../store/state';
import '../styles/provisioning.requirements.style.scss';
import { ProvisioningOriginEnum } from '../views/provisioning.view';
export interface ProvisioningRequirementsComponentProps {
    projectId?: string;
    service?: WebService;
    origin?: ProvisioningOriginEnum;
    requirements?: ProvisioningRequirementsData;
    currentPage?: ProvisioningPageEnum;
    isRequirementsNextClicked?: boolean;
}

export const ProvisioningRequirementsComponent: React.FC<ProvisioningRequirementsComponentProps> = ({
    projectId = '',
    service,
    origin,
    requirements,
    currentPage,
    isRequirementsNextClicked,
}) => {
    const translator = TranslatorManager.getInstance();
    const provisioningManager = ProvisioningManager.getInstance();

    const handleRequirementsCheckChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        provisioningManager.setRequirements(e.target.name as keyof ProvisioningRequirementsData, e.target.checked);
        setValue(e.target.name, e.target.checked);

        isRequirementsNextClicked && triggerValidation();
    };

    const triggerValidation = async () => {
        let values = await getValues();
        const serviceKeys = ['easyToFind', 'retrivable', 'interoperable', 'reusable'];

        let isValidated =
            currentPage === ProvisioningPageEnum.PROJECT
                ? Object.keys(values)
                      .filter(key => !serviceKeys.includes(key))
                      .every(requirementKey => values[requirementKey] === true)
                : Object.values(values).every(requirement => requirement === true);

        isValidated ? clearErrors('dataValidation') : setError('dataValidation', { type: 'required' });
    };

    useEffect(() => {
        triggerValidation();
    }, [isRequirementsNextClicked]);

    const { errors, clearErrors, setValue, register, setError, getValues } = FormManager.getInstance().buildFormProxy<ProvisioningRequirementsData>(
        ProvisioningRequirementsData,
        () => {},
    );

    return (
        <>
            <div className='container'>
                <div className='row justify-content-md-center'>
                    <p className='d-flex'>
                        {translator.get('provisioning.requirements.technical.quality.title')} <p className='requiredSign ml-2'>*</p>
                    </p>
                </div>
                <div className='form-check col'>
                    <input
                        id='dataValidation'
                        {...register('dataValidation')}
                        type='checkbox'
                        className='focus--mouse position-fixed'
                        defaultChecked={requirements?.dataValidation}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRequirementsCheckChange(e)}
                    />
                    <label className='requirements-label-color' htmlFor='dataValidation'>
                        {translator.get('provisioning.requirements.technical.quality.data.validation')}
                    </label>
                    <br></br>
                    <input
                        id='temporaryFiles'
                        type='checkbox'
                        className='focus--mouse position-fixed'
                        {...register('temporaryFiles')}
                        defaultChecked={requirements?.temporaryFiles}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRequirementsCheckChange(e)}
                    />
                    <label className='requirements-label-color' htmlFor='temporaryFiles'>
                        {translator.get('provisioning.requirements.technical.quality.temporary.files')}
                    </label>
                    <br></br>
                    <input
                        id='diskSpaceUsage'
                        type='checkbox'
                        className='focus--mouse position-fixed'
                        {...register('diskSpaceUsage')}
                        defaultChecked={requirements?.diskSpaceUsage}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRequirementsCheckChange(e)}
                    />
                    <label className='requirements-label-color' htmlFor='diskSpaceUsage'>
                        {translator.get('provisioning.requirements.technical.quality.disk.space.usage')}
                    </label>
                    <br></br>
                    <input
                        id='executionTime'
                        type='checkbox'
                        className='focus--mouse position-fixed'
                        {...register('executionTime')}
                        defaultChecked={requirements?.executionTime}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRequirementsCheckChange(e)}
                    />
                    <label className='requirements-label-color' htmlFor='executionTime'>
                        {translator.get('provisioning.requirements.technical.quality.execution.time')}
                    </label>
                    <br></br>
                    <input
                        id='cpuUsage'
                        type='checkbox'
                        className='focus--mouse position-fixed'
                        {...register('cpuUsage')}
                        defaultChecked={requirements?.cpuUsage}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRequirementsCheckChange(e)}
                    />
                    <label className='requirements-label-color' htmlFor='cpuUsage'>
                        {translator.get('provisioning.requirements.technical.quality.cpu.usage')}
                    </label>
                </div>
                {(currentPage === ProvisioningPageEnum.SCRIPTS || currentPage === ProvisioningPageEnum.SERVICE) && (
                    <>
                        <div className='container'></div>
                        <div className='row justify-content-md-center'>
                            <p className='d-flex'>
                                {translator.get('provisioning.requirements.fairness.title')} <p className='requiredSign pl-2'>*</p>
                            </p>
                        </div>
                        <div className='mb-4'>
                            <div className='form-check col'>
                                <input
                                    id='easyToFind'
                                    type='checkbox'
                                    className='focus--mouse position-fixed'
                                    {...register('easyToFind')}
                                    defaultChecked={requirements?.easyToFind}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRequirementsCheckChange(e)}
                                />
                                <label className='requirements-label-color' htmlFor='easyToFind'>
                                    {translator.get('provisioning.requirements.fairness.easy.to.find')}
                                </label>
                                <br></br>
                                <input
                                    id='retrivable'
                                    type='checkbox'
                                    className='focus--mouse position-fixed'
                                    {...register('retrivable')}
                                    defaultChecked={requirements?.retrivable}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRequirementsCheckChange(e)}
                                />

                                <label className='requirements-label-color' htmlFor='retrivable'>
                                    {translator.get('provisioning.requirements.fairness.retrivable')}
                                </label>
                                <br></br>
                                <input
                                    id='interoperable'
                                    type='checkbox'
                                    className='focus--mouse position-fixed'
                                    {...register('interoperable')}
                                    defaultChecked={requirements?.interoperable}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRequirementsCheckChange(e)}
                                />
                                <label className='requirements-label-color' htmlFor='interoperable'>
                                    {translator.get('provisioning.requirements.fairness.interoperable')}
                                </label>
                                <br></br>
                                <input
                                    id='reusable'
                                    type='checkbox'
                                    className='focus--mouse position-fixed'
                                    {...register('reusable')}
                                    defaultChecked={requirements?.reusable}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRequirementsCheckChange(e)}
                                />
                                <label className='requirements-label-color' htmlFor='reusable'>
                                    {translator.get('provisioning.requirements.fairness.reusable')}
                                </label>
                            </div>
                        </div>
                    </>
                )}
                {errors.dataValidation && <small className='form-text error-message'>{translator.get(`form.provisioning.requirements.error.required`)}</small>}

                <div className={`${currentPage === ProvisioningPageEnum.PROJECT ? 'button-container-padding' : ''} button-container container-fluid`}>
                    <div className='row d-flex justify-content-center'>
                        <a
                            className='align-items-center me-sm-4 inline-text text-center link'
                            onClick={() => {
                                origin === ProvisioningOriginEnum.PROJECT || origin === ProvisioningOriginEnum.PROJECTDETAIL
                                    ? NavigationManager.getInstance().navigateTo(`/project/${projectId}` as Paths)
                                    : NavigationManager.getInstance().navigateTo(`/service/${service?.id}` as Paths);
                            }}>
                            {origin === ProvisioningOriginEnum.PROJECT || origin === ProvisioningOriginEnum.PROJECTDETAIL
                                ? translator.get('provisioning.project.open.button')
                                : translator.get('provisioning.service.open.button')}
                            <Arrow className='icon ms-1' fill='#009138' />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};
