/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { UserRepresentation } from '../entities';
/**
 * KeycloakControllerApi - axios parameter creator
 * @export
 */
export const KeycloakControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves user details on Keycloak
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetails: async (userId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getUserDetails', 'userId', userId);
            const localVarPath = `/keycloak/getuserdetails/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates user attributes on Keycloak
         * @param {string} userId
         * @param {{ [key: string]: Array<string> | undefined; }} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserAttributes: async (userId: string, requestBody: { [key: string]: Array<string> | undefined }, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('setUserAttributes', 'userId', userId);
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('setUserAttributes', 'requestBody', requestBody);
            const localVarPath = `/keycloak/setuserdetails/{userId}`.replace(`{${'userId'}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * KeycloakControllerApi - functional programming interface
 * @export
 */
export const KeycloakControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = KeycloakControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Retrieves user details on Keycloak
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserDetails(userId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserRepresentation>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserDetails(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates user attributes on Keycloak
         * @param {string} userId
         * @param {{ [key: string]: Array<string> | undefined; }} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setUserAttributes(
            userId: string,
            requestBody: { [key: string]: Array<string> | undefined },
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setUserAttributes(userId, requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * KeycloakControllerApi - factory interface
 * @export
 */
export const KeycloakControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KeycloakControllerApiFp(configuration);
    return {
        /**
         * Retrieves user details on Keycloak
         * @param {string} userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserDetails(userId: string, options?: any): AxiosPromise<UserRepresentation> {
            return localVarFp.getUserDetails(userId, options).then(request => request(axios, basePath));
        },
        /**
         * Updates user attributes on Keycloak
         * @param {string} userId
         * @param {{ [key: string]: Array<string> | undefined; }} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setUserAttributes(userId: string, requestBody: { [key: string]: Array<string> | undefined }, options?: any): AxiosPromise<void> {
            return localVarFp.setUserAttributes(userId, requestBody, options).then(request => request(axios, basePath));
        },
    };
};

/**
 * KeycloakControllerApi - interface
 * @export
 * @interface KeycloakControllerApi
 */
export interface KeycloakControllerApiInterface {
    /**
     * Retrieves user details on Keycloak
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakControllerApiInterface
     */
    getUserDetails(userId: string, options?: AxiosRequestConfig): AxiosPromise<UserRepresentation>;

    /**
     * Updates user attributes on Keycloak
     * @param {string} userId
     * @param {{ [key: string]: Array<string> | undefined; }} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakControllerApiInterface
     */
    setUserAttributes(userId: string, requestBody: { [key: string]: Array<string> | undefined }, options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * KeycloakControllerApi - object-oriented interface
 * @export
 * @class KeycloakControllerApi
 * @extends {BaseAPI}
 */
export class KeycloakControllerApi extends BaseAPI implements KeycloakControllerApiInterface {
    /**
     * Retrieves user details on Keycloak
     * @param {string} userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakControllerApi
     */
    public getUserDetails(userId: string, options?: AxiosRequestConfig) {
        return KeycloakControllerApiFp(this.configuration)
            .getUserDetails(userId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Updates user attributes on Keycloak
     * @param {string} userId
     * @param {{ [key: string]: Array<string> | undefined; }} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KeycloakControllerApi
     */
    public setUserAttributes(userId: string, requestBody: { [key: string]: Array<string> | undefined }, options?: AxiosRequestConfig) {
        return KeycloakControllerApiFp(this.configuration)
            .setUserAttributes(userId, requestBody, options)
            .then(request => request(this.axios, this.basePath));
    }
}
