import { RequestProjectUrlFileSelect } from 'src/api/entities/request-project-url-file-select';
import { ResponseProjectUrlFileSelect } from 'src/api/entities/response-project-url-file-select';
import {
    BaseResponse,
    Project,
    ProjectNode,
    ProjectSearchRequest,
    RequestBodyGenericStopProcess,
    RequestFile,
    RequestGenericStart,
    RequestProjectAddUser,
    RequestProjectClone,
    RequestProjectCreate,
    RequestProjectFileCreate,
    RequestProjectPublish,
    RequestProjectRemoveUser,
    RequestProjectUpdate,
    RequestScriptPublish,
    RequestUpdateProvisioning,
    ResponseProjectAddUser,
    ResponseProjectCategories,
    ResponseProjectCreate,
    ResponseProjectFileCreate,
    ResponseProjectGet,
    ResponseProjectListInputRemove,
    ResponseProjectListOutput,
    ResponseProjectListUser,
    ResponseProjectPublish,
    ResponseProjectSearch,
    ResponseProjectTemplate,
    ResponseProjectUpdate,
    ResponseScriptPublish,
    ResponseUpdateProvisioning,
} from '../../../api/entities';
import { IProjectApiClient, ProjectApiClients } from './project.api.client';
import { AxiosPromise } from 'axios';

export interface IProjectApiService {
    create(requestProjectCreate: RequestProjectCreate): Promise<ResponseProjectCreate>;
    search(projectSearchRequest: ProjectSearchRequest): Promise<ResponseProjectSearch>;
    getProjectById(id: string): Promise<ResponseProjectGet>;
    clone(requestProjectClone: RequestProjectClone): Promise<Project>;
    getProjectTemplates(): Promise<ResponseProjectTemplate>;
    createInput(id: string, requestProjectFileCreate: RequestProjectFileCreate): Promise<ResponseProjectFileCreate>;
    selectExternalUrlInput(id: string, requestProjectUrlFileSelect: RequestProjectUrlFileSelect): Promise<ResponseProjectUrlFileSelect>;
    start(id: string, requestGenericStart: RequestGenericStart): Promise<BaseResponse>;
    stop(id: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess): Promise<BaseResponse>;
    outputList(id: string): Promise<ResponseProjectListOutput>;
    addNewMember(id: string, requestProjectAddUser: RequestProjectAddUser): Promise<ResponseProjectAddUser>;
    removeMember(id: string, requestProjectRemove: RequestProjectRemoveUser): Promise<ResponseProjectListUser>;
    update(id: string, requestProjectUpdate: RequestProjectUpdate): Promise<ResponseProjectUpdate>;
    downloadInput(id: string, requestFile: RequestFile): Promise<File>;
    removeInput(id: string, requestFile: RequestFile): Promise<ResponseProjectListInputRemove>;
    downloadOutput(id: string, requestFile: RequestFile): Promise<File>;
    publishProject(id: string, requestPublish: RequestProjectPublish): Promise<ResponseProjectPublish>;
    getProjectsByParentId(id: string): Promise<ProjectNode>;
    getProjectCategories(): Promise<ResponseProjectCategories>;
    downloadRepo(projectId: string, zipname: string): Promise<AxiosPromise<File>>;
    publishScript(id: string, requestScriptPublish: RequestScriptPublish): Promise<ResponseScriptPublish>;
    updateProvisioning(id: string, requestUpdateProvisioning: RequestUpdateProvisioning): Promise<ResponseUpdateProvisioning>;
    archive(id: string): Promise<void>;
    delete(id: string): Promise<void>;
}

export class ProjectApiService implements IProjectApiService {
    private static instance: ProjectApiService;
    private api: IProjectApiClient;

    private constructor(basePath: string) {
        this.api = ProjectApiClients.getInstance(basePath);
    }

    static getInstance(basePath: string) {
        if (ProjectApiService.instance == null) {
            ProjectApiService.instance = new ProjectApiService(basePath);
        }
        return ProjectApiService.instance;
    }

    async create(requestProjectCreate: RequestProjectCreate): Promise<ResponseProjectCreate> {
        return (await this.api.project.createProject(requestProjectCreate)).data;
    }

    async search(projectSearchRequest: ProjectSearchRequest): Promise<ResponseProjectSearch> {
        return (await this.api.project.search1(projectSearchRequest)).data;
    }

    async getProjectById(id: string): Promise<ResponseProjectGet> {
        return (await this.api.project.get1(id)).data;
    }

    async clone(requestProjectClone: RequestProjectClone): Promise<Project> {
        return (await this.api.project.clone(requestProjectClone)).data;
    }

    async getProjectTemplates(): Promise<ResponseProjectTemplate> {
        return (await this.api.project.getProjectTemplates()).data;
    }
    async createInput(id: string, requestProjectFileCreate: RequestProjectFileCreate): Promise<ResponseProjectFileCreate> {
        return (await this.api.project.createInput1(id, requestProjectFileCreate)).data;
    }
    async selectExternalUrlInput(id: string, requestProjectUrlFileSelect: RequestProjectUrlFileSelect): Promise<ResponseProjectUrlFileSelect> {
        return (await this.api.project.selectExternalUrlInput2(id, requestProjectUrlFileSelect)).data;
    }
    async start(id: string, requestGenericStart: RequestGenericStart): Promise<BaseResponse> {
        return (await this.api.project.start(id, requestGenericStart)).data;
    }
    async stop(id: string, requestBodyGenericStopProcess: RequestBodyGenericStopProcess): Promise<BaseResponse> {
        return (await this.api.project.stop1(id, requestBodyGenericStopProcess)).data;
    }

    async addNewMember(id: string, requestProjectAddUser: RequestProjectAddUser): Promise<ResponseProjectAddUser> {
        return (await this.api.project.addUser1(id, requestProjectAddUser)).data;
    }

    async removeMember(id: string, requestProjectRemoveUser: RequestProjectRemoveUser): Promise<ResponseProjectListUser> {
        return (await this.api.project.removeUser(id, requestProjectRemoveUser)).data;
    }

    async outputList(id: string): Promise<ResponseProjectListOutput> {
        return (await this.api.project.outputList1(id)).data;
    }

    async update(id: string, requestProjectUpdate: RequestProjectUpdate): Promise<ResponseProjectUpdate> {
        return (await this.api.project.update1(id, requestProjectUpdate)).data;
    }

    async downloadInput(id: string, requestFile: RequestFile): Promise<File> {
        return (await this.api.project.downloadInput1(id, requestFile, { responseType: 'blob' })).data;
    }

    async removeInput(id: string, requestFile: RequestFile): Promise<ResponseProjectListInputRemove> {
        return (await this.api.project.removeInput(id, requestFile)).data;
    }

    async downloadOutput(id: string, requestFile: RequestFile): Promise<File> {
        return (await this.api.project.downloadOutput1(id, requestFile, { responseType: 'blob' })).data;
    }

    async publishProject(id: string, requestPublish: RequestProjectPublish): Promise<ResponseProjectPublish> {
        return (await this.api.project.publishProject(id, requestPublish)).data;
    }

    async getProjectsByParentId(id: string): Promise<ProjectNode> {
        return (await this.api.project.getProjectsByParentId(id)).data;
    }

    async getProjectCategories(): Promise<ResponseProjectCategories> {
        return (await this.api.project.getProjectCategories()).data;
    }

    async downloadRepo(projectId: string, zipname: string): Promise<AxiosPromise<File>> {
        return await this.api.project.downloadRepo(projectId, zipname, { responseType: 'blob' });
    }

    async publishScript(id: string, requestScriptPublish: RequestScriptPublish): Promise<ResponseScriptPublish> {
        return (await this.api.project.publishScript(id, requestScriptPublish)).data;
    }

    async updateProvisioning(id: string, requestUpdateProvisioning: RequestUpdateProvisioning): Promise<ResponseUpdateProvisioning> {
        return (await this.api.project.updateProvisioning(id, requestUpdateProvisioning)).data;
    }

    async archive(id: string): Promise<void> {
        return (await this.api.project.archive(id)).data;
    }

    async delete(id: string): Promise<void> {
        return (await this.api.project.delete1(id)).data;
    }
}
