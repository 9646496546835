import { Matches } from 'class-validator';

export class ServiceInputUploadData {
    filename: string;

    @Matches(/^((http|https|ftp|ftps):\/\/)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/)
    url: string;

    persistence: boolean;
}
