/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RequestInBoundMetrics } from '../entities';
// @ts-ignore
import { RequestInBoundWarnings } from '../entities';
// @ts-ignore
import { RequestProjectInBoundUpdate } from '../entities';
// @ts-ignore
import { RequestServiceExecutionInBoundMetrics } from '../entities';
// @ts-ignore
import { RequestServiceExecutionInBoundUpdate } from '../entities';
// @ts-ignore
import { ResponseInBound } from '../entities';
/**
 * CoreInBoundControllerApi - axios parameter creator
 * @export
 */
export const CoreInBoundControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Update the metrics for a project-specific IDE instance
         * @param {string} projectId
         * @param {RequestInBoundMetrics} requestInBoundMetrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetricsProject: async (projectId: string, requestInBoundMetrics: RequestInBoundMetrics, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateMetricsProject', 'projectId', projectId);
            // verify required parameter 'requestInBoundMetrics' is not null or undefined
            assertParamExists('updateMetricsProject', 'requestInBoundMetrics', requestInBoundMetrics);
            const localVarPath = `/core/inbound/projects/{projectId}/metrics`.replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestInBoundMetrics, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the metrics for a service-specific execution instance
         * @param {string} serviceId
         * @param {string} execId
         * @param {RequestServiceExecutionInBoundMetrics} requestServiceExecutionInBoundMetrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetricsServiceExecution: async (
            serviceId: string,
            execId: string,
            requestServiceExecutionInBoundMetrics: RequestServiceExecutionInBoundMetrics,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('updateMetricsServiceExecution', 'serviceId', serviceId);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('updateMetricsServiceExecution', 'execId', execId);
            // verify required parameter 'requestServiceExecutionInBoundMetrics' is not null or undefined
            assertParamExists('updateMetricsServiceExecution', 'requestServiceExecutionInBoundMetrics', requestServiceExecutionInBoundMetrics);
            const localVarPath = `/core/inbound/services/{serviceId}/execution/{execId}/metrics`
                .replace(`{${'serviceId'}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestServiceExecutionInBoundMetrics, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a project-specific IDE instance state
         * @param {string} projectId
         * @param {RequestProjectInBoundUpdate} requestProjectInBoundUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStateProject: async (projectId: string, requestProjectInBoundUpdate: RequestProjectInBoundUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateStateProject', 'projectId', projectId);
            // verify required parameter 'requestProjectInBoundUpdate' is not null or undefined
            assertParamExists('updateStateProject', 'requestProjectInBoundUpdate', requestProjectInBoundUpdate);
            const localVarPath = `/core/inbound/projects/{projectId}/update`.replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestProjectInBoundUpdate, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update a service-specific IDE instance state
         * @param {string} serviceId
         * @param {string} execId
         * @param {RequestServiceExecutionInBoundUpdate} requestServiceExecutionInBoundUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStateServiceExecution: async (
            serviceId: string,
            execId: string,
            requestServiceExecutionInBoundUpdate: RequestServiceExecutionInBoundUpdate,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('updateStateServiceExecution', 'serviceId', serviceId);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('updateStateServiceExecution', 'execId', execId);
            // verify required parameter 'requestServiceExecutionInBoundUpdate' is not null or undefined
            assertParamExists('updateStateServiceExecution', 'requestServiceExecutionInBoundUpdate', requestServiceExecutionInBoundUpdate);
            const localVarPath = `/core/inbound/services/{serviceId}/execution/{execId}/update`
                .replace(`{${'serviceId'}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestServiceExecutionInBoundUpdate, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the warnings for a project-specific IDE instance
         * @param {string} projectId
         * @param {RequestInBoundWarnings} requestInBoundWarnings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWarningsProject: async (projectId: string, requestInBoundWarnings: RequestInBoundWarnings, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateWarningsProject', 'projectId', projectId);
            // verify required parameter 'requestInBoundWarnings' is not null or undefined
            assertParamExists('updateWarningsProject', 'requestInBoundWarnings', requestInBoundWarnings);
            const localVarPath = `/core/inbound/projects/{projectId}/warnings`.replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestInBoundWarnings, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update the warings for a service-specific execution instance
         * @param {string} serviceId
         * @param {string} execId
         * @param {RequestInBoundWarnings} requestInBoundWarnings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWarningsServiceExecution: async (
            serviceId: string,
            execId: string,
            requestInBoundWarnings: RequestInBoundWarnings,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('updateWarningsServiceExecution', 'serviceId', serviceId);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('updateWarningsServiceExecution', 'execId', execId);
            // verify required parameter 'requestInBoundWarnings' is not null or undefined
            assertParamExists('updateWarningsServiceExecution', 'requestInBoundWarnings', requestInBoundWarnings);
            const localVarPath = `/core/inbound/services/{serviceId}/execution/{execId}/warnings`
                .replace(`{${'serviceId'}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestInBoundWarnings, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * CoreInBoundControllerApi - functional programming interface
 * @export
 */
export const CoreInBoundControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = CoreInBoundControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Update the metrics for a project-specific IDE instance
         * @param {string} projectId
         * @param {RequestInBoundMetrics} requestInBoundMetrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMetricsProject(
            projectId: string,
            requestInBoundMetrics: RequestInBoundMetrics,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseInBound>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMetricsProject(projectId, requestInBoundMetrics, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the metrics for a service-specific execution instance
         * @param {string} serviceId
         * @param {string} execId
         * @param {RequestServiceExecutionInBoundMetrics} requestServiceExecutionInBoundMetrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateMetricsServiceExecution(
            serviceId: string,
            execId: string,
            requestServiceExecutionInBoundMetrics: RequestServiceExecutionInBoundMetrics,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseInBound>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateMetricsServiceExecution(serviceId, execId, requestServiceExecutionInBoundMetrics, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a project-specific IDE instance state
         * @param {string} projectId
         * @param {RequestProjectInBoundUpdate} requestProjectInBoundUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStateProject(
            projectId: string,
            requestProjectInBoundUpdate: RequestProjectInBoundUpdate,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseInBound>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStateProject(projectId, requestProjectInBoundUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update a service-specific IDE instance state
         * @param {string} serviceId
         * @param {string} execId
         * @param {RequestServiceExecutionInBoundUpdate} requestServiceExecutionInBoundUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateStateServiceExecution(
            serviceId: string,
            execId: string,
            requestServiceExecutionInBoundUpdate: RequestServiceExecutionInBoundUpdate,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseInBound>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateStateServiceExecution(serviceId, execId, requestServiceExecutionInBoundUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the warnings for a project-specific IDE instance
         * @param {string} projectId
         * @param {RequestInBoundWarnings} requestInBoundWarnings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWarningsProject(
            projectId: string,
            requestInBoundWarnings: RequestInBoundWarnings,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseInBound>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWarningsProject(projectId, requestInBoundWarnings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update the warings for a service-specific execution instance
         * @param {string} serviceId
         * @param {string} execId
         * @param {RequestInBoundWarnings} requestInBoundWarnings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateWarningsServiceExecution(
            serviceId: string,
            execId: string,
            requestInBoundWarnings: RequestInBoundWarnings,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseInBound>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateWarningsServiceExecution(serviceId, execId, requestInBoundWarnings, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * CoreInBoundControllerApi - factory interface
 * @export
 */
export const CoreInBoundControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CoreInBoundControllerApiFp(configuration);
    return {
        /**
         * Update the metrics for a project-specific IDE instance
         * @param {string} projectId
         * @param {RequestInBoundMetrics} requestInBoundMetrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetricsProject(projectId: string, requestInBoundMetrics: RequestInBoundMetrics, options?: any): AxiosPromise<ResponseInBound> {
            return localVarFp.updateMetricsProject(projectId, requestInBoundMetrics, options).then(request => request(axios, basePath));
        },
        /**
         * Update the metrics for a service-specific execution instance
         * @param {string} serviceId
         * @param {string} execId
         * @param {RequestServiceExecutionInBoundMetrics} requestServiceExecutionInBoundMetrics
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMetricsServiceExecution(
            serviceId: string,
            execId: string,
            requestServiceExecutionInBoundMetrics: RequestServiceExecutionInBoundMetrics,
            options?: any,
        ): AxiosPromise<ResponseInBound> {
            return localVarFp.updateMetricsServiceExecution(serviceId, execId, requestServiceExecutionInBoundMetrics, options).then(request => request(axios, basePath));
        },
        /**
         * Update a project-specific IDE instance state
         * @param {string} projectId
         * @param {RequestProjectInBoundUpdate} requestProjectInBoundUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStateProject(projectId: string, requestProjectInBoundUpdate: RequestProjectInBoundUpdate, options?: any): AxiosPromise<ResponseInBound> {
            return localVarFp.updateStateProject(projectId, requestProjectInBoundUpdate, options).then(request => request(axios, basePath));
        },
        /**
         * Update a service-specific IDE instance state
         * @param {string} serviceId
         * @param {string} execId
         * @param {RequestServiceExecutionInBoundUpdate} requestServiceExecutionInBoundUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStateServiceExecution(
            serviceId: string,
            execId: string,
            requestServiceExecutionInBoundUpdate: RequestServiceExecutionInBoundUpdate,
            options?: any,
        ): AxiosPromise<ResponseInBound> {
            return localVarFp.updateStateServiceExecution(serviceId, execId, requestServiceExecutionInBoundUpdate, options).then(request => request(axios, basePath));
        },
        /**
         * Update the warnings for a project-specific IDE instance
         * @param {string} projectId
         * @param {RequestInBoundWarnings} requestInBoundWarnings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWarningsProject(projectId: string, requestInBoundWarnings: RequestInBoundWarnings, options?: any): AxiosPromise<ResponseInBound> {
            return localVarFp.updateWarningsProject(projectId, requestInBoundWarnings, options).then(request => request(axios, basePath));
        },
        /**
         * Update the warings for a service-specific execution instance
         * @param {string} serviceId
         * @param {string} execId
         * @param {RequestInBoundWarnings} requestInBoundWarnings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWarningsServiceExecution(serviceId: string, execId: string, requestInBoundWarnings: RequestInBoundWarnings, options?: any): AxiosPromise<ResponseInBound> {
            return localVarFp.updateWarningsServiceExecution(serviceId, execId, requestInBoundWarnings, options).then(request => request(axios, basePath));
        },
    };
};

/**
 * CoreInBoundControllerApi - interface
 * @export
 * @interface CoreInBoundControllerApi
 */
export interface CoreInBoundControllerApiInterface {
    /**
     * Update the metrics for a project-specific IDE instance
     * @param {string} projectId
     * @param {RequestInBoundMetrics} requestInBoundMetrics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreInBoundControllerApiInterface
     */
    updateMetricsProject(projectId: string, requestInBoundMetrics: RequestInBoundMetrics, options?: AxiosRequestConfig): AxiosPromise<ResponseInBound>;

    /**
     * Update the metrics for a service-specific execution instance
     * @param {string} serviceId
     * @param {string} execId
     * @param {RequestServiceExecutionInBoundMetrics} requestServiceExecutionInBoundMetrics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreInBoundControllerApiInterface
     */
    updateMetricsServiceExecution(
        serviceId: string,
        execId: string,
        requestServiceExecutionInBoundMetrics: RequestServiceExecutionInBoundMetrics,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseInBound>;

    /**
     * Update a project-specific IDE instance state
     * @param {string} projectId
     * @param {RequestProjectInBoundUpdate} requestProjectInBoundUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreInBoundControllerApiInterface
     */
    updateStateProject(projectId: string, requestProjectInBoundUpdate: RequestProjectInBoundUpdate, options?: AxiosRequestConfig): AxiosPromise<ResponseInBound>;

    /**
     * Update a service-specific IDE instance state
     * @param {string} serviceId
     * @param {string} execId
     * @param {RequestServiceExecutionInBoundUpdate} requestServiceExecutionInBoundUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreInBoundControllerApiInterface
     */
    updateStateServiceExecution(
        serviceId: string,
        execId: string,
        requestServiceExecutionInBoundUpdate: RequestServiceExecutionInBoundUpdate,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseInBound>;

    /**
     * Update the warnings for a project-specific IDE instance
     * @param {string} projectId
     * @param {RequestInBoundWarnings} requestInBoundWarnings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreInBoundControllerApiInterface
     */
    updateWarningsProject(projectId: string, requestInBoundWarnings: RequestInBoundWarnings, options?: AxiosRequestConfig): AxiosPromise<ResponseInBound>;

    /**
     * Update the warings for a service-specific execution instance
     * @param {string} serviceId
     * @param {string} execId
     * @param {RequestInBoundWarnings} requestInBoundWarnings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreInBoundControllerApiInterface
     */
    updateWarningsServiceExecution(serviceId: string, execId: string, requestInBoundWarnings: RequestInBoundWarnings, options?: AxiosRequestConfig): AxiosPromise<ResponseInBound>;
}

/**
 * CoreInBoundControllerApi - object-oriented interface
 * @export
 * @class CoreInBoundControllerApi
 * @extends {BaseAPI}
 */
export class CoreInBoundControllerApi extends BaseAPI implements CoreInBoundControllerApiInterface {
    /**
     * Update the metrics for a project-specific IDE instance
     * @param {string} projectId
     * @param {RequestInBoundMetrics} requestInBoundMetrics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreInBoundControllerApi
     */
    public updateMetricsProject(projectId: string, requestInBoundMetrics: RequestInBoundMetrics, options?: AxiosRequestConfig) {
        return CoreInBoundControllerApiFp(this.configuration)
            .updateMetricsProject(projectId, requestInBoundMetrics, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Update the metrics for a service-specific execution instance
     * @param {string} serviceId
     * @param {string} execId
     * @param {RequestServiceExecutionInBoundMetrics} requestServiceExecutionInBoundMetrics
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreInBoundControllerApi
     */
    public updateMetricsServiceExecution(
        serviceId: string,
        execId: string,
        requestServiceExecutionInBoundMetrics: RequestServiceExecutionInBoundMetrics,
        options?: AxiosRequestConfig,
    ) {
        return CoreInBoundControllerApiFp(this.configuration)
            .updateMetricsServiceExecution(serviceId, execId, requestServiceExecutionInBoundMetrics, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Update a project-specific IDE instance state
     * @param {string} projectId
     * @param {RequestProjectInBoundUpdate} requestProjectInBoundUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreInBoundControllerApi
     */
    public updateStateProject(projectId: string, requestProjectInBoundUpdate: RequestProjectInBoundUpdate, options?: AxiosRequestConfig) {
        return CoreInBoundControllerApiFp(this.configuration)
            .updateStateProject(projectId, requestProjectInBoundUpdate, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Update a service-specific IDE instance state
     * @param {string} serviceId
     * @param {string} execId
     * @param {RequestServiceExecutionInBoundUpdate} requestServiceExecutionInBoundUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreInBoundControllerApi
     */
    public updateStateServiceExecution(
        serviceId: string,
        execId: string,
        requestServiceExecutionInBoundUpdate: RequestServiceExecutionInBoundUpdate,
        options?: AxiosRequestConfig,
    ) {
        return CoreInBoundControllerApiFp(this.configuration)
            .updateStateServiceExecution(serviceId, execId, requestServiceExecutionInBoundUpdate, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Update the warnings for a project-specific IDE instance
     * @param {string} projectId
     * @param {RequestInBoundWarnings} requestInBoundWarnings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreInBoundControllerApi
     */
    public updateWarningsProject(projectId: string, requestInBoundWarnings: RequestInBoundWarnings, options?: AxiosRequestConfig) {
        return CoreInBoundControllerApiFp(this.configuration)
            .updateWarningsProject(projectId, requestInBoundWarnings, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Update the warings for a service-specific execution instance
     * @param {string} serviceId
     * @param {string} execId
     * @param {RequestInBoundWarnings} requestInBoundWarnings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CoreInBoundControllerApi
     */
    public updateWarningsServiceExecution(serviceId: string, execId: string, requestInBoundWarnings: RequestInBoundWarnings, options?: AxiosRequestConfig) {
        return CoreInBoundControllerApiFp(this.configuration)
            .updateWarningsServiceExecution(serviceId, execId, requestInBoundWarnings, options)
            .then(request => request(this.axios, this.basePath));
    }
}
