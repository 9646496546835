/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { CellInBoundMetricsExecutionTime } from './cell-in-bound-metrics-execution-time';
// May contain unused imports in some cases
// @ts-ignore
import { InBoundMetrics } from './in-bound-metrics';
// May contain unused imports in some cases
// @ts-ignore
import { InBoundWarnings } from './in-bound-warnings';
// May contain unused imports in some cases
// @ts-ignore
import { InputFile } from './input-file';
// May contain unused imports in some cases
// @ts-ignore
import { OutputFile } from './output-file';
// May contain unused imports in some cases
// @ts-ignore
import { User } from './user';

/**
 *
 * @export
 * @interface ResponseServiceExecutionMinisite
 */
export interface ResponseServiceExecutionMinisite {
    /**
     *
     * @type {string}
     * @memberof ResponseServiceExecutionMinisite
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseServiceExecutionMinisite
     */
    status?: ResponseServiceExecutionMinisiteStatusEnum;
    /**
     *
     * @type {number}
     * @memberof ResponseServiceExecutionMinisite
     */
    run?: number;
    /**
     *
     * @type {User}
     * @memberof ResponseServiceExecutionMinisite
     */
    author?: User;
    /**
     *
     * @type {{ [key: string]: InputFile | undefined; }}
     * @memberof ResponseServiceExecutionMinisite
     */
    inputs?: { [key: string]: InputFile | undefined };
    /**
     *
     * @type {{ [key: string]: OutputFile | undefined; }}
     * @memberof ResponseServiceExecutionMinisite
     */
    outputs?: { [key: string]: OutputFile | undefined };
    /**
     *
     * @type {Array<InBoundWarnings>}
     * @memberof ResponseServiceExecutionMinisite
     */
    warnings?: Array<InBoundWarnings>;
    /**
     *
     * @type {InBoundMetrics}
     * @memberof ResponseServiceExecutionMinisite
     */
    metrics?: InBoundMetrics;
    /**
     *
     * @type {string}
     * @memberof ResponseServiceExecutionMinisite
     */
    data?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseServiceExecutionMinisite
     */
    minisiteUrl?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseServiceExecutionMinisite
     */
    parent_service_id?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseServiceExecutionMinisite
     */
    date_of_started?: string;
    /**
     *
     * @type {string}
     * @memberof ResponseServiceExecutionMinisite
     */
    date_of_finished?: string;
    /**
     *
     * @type {CellInBoundMetricsExecutionTime}
     * @memberof ResponseServiceExecutionMinisite
     */
    execution_time?: CellInBoundMetricsExecutionTime;
    /**
     *
     * @type {{ [key: string]: InputFile | undefined; }}
     * @memberof ResponseServiceExecutionMinisite
     */
    project_inputs?: { [key: string]: InputFile | undefined };
    /**
     *
     * @type {string}
     * @memberof ResponseServiceExecutionMinisite
     */
    created_at?: string;
}

export const ResponseServiceExecutionMinisiteStatusEnum = {
    RUNNING: 'RUNNING',
    WAIT: 'WAIT',
    COMPLETED: 'COMPLETED',
    FAILED: 'FAILED',
} as const;

export type ResponseServiceExecutionMinisiteStatusEnum = (typeof ResponseServiceExecutionMinisiteStatusEnum)[keyof typeof ResponseServiceExecutionMinisiteStatusEnum];
