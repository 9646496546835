import { BackofficeSetKeys } from '@features/_shared/store/actions';
import { StoreManager } from '@hypereact/state';
import { RequestConfigurationGet } from '../../api';
import { BackofficeApiService, IBackofficeApiService } from '../services/backoffice/backoffice.api.service';

export interface IBackofficeManagerProps {
    getKeys(requestConfigurationGet: RequestConfigurationGet): Promise<void>;
}

export class BackofficeManager implements IBackofficeManagerProps {
    private static instance: BackofficeManager;
    private backofficeService: IBackofficeApiService;
    private storeManager: StoreManager;
    private constructor() {
        this.backofficeService = BackofficeApiService.getInstance(process.env.REACT_APP_MICROSERVICE_DASHBOARD_API_BASEPATH!);
        this.storeManager = StoreManager.getInstance();
    }

    static getInstance() {
        if (BackofficeManager.instance == null) {
            BackofficeManager.instance = new BackofficeManager();
        }
        return BackofficeManager.instance;
    }

    async getKeys(requestConfigurationGet: RequestConfigurationGet): Promise<void> {
        const response = await this.backofficeService.getKeys(requestConfigurationGet);
        response.configuration &&
            Object.keys(response.configuration).forEach(function (item) {
                var newkey = item
                    .replace('ui.homepage.statistics.', '')
                    .replace('ui.project.metrics.resources.', '')
                    .replace('ui.service.metrics.resources.', '')
                    .replace('.visibile', '')
                    .replace('.visible', '')
                    .replace(/\./g, '_');
                response.configuration![newkey] = response.configuration![item];
                delete response.configuration![item];
            });
        this.storeManager.dispatch(new BackofficeSetKeys(response.configuration as { [key: string]: string }));
    }
}
