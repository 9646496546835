import { ReactComponent as ArrowDown } from '@assets/svg/it-arrow-down-triangle.svg';
import { ReactComponent as Back } from '@assets/svg/it-arrow-left.svg';
import { ReactComponent as ArrowUp } from '@assets/svg/it-arrow-up-triangle.svg';
import { ReactComponent as MoreAction } from '@assets/svg/it-more-actions.svg';
import { ReactComponent as Plus } from '@assets/svg/it-plus.svg';
import { ReactComponent as Play } from '@assets/svg/play.svg';
import { ReactComponent as Stop } from '@assets/svg/stop.svg';
import { Breadcrumb } from '@components/Breadcrumb/breadcrumb.component';
import { Table } from '@components/Table';
import { Paginator } from '@features/_shared/components/Paginator';
import { ListPaginationInterface } from '@features/_shared/components/Paginator/enums/ListPaginationInterface';
import { PopupType } from '@features/_shared/components/Popup/popup.item';
import { TextField } from '@features/_shared/components/TextField';
import { StyledTooltip } from '@features/_shared/components/Tooltip/Tooltip';
import CreateFormProjectView from '@features/project/components/project.create.form.component';
import FilterBarProjectView from '@features/project/components/project.filterbar.component';
import { ProvisioningPageEnum } from '@features/provisioning/store/state';
import { wizardPublicationDataset, wizardPublicationProject, wizardPublicationScript } from '@features/provisioning/utils/constant';
import { ProvisioningOriginEnum } from '@features/provisioning/views/provisioning.view';
import ServiceCreateFormComponent from '@features/service/components/service.create.form.component';
import { ServiceState } from '@features/service/store/state';
import { ReduxConnect, StoreManager } from '@hypereact/state';
import { TranslatorManager } from '@managers/TranslatorManager';
import { NavigationManager } from '@managers/navigation.manager';
import { PopupManager } from '@managers/popup.manager';
import { ProjectManager } from '@managers/project.manager';
import { ProvisioningManager } from '@managers/provisioning.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { ServiceManager } from '@managers/service.manager';
import { Paths } from '@routes';
import { RootState } from '@store/state';
import { FormatUtil } from '@utils/formaters.util';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';
import { PollingUtil } from '@utils/polling.util';
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import _ from 'lodash';
import React, { RefObject, createRef } from 'react';
import {
    BaseProjectTemplate,
    Project,
    ProjectSearchFilter,
    ProjectStatusEnum,
    RequestProjectClone,
    RequestProjectCreate,
    RequestServiceCreate,
    User,
    WebService,
} from '../../../api/entities';
import { ProjectCloneFormView } from '../components/project.clone.form.components';
import '../components/styles/project.style.scss';
import { ProjectSetProjects } from '../state/actions';
import { ProjectCreateUpdate } from '../state/state';

export interface IProjectViewProps {
    projects: Array<Project>;
    project: Project;
    createUpdate: ProjectCreateUpdate;
    user: User;
    templates: Array<BaseProjectTemplate>;
    filters: ProjectSearchFilter;
    service: ServiceState;
    pagination: ListPaginationInterface;
    availableCategoryOptions: string[];
    serviceCreateUpdate: WebService;
    categories: Array<string>;
}

export interface IProjectViewState {
    isModalOpen: boolean;
    isAddingTimeout: boolean;
    timeoutValue: number;
    openedMenu: string | null;
}

@ReduxConnect((state: RootState) => {
    return {
        projects: state.project.project.projects,
        project: state.project.project.selectedProject,
        user: state.login,
        createUpdate: state.project.project.createUpdate,
        templates: state.project.template,
        filters: state.project.project.filters,
        service: state.service,
        pagination: state.project.project.pagination,
        availableCategoryOptions: state.project.project.categoryAvailableOptions,
        serviceCreateUpdate: state.service.service.createUpdate,
    };
})
export class ProjectView extends React.Component<IProjectViewProps, IProjectViewState> {
    private projectManager: ProjectManager;
    private serviceManager: ServiceManager;
    private navigationManager: NavigationManager;
    private translator: TranslatorManager;
    private storeManager: StoreManager;
    private queueManager: QueueManager;
    private provisioningManager: ProvisioningManager;
    private popupManager: PopupManager;
    private ref: RefObject<HTMLDivElement>;
    private dropdownRef: RefObject<HTMLDivElement>;

    constructor(props: IProjectViewProps) {
        super(props);
        this.projectManager = ProjectManager.getInstance();
        this.navigationManager = NavigationManager.getInstance();
        this.translator = TranslatorManager.getInstance();
        this.provisioningManager = ProvisioningManager.getInstance();
        this.queueManager = QueueManager.getInstance();
        this.popupManager = PopupManager.getInstance();
        this.storeManager = StoreManager.getInstance();
        this.dropdownRef = createRef();
        this.ref = createRef();
        this.handleTableRowClick = this.handleTableRowClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnServiceChange = this.handleOnServiceChange.bind(this);
        this.handleCreateProject = this.handleCreateProject.bind(this);
        this.handleCreateService = this.handleCreateService.bind(this);
        this.handleCloneProject = this.handleCloneProject.bind(this);
        this.handleStopClick = this.handleStopClick.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handleClickOutsideDropdown = this.handleClickOutsideDropdown.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleClearClick = this.handleClearClick.bind(this);
        this.serviceManager = ServiceManager.getInstance();
        this.handlePublicationClick = this.handlePublicationClick.bind(this);
        this.handleArchiveClick = this.handleArchiveClick.bind(this);
        this.handleDeleteClick = this.handleDeleteClick.bind(this);
        this.handleOnPaginationChange = this.handleOnPaginationChange.bind(this);
        this.handleDropdownClose = this.handleDropdownClose.bind(this);

        this.state = {
            isModalOpen: false,
            isAddingTimeout: false,
            timeoutValue: 0,
            openedMenu: null,
        };
    }

    async pollProjects(page: number, size: number) {
        const validateProjects = () => this.navigationManager.getCurrentRoute() === '/project';
        PollingUtil.register(
            'projects',
            async () => {
                await this.projectManager.search({
                    page: page,
                    size: size,
                    filter: {
                        published: KeycloakUtil.isLoggedIn() ? undefined : true,
                        ...this.props.filters,
                    },
                });
            },
            validateProjects,
            10000,
        );
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.handleOnProjectDashboardLoad());
        await this.queueManager.queue(QueueKeyType.POLLER, this.pollProjects(this.props.pagination.page, this.props.pagination.size));
        document.addEventListener('click', this.handleClickOutsideDropdown);
    }

    componentWillUnmount() {
        PollingUtil.unregister('projects');
        document.removeEventListener('blur', this.handleClickOutsideDropdown, true);
        this.projectManager.resetFilters();
        this.projectManager.resetPagination();
        document.removeEventListener('click', this.handleClickOutsideDropdown);
    }

    shouldComponentUpdate(nextProp: IProjectViewProps, nextState: IProjectViewState) {
        if (_.isEqual(nextProp, this.props) && _.isEqual(nextState, this.state)) {
            return false;
        } else {
            return true;
        }
    }

    handleClickOutsideDropdown(event: any) {
        if (!(event.target.closest('.more-action-button') || event.target.closest('.publish-container'))) {
            this.setState({
                openedMenu: null,
                isAddingTimeout: false,
            });
        }
    }

    handleDropdownClose(id: string, actionMenu?: boolean) {
        let newDropdownMenuId = actionMenu ? `actionMenu${id}` : `moreActionMenu${id}`;
        this.state.openedMenu === newDropdownMenuId ? this.setState({ openedMenu: null }) : this.setState({ openedMenu: newDropdownMenuId });
    }

    async handleOnProjectDashboardLoad() {
        await this.projectManager.getTemplateList();
        await this.projectManager.getProjectCategories();
        await this.projectManager.search({
            page: this.props.pagination.page,
            size: this.props.pagination.size,
            filter: {
                published: KeycloakUtil.isLoggedIn() ? undefined : true,
                ...this.props.filters,
            },
        });
    }

    async handleTableRowClick(id: string) {
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(id));
        this.provisioningManager.setOrigin(ProvisioningOriginEnum.PROJECT);
        this.projectManager.saveValue('keywords' as keyof ProjectCreateUpdate, this.props.project?.keywords as any);
        this.projectManager.saveValue('categories' as keyof ProjectCreateUpdate, this.props.project?.categories as any);
        this.projectManager.manageIDE(false);
        this.navigationManager.navigateTo(`/project/${id}` as Paths, { id: id });
    }

    async handleOpenClick(e: any) {
        e.stopPropagation();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(e.target.id));
        this.projectManager.manageIDE(true);
        this.navigationManager.navigateTo(`/project/${e.target.id}` as Paths);
    }

    async handleStartClick(e: any) {
        const updatedProjectList = this.props.projects.map((project: Project) => {
            if (project.id === e.currentTarget.id) {
                return {
                    ...project,
                    status: ProjectStatusEnum.LOADING,
                };
            } else {
                return project;
            }
        });
        this.storeManager.dispatch(new ProjectSetProjects(updatedProjectList as Project[]));
        e.stopPropagation();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.start(e.currentTarget.id, this.state.timeoutValue));
        //await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(e.currentTarget.id));
    }

    handleOnChange(key: keyof ProjectCreateUpdate, value: string | number | boolean | Array<string>) {
        this.projectManager.saveValue(key, value);
        if (Array.isArray(value)) {
            this.projectManager.updateAvailableOptions(value);
        }
    }

    handleOnServiceChange(key: keyof WebService, value: string | number | boolean | Array<string>) {
        this.serviceManager.saveValue(key, value);
        if (Array.isArray(value)) {
            this.projectManager.updateAvailableOptions(value);
        }
    }

    async handleStopClick(e: React.MouseEvent<HTMLButtonElement>, force: boolean) {
        const updatedProjectList = this.props.projects.map((project: Project) => {
            if (project.id === e.currentTarget.id) {
                return {
                    ...project,
                    status: ProjectStatusEnum.STOPPING,
                };
            } else {
                return project;
            }
        });
        this.storeManager.dispatch(new ProjectSetProjects(updatedProjectList as Project[]));
        e.stopPropagation();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.stop(e.currentTarget.id, force));
        this.projectManager.manageIDE(false);
    }

    async handleForceStop(projectId: string) {
        const updatedProjectList = this.props.projects.map((project: Project) => {
            if (project.id === projectId) {
                return {
                    ...project,
                    status: ProjectStatusEnum.STOPPING,
                };
            } else {
                return project;
            }
        });
        this.storeManager.dispatch(new ProjectSetProjects(updatedProjectList as Project[]));
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.stop(projectId, true));
    }

    async handleForceStopClick(e: any, projectId: string) {
        e.stopPropagation();
        this.popupManager.show({
            type: PopupType.Info,
            title: this.translator.get('project.popup.force.stop.title'),
            description: this.translator.get('project.popup.force.stop.description'),
            maxWidth: 'lg',
            stylingProp: 'popup-display',
            ctas: {
                primary: {
                    label: this.translator.get('project.popup.force.stop.button'),
                    className: 'popup-accept-button',
                    cta: async () => this.handleForceStop(projectId),
                },
                secondary: {
                    label: this.translator.get('common.button.cancel.label'),
                    className: 'popup-accept-button',
                    cta: () => this.popupManager.hide(),
                },
            },
        });
    }

    async handleCreateProject() {
        const { name, version, technology, description, categories, keywords } = this.props.createUpdate;
        const username = KeycloakUtil.getName();
        const email = KeycloakUtil.getEmail();
        const request: RequestProjectCreate = {
            name: name as string,
            version: version as string,
            technology: technology,
            author: {
                name: username,
                email: email,
            },
            description: description,
            categories: categories as string[],
            keywords: keywords as Array<string>,
        };
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.create(request));
        document.getElementById('createModalBtnClose')?.click();
    }

    async handleCloneClick(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(e.currentTarget.id));
        this.handleOnChange('categories' as keyof ProjectCreateUpdate, this.props.project.categories as string[]);
        this.handleOnChange('keywords' as keyof ProjectCreateUpdate, this.props.project.keywords as any[]);
        this.handleOnChange('description' as keyof ProjectCreateUpdate, this.props.project.description as string);
        this.setState({
            isModalOpen: true,
        });
    }

    async handleCreateClick(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation();
        //this.projectManager.resetCategoryCreate();
        this.projectManager.resetCreateUpdate();
        this.setState({
            isModalOpen: true,
        });
    }

    async handleCloneProject() {
        const { name, version, description, categories, keywords } = this.props.createUpdate;
        const { id } = this.props.project;
        const username = KeycloakUtil.getName();
        const email = KeycloakUtil.getEmail();
        const request: RequestProjectClone = {
            source_project_id: id,
            name: name as string,
            version: version as string,
            author: {
                name: username,
                email: email,
            },
            description: description ? description : this.props.project.description,
            categories: categories as string[],
            keywords: keywords,
        };
        const response = await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.clone(request));
        document.getElementById('modalBtnClose')?.click();
        this.navigationManager.navigateTo(`/project/${response.id}` as Paths);
    }

    async handleCreateServiceClick(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(e.currentTarget.id));
        this.handleOnServiceChange('categories' as keyof WebService, this.props.project.categories as string[]);
        this.handleOnServiceChange('keywords' as keyof WebService, this.props.project.keywords as any[]);
        this.handleOnServiceChange('description' as keyof WebService, this.props.project.description as string);
        this.setState({
            isModalOpen: true,
        });
    }

    async handleCreateService() {
        const { technology, id } = this.props.project;
        const username = KeycloakUtil.getName();
        const email = KeycloakUtil.getEmail();
        const { name, version, description, keywords, categories } = this.props.service.service.createUpdate;
        const request: RequestServiceCreate = {
            name: name as string,
            idReferenceProject: id,
            version: version as string,
            technology: technology,
            author: {
                name: username,
                email: email,
            },
            description: description,
            categories: categories as string[],
            keywords: keywords,
        };
        const response = await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.create(request));
        const firstExecution = await this.queueManager.queue(
            QueueKeyType.DEFAULT,
            this.serviceManager.createExecution(response.id as string, {
                author: {
                    email: email,
                    name: username,
                },
            }),
        );
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.launch(response.id as string, firstExecution.id as string));
        document.getElementById('serviceCreateModalBtnClose')?.click();
        this.popupManager.show({
            type: PopupType.Info,
            title: this.translator.get('project.popup.service.created.title'),
            description: this.translator.get('project.popup.service.created.description'),
            maxWidth: 'xl',
            stylingProp: 'popup-display',
            ctas: {
                primary: {
                    label: this.translator.get('popup.menu.ok.title'),
                    className: 'popup-accept-button',
                    cta: async () => {
                        this.popupManager.hide();
                        this.navigationManager.navigateTo('/service');
                    },
                },
            },
        });
    }

    async handleModalClose() {
        this.setState({
            isModalOpen: false,
        });
        this.projectManager.resetCreateUpdate();
        this.serviceManager.resetCreateUpdate();
        await this.projectManager.getProjectCategories();
    }

    async handleFilterChange() {
        PollingUtil.unregister('projects');
        this.pollProjects(0, 10);
    }

    async handleClearClick(e: React.MouseEvent<HTMLButtonElement>) {
        e.stopPropagation();
        if (this.props.filters.status) {
            await this.queueManager.queue(
                QueueKeyType.DEFAULT,
                this.projectManager.search({
                    size: this.props.pagination.size,
                    page: this.props.pagination.page,
                    filter: {
                        published: KeycloakUtil.isLoggedIn() ? undefined : true,
                        ...this.props.filters,
                    },
                }),
            );
        } else {
            await this.queueManager.queue(
                QueueKeyType.DEFAULT,
                this.projectManager.search({
                    size: this.props.pagination.size,
                    page: this.props.pagination.page,
                    filter: {
                        published: KeycloakUtil.isLoggedIn() ? undefined : true,
                        name: this.props.filters.name,
                        creatorName: this.props.filters.creatorName,
                    },
                }),
            );
        }
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectCategories());
    }

    async handlePublicationClick(e: React.MouseEvent<HTMLHyperlinkElementUtils>, id: string, wizardPublication: string[], currentPage: ProvisioningPageEnum, index: number) {
        e.stopPropagation();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(id));
        this.provisioningManager.setSteps(wizardPublication);
        this.provisioningManager.setCurrentPage(currentPage);
        this.provisioningManager.setOrigin(ProvisioningOriginEnum.PROJECT);
        this.navigationManager.navigateTo(wizardPublication[index ? index : 0] as Paths);
    }

    async handleOnPaginationChange(page: number, size: number, total: number) {
        PollingUtil.unregister('projects');
        this.pollProjects(page, size);
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.setPagination(page, size, total));
    }

    async handleDownloadRepoClick(e: React.MouseEvent<HTMLHyperlinkElementUtils>, projectId: string, zipname: string) {
        e.stopPropagation();
        const response = await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.downloadRepo(projectId, zipname));
        const href = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${zipname}.zip`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    async handleArchiveClick(e: React.MouseEvent<HTMLHyperlinkElementUtils>, projectId: string) {
        e.stopPropagation();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.archive(projectId));
        await this.queueManager.queue(
            QueueKeyType.DEFAULT,
            this.projectManager.search({
                page: this.props.pagination.page,
                size: this.props.pagination.size,
                filter: {
                    published: KeycloakUtil.isLoggedIn() ? undefined : true,
                    ...this.props.filters,
                },
            }),
        );
    }

    async handleDeleteProject(e: React.MouseEvent<HTMLHyperlinkElementUtils>, projectId: string) {
        e.stopPropagation();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.delete(projectId));
        await this.queueManager.queue(
            QueueKeyType.DEFAULT,
            this.projectManager.search({
                page: this.props.pagination.page,
                size: this.props.pagination.size,
                filter: {
                    published: KeycloakUtil.isLoggedIn() ? undefined : true,
                    ...this.props.filters,
                },
            }),
        );
    }

    handleDeleteClick(e: React.MouseEvent<HTMLHyperlinkElementUtils>, projectId: string) {
        e.stopPropagation();
        this.popupManager.show({
            type: PopupType.Info,
            title: this.translator.get('project.popup.delete.project.title'),
            description: this.translator.get('project.popup.delete.project.description'),
            maxWidth: 'lg',
            stylingProp: 'popup-display',
            ctas: {
                primary: {
                    label: this.translator.get('project.popup.delete.project.button'),
                    className: 'popup-accept-button',
                    cta: () => this.handleDeleteProject(e, projectId),
                },
                secondary: {
                    label: this.translator.get('common.button.cancel.label'),
                    className: 'popup-accept-button',
                    cta: () => this.popupManager.hide(),
                },
            },
        });
    }

    render() {
        const { user, templates, filters, serviceCreateUpdate } = this.props;
        const { page, size, total } = this.props.pagination;
        const tableColumns = [
            this.translator.get('project.table.header.title.text'),
            this.translator.get('project.table.header.version.text'),
            this.translator.get('project.table.header.language.text'),
            this.translator.get('project.table.header.category.text'),
            this.translator.get('project.table.header.status.text'),
            this.translator.get('project.table.header.published.text'),
            '',
        ];

        const tableRows = this.props.projects?.map((item: Project, index) => {
            return {
                id: item.id as string,
                values: {
                    title: item.name,
                    version: item.version,
                    programmingLanguage: this.props.templates.find((template: BaseProjectTemplate) => {
                        return template?.id == item.technology;
                    })?.name,
                    category: <div className='td-category-size'>{item.categories?.join(', ')}</div>,
                    status: (
                        <div className='chip chip-simple bg-secondary m-1'>
                            <span className='chip-label text-white mt-1 chip-status-container'>{item.status?.replace(/_/g, ' ')}</span>
                        </div>
                    ),
                    published: (
                        <div className='published-column'>
                            {item.project_published ? this.translator.get('project.table.column.published.text') : this.translator.get('project.table.column.not.published.text')}
                        </div>
                    ),
                },
                collapsedRow: (
                    <>
                        <div className='description table-padding'>{FormatUtil.truncateText(item.description as string)}</div>
                        <div className='d-flex justify-content-space-between w-100 table-padding'>
                            <div className='creator-container'>
                                <div className='d-flex'>
                                    <div className='creator-label-container'>Creator: </div>
                                    <div className='creator-content-container'>{`${item.author?.name} (${item.author?.email})`}</div>
                                </div>
                            </div>
                            {item.status !== ProjectStatusEnum.TO_BE_ARCHIVED && item.status !== ProjectStatusEnum.FORKING && (
                                <div className='action-container'>
                                    {(item.status === ProjectStatusEnum.LOADING || item.status === ProjectStatusEnum.STOPPING) && (
                                        <div className='d-flex align-items-center me-4'>
                                            <div className='input-loader'></div>
                                        </div>
                                    )}
                                    {KeycloakUtil.isLoggedIn() &&
                                        !item?.script_published &&
                                        item?.service_publish_date === null &&
                                        (item?.author?.email === KeycloakUtil.getEmail() ||
                                            KeycloakUtil.hasRole([KeycloakRoleEnum.reviewer]) ||
                                            KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                            item?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())) && (
                                            <StyledTooltip trigger='hover' position='top' labelKey={this.translator.get('common.open.button.tooltip')}>
                                                <div>
                                                    <button
                                                        className='btn btn-primary text-white action-item btn-open'
                                                        id={item.id}
                                                        onClick={(e: React.MouseEvent<HTMLElement>) => this.handleOpenClick(e)}
                                                        disabled={item?.status === ProjectStatusEnum.RUNNING ? false : true}>
                                                        {this.translator.get('common.open.button.text')}
                                                    </button>
                                                </div>
                                            </StyledTooltip>
                                        )}
                                    <div className='d-flex flex-row action-item'>
                                        {item?.status === ProjectStatusEnum.READY ||
                                        item?.status === ProjectStatusEnum.STOPPED ||
                                        item?.status === ProjectStatusEnum.ARCHIVED ||
                                        item?.status === ProjectStatusEnum.FAILED
                                            ? !item?.script_published &&
                                              item?.service_publish_date === null &&
                                              KeycloakUtil.isLoggedIn() &&
                                              (item?.author?.email === KeycloakUtil.getEmail() ||
                                                  KeycloakUtil.hasRole([KeycloakRoleEnum.reviewer]) ||
                                                  KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                                  item?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())) && (
                                                  <StyledTooltip trigger='hover' position='top' labelKey={this.translator.get('common.start.button.tooltip')}>
                                                      <div>
                                                          <button
                                                              className='btn btn-outline-primary d-flex flex-row align-items-center  start-stop-button'
                                                              id={item?.id}
                                                              onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handleStartClick(e)}>
                                                              <>
                                                                  {this.translator.get('common.start.button.text')}
                                                                  <Play className='ms-2 icon' />
                                                              </>
                                                          </button>
                                                      </div>
                                                  </StyledTooltip>
                                              )
                                            : !item.script_published &&
                                              item.service_publish_date === null &&
                                              KeycloakUtil.isLoggedIn() &&
                                              (item?.author?.email === KeycloakUtil.getEmail() ||
                                                  KeycloakUtil.hasRole([KeycloakRoleEnum.reviewer]) ||
                                                  KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                                  item?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())) && (
                                                  <button
                                                      className='btn btn-outline-primary d-flex flex-row align-items-center  start-stop-button'
                                                      id={item?.id}
                                                      disabled={item?.status === ProjectStatusEnum.RUNNING ? false : true}
                                                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.handleStopClick(e, false)}>
                                                      <>
                                                          {this.translator.get('common.stop.button.text')}
                                                          <Stop className='ms-2 icon' fill='##009138' />
                                                      </>
                                                  </button>
                                              )}

                                        <div className='btn-group'>
                                            {!item.script_published &&
                                                item.service_publish_date === null &&
                                                KeycloakUtil.isLoggedIn() &&
                                                (item?.author?.email === KeycloakUtil.getEmail() ||
                                                    KeycloakUtil.hasRole([KeycloakRoleEnum.reviewer]) ||
                                                    KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                                    item?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())) && (
                                                    <button
                                                        className='btn btn-outline-primary d-flex flex-row  more-action-button'
                                                        type='button'
                                                        data-bs-toggle='dropdown'
                                                        aria-controls='dropdown'
                                                        aria-haspopup='true'
                                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                            e.stopPropagation();
                                                            this.handleDropdownClose(item.id as string, false);
                                                        }}
                                                        aria-expanded='false'>
                                                        <MoreAction className='more-action-icon-button' fill='#009102' />
                                                    </button>
                                                )}
                                            <div
                                                className={`dropdown-menu more-action-dropdown w-75 ${`moreActionMenu${item.id}` === this.state.openedMenu && 'show'}`}
                                                ref={this.dropdownRef}
                                                id={`moreActionMenu${item.id}`}
                                                tabIndex={1}>
                                                {this.state.isAddingTimeout ? (
                                                    <div className='timeout-input-container d-flex flex-column'>
                                                        <div className=' d-flex flex-row align-items-end'>
                                                            <div className='form-group mt-3 mb-2 '>
                                                                <label htmlFor='creator' className='active label-color'>
                                                                    {this.translator.get('project.timeout.label')}
                                                                </label>
                                                                <TextField
                                                                    type='number'
                                                                    key={'timeout'}
                                                                    placeholder={this.translator.get('project.timeout.placeholder')}
                                                                    defaultValue={this.state.timeoutValue !== 0 ? this.state.timeoutValue : 0}
                                                                    className='form-control'
                                                                    onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                                                                        e.stopPropagation();
                                                                    }}
                                                                    onBlur={(e: any) =>
                                                                        this.setState({
                                                                            timeoutValue: e.target.value,
                                                                        })
                                                                    }
                                                                    name='timeout'
                                                                />
                                                            </div>
                                                            <div className='d-flex flex-column'>
                                                                <button
                                                                    className='btn pb-1'
                                                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                                        e.stopPropagation();
                                                                        this.setState({
                                                                            isAddingTimeout: false,
                                                                            timeoutValue: 0,
                                                                        });
                                                                    }}>
                                                                    <Back className='back-icon' />
                                                                </button>
                                                                <button
                                                                    className='btn mb-1 btn btn-primary btn-save'
                                                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                                        e.stopPropagation();
                                                                        this.setState({
                                                                            isAddingTimeout: false,
                                                                        });
                                                                    }}>
                                                                    {this.translator.get('project.detail.save.button.text')}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className='link-list-wrapper'>
                                                        <ul className='link-list'>
                                                            {(item?.status === ProjectStatusEnum.LOADING ||
                                                                item?.status === ProjectStatusEnum.RUNNING ||
                                                                item?.status === ProjectStatusEnum.STOPPING) && (
                                                                <li>
                                                                    <a
                                                                        className='dropdown-item list-item'
                                                                        id={item.id}
                                                                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) => this.handleForceStopClick(e, item.id as string)}>
                                                                        <span>{this.translator.get('project.more.action.forced.stop.text')}</span>
                                                                    </a>
                                                                </li>
                                                            )}
                                                            {!(item?.status === ProjectStatusEnum.LOADING || item?.status === ProjectStatusEnum.RUNNING) && (
                                                                <li>
                                                                    <a
                                                                        className='dropdown-item list-item set-timeout-container'
                                                                        onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) => {
                                                                            e.stopPropagation();
                                                                            this.setState({
                                                                                isAddingTimeout: true,
                                                                            });
                                                                        }}>
                                                                        <span>{this.translator.get('project.more.action.set.timeout.text')}</span>
                                                                    </a>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {((!item.script_published &&
                                        item.service_publish_date === null &&
                                        KeycloakUtil.isLoggedIn() &&
                                        (item.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin]))) ||
                                        (item?.project_published && KeycloakUtil.isLoggedIn())) && (
                                        <div className='btn-group'>
                                            <button
                                                className='btn btn-outline-primary publish-container d-flex flex-row'
                                                type='button'
                                                data-bs-toggle='dropdown'
                                                aria-controls='dropdown'
                                                aria-haspopup='true'
                                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                    e.stopPropagation();
                                                    this.handleDropdownClose(item.id as string, true);
                                                }}
                                                aria-expanded='false'>
                                                {this.translator.get('common.button.action.label')}
                                                <ArrowDown className='arrow-down icon' fill='#009138' />
                                                <ArrowUp className='arrow-up icon' fill='#009138' />
                                            </button>
                                            <div className={`dropdown-menu ${`actionMenu${item.id}` === this.state.openedMenu && 'show'}`} id={`actionMenu${item.id}`} tabIndex={2}>
                                                <div className='link-list-wrapper'>
                                                    <ul className='link-list'>
                                                        {((!item.script_published &&
                                                            item.service_publish_date === null &&
                                                            item.status !== ProjectStatusEnum.RUNNING &&
                                                            item.status !== ProjectStatusEnum.STOPPING &&
                                                            (item.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin]))) ||
                                                            (item?.project_published && KeycloakUtil.isLoggedIn())) && (
                                                            <li>
                                                                <a
                                                                    className='dropdown-item list-item'
                                                                    href='#'
                                                                    data-bs-toggle='modal'
                                                                    data-bs-target='#cloneNewProject'
                                                                    id={item.id}
                                                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                                        this.handleCloneClick(e);
                                                                    }}>
                                                                    <span>{this.translator.get('project.table.action.clone.project.text')}</span>
                                                                </a>
                                                            </li>
                                                        )}
                                                        {(item.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) &&
                                                            item.status !== ProjectStatusEnum.RUNNING &&
                                                            item.status !== ProjectStatusEnum.STOPPING &&
                                                            !item.service_publish_date &&
                                                            !item.id_reference_service && (
                                                                <li>
                                                                    <a
                                                                        className='dropdown-item list-item'
                                                                        href='#'
                                                                        data-bs-toggle='modal'
                                                                        data-bs-target='#createServiceModal'
                                                                        id={item.id}
                                                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                                            this.handleCreateServiceClick(e);
                                                                        }}>
                                                                        <span>{this.translator.get('project.table.action.create.service.text')}</span>
                                                                    </a>
                                                                </li>
                                                            )}
                                                        {(item.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) && (
                                                            <li>
                                                                <a
                                                                    className='dropdown-item list-item'
                                                                    onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) =>
                                                                        this.handlePublicationClick(
                                                                            e,
                                                                            item.id as string,
                                                                            wizardPublicationDataset,
                                                                            ProvisioningPageEnum.DATASET,
                                                                            item.provisioningPageIndex?.datasetIndex as number,
                                                                        )
                                                                    }>
                                                                    <span>{this.translator.get('project.table.action.pubblication.dataset.text')}</span>
                                                                </a>
                                                            </li>
                                                        )}
                                                        {!item?.project_published &&
                                                            item.status !== ProjectStatusEnum.RUNNING &&
                                                            item.status !== ProjectStatusEnum.LOADING &&
                                                            item.status !== ProjectStatusEnum.STOPPING &&
                                                            (item.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) && (
                                                                <li>
                                                                    <a
                                                                        className='dropdown-item list-item'
                                                                        onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) =>
                                                                            this.handlePublicationClick(
                                                                                e,
                                                                                item.id as string,
                                                                                wizardPublicationProject,
                                                                                ProvisioningPageEnum.PROJECT,
                                                                                item.provisioningPageIndex?.projectIndex as number,
                                                                            )
                                                                        }>
                                                                        <span>{this.translator.get('project.table.action.pubblication.project.text')}</span>
                                                                    </a>
                                                                </li>
                                                            )}
                                                        {(item.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) &&
                                                            item.status !== ProjectStatusEnum.RUNNING &&
                                                            item.status !== ProjectStatusEnum.LOADING &&
                                                            item.status !== ProjectStatusEnum.STOPPING &&
                                                            !item.script_published && (
                                                                <li>
                                                                    <a
                                                                        className='dropdown-item list-item'
                                                                        onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) =>
                                                                            this.handlePublicationClick(
                                                                                e,
                                                                                item.id as string,
                                                                                wizardPublicationScript,
                                                                                ProvisioningPageEnum.SCRIPTS,
                                                                                item.provisioningPageIndex?.scriptIndex as number,
                                                                            )
                                                                        }>
                                                                        <span>{this.translator.get('project.table.action.pubblication.script.text')}</span>
                                                                    </a>
                                                                </li>
                                                            )}
                                                        {(item.project_published || item.script_published) && (
                                                            <li>
                                                                <a
                                                                    className='dropdown-item list-item'
                                                                    id={item.id}
                                                                    onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) =>
                                                                        this.handleDownloadRepoClick(e, item.id as string, item.name as string)
                                                                    }>
                                                                    <span>{this.translator.get('project.table.action.download.project.text')}</span>
                                                                </a>
                                                            </li>
                                                        )}
                                                        {(item.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) &&
                                                            item?.status !== ProjectStatusEnum.ARCHIVED && (
                                                                <li>
                                                                    <a
                                                                        className='dropdown-item list-item'
                                                                        id={item.id}
                                                                        onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) => this.handleArchiveClick(e, item.id as string)}>
                                                                        <span>{this.translator.get('project.table.action.archive.project.text')}</span>
                                                                    </a>
                                                                </li>
                                                            )}

                                                        {(item.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) && (
                                                            <li>
                                                                <a
                                                                    className='dropdown-item list-item'
                                                                    id={item.id}
                                                                    onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) => this.handleDeleteClick(e, item.id as string)}>
                                                                    <span>{this.translator.get('project.table.action.delete.project.text')}</span>
                                                                </a>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </>
                ),
            };
        });

        return (
            <>
                <div className='container-fluid'>
                    <div className='row header-title-container pb-0'>
                        <p className='header-title col-12 mb-4'>{this.translator.get('project.view.title')}</p>
                        <Breadcrumb secondaryPages={[this.translator.get('project.breadcrumb.secondary.item')]} />
                    </div>
                    <div className='row home-title-container '>
                        <h5 className='title-description col-12'>{this.translator.get('project.title.description.part.one')}</h5>
                        <h5 className='title-description col-12'>{this.translator.get('project.title.description.part.two')}</h5>
                    </div>
                    <div className='row'>
                        <div className='col-12 p-0'>
                            <FilterBarProjectView
                                filters={filters}
                                onFilterChange={this.handleFilterChange}
                                clearFilters={this.handleClearClick}
                                availableCategoryOptions={this.props.availableCategoryOptions}
                                pagination={this.props.pagination}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='d-flex justify-content-between align-items-center p-2'>
                                <p className='tableTitle mb-3 mt-4'>{this.translator.get('project.table.title')}</p>
                                {KeycloakUtil.isLoggedIn() && (
                                    <button
                                        className='btn btn-primary text-white d-flex align-items-center btn-create-padding'
                                        data-bs-toggle='modal'
                                        data-bs-target='#createNewProject'
                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                            this.handleCreateClick(e);
                                        }}>
                                        {this.translator.get('project.create.button.text')}
                                        <Plus className='icon ms-1' fill='white' />
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <div className='project-table-container'>
                                <div className='size-tab'>
                                    <Table
                                        columns={tableColumns}
                                        rows={tableRows}
                                        isStyledHeaders={true}
                                        onRowClick={this.handleTableRowClick}
                                        keyLabel='project'
                                        handleRowExpansion={this.handleClickOutsideDropdown}
                                    />
                                </div>
                                {<Paginator onChange={this.handleOnPaginationChange} page={page} size={size} total={total as number} />}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className='modal fade scrollable'
                    role='dialog'
                    id='createNewProject'
                    aria-labelledby='createNewProjectTitle'
                    data-bs-backdrop='static'
                    data-bs-keyboard='false'>
                    <div className='modal-dialog modal-xl' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h2 className='modal-title h5 no_toc' id='createNewProjectTitle'>
                                    {this.translator.get('project.create.modal.title')}
                                </h2>
                                <button
                                    className='btn-close'
                                    type='button'
                                    data-bs-dismiss='modal'
                                    aria-label='Close modal'
                                    id='createModalBtnClose'
                                    onClick={this.handleModalClose}
                                />
                            </div>
                            <div className='modal-body'>
                                <CreateFormProjectView
                                    user={user}
                                    technologies={templates}
                                    onBlur={this.handleOnChange}
                                    onChange={this.handleOnChange}
                                    onCreate={this.handleCreateProject}
                                    isModalClosed={this.state.isModalOpen}
                                    availableCategoryOptions={this.props.availableCategoryOptions.filter((item: string) => item !== 'ALL')}
                                    projectCreate={this.props.createUpdate}
                                />
                            </div>
                            <div className='modal-footer modal-footer-shadow'></div>
                        </div>
                    </div>
                </div>

                <div
                    className='modal fade scrollable'
                    role='dialog'
                    id='cloneNewProject'
                    aria-labelledby='cloneNewProjectTitle'
                    ref={this.ref}
                    data-bs-backdrop='static'
                    data-bs-keyboard='false'>
                    <div className='modal-dialog modal-xl' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h2 className='modal-title h5 no_toc' id='cloneNewProjectTitle'>
                                    {this.translator.get('project.modal.clone.title')}
                                </h2>
                                <button className='btn-close' type='button' data-bs-dismiss='modal' aria-label='Close modal' id='modalBtnClose' onClick={this.handleModalClose} />
                            </div>
                            <div className='modal-body'>
                                <ProjectCloneFormView
                                    user={user}
                                    project={this.props.project}
                                    onBlur={this.handleOnChange}
                                    onClone={this.handleCloneProject}
                                    isModalClosed={this.state.isModalOpen}
                                    templates={this.props.templates}
                                    availableCategoryOptions={this.props.availableCategoryOptions.filter((item: string) => item !== 'ALL')}
                                    projectCreate={this.props.createUpdate}
                                />
                            </div>
                            <div className='modal-footer modal-footer-shadow'></div>
                        </div>
                    </div>
                </div>

                <div
                    className='modal fade scrollable'
                    role='dialog'
                    id='createServiceModal'
                    aria-labelledby='createNewServiceTitle'
                    data-bs-backdrop='static'
                    data-bs-keyboard='false'>
                    <div className='modal-dialog modal-xl' role='document'>
                        <div className='modal-content '>
                            <div className='modal-header'>
                                <h2 className='modal-title h5 no_toc' id='createNewServiceTitle'>
                                    {this.translator.get('project.create.service.modal.title')}
                                </h2>
                                <button
                                    className='btn-close'
                                    type='button'
                                    data-bs-dismiss='modal'
                                    aria-label='Close modal'
                                    id='serviceCreateModalBtnClose'
                                    onClick={this.handleModalClose}
                                />
                            </div>
                            <div className='modal-body'>
                                <ServiceCreateFormComponent
                                    user={user}
                                    technologies={templates}
                                    onBlur={this.handleOnServiceChange}
                                    onChange={this.handleOnServiceChange}
                                    onCreate={this.handleCreateService}
                                    isModalClosed={this.state.isModalOpen}
                                    project={this.props.project}
                                    availableCategoryOptions={this.props.availableCategoryOptions.filter((item: string) => item !== 'ALL')}
                                    serviceCreate={serviceCreateUpdate}
                                />
                            </div>
                            <div className='modal-footer modal-footer-shadow'></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
