import { PopupType } from '@features/_shared/components/Popup/popup.item';
import { NavigationManager } from '@managers/navigation.manager';
import { PopupManager } from '@managers/popup.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import { Paths } from '@routes';
import React, { useEffect, useState } from 'react';
import { BaseProjectTemplate, Project, WebService } from 'src/api';
import '../styles/provisioning.metadata.component.scss';
import { ProvisioningOriginEnum } from '../views/provisioning.view';

export interface IProvisioningMetadataComponentProps {
    service?: WebService;
    templates?: Array<BaseProjectTemplate>;
    project?: Project;
    origin?: ProvisioningOriginEnum;
    onMetadatactionSuccess?: () => void;
}

export const ProvisioningMetadataComponent: React.FC<IProvisioningMetadataComponentProps> = ({ service, templates, project, origin, onMetadatactionSuccess }) => {
    const [url, setUrl] = useState<string | undefined>(undefined);
    const navigationManager = NavigationManager.getInstance();
    const popupManager = PopupManager.getInstance();
    const translator = TranslatorManager.getInstance();
    const element = document.querySelector('script[id="injected-integration-belinda-publish"]');
    const iframeURL = element?.textContent!;

    (window as any).handleOnSuccess = () => {
        onMetadatactionSuccess?.();
    };

    (window as any).handleOnError = () => {
        const redirectionPath =
            origin === ProvisioningOriginEnum.PROJECT || origin === ProvisioningOriginEnum.PROJECTDETAIL ? `/project/${project?.id}` : `/service/${service?.id}`;
        popupManager.show({
            type: PopupType.Info,
            title: translator.get('provisioning.popup.error.publication.title'),
            description: translator.get('provisioning.popup.error.publication.description'),
            maxWidth: 'lg',
            stylingProp: 'popup-display',
            ctas: {
                primary: {
                    label: translator.get('popup.menu.ok.title'),
                    className: 'popup-accept-button',
                    cta: async () => {
                        popupManager.hide();
                        navigationManager.navigateTo(redirectionPath as Paths);
                    },
                },
            },
        });
    };

    (window as any).handleOnCancel = () => {
        const redirectionPath =
            origin === ProvisioningOriginEnum.PROJECT || origin === ProvisioningOriginEnum.PROJECTDETAIL ? `/project/${project?.id}` : `/service/${service?.id}`;
        navigationManager.navigateTo(redirectionPath as Paths);
    };

    useEffect(() => {
        if (origin === ProvisioningOriginEnum.PROJECT || origin === ProvisioningOriginEnum.PROJECTDETAIL) {
            const payload = {
                title: project?.name,
                serviceStatus: project?.status,
                programmingLanguage: templates?.find((template: BaseProjectTemplate) => {
                    return template?.id == project?.technology;
                })?.name,
                date: project?.created_at,
                dateType: 'Creation',
                abstractField: project?.description,
                status: project?.status,
                version: project?.version,
                keywords: project?.keywords,

                service: {
                    srvClassification: {
                        serviceCategory: project?.categories,
                        serviceLanguage: templates?.find((template: BaseProjectTemplate) => {
                            return template?.id == project?.technology;
                        })?.name,
                    },

                    srvOtherInformation: {
                        serviceHelpdesk: project?.helpdesk,
                        serviceTraining: project?.training ? [project?.training] : null,
                    },
                },
            };

            const encodedPayload = encodeURI(JSON.stringify(payload));
            const encodedUrl = encodeURI('/geonetwork/srv/eng/catalog.edit#/create-external?');
            const templateId = document.querySelector('script[id="injected-integration-belinda-script-template-id"]')?.textContent!.toString();
            const encodedQueryParams = encodeURI(`embedder=becode&embedderid=${project?.id}&belinda_template_id=${templateId}&payload=`);
            const url = `${iframeURL}/geonetwork/signin?redirectUrl=${encodedUrl}${encodedQueryParams}${encodedPayload}`;

            setUrl(url);
        } else {
            const payload = {
                title: service?.name,
                serviceStatus: service?.status,
                programmingLanguage: templates?.find((template: BaseProjectTemplate) => {
                    return template?.id == service?.technology;
                })?.name,
                date: service?.created_at,
                dateType: 'Creation',
                abstractField: service?.description,
                status: service?.status,
                version: service?.version,
                keywords: service?.keywords,

                service: {
                    srvClassification: {
                        serviceCategory: service?.categories,
                        serviceLanguage: templates?.find((template: BaseProjectTemplate) => {
                            return template?.id == service?.technology;
                        })?.name,
                    },

                    srvOtherInformation: {
                        serviceHelpdesk: service?.helpdesk,
                        serviceTraining: service?.training,
                    },
                },
            };
            const encodedPayload = encodeURI(JSON.stringify(payload));
            const encodedUrl = encodeURI('/geonetwork/srv/eng/catalog.edit#/create-external?');
            const templateId = document.querySelector('script[id="injected-integration-belinda-service-template-id"]')?.textContent!.toString();
            const encodedQueryParams = encodeURI(`embedder=becode&embedderid=${service?.id}&belinda_template_id=${templateId}&payload=`);
            const url = `${iframeURL}/geonetwork/signin?redirectUrl=${encodedUrl}${encodedQueryParams}${encodedPayload}`;
            setUrl(url);
        }
    });

    return (
        <>
            <div className='provisioning-ide-component'>{url && <iframe src={url} style={{ width: 'inherit', height: 'inherit' }} />}</div>
        </>
    );
};
