import { IsEmail, IsNotEmpty } from 'class-validator';

export class ProjectAddTeamMember {
    @IsEmail()
    @IsNotEmpty()
    collaboratorEmail: string;

    @IsNotEmpty()
    collaboratorName: string;
}
