import React, { useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Info from '../../../../_shared/assets/svg/it-check.svg';
import { ReactComponent as Error } from '../../../../_shared/assets/svg/it-warning-circle.svg';
import { PopupManager } from '../../../../_shared/managers/popup.manager';

export type PopupItemButton = Partial<{
    label: string;
    cta: () => void;
    hide: boolean;
    className: string;
}>;

export enum PopupType {
    Error,
    Info,
}

export type PopupItemCTA = Partial<{
    primary: PopupItemButton | boolean;
    secondary: PopupItemButton | boolean;
    close: PopupItemButton | boolean;
}>;

const Icons = {
    [PopupType.Error]: Error,
    [PopupType.Info]: Info,
};

export interface PopupItemProps {
    type: PopupType;
    title?: string;
    closeOnClickOutside?: boolean;
    children?: JSX.Element | React.ReactNode;
    description?: string;
    ctas?: PopupItemCTA;
    maxWidth?: 'sm' | 'lg' | 'xl' | undefined;
    stylingProp?: string;
    dialogActionStyle?: string;
    close?: boolean;
}

const PopupItem: React.FC<PopupItemProps> = ({ type, title, description, maxWidth, ctas = {}, stylingProp, dialogActionStyle, closeOnClickOutside, close, children }) => {
    const primary = ctas.primary as PopupItemButton;
    const popupManager = PopupManager.getInstance();
    const ref = useRef<HTMLDivElement>(null);
    const secondary = ctas.secondary as PopupItemButton;
    const closed = ctas.close as PopupItemButton;
    const [show, setShow] = useState<boolean>(true);

    const handleClick =
        (callback?: () => void, hide = true) =>
        async () => {
            callback && (await callback());
            hide && PopupManager.getInstance().hide();
            setShow(false);
        };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                popupManager.hide();
            }
        };
        closeOnClickOutside && document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    });

    return (
        <>
            <Modal show={show} backdrop='static' keyboard={false} centered size={maxWidth ? maxWidth : 'lg'}>
                <Modal.Header closeButton style={{ height: '30%' }} onClick={() => PopupManager.getInstance().hide()}>
                    <Modal.Title>
                        <div className='d-flex' style={{ gap: '6%', alignItems: 'center' }}>
                            <Error fill='#009133' height='inherit' width='10%' />
                            {title}
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ whiteSpace: 'pre-line' }}>{description}</Modal.Body>
                <Modal.Footer>
                    {secondary && (
                        <Button variant='outline-primary' onClick={handleClick(secondary.cta, secondary.hide)} color='primary'>
                            {secondary.label ? secondary.label : 'Cancel'}
                        </Button>
                    )}
                    <Button variant='primary' style={{ color: '#FFFFFF' }} onClick={handleClick(primary.cta, primary.hide)}>
                        {primary.label ? primary.label : 'OK'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default PopupItem;
