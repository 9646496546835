import { ReactComponent as Delete } from '@assets/svg/it-close.svg';
import { ReactComponent as ArrowDown } from '@assets/svg/it-expand.svg';
import { ServiceCategoryEnum } from '@features/service/store/state';
import React, { useRef, useState } from 'react';
import { usePopper } from 'react-popper';
import './styles/select.multiple.component.style.scss';

export interface ISelectMultipleComponentProps {
    options: Array<string>;
    onChange: (value: Array<any>) => void;
    handleUnselectAll: (e?: React.MouseEvent<HTMLButtonElement>) => void;
    value?: Array<string>;
    placeholder?: string;
    availableValues: Array<any> | undefined;
    disabled?: boolean;
    isFilterbar?: boolean;
    isDeleteShow?: boolean;
}
export const SelectMultipleComponent: React.FC<ISelectMultipleComponentProps> = ({
    options,
    onChange,
    value,
    placeholder,
    availableValues,
    handleUnselectAll,
    disabled,
    isFilterbar,
    isDeleteShow = true,
}) => {
    const [isOpenDropdown, setIsOpenDropdown] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const dropdownToggleRef = useRef<HTMLDivElement>(null);
    const { attributes, update } = usePopper(dropdownToggleRef.current, dropdownRef.current, { placement: 'bottom-start' });

    const handleSelectItem = (e: React.MouseEvent<HTMLHyperlinkElementUtils>, item: ServiceCategoryEnum | string) => {
        e.stopPropagation();
        if (item !== 'ALL') {
            const currentValues = value ? (value?.filter(currentValue => currentValue !== 'ALL') as Array<ServiceCategoryEnum | string>) : [];
            onChange([...currentValues, item]);
        } else {
            handleUnselectAll();
            setIsOpenDropdown(false);
        }
        handleOpenDropDown(false);
        update?.();
    };

    //TODO: implement if needed
    const handleUnselectItem = (e: React.MouseEvent<HTMLHyperlinkElementUtils>, item: ServiceCategoryEnum | string) => {
        e.stopPropagation();
    };

    const handleOpenDropDown = (openDropdownValue: boolean) => {
        setIsOpenDropdown(openDropdownValue);
    };

    const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsOpenDropdown(false);
        handleUnselectAll(e);
    };

    const isMacOs = window.navigator.userAgent.indexOf('Mac') !== -1;
    const isWindows = window.navigator.userAgent.indexOf('Windows') !== -1;

    return (
        <>
            <div
                tabIndex={0}
                onBlur={() => {
                    handleOpenDropDown(false);
                }}
                className={`multi-select-container  ${disabled ? 'disabled' : ''}`}>
                <div className={`form-control select-multiple-border ${disabled ? 'disabled' : ''}`} ref={dropdownToggleRef}>
                    <div className='d-flex flex-row align-items-center'>
                        <a
                            onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) => {
                                e.stopPropagation();
                                handleOpenDropDown(!isOpenDropdown);
                            }}
                            className='w-100 d-flex flex-row align-items-center selected-items-container'>
                            <div className={`${!disabled && value && !value?.includes('ALL') && isFilterbar ? 'option-wrapper' : 'option-wrapper-full-width'}`}>
                                {value && value.length > 0 ? (
                                    <p className=' m-0 multiple-select-form-control'>{String(value).replace(/,/g, ', ')}</p>
                                ) : (
                                    placeholder && <p className='m-0 multiple-select-form-control'>{placeholder}</p>
                                )}
                            </div>
                            <div className='d-flex flex-row'>
                                {!disabled && value && isDeleteShow && !value.includes('ALL') && (
                                    <button
                                        className='btn p-0 ms-1'
                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                            handleDeleteClick(e);
                                        }}>
                                        <Delete className='icon' />
                                    </button>
                                )}
                                {!disabled && (
                                    <a
                                        className='btn p-0'
                                        onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) => {
                                            e.stopPropagation();
                                            handleOpenDropDown(!isOpenDropdown);
                                        }}>
                                        <ArrowDown className='icon' fill='#009138' />
                                    </a>
                                )}
                            </div>
                        </a>
                    </div>
                </div>
                <div
                    className={`popper ${isMacOs ? 'mac-rounded-border' : isWindows ? 'windows-rounded-border' : ''} ${!isOpenDropdown && 'popper-hidden'}`}
                    ref={dropdownRef}
                    style={{ width: dropdownToggleRef.current?.style.width || undefined }}
                    {...attributes.popper}>
                    <div
                        className={`${
                            isMacOs
                                ? 'mac-multi-select-dropdown-container mac-rounded-border'
                                : isWindows
                                ? 'windows-multi-select-dropdown-container'
                                : 'multi-select-dropdown-container'
                        } w-100`}>
                        <div className='link-list-wrapper'>
                            <ul className='link-list margin-list'>
                                {availableValues?.map((item, index) => (
                                    <li key={index}>
                                        <a
                                            className={`dropdown-item list-item ${index === 0 && isMacOs ? 'first-item' : index === 0 && isWindows ? 'windows-first-item' : ''} ${
                                                index === availableValues.length - 1 && isMacOs
                                                    ? 'last-item'
                                                    : index === availableValues.length - 1 && isWindows
                                                    ? 'windows-last-item'
                                                    : ''
                                            } ${isMacOs ? 'mac-multi-select-option' : isWindows ? 'windows-multi-select-option' : 'multi-select-option'} dropdown-item-background`}
                                            onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) => handleSelectItem(e, item)}>
                                            {item}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
