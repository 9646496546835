import { RequestConfigurationGet, ResponseConfiguration } from '../../../api/entities';
import { BackofficeApiClients, IBackofficeApiClient } from './backoffice.api.client';

export interface IBackofficeApiService {
    getKeys(requestConfigurationGet: RequestConfigurationGet): Promise<ResponseConfiguration>;
}

export class BackofficeApiService implements IBackofficeApiService {
    private static instance: BackofficeApiService;
    private api: IBackofficeApiClient;

    private constructor(basePath: string) {
        this.api = BackofficeApiClients.getInstance(basePath);
    }

    static getInstance(basePath: string) {
        if (BackofficeApiService.instance == null) {
            BackofficeApiService.instance = new BackofficeApiService(basePath);
        }
        return BackofficeApiService.instance;
    }

    async getKeys(requestConfigurationGet: RequestConfigurationGet): Promise<ResponseConfiguration> {
        return (await this.api.configurator.getKeys(requestConfigurationGet)).data;
    }
}
