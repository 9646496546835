import { Footer } from '@features/_shared/components/Footer';
import LoginHeader from '@features/_shared/components/Header/login.header.component';
import { Loader } from '@features/_shared/components/Loader';
import { NavBar } from '@features/_shared/components/Navbar';
import SideBar from '@features/_shared/components/Navbar/sidebar.component';
import Popup from '@features/_shared/components/Popup/popup.container';
import { ReduxConnect } from '@hypereact/state';
import { NavigationManager } from '@managers/navigation.manager';
import { PRIVATE_ROUTES, PrivateRoutes } from '@routes';
import { RootState } from '@store/state';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import './App.css';

const history = NavigationManager.getInstance().getHistory();
interface IAppProps {
    isLogged?: boolean;
    isLoading?: boolean;
}

@ReduxConnect((state: RootState) => {
    return {
        isLoading: state.shared.loading.global,
    };
})
export class App extends React.Component<IAppProps> {
    render() {
        const isLogged = KeycloakUtil.isLoggedIn();
        return (
            <>
                <Loader isLoading={!!this.props.isLoading} />
                <Popup />
                <div className='app-container d-flex flex-column justify-content-between'>
                    <div className='d-flex flex-row'>
                        <div className='d-none d-lg-block'>
                            <SideBar isLogged={isLogged} />
                        </div>
                        <div className={`app-view-container sidepanel-space`}>
                            <div>
                                <div className='d-lg-none'>
                                    <NavBar isLogged={isLogged} />
                                </div>
                                <LoginHeader />

                                <Router history={history}>
                                    <Switch>
                                        <Route exact path='/' component={() => <Redirect to='/home' />} />
                                        {/* <Route path={PUBLIC_ROUTES.map(({ path }) => path)} component={PublicRoutes} /> */}
                                        <Route path={PRIVATE_ROUTES.map(({ path }) => path)} component={PrivateRoutes} />
                                    </Switch>
                                </Router>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        );
    }
}

export default App;
