import { HomeReducer } from '@features/home/state/reducer';
import { initialHomeState } from '@features/home/state/state';
import { LoginReducer } from '@features/login/store/reducer';
import { initialLoginState } from '@features/login/store/state';
import { MinisiteReducer } from '@features/minisite/store/reducers';
import { initialMinisiteState } from '@features/minisite/store/state';
import { ProjectReducer } from '@features/project/state/reducer';
import { initialProjectState } from '@features/project/state/state';
import { ProvisioningReducer } from '@features/provisioning/store/reducer';
import { initialProvisioningState } from '@features/provisioning/store/state';
import { ServiceReducer } from '@features/service/store/reducer';
import { initialServiceState } from '@features/service/store/state';
import { initialSharedState, initialShellState } from '@features/_shared/store/state';
import { IReduxConfig, ReduceableReducer } from '@hypereact/state';
import { Slices } from './state';

export const reduxConfig: IReduxConfig = {
    [Slices.Login]: new LoginReducer(initialLoginState),
    [Slices.Project]: new ProjectReducer(initialProjectState),
    [Slices.Service]: new ServiceReducer(initialServiceState),
    [Slices.Provisioning]: new ProvisioningReducer(initialProvisioningState),
    [Slices.Minisite]: new MinisiteReducer(initialMinisiteState),
    [Slices.Home]: new HomeReducer(initialHomeState),
    [Slices.Shell]: new ReduceableReducer(initialShellState),
    [Slices.Shared]: new ReduceableReducer(initialSharedState),
};
