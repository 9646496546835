import { ReactComponent as Edit } from '@assets/svg/edit-regular.svg';
import { ReactComponent as CheckCircle } from '@assets/svg/it-check-circle.svg';
import { ReactComponent as CloseCircle } from '@assets/svg/it-close-circle.svg';
import { ReactComponent as Close } from '@assets/svg/it-close.svg';
import { ReactComponent as Error } from '@assets/svg/it-warning-circle.svg';
import { ReactComponent as Publish } from '@assets/svg/publish.svg';
import { Table } from '@components/Table';
import { ListPaginationInterface } from '@features/_shared/components/Paginator/enums/ListPaginationInterface';
import Paginator from '@features/_shared/components/Paginator/paginator.components';
import { SelectMultipleComponent } from '@features/_shared/components/SelectMultiple/SelectMultiple.component';
import { TextArea } from '@features/_shared/components/TextArea';
import { TextField } from '@features/_shared/components/TextField';
import { User } from '@features/login/store/state';
import { ProjectDetailInfoData } from '@features/project/beans/projectDetailInfoData';
import { SaveInfoTypeEnum } from '@features/project/components/project.detail.component';
import { ProvisioningPageEnum } from '@features/provisioning/store/state';
import { wizardPublicationDataset } from '@features/provisioning/utils/constant';
import { ProvisioningOriginEnum } from '@features/provisioning/views/provisioning.view';
import { TranslatorManager } from '@managers/TranslatorManager';
import { FormManager } from '@managers/form.manager';
import { NavigationManager } from '@managers/navigation.manager';
import { ProjectManager } from '@managers/project.manager';
import { ProvisioningManager } from '@managers/provisioning.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { ServiceManager } from '@managers/service.manager';
import { Paths } from '@routes';
import { FormHelperUtil } from '@utils/form.helper.util';
import { FormatUtil } from '@utils/formaters.util';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';
import moment from 'moment';
import { useContext, useState } from 'react';
import { IntlContext } from 'react-intl';
import { InputFile, InputFileTypeEnum, RequestServiceUpdate, ServiceExecution, ServiceExecutionStatusEnum, WebService, WebServiceStatusEnum } from '../../../api';
import { ExecutionState } from '../store/state';
import '../styles/service.detail.style.scss';

export interface AccordionContainerProps {
    service: WebService;
    executionsPagination: ListPaginationInterface;
    execution: ExecutionState;
    createUpdate: WebService;
    user: User;
    onRowClick?(execId: string): void;
    availableCategoryOptions?: string[];
    onBlur?: (key: keyof WebService, value: string | number | boolean | Array<string>) => void;
}

export const AccordionContainer: React.FC<AccordionContainerProps> = ({ executionsPagination, service, onRowClick, execution, createUpdate, availableCategoryOptions, onBlur }) => {
    const translator = TranslatorManager.getInstance();
    const serviceManager = ServiceManager.getInstance();
    const projectManager = ProjectManager.getInstance();
    const provisioningManager = ProvisioningManager.getInstance();
    const navigationManager = NavigationManager.getInstance();
    const queueManager = QueueManager.getInstance();

    const intl = useContext(IntlContext);
    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator('service.detail', intl as any);

    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isEditingInfoAbstract, setIsEditingInfoAbstract] = useState<boolean>(false);
    const [isEditingInfoKeywords, setIsEditingInfoKeywords] = useState<boolean>(false);
    const [isEditingInfoMetadata, setIsEditingInfoMetadata] = useState<boolean>(false);
    const [isEditingInfoCategory, setIsEditingInfoCategory] = useState<boolean>(false);
    const [isEditingInfoTrainingRoom, setIsEditingInfoTrainingRoom] = useState<boolean>(false);
    const [isEditingInfoWorkingGroup, setIsEditingInfoWorkingGroup] = useState<boolean>(false);
    const [isEditingInfoHelpdesk, setIsEditingInfoHelpdesk] = useState<boolean>(false);

    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        //needed for the form manager
    };

    const handleOnSubmit = async (e: React.MouseEvent<HTMLButtonElement>, type: SaveInfoTypeEnum) => {
        let error = true;
        switch (type) {
            case SaveInfoTypeEnum.ABSTRACT: {
                error = await trigger('description');
                break;
            }
            case SaveInfoTypeEnum.KEYWORDS: {
                const isKeywordsValidated = validateKeywords();
                error = error && isKeywordsValidated;
                break;
            }
            case SaveInfoTypeEnum.CATEGORY: {
                const isCategoryValidated = validateCategory();
                error = error && isCategoryValidated;
                break;
            }
            case SaveInfoTypeEnum.METADATA: {
                //TODO: To be implemented
                break;
            }

            case SaveInfoTypeEnum.TRAINING_ROOM: {
                error = await trigger('training');
                break;
            }
            case SaveInfoTypeEnum.WORKING_GROUP: {
                error = await trigger('community');
                break;
            }
            case SaveInfoTypeEnum.HELPDESK: {
                error = await trigger('helpdesk');
                break;
            }
        }

        setIsSubmitted(true);
        if (error) {
            handleSaveInfo(type);
            reset();
            setIsSubmitted(false);
        } else {
            e.preventDefault();
        }
    };

    const handleOnKeywordRemoveClick = (keywordToBeRemoved: string) => {
        if (createUpdate?.keywords) {
            const updatedKeywords: any[] = createUpdate?.keywords.filter(currentKeyword => currentKeyword !== keywordToBeRemoved);
            serviceManager.saveValue('keywords' as keyof WebService, updatedKeywords);
        }
    };

    const handleCategoryChange = async (newValues: Array<string>) => {
        onBlur?.('categories' as keyof WebService, newValues);
        if (newValues.length > 0) {
            clearErrors('categories');
        } else {
            setError('categories', { type: 'isNotEmpty' });
        }
    };

    const handleUnselectAll = async (e?: React.MouseEvent<HTMLButtonElement>) => {
        e && e.preventDefault();
        serviceManager.resetCategoryCreate();
        await serviceManager.getServiceCategories();
    };

    const handleOnAddKeywordButtonClick = async () => {
        const currentKeyword = getValues('keyword');
        const noError = (await trigger('keyword')) && !createUpdate?.keywords?.find((item: string) => item.toLowerCase() === currentKeyword.toLowerCase());
        if (currentKeyword !== '' && noError) {
            const updatedKeywords = createUpdate?.keywords ? [...(createUpdate.keywords as string[]), currentKeyword] : [currentKeyword];
            serviceManager.saveValue('keywords' as keyof WebService, updatedKeywords);
            setValue('keyword', undefined);
            clearErrors('keyword');
        } else if (createUpdate?.keywords?.find((item: string) => item.toLowerCase() === currentKeyword.toLowerCase())) {
            setError('keyword', { type: 'keywordlrdxst' });
        }
    };

    const validateKeywords = () => {
        const isKeywords = createUpdate?.keywords && createUpdate?.keywords.length > 0 ? true : false;

        if (isKeywords) {
            if (isKeywords) {
                clearErrors('keyword');
            }
        } else {
            setError('keyword', { type: 'isNotEmpty' });
        }
        return isKeywords as boolean;
    };

    const validateCategory = () => {
        const isCategory = createUpdate?.categories && createUpdate?.categories.length > 0 ? true : false;

        if (isCategory) {
            clearErrors('categories');
        } else {
            setError('categories', { type: 'isNotEmpty' });
        }
        return isCategory as boolean;
    };

    const handleCreateExecutionClick = async () => {
        const response = await queueManager.queue(
            QueueKeyType.DEFAULT,
            serviceManager.createExecution(service?.id as string, {
                author: {
                    email: KeycloakUtil.getEmail(),
                    name: KeycloakUtil.getName(),
                },
            }),
        );
        navigationManager.navigateTo(`${service.id}/execution/${response.id}` as Paths);
    };

    const triggerValidation = (key: string, value: string) => {
        setValue(key, value);
        (isSubmitted || key === 'keyword') && trigger(key);
    };

    const handleSaveInfo = (type: SaveInfoTypeEnum, e?: React.MouseEvent<HTMLButtonElement>) => {
        e && e.stopPropagation();
        let serviceUpdateRequest: RequestServiceUpdate = {};
        switch (type) {
            case SaveInfoTypeEnum.ABSTRACT: {
                serviceUpdateRequest = { description: createUpdate?.description };
                handleOpenEditInfoAbstract(false);
                break;
            }
            case SaveInfoTypeEnum.KEYWORDS: {
                serviceUpdateRequest = { keywords: createUpdate?.keywords };
                handleOpenEditInfoKeywords(false);

                break;
            }
            case SaveInfoTypeEnum.CATEGORY: {
                serviceUpdateRequest = { categories: createUpdate?.categories };
                handleOpenEditInfoCategory(false);
                break;
            }
            case SaveInfoTypeEnum.METADATA: {
                handleOpenEditInfoMetadata(false);

                //TODO: To be implemented
                break;
            }

            case SaveInfoTypeEnum.TRAINING_ROOM: {
                serviceUpdateRequest = { training: createUpdate?.training ? createUpdate?.training : '' };
                setIsEditingInfoTrainingRoom(false);
                break;
            }

            case SaveInfoTypeEnum.WORKING_GROUP: {
                serviceUpdateRequest = { community: createUpdate?.community ? createUpdate?.community : '' };
                setIsEditingInfoWorkingGroup(false);
                break;
            }

            case SaveInfoTypeEnum.HELPDESK: {
                serviceUpdateRequest = { helpdesk: createUpdate?.helpdesk ? createUpdate.helpdesk : '' };
                setIsEditingInfoHelpdesk(false);
                break;
            }
        }
        service?.id && queueManager.queue(QueueKeyType.DEFAULT, serviceManager.update(service?.id, serviceUpdateRequest));
        handleOpenEditInfoAbstract(false);
    };

    const handleOpenEditInfoAbstract = (isOpen: boolean) => {
        setIsEditingInfoAbstract(isOpen);
    };

    const handleOpenEditInfoKeywords = (isOpen: boolean) => {
        setIsEditingInfoKeywords(isOpen);
    };

    const handleOpenEditInfoCategory = (isOpen: boolean) => {
        setIsEditingInfoCategory(isOpen);
    };

    const handleOpenEditInfoMetadata = (isOpen: boolean) => {
        setIsEditingInfoMetadata(isOpen);
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
        serviceManager.saveValue(e.target.name as keyof WebService, e.target.value);
    };

    const handlePublicationDatasetClick = async (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
        e.stopPropagation();
        const response = await queueManager.queue(QueueKeyType.DEFAULT, serviceManager.getServiceById(id));
        const referenceProject = await queueManager.queue(QueueKeyType.DEFAULT, projectManager.getProjectById(response.id_reference_project as string));
        provisioningManager.setSteps(wizardPublicationDataset);
        provisioningManager.setCurrentPage(ProvisioningPageEnum.DATASET);
        provisioningManager.setOrigin(ProvisioningOriginEnum.SERVICE);
        navigationManager.navigateTo(
            wizardPublicationDataset[referenceProject?.provisioningPageIndex?.datasetIndex ? referenceProject?.provisioningPageIndex?.datasetIndex : 0] as Paths,
        );
    };

    const handleOnExecutionsPaginationChange = async (page: number, size: number, total: number) => {
        service?.id && (await queueManager.queue(QueueKeyType.DEFAULT, serviceManager.setExecutionsPagination(service?.id, page, size, total as number)));
    };

    const handleUploadFromLocal = () => {
        document.getElementById('localUploadModalClose')?.click();
    };

    const executionsColumns = [
        translator.get('service.detail.execution.accordion.table.header.status.text'),
        translator.get('service.detail.execution.accordion.table.header.run.text'),
        translator.get('service.detail.execution.accordion.table.header.startedAt.text'),
        translator.get('service.detail.execution.accordion.table.header.finishedAt.text'),
        translator.get('service.detail.execution.accordion.table.header.executionTime.text'),
        translator.get('service.detail.execution.accordion.table.header.author.text'),
    ];

    const executionsRows =
        execution.executions && Object.keys(execution.executions).length > 0
            ? Object.values(execution.executions).map((execution: ServiceExecution | undefined, index: number) => {
                  return {
                      id: execution?.id,
                      values: {
                          Status:
                              execution?.status === ServiceExecutionStatusEnum.FAILED ? (
                                  <CloseCircle className='icon' fill='#ff0000' />
                              ) : execution?.status === ServiceExecutionStatusEnum.RUNNING || execution?.status === ServiceExecutionStatusEnum.WAIT ? (
                                  <Error className='icon' fill='#FF6600' />
                              ) : (
                                  <CheckCircle className='icon icon-primary' />
                              ),
                          Run: execution?.run,
                          StartedAt: execution?.date_of_started
                              ? moment
                                    .utc(execution?.date_of_started as string)
                                    .local()
                                    .format('DD/MM/YYYY HH:mm')
                              : '-',
                          FinishedAt: execution?.date_of_finished
                              ? moment
                                    .utc(execution?.date_of_finished as string)
                                    .local()
                                    .format('DD/MM/YYYY HH:mm')
                              : '-',
                          ExecutionTime: execution?.execution_time ? moment.utc(moment.duration(execution?.execution_time).asMilliseconds()).format('H[h] m[m] s[s]') : '-',
                          Author: `${execution?.author?.name} (${execution?.author?.email})`,
                      },
                  };
              })
            : [];

    const inputColumns = [
        translator.get('service.detail.input.accordion.table.header.name.text'),
        translator.get('service.detail.input.accordion.table.header.source.text'),
        translator.get('service.detail.input.accordion.table.header.size.text'),
        translator.get('service.detail.input.accordion.table.header.date.text'),
        service?.inputs &&
            Object.values(service?.inputs).find((item: InputFile | undefined) => item?.type === InputFileTypeEnum.LOCAL) &&
            translator.get('service.detail.input.accordion.table.header.action.text'),
    ];

    const inputRows =
        service?.inputs && Object.keys(service?.inputs).length > 0
            ? Object.values(service?.inputs)
                  .filter((item: InputFile | undefined) => !item?.persistent)
                  .map((inputFromProject: InputFile | undefined, index: number) => {
                      return {
                          id: index,
                          values: {
                              fileName: inputFromProject?.filename,
                              source: inputFromProject?.type,
                              size: inputFromProject?.size ? FormatUtil.formatBytes(inputFromProject.size) : '0 Bytes',
                              Date: moment
                                  .utc(inputFromProject?.created_at as string)
                                  .local()
                                  .format('DD/MM/YYYY HH:mm'),
                              action: (
                                  <div className='d-flex flex-row '>
                                      {!inputFromProject?.behub && (
                                          <button
                                              className={`btn action-item ${
                                                  KeycloakUtil.isLoggedIn() && !service?.published && inputFromProject?.type === InputFileTypeEnum.LOCAL ? '' : 'hidden'
                                              }`}
                                              onClick={(e: React.MouseEvent<HTMLButtonElement>) => handlePublicationDatasetClick(e, service?.id as string)}>
                                              <Publish className='icon' fill='#009138' />
                                          </button>
                                      )}
                                  </div>
                              ),
                          },
                      };
                  })
            : [];

    const { errors, register, trigger, setValue, reset, clearErrors, setError, getValues } = FormManager.getInstance().buildFormProxy<ProjectDetailInfoData>(
        ProjectDetailInfoData,
        onSubmit,
    );

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col'>
                        <div className='accordion accordion-background-active border bg-white' id='accordion'>
                            <div className='accordion-item'>
                                <h2 className='accordion-header ' id='heading1c'>
                                    <button
                                        className='accordion-button border'
                                        type='button'
                                        data-bs-toggle='collapse'
                                        data-bs-target='#info'
                                        aria-expanded='true'
                                        aria-controls='info'>
                                        {translator.get('service.detail.info.accordion.header.button.text')}
                                    </button>
                                </h2>
                                <div id='info' className='size-tab collapse show' role='region' aria-labelledby='heading1c'>
                                    <div className='accordion-body'>
                                        <h6 className='d-flex flex-row align-items-center'>
                                            {translator.get('service.detail.info.accordion.abstract.title')}
                                            <p className='requiredSign'>*</p>

                                            {!service?.published &&
                                                (isEditingInfoAbstract ? (
                                                    <button
                                                        className='btn btn-primary btn-save'
                                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnSubmit(e, SaveInfoTypeEnum.ABSTRACT)}>
                                                        {translator.get('project.detail.save.button.text')}
                                                    </button>
                                                ) : (
                                                    <button className='btn' onClick={() => setIsEditingInfoAbstract(true)}>
                                                        <Edit className=' icon-edit' width={10} fill='#009138' />
                                                    </button>
                                                ))}
                                        </h6>
                                        {isEditingInfoAbstract ? (
                                            <div>
                                                <div className='form-group'>
                                                    <TextArea
                                                        isAutoSize={true}
                                                        id='description'
                                                        {...register('description', {
                                                            onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => triggerValidation('description', e.target.value),
                                                        })}
                                                        defaultValue={service?.description}
                                                        onBlur={(e: React.FocusEvent<HTMLTextAreaElement, Element>) => handleOnBlur(e)}
                                                    />
                                                    {errors.description && (
                                                        <small className='form-text error-message'>
                                                            {translator.get(`form.service.detail.description.${errors.description.type}`)}
                                                        </small>
                                                    )}

                                                    <div className='row'></div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <p>{service?.description}</p>
                                            </>
                                        )}

                                        <h6 className='d-flex flex-row align-items-center'>
                                            {translator.get('service.detail.info.accordion.keyword.title')}
                                            <p className='requiredSign'>*</p>

                                            {!service?.published &&
                                                (isEditingInfoKeywords ? (
                                                    <button
                                                        className='btn btn-primary btn-save'
                                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnSubmit(e, SaveInfoTypeEnum.KEYWORDS)}>
                                                        {translator.get('project.detail.save.button.text')}
                                                    </button>
                                                ) : (
                                                    <button className='btn' onClick={() => setIsEditingInfoKeywords(true)}>
                                                        <Edit className=' icon-edit' width={10} fill='#009138' />
                                                    </button>
                                                ))}
                                        </h6>
                                        {isEditingInfoKeywords ? (
                                            <div>
                                                <div className='form-group mb-1'>
                                                    <div className='row'>
                                                        <div className='col-12'>
                                                            <div className='form-group mb-0'>
                                                                <div className='mt-1'>
                                                                    {createUpdate?.keywords &&
                                                                        createUpdate?.keywords.map(keyword => {
                                                                            return (
                                                                                <div className='row'>
                                                                                    <div className='col-12'>
                                                                                        <div className='keyword-container pl-3'>
                                                                                            {keyword}
                                                                                            <button
                                                                                                onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                                                                                    handleOnKeywordRemoveClick(keyword)
                                                                                                }
                                                                                                className='btn btn-primary text-white btn-remove'>
                                                                                                <div className='d-flex'>
                                                                                                    <Close className='icon' fill='white' />
                                                                                                </div>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        })}

                                                                    <TextField
                                                                        type='text'
                                                                        className='form-control'
                                                                        placeholder='Keywords'
                                                                        id='keyword'
                                                                        {...register('keyword', {
                                                                            onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('keyword', e.target.value),
                                                                        })}
                                                                        error={errors.keyword}
                                                                        errorMessage={errors.keyword && getMessageByFieldAndError('keyword', errors)}
                                                                    />

                                                                    <div className='col-12 mb-2 mt-2'>
                                                                        <button
                                                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnAddKeywordButtonClick()}
                                                                            className='btn btn-primary text-white'>
                                                                            {translator.get('project.modal.create.add.keyword.button.text')}
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <p>
                                                    {service?.keywords &&
                                                        service?.keywords.map((keyword, index) => {
                                                            return (
                                                                <>
                                                                    {keyword} {service?.keywords && index < service?.keywords.length - 1 ? ', ' : ''}
                                                                </>
                                                            );
                                                        })}
                                                </p>
                                            </>
                                        )}
                                        <h6 className='d-flex flex-row align-items-center'>
                                            {translator.get('project.detail.info.accordion.category.title')}
                                            <p className='requiredSign'>*</p>

                                            {KeycloakUtil.isLoggedIn() &&
                                                !service?.published &&
                                                (isEditingInfoCategory ? (
                                                    <button
                                                        className='btn btn-primary btn-save'
                                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnSubmit(e, SaveInfoTypeEnum.CATEGORY)}>
                                                        {translator.get('project.detail.save.button.text')}
                                                    </button>
                                                ) : (
                                                    <button className='btn' onClick={() => handleOpenEditInfoCategory(true)}>
                                                        <Edit className=' icon-edit' width={10} fill='#009138' />
                                                    </button>
                                                ))}
                                        </h6>
                                        {isEditingInfoCategory ? (
                                            <div>
                                                <div className='form-group grow-wrap mb-1'>
                                                    <div className='row pt-3'>
                                                        <div className='col-12 category-index'>
                                                            <div className='form-group'>
                                                                <div className='select-wrapper'>
                                                                    <div>
                                                                        <SelectMultipleComponent
                                                                            placeholder={translator.get('service.filterbar.category.placeholder')}
                                                                            options={availableCategoryOptions as string[]}
                                                                            value={createUpdate.categories}
                                                                            {...register('categories')}
                                                                            onChange={handleCategoryChange}
                                                                            availableValues={availableCategoryOptions?.filter(option => option !== 'ALL') as Array<string>}
                                                                            handleUnselectAll={handleUnselectAll}
                                                                        />
                                                                    </div>
                                                                    {errors.categories && (
                                                                        <small className='form-text error-message'>
                                                                            {translator.get(`form.project.create.category.${errors.categories.type}`)}
                                                                        </small>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <p>
                                                    {service?.categories &&
                                                        service?.categories.map((categories, index) => {
                                                            return (
                                                                <>
                                                                    {categories}
                                                                    {service.categories && index < service.categories.length - 1 ? ', ' : ''}
                                                                </>
                                                            );
                                                        })}
                                                </p>
                                            </>
                                        )}
                                        <h6 className={` mt-2 ${!service?.training ? 'mb-4' : ''}`}>
                                            {translator.get('service.detail.accordion.info.training.text')}
                                            {KeycloakUtil.isLoggedIn() &&
                                                !service?.published &&
                                                (isEditingInfoTrainingRoom ? (
                                                    <button
                                                        className='btn btn-primary btn-save'
                                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnSubmit(e, SaveInfoTypeEnum.TRAINING_ROOM)}>
                                                        {translator.get('service.detail.save.button.text')}
                                                    </button>
                                                ) : (
                                                    <button className='btn' onClick={() => setIsEditingInfoTrainingRoom(true)}>
                                                        <Edit className=' icon-edit' width={10} fill='#009138' />
                                                    </button>
                                                ))}
                                        </h6>
                                        {isEditingInfoTrainingRoom ? (
                                            <div>
                                                <div className='form-group grow-wrap'>
                                                    <TextField
                                                        id='training'
                                                        type='text'
                                                        className='form-control'
                                                        {...register('training', {
                                                            onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => triggerValidation('training', e.target.value),
                                                        })}
                                                        defaultValue={service?.training}
                                                        error={errors.training}
                                                        errorMessage={errors.training && getMessageByFieldAndError('training', errors)}
                                                        placeholder={translator.get('service.detail.accordion.info.training.text')}
                                                        onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <a className={!service?.training ? 'pt-4' : ''} href={service?.training} target='_blank' rel='noreferrer'>
                                                    {service?.training}
                                                </a>
                                            </>
                                        )}

                                        <h6 className={` mt-2 ${!service?.community ? 'mb-4' : ''}`}>
                                            {translator.get('service.detail.accordion.info.working.group.text')}
                                            {KeycloakUtil.isLoggedIn() &&
                                                !service?.published &&
                                                (isEditingInfoWorkingGroup ? (
                                                    <button
                                                        className='btn btn-primary btn-save'
                                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnSubmit(e, SaveInfoTypeEnum.WORKING_GROUP)}>
                                                        {translator.get('service.detail.save.button.text')}
                                                    </button>
                                                ) : (
                                                    <button className='btn' onClick={() => setIsEditingInfoWorkingGroup(true)}>
                                                        <Edit className=' icon-edit' width={10} fill='#009138' />
                                                    </button>
                                                ))}
                                        </h6>
                                        {isEditingInfoWorkingGroup ? (
                                            <div>
                                                <div className='form-group grow-wrap'>
                                                    <TextField
                                                        id='community'
                                                        type='text'
                                                        className='form-control'
                                                        {...register('community', {
                                                            onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => triggerValidation('community', e.target.value),
                                                        })}
                                                        defaultValue={service?.community}
                                                        error={errors.community}
                                                        errorMessage={errors.community && getMessageByFieldAndError('community', errors)}
                                                        placeholder={translator.get('service.detail.accordion.info.working.group.text')}
                                                        onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <a className={!service?.community ? 'pt-4' : ''} href={service?.community} target='_blank' rel='noreferrer'>
                                                    {service?.community}
                                                </a>
                                            </>
                                        )}

                                        <h6 className={` mt-2 ${!service?.helpdesk ? 'mb-4' : ''}`}>
                                            {translator.get('service.detail.accordion.info.helpdesk.text')}
                                            {KeycloakUtil.isLoggedIn() &&
                                                !service?.published &&
                                                (isEditingInfoHelpdesk ? (
                                                    <button
                                                        className='btn btn-primary btn-save'
                                                        onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnSubmit(e, SaveInfoTypeEnum.HELPDESK)}>
                                                        {translator.get('service.detail.save.button.text')}
                                                    </button>
                                                ) : (
                                                    <button className='btn' onClick={() => setIsEditingInfoHelpdesk(true)}>
                                                        <Edit className=' icon-edit' width={10} fill='#009138' />
                                                    </button>
                                                ))}
                                        </h6>
                                        {isEditingInfoHelpdesk ? (
                                            <div>
                                                <div className='form-group grow-wrap'>
                                                    <TextField
                                                        id='helpdesk'
                                                        type='text'
                                                        className='form-control'
                                                        {...register('helpdesk', {
                                                            onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => triggerValidation('helpdesk', e.target.value),
                                                        })}
                                                        defaultValue={service?.helpdesk}
                                                        error={errors.helpdesk}
                                                        errorMessage={errors.helpdesk && getMessageByFieldAndError('helpdesk', errors)}
                                                        placeholder={translator.get('service.detail.accordion.info.helpdesk.text')}
                                                        onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                                                    />
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <a className={!service?.helpdesk ? 'pt-4' : ''} href={service?.helpdesk} target='_blank' rel='noreferrer'>
                                                    {service?.helpdesk}
                                                </a>
                                            </>
                                        )}
                                        {/* <h6>{translator.get('service.detail.info.accordion.abstract.metadata.title')}</h6>
                                        <div className='container-info-metadata'>
                                            <div className='info-metadata'>
                                                <th scope='row'>{translator.get('service.detail.info.accordion.abstract.metadata.table.header')}</th>
                                            </div>
                                            <div className='info-value'>
                                                <td>Value</td>
                                            </div>
                                        </div>
                                        <div className='container-info-metadata'>
                                            <div className='info-metadata'>
                                                <th scope='row'>{translator.get('service.detail.info.accordion.abstract.metadata.table.header')}</th>
                                            </div>
                                            <div className='info-value'>
                                                <td>Value</td>
                                            </div>
                                        </div>
                                        <div className='container-info-metadata'>
                                            <div className='info-metadata'>
                                                <th scope='row'>{translator.get('service.detail.info.accordion.abstract.metadata.table.header')}</th>
                                            </div>
                                            <div className='info-value'>
                                                <td>Value</td>
                                            </div>
                                        </div>
                                        <div className='container-info-metadata'>
                                            <div className='info-metadata'>
                                                <th scope='row'>{translator.get('service.detail.info.accordion.abstract.metadata.table.header')}</th>
                                            </div>
                                            <div className='info-value'>
                                                <td>Value</td>
                                            </div>
                                        </div>*/}
                                    </div>
                                    <div className='creator-container'>
                                        <div className='d-flex creator-container-info-accordion'>
                                            <div className='creator-label-container'>Creator: </div>
                                            <div className='creator-content-container'>{`${service?.author?.name} (${service?.author?.email})`}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {KeycloakUtil.isLoggedIn() && (
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='accordion accordion-background-active border bg-white' id='accordion'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            className='accordion-button border collapsed'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#executions'
                                            aria-expanded='false'
                                            aria-controls='executions'>
                                            {translator.get('service.detail.execution.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div id='executions' className='collapse size-tab' role='region' aria-labelledby='executions'>
                                        <div className='accordion-body'>
                                            <div className='executionsAccordion'>
                                                <div className='d-flex justify-content-end mb-4 mt-3'>
                                                    <button
                                                        id='btnCreateExecution'
                                                        className='btn btn-primary w-15 text-white text-decoration-none'
                                                        onClick={handleCreateExecutionClick}
                                                        disabled={
                                                            KeycloakUtil.isLoggedIn() &&
                                                            !KeycloakUtil.hasRole([KeycloakRoleEnum.reviewer]) &&
                                                            (service?.status === WebServiceStatusEnum.TO_BE_PUBLISHED ||
                                                                service?.status === WebServiceStatusEnum.PUBLISHED ||
                                                                service?.status === WebServiceStatusEnum.REJECTED ||
                                                                service?.status === WebServiceStatusEnum.TO_BE_APPROVED)
                                                                ? false
                                                                : true
                                                        }>
                                                        {translator.get('service.detail.exectution.accordion.create.button')}
                                                    </button>
                                                </div>

                                                <Table columns={executionsColumns} rows={executionsRows} onRowClick={onRowClick} keyLabel='executuion' />
                                                {
                                                    <Paginator
                                                        onChange={handleOnExecutionsPaginationChange}
                                                        page={executionsPagination?.page}
                                                        size={executionsPagination?.size}
                                                        total={executionsPagination?.total as number}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {KeycloakUtil.isLoggedIn() && (
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='accordion accordion-background-active border bg-white' id='accordion'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            className='accordion-button border collapsed'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#input'
                                            aria-expanded='false'
                                            aria-controls='input'>
                                            {translator.get('service.detail.input.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div id='input' className='collapse size-tab' role='region' aria-labelledby='input'>
                                        <div className='accordion-body'>
                                            <Table columns={inputColumns as Array<string>} rows={inputRows} keyLabel='input' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {service?.integrations?.belindaMetadata?.embed_url && service?.integrations?.belindaMetadata?.status === 'PUBLISHED' && (
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='accordion accordion-background-active border bg-white' id='accordion'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            className={`accordion-button border collapsed`}
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#BelindaMetadata'
                                            aria-expanded='false'
                                            aria-controls='BelindaMetadata'>
                                            {translator.get('project.detail.belinda.metadata.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div id='BelindaMetadata' className={`collapse`} role='region' aria-labelledby='heading1c'>
                                        <div className='accordion-body' style={{ height: 800 }}>
                                            <iframe src={service?.integrations?.belindaMetadata?.embed_url} width={'100%'} height='100%' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
