import { NavigationManager } from '@managers/navigation.manager';
import { ProjectManager } from '@managers/project.manager';
import { Paths } from '@routes';
import { PollingUtil } from '@utils/polling.util';
import d3, { select, hierarchy, tree, linkHorizontal, svg } from 'd3';
import moment from 'moment';
import React from 'react';
import { useEffect, useRef } from 'react';
import { BaseProjectTemplate } from '../../../api/entities';
import useResizeObsever from '../../../_shared/utils/customHooks.util';

export interface ProjectProvenanceComponentProps {
    treeData?: any;
    templates?: Array<BaseProjectTemplate>;
}

function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

const ProjectProvenanceComponent: React.FC<ProjectProvenanceComponentProps> = ({ treeData, templates }): any => {
    const svgRef = useRef(null);
    const wrapperRef = useRef<HTMLDivElement>(null);
    const dimensions = useResizeObsever(wrapperRef);

    const previouslyRenderedData = usePrevious(treeData);

    const latestNodeDepth =
        treeData &&
        hierarchy(treeData)
            .leaves()
            .sort((a: any, b: any) => a.depth - b.depth)
            .pop()?.depth;
    // const wrapperWidth = latestNodeDepth! * 200 > 1400 ? latestNodeDepth! * 200 : ;
    // const wrapperHeight = wrapperWidth > 1400 ? wrapperWidth / 3 : 600;

    useEffect(() => {
        const svg = select(svgRef.current);
        if (!dimensions) return;

        const root = hierarchy(treeData);
        const treeLayout = dimensions && tree().size([wrapperRef.current?.clientHeight as number, wrapperRef.current?.clientWidth as number]);
        treeLayout(root);

        const linkGenerator = linkHorizontal()
            .x(node => ((node as any).y > 0 ? (node as any).y * 0.6 : +100))
            .y(node => (node as any).x);

        //render links
        const enteringAndUpdatingLinks = svg
            .selectAll('.link')
            .data(root.links())
            .join('path')
            .attr('class', 'link')
            .attr('d', linkGenerator as any)
            .attr('stroke-dasharray', function () {
                const length = (this as any).getTotalLength();
                return `${length} ${length}`;
            })
            .attr('d', linkGenerator as any)
            .attr('stroke', 'black')
            .attr('fill', 'none')
            .attr('opacity', 1);

        if (tree !== previouslyRenderedData) {
            enteringAndUpdatingLinks
                .attr('stroke-dashoffset', function () {
                    return (this as any).getTotalLength();
                })
                .transition()
                .duration(100)
                .delay(link => link.source.depth * 150)
                .attr('stroke-dashoffset', 0);
        }

        //render nodes
        svg.selectAll('.node')
            .data(root.descendants())
            .join(enter => enter.append('circle').attr('opacity', 0))
            .attr('class', 'node')
            .attr('id', node => node.data.id)
            .attr('r', 7)
            .attr('fill', '#009138')
            .attr('cursor', 'pointer')
            .attr('cx', node => {
                return (node as any).y > 0 ? (node as any).y * 0.6 : +100;
            })
            .attr('cy', node => {
                return (node as any).x;
            })
            .on('click', async node => {
                const id = node.target.id;
                PollingUtil.unregister('projectdetaildata');
                PollingUtil.unregister('projectdetailoutput');
                await ProjectManager.getInstance().getProvenanceTree(id);
                await ProjectManager.getInstance().getProjectById(id);
                NavigationManager.getInstance().navigateTo(`/project/${id}` as Paths);
            })
            .on('mouseenter', (event, value) => {
                svg.selectAll('.tooltipTest')
                    .data([value])
                    .join(enter => enter.append('foreignObject').attr('opacity', 0))
                    .attr('class', 'tooltipTest')
                    .style('height', '13%')
                    .style('width', '17%')
                    .style('background-color', '#ededeb')
                    .style('line-height', 1.5)
                    .style('box-shadow', '0 0 7px 0 rgb(0 0 0 / 33%)')
                    .style('border-radius', '20px')
                    .style('padding', 5)
                    .style('color', 'black')
                    .html(
                        `<div style=display:flex;padding-top:5px;flex-direction:column;padding-left:5px>
                         <span> <span style=color:#009138;font-weight:bold>Created by:</span> ${value.data.author?.name}</span>
                         <span> <span style=color:#009138;font-weight:bold>Version:</span> ${value.data.version} </span>
                         <span> <span style=color:#009138;font-weight:bold>Created at:</span> ${moment
                             .utc(value.data?.created_at as string)
                             .local()
                             .format('YYYY-MM-DD')}</span>
                         </div>`,
                    )
                    .attr('font-size', 12)
                    .attr('x', Number(event.target.attributes['cx'].value) - 30)
                    .attr('y', Number(event.target.attributes['cy'].value) + 30)
                    .transition()
                    .attr('opacity', 1)
                    .attr('z-index', 9999);
            })
            .on('mouseleave', () => svg.select('.tooltipTest').remove())
            .join('text')
            .attr('class', 'label')
            .attr('text-anchor', 'middle')
            .attr('font-size', 4)
            .transition()
            .duration(200)
            .delay(node => node.depth * 150)
            .attr('opacity', 1);

        //render labels
        svg.selectAll('text')
            .data(root.descendants())
            .join((enter: any) => enter.append('text').attr('opacity', 0))
            .attr('class', 'label')
            .attr('x', node => ((node as any).y > 0 ? (node as any).y * 0.6 : +100))
            .attr('y', node => (node as any).x - 15)
            .attr('text-anchor', 'middle')
            .attr('font-size', 24)
            .text(node => node.data.name)
            .transition()
            .duration(200)
            .delay(node => node.depth * 150)
            .attr('opacity', 1);

        return () => {
            svg.selectAll('circle').data(treeData).exit().remove();
            svg.selectAll('.tooltipTest').remove();
        };
    }, [treeData, dimensions]);

    return (
        <React.Fragment>
            <div ref={wrapperRef as React.MutableRefObject<HTMLDivElement>} id='svgCanvas' style={{ height: 600, overflow: 'auto' }}>
                <svg ref={svgRef} className='svg-container' style={{ height: 'inherit' }} />
            </div>
        </React.Fragment>
    );
};

export default ProjectProvenanceComponent;
