import { StyledTooltip } from '@components/Tooltip/Tooltip';
import { TextField } from '@features/_shared/components/TextField';
import { LoginState } from '@features/login/store/state';
import { TranslatorManager } from '@managers/TranslatorManager';
import { FormManager } from '@managers/form.manager';
import { ProjectManager } from '@managers/project.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { FormHelperUtil } from '@utils/form.helper.util';
import KeycloakUtil from '@utils/keycloak.util';
import React, { useContext, useEffect, useState } from 'react';
import { IntlContext } from 'react-intl';
import { Project } from 'src/api';
import * as tus from 'tus-js-client';
import { PreviousUpload } from 'tus-js-client';
import { ProjectInputUploadData } from '../beans/ProjectInputUploadData';

export interface LocalInputUploadComponentProps {
    project: Project;
    user: LoginState;
    isClosed: boolean;
    onUpload(): void;
}

export const LocalInputUploadComponent: React.FC<LocalInputUploadComponentProps> = ({ project, user, isClosed, onUpload }) => {
    const projectManager = ProjectManager.getInstance();
    const translator = TranslatorManager.getInstance();
    const [showInputField, setShowInputField] = useState<boolean>(false);
    const [isUploadEnabled, setIsUploadEnabled] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>('');
    const username = KeycloakUtil.getName();
    const email = KeycloakUtil.getEmail();
    const intl = useContext(IntlContext);

    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator('project.input', intl as any);

    useEffect(() => {
        if (isClosed) {
            setValue('filename', '');
            setValue('persistenceLocal', false);
            setShowInputField(false);
        }
    }, [isClosed]);

    const handleInputCreate = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const fileName: string = getValues('file')[0].name;
        setValue('filename', fileName);
        setShowInputField(true);
    };

    const handleResumableUpload = async (file: any, fileName: string) => {
        let retryCount = 0;

        const createUploadInstance = () => {
            return new tus.Upload(file, {
                endpoint: `${window.location.origin}/dashboard/api/project/input`,
                metadata: {
                    filename: file.name,
                    filetype: file.type,
                    slotname: fileName,
                    projectid: project.id as string,
                },
                chunkSize: 104857600, // 100MB
                removeFingerprintOnSuccess: true,
                headers: {
                    slotname: fileName,
                    projectid: project.id as string,
                    slotsize: file.size as string,
                    Authorization: `Bearer ${KeycloakUtil.getToken()}`,
                },
                onError: function (error: tus.DetailedError | Error) {
                    if (error.message.includes('401') && retryCount < 1) {
                        retryCount++;
                        upload.options.headers = {
                            ...upload.options.headers,
                            Authorization: `Bearer ${KeycloakUtil.getToken()}`,
                        };
                        upload.start();
                        retryCount = 0;
                    } else {
                        console.log('Failed because: ' + error);
                    }
                },
                onChunkComplete: async function () {
                    await projectManager.getProjectById(project.id as string);
                },
                onSuccess: function () {
                    console.log('Download %s from %s', upload.file, upload.url);
                },
            });
        };

        let upload = createUploadInstance();
        upload.findPreviousUploads().then(function (previousUploads) {
            let prvUpload = previousUploads.find((item: any) => item.metadata.slotname === fileName);
            if (previousUploads && previousUploads.length > 0 && prvUpload) {
                upload.resumeFromPreviousUpload(prvUpload as PreviousUpload);
            }
            upload.start();
        });
    };

    const handleSelectLocalInput = async () => {
        let file = getValues('file')[0];
        let fileName = getValues('filename');
        let persistence = getValues('persistenceLocal');
        if (fileName) {
            onUpload?.();
            await ProjectManager.getInstance().createInput(project.id as string, {
                type: 'LOCAL',
                filename: fileName,
                author: {
                    name: username,
                    email: email,
                },
                persistent: persistence,
                total: file.size,
            });
            await handleResumableUpload(file, fileName);
            setShowInputField(false);
            setFileName('');
            setIsUploadEnabled(false);
            project.id && QueueManager.getInstance().queue(QueueKeyType.DEFAULT, ProjectManager.getInstance().getProjectById(project.id));
            clearErrors();
        } else {
            setError('filename', { type: 'isNotEmpty' });
        }
    };

    const triggerValidation = (key: string, value: string) => {
        let filenameValue = value;
        setValue(key, value);
        if (filenameValue !== '') {
            clearErrors(key);
        } else {
            setError(key, { type: 'isNotEmpty' });
        }
    };

    const enableUpload = () => {
        getValues('file') ? setIsUploadEnabled(true) : setIsUploadEnabled(false);
    };

    const { register, getValues, setValue, trigger, errors, clearErrors, setError } = FormManager.getInstance().buildFormProxy<ProjectInputUploadData>(
        ProjectInputUploadData,
        () => {},
    );

    return (
        <>
            {!showInputField && (
                <div>
                    <div className='form-group'>
                        <TextField type='file' className='form-control' id='file' {...register('file', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => enableUpload() })} />
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button
                            className='btn btn-primary text-white d-flex align-items-center'
                            disabled={!isUploadEnabled}
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleInputCreate(e)}>
                            {translator.get('project.modal.upload.input.next.button')}
                        </button>
                    </div>
                </div>
            )}
            {showInputField && (
                <div>
                    <div className='form-group'>
                        <label htmlFor='filename' className='active d-flex flex-row align-items-center label-color'>
                            {translator.get('project.modal.upload.input.filename.title')}
                        </label>
                        <TextField
                            type='text'
                            id='filename'
                            {...register('filename', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('filename', e.target.value) })}
                            error={errors.filename}
                            errorMessage={errors.filename && getMessageByFieldAndError('filename', errors)}
                            placeholder='Filename'
                        />
                    </div>
                    <div className='form-check' style={{ marginLeft: 0 }}>
                        <input id='persistenceLocal' type='checkbox' {...register('persistenceLocal')} className='focus--mouse' />
                        <label className='label-color d-flex flex-row align-items-center' htmlFor='persistenceLocal'>
                            {translator.get('project.modal.upload.input.persistence.title')}
                            <StyledTooltip trigger='hover' position='right' labelKey={translator.get('project.modal.upload.input.persistence.tooltip')}>
                                <div className='tooltip-icon-container'>?</div>
                            </StyledTooltip>
                        </label>
                    </div>
                    <div className='d-flex justify-content-end mb-1'>
                        <button className='btn btn-primary text-white d-flex align-items-center' onClick={handleSelectLocalInput}>
                            {translator.get('project.modal.upload.input.upload.button')}
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};
