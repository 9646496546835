import { NavigationManager } from '@managers/navigation.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import '../styles/tabbar.style.scss';

export interface ITabBarProps {
    onRouteChange: (route: string) => void;
}

const TabBar: React.FC<ITabBarProps> = ({ onRouteChange }) => {
    const translator = TranslatorManager.getInstance();

    const currentRoute = NavigationManager.getInstance().getCurrentRoute();

    return (
        <>
            <ul className='nav nav-pills' id='pills-tab' role='tablist'>
                <li className='nav-item' role='presentation'>
                    <button
                        className={`nav-link 
            ${currentRoute.includes('/project') ? 'active' : ''} tabButton`}
                        id='project-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#project'
                        type='button'
                        role='tab'
                        aria-controls='project'
                        aria-selected='true'
                        onClick={() => {
                            onRouteChange('/project');
                            NavigationManager.getInstance().navigateTo('/project');
                        }}>
                        {translator.get('tabbar.projects.tab.text')}
                    </button>
                </li>
                <li className='nav-item' role='presentation'>
                    <button
                        className={`nav-link ${currentRoute.includes('/service') ? 'active' : ''} tabButton`}
                        id='pills-profile-tab'
                        data-bs-toggle='pill'
                        data-bs-target='#pills-profile'
                        type='button'
                        role='tab'
                        aria-controls='pills-profile'
                        aria-selected='false'
                        onClick={() => {
                            onRouteChange('/service');
                            NavigationManager.getInstance().navigateTo('/service');
                        }}>
                        {translator.get('tabbar.services.tab.text')}
                    </button>
                </li>
            </ul>
        </>
    );
};

export default TabBar;
