import { LoginState } from '@features/login/store/state';
import { FormManager } from '@managers/form.manager';
import React, { useEffect, useState } from 'react';
import { ServiceExecution, WebService } from 'src/api';
import { ServiceInputUploadData } from '../beans/ServiceInputUploadData';

export interface ServiceBehubInputUploadComponentProps {
    service: WebService;
    execution: ServiceExecution;
    user: LoginState;
    isClosed: boolean;
    onUpload?(id: string, execId: string, fileUrl: string, fileName: string): void;
    onMinisiteUpload?: (token: string, fileUrl: string, fileName: string) => {};
    filename: string;
    token?: string;
}

export const ServiceBehubInputUploadComponent: React.FC<ServiceBehubInputUploadComponentProps> = ({
    filename,
    service,
    execution,
    isClosed,
    onUpload,
    onMinisiteUpload,
    token,
}) => {
    const { setValue } = FormManager.getInstance().buildFormProxy<ServiceInputUploadData>(ServiceInputUploadData, () => {});
    const element = document.querySelector('script[id="injected-integration-behub-select"]');
    const iframeURL = element?.textContent!;

    (window as any).handleDatasetDownload = () => {
        const params: string = (document.getElementById('behubIframe') as any).contentWindow.location.href;
        const url = params.split('url=')[1];
        handleSelectUrlInput(url);
    };

    useEffect(() => {
        if (isClosed) {
            setValue('url', '');
        }
    }, [isClosed]);

    const handleSelectUrlInput = async (fileUrl: string) => {
        service.id && execution.id && token ? onMinisiteUpload?.(token, fileUrl, filename) : onUpload?.(service.id as string, execution.id as string, fileUrl, filename);
    };

    return !isClosed ? <iframe src={iframeURL} className='behub-iframe' id='behubIframe' /> : null;
};
