import { DequeueTaskForLoader, QueueTaskForLoader } from '@features/_shared/store/actions';
import { StoreManager } from '@hypereact/state';

export interface IQueueManagerProps {}

export enum QueueKeyType {
    DEFAULT = 'default',
    POLLER = 'poller',
}

export class QueueManager implements IQueueManagerProps {
    private static instance: QueueManager;
    private storeManager: StoreManager;

    constructor() {
        this.storeManager = StoreManager.getInstance();
    }

    static getInstance() {
        if (QueueManager.instance == null) {
            QueueManager.instance = new QueueManager();
        }
        return QueueManager.instance;
    }

    async queue<T>(key: QueueKeyType, task: Promise<T>): Promise<T> {
        if (key === QueueKeyType.DEFAULT) {
            try {
                this.storeManager.dispatch(new QueueTaskForLoader());
                let result = await task;
                this.storeManager.dispatch(new DequeueTaskForLoader());
                return result;
            } catch (e) {
                this.storeManager.dispatch(new DequeueTaskForLoader());
                throw e;
            }
        } else {
            let result = await task;
            return result;
        }
    }
}
