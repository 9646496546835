import { ReactComponent as Close } from '@assets/svg/it-close.svg';
import { TextField } from '@components/TextField';
import { SelectMultipleComponent } from '@features/_shared/components/SelectMultiple/SelectMultiple.component';
import { TextArea } from '@features/_shared/components/TextArea';
import { StyledTooltip } from '@features/_shared/components/Tooltip/Tooltip';
import { StoreManager } from '@hypereact/state';
import { TranslatorManager } from '@managers/TranslatorManager';
import { FormManager } from '@managers/form.manager';
import { ProjectManager } from '@managers/project.manager';
import { ServiceManager } from '@managers/service.manager';
import { FormHelperUtil } from '@utils/form.helper.util';
import { useContext, useEffect, useState } from 'react';
import { IntlContext } from 'react-intl';
import { BaseProjectTemplate, Project, User, WebService } from '../../../api';
import { ServiceCreateFormData } from '../beans/serviceCreateData';
import { ServiceSetValue } from '../store/actions';
import '../styles/service.create.style.scss';

export interface ServiceCreateFormComponentProps {
    user: User;
    technologies: Array<BaseProjectTemplate>;
    serviceCreate: WebService;
    onBlur?: (key: keyof WebService, value: string | number | boolean | Array<string>) => void;
    onChange?: (key: keyof WebService, value: string | number | boolean | Array<string>) => void;
    onCreate?: () => void;
    isModalClosed?: boolean;
    project: Project;
    availableCategoryOptions?: string[];
}

const ServiceCreateFormComponent: React.FC<ServiceCreateFormComponentProps> = ({
    project,
    isModalClosed,
    onBlur,
    onCreate,
    technologies,
    availableCategoryOptions,
    serviceCreate,
}) => {
    const translator = TranslatorManager.getInstance();
    const serviceManager = ServiceManager.getInstance();
    const intl = useContext(IntlContext);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator('service.create', intl as any);

    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        let error = await trigger(['name', 'version', 'description']);
        //needs to be called after trigger , otherwise the values will be reset
        const isCategoriesAndKeywordsValidated = validateCategoriesAndKeywords();
        error = error && isCategoriesAndKeywordsValidated;

        setIsSubmitted(true);
        if (error) {
            onCreate?.();
            reset();
        } else {
            e.preventDefault();
        }
    };

    const validateCategoriesAndKeywords = () => {
        const isCategories = serviceCreate.categories && serviceCreate.categories?.length > 0 ? true : false;
        const isKeywords = serviceCreate.keywords && serviceCreate.keywords.length > 0 ? true : false;

        if (isCategories || isKeywords) {
            if (isCategories) {
                clearErrors('categories');
            }
            if (isKeywords) {
                clearErrors('keyword');
            }
        }
        if (!isCategories || !isKeywords) {
            if (!isCategories) {
                setError('categories', { type: 'isNotEmpty' });
            }
            if (!isKeywords) {
                setError('keyword', { type: 'isNotEmpty' });
            }
        }
        return (isCategories && isKeywords) as boolean;
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        onBlur?.(e.target.name as keyof WebService, e.target.value);
    };

    const triggerValidation = (key: string, value: string) => {
        setValue(key, value);
        (isSubmitted || key === 'keyword') && trigger(key);
    };

    useEffect(() => {
        clearErrors(['name', 'version', 'description', 'keyword', 'categories']);
        const fieldNames = ['name', 'version'];
        fieldNames.map((item: string) => setValue(item, ''));
        setValue('description', project?.description);
        setValue('categories', project?.categories);
        StoreManager.getInstance().dispatch(new ServiceSetValue('description', project?.description));
        StoreManager.getInstance().dispatch(new ServiceSetValue('categories', project?.categories));
        setIsSubmitted(false);
    }, [isModalClosed]);

    const handleCategoryChange = async (newValues: Array<string>) => {
        onBlur?.('categories' as keyof WebService, newValues);
        if (newValues.length > 0) {
            clearErrors('categories');
        } else {
            setError('categories', { type: 'isNotEmpty' });
        }
    };

    const handleOnAddKeywordButtonClick = async () => {
        const currentKeyword = getValues('keyword');
        const noError = (await trigger('keyword')) && !serviceCreate?.keywords?.find((item: string) => item.toLowerCase() === currentKeyword.toLowerCase());
        if (currentKeyword !== '' && noError) {
            const updatedKeywords = serviceCreate.keywords ? [...(serviceCreate.keywords as string[]), currentKeyword] : [currentKeyword];
            onBlur?.('keywords' as keyof WebService, updatedKeywords);
            setValue('keyword', undefined);
            clearErrors('keyword');
        } else if (serviceCreate?.keywords?.find((item: string) => item.toLowerCase() === currentKeyword.toLowerCase())) {
            setError('keyword', { type: 'keywordlrdxst' });
        }
    };

    const handleOnKeywordRemoveClick = (keywordToBeRemoved: string) => {
        if (serviceCreate.keywords) {
            const updatedKeywords: any[] = serviceCreate.keywords.filter(currentKeyword => currentKeyword !== keywordToBeRemoved);
            onBlur?.('keywords' as keyof WebService, updatedKeywords);
        }
    };

    const handleUnselectAll = async (e?: React.MouseEvent<HTMLButtonElement>) => {
        e && e.preventDefault();
        serviceManager.resetCategoryCreate();
        await ProjectManager.getInstance().getProjectCategories();
    };

    const { errors, register, trigger, setValue, reset, clearErrors, setError, getValues } = FormManager.getInstance().buildFormProxy<ServiceCreateFormData>(
        ServiceCreateFormData,
        onSubmit,
    );

    return (
        <div className='container-fluid mt-4'>
            <div className='row'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='title' className='active d-flex flex-row align-items-center label-color'>
                            {translator.get('service.modal.create.new.title.label')}
                            <p className='requiredSign'>*</p>
                            <StyledTooltip trigger='hover' position='right' labelKey={translator.get('service.modal.create.title.tooltip')}>
                                <div className='tooltip-icon-container'>?</div>
                            </StyledTooltip>
                        </label>
                        <TextField
                            type='text'
                            className='form-control'
                            placeholder='Title'
                            id='name'
                            maxLength={40}
                            {...register('name', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('name', e.target.value) })}
                            error={errors.name}
                            errorMessage={errors.name && getMessageByFieldAndError('name', errors)}
                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                        />
                    </div>
                </div>
            </div>
            <div className='row pt-3'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='version' className='active d-flex flex-row align-items-center label-color'>
                            {translator.get('service.modal.create.version.label')}
                            <p className='requiredSign'>*</p>
                            <StyledTooltip trigger='hover' position='right' labelKey={translator.get('service.modal.create.version.tooltip')}>
                                <div className='tooltip-icon-container'>?</div>
                            </StyledTooltip>
                        </label>
                        <TextField
                            type='text'
                            className='form-control'
                            id='version'
                            maxLength={20}
                            {...register('version', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('version', e.target.value) })}
                            error={errors.version}
                            errorMessage={errors.version && getMessageByFieldAndError('version', errors)}
                            placeholder='Version'
                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                        />
                    </div>
                </div>
            </div>
            <div className='row pt-3'>
                <div className='col-12'>
                    <div className='form-group'>
                        <div className='select-wrapper'>
                            <label htmlFor='technology' className='active d-flex flex-row align-items-center label-color'>
                                {translator.get('service.modal.create.language.label')}
                                <StyledTooltip trigger='hover' position='right' labelKey={translator.get('service.modal.create.technology.tooltip')}>
                                    <div className='tooltip-icon-container'>?</div>
                                </StyledTooltip>
                            </label>

                            <div>
                                <select
                                    {...register('technology', { onChange: (e: React.ChangeEvent<HTMLSelectElement>) => triggerValidation('technology', e.target.value) })}
                                    className='select-container'
                                    disabled>
                                    <option value={project?.technology} disabled selected>
                                        {
                                            technologies?.find((item: BaseProjectTemplate) => {
                                                return item.id == project?.technology;
                                            })?.name
                                        }
                                    </option>
                                </select>
                            </div>
                            {errors.technology && <small className='form-text error-message'>{translator.get(`form.project.create.technology.${errors.technology.type}`)}</small>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row pt-3'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='creator' className='active label-color'>
                            {translator.get('service.modal.create.creator.label')}
                        </label>
                        <TextField id='creator' value={project?.author?.name} name='creator' readOnly />
                    </div>
                </div>
            </div>
            <div className='row pt-3'>
                <div className='col-12 category-index'>
                    <div className='form-group'>
                        <div className='select-wrapper'>
                            <label htmlFor='category' className='active d-flex flex-row align-items-center label-color'>
                                {translator.get('service.modal.create.category.label')}
                                <p className='requiredSign'>*</p>
                                <StyledTooltip trigger='hover' position='right' labelKey={translator.get('service.modal.create.category.tooltip')}>
                                    <div className='tooltip-icon-container'>?</div>
                                </StyledTooltip>
                            </label>
                            <div>
                                <SelectMultipleComponent
                                    placeholder={translator.get('service.filterbar.category.placeholder')}
                                    options={availableCategoryOptions as string[]}
                                    value={serviceCreate && serviceCreate?.categories ? serviceCreate?.categories : []}
                                    {...register('categories')}
                                    onChange={handleCategoryChange}
                                    availableValues={availableCategoryOptions as Array<string>}
                                    handleUnselectAll={handleUnselectAll}
                                />
                            </div>
                            {errors.categories && <small className='form-text error-message'>{translator.get(`form.project.create.category.${errors.categories.type}`)}</small>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row pt-3 mb-3'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='abstract' className='active d-flex flex-row align-items-center label-color'>
                            {translator.get('service.modal.create.abstract.label')}
                            <p className='requiredSign'>*</p>
                            <StyledTooltip trigger='hover' position='right' labelKey={translator.get('service.modal.create.abstract.tooltip')}>
                                <div className='tooltip-icon-container'>?</div>
                            </StyledTooltip>
                        </label>
                        <TextArea
                            rows={3}
                            id='description'
                            {...register('description', { onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => triggerValidation('description', e.target.value) })}
                            placeholder={translator.get('service.modal.create.abstract.placeholder')}
                            defaultValue={project?.description}
                            onBlur={(e: React.FocusEvent<HTMLTextAreaElement, Element>) => handleOnBlur(e)}
                        />
                        {errors.description && <small className='form-text error-message'>{translator.get(`form.service.create.description.${errors.description.type}`)}</small>}
                    </div>
                </div>
            </div>
            <div className='row'></div>
            <div className='col-12 mb-4 mt-3'>
                <div className='form-group'>
                    <label htmlFor='keywords' className='active d-flex flex-row align-items-center label-color'>
                        {translator.get('project.modal.clone.keywords.label')}
                        <p className='requiredSign'>*</p>
                        <StyledTooltip trigger='hover' position='right' labelKey={translator.get('service.modal.create.keywords.tooltip')}>
                            <div className='tooltip-icon-container'>?</div>
                        </StyledTooltip>
                    </label>
                    {serviceCreate.keywords &&
                        serviceCreate.keywords.map(keyword => {
                            return (
                                <div className='row'>
                                    <div className='col-12'>
                                        <div className='keyword-container pl-3'>
                                            {keyword}
                                            <button
                                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnKeywordRemoveClick(keyword)}
                                                className='btn btn-primary text-white btn-remove'>
                                                <div className='d-flex'>
                                                    <Close className='icon' fill='white' />
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    <TextField
                        type='text'
                        className='form-control'
                        placeholder='Keywords'
                        id='keyword'
                        {...register('keyword', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('keyword', e.target.value) })}
                        error={errors.keyword}
                        errorMessage={errors.keyword && getMessageByFieldAndError('keyword', errors)}
                    />

                    <div className='col-12 mb-5 mt-2'>
                        <button onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnAddKeywordButtonClick()} className='btn btn-primary text-white'>
                            {translator.get('project.modal.create.add.keyword.button.text')}
                        </button>
                    </div>
                </div>
            </div>
            {/* <div className='row mb-4'>
                <h6 className='additional-info'>{translator.get('service.modal.create.additional.metadata.title')}</h6>
                <div className='modal-additional-info-container d-flex justify-content-end'>
                    <button
                        className='btn btn-outline-secondary button-plus'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#plus'
                        aria-expanded='true'
                        aria-controls='plus'>
                        <Plus className='plus-container icon' />
                        <Minus className='minus-container icon' />
                    </button>
                </div>
                <div id='plus' className='collapse' role='region' aria-labelledby='heading1c'>
                    <div className='row mt-4'>
                        <div className='form-group mt-3'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <label htmlFor='metadata' className='active metadata label-color'>
                                        {translator.get('service.modal.create.metadata.label')}
                                    </label>
                                </div>
                            </div>
                            <TextField type='text' className='form-control' id='metadata' placeholder='Keywords' name='metadata' />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label htmlFor='value' className='active label-color'>
                                    {translator.get('service.modal.create.value.label')}
                                </label>
                                <TextArea
                                    rows={3}
                                    id='value'
                                    name='metadataValue'
                                    placeholder='Lorem ipsum'
                                    onBlur={(e: React.FocusEvent<HTMLTextAreaElement, Element>) => handleOnBlur(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <h6 className='metadata-list-header'>{translator.get('service.modal.create.metadata.table.title')}</h6>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('service.modal.create.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('service.modal.create.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('service.modal.create.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('service.modal.create.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                    </div> 
                </div>
            </div>*/}
            <div className='row'>
                <div className='col-12 mb-3'>
                    <button onClick={(e: React.MouseEvent<HTMLButtonElement>) => onSubmit(e)} className='btn btn-primary w-100 text-white'>
                        {translator.get('service.modal.create.submit.button.text')}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default ServiceCreateFormComponent;
