export class FormatUtil {
    static round = (value: number, precision: number) => {
        if (precision === 0) {
            return Math.round(value);
        }

        let exp = 1;
        for (let i = 0; i < precision; i++) {
            exp *= 10;
        }

        return Math.round(value * exp) / exp;
    };

    static formatBytes = (bytes: number, decimals = 2) => {
        if (!+bytes) {
            return '0 Bytes';
        }

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    };

    static truncateText = (str: string, length?: number, ending?: string) => {
        if (!length) {
            length = 750;
        }
        if (!ending) {
            ending = '...';
        }
        if (str && str?.length > length) {
            return str.substring(0, length - ending?.length) + ending;
        } else {
            return str;
        }
    };

    static roundNumberAfterComma = (num: number, numbersAfterComma: number) => {
        return parseFloat((Math.round(num * 100) / 100).toFixed(numbersAfterComma));
    };
}
