/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
/**
 * FetcherTestControllerApi - axios parameter creator
 * @export
 */
export const FetcherTestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        check2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/fetcher/check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchProject: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('fetchProject', 'projectId', projectId);
            const localVarPath = `/fetcher/fetchproject/{projectId}`.replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchService: async (projectId: string, serviceId: string, serviceExecutionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('fetchService', 'projectId', projectId);
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('fetchService', 'serviceId', serviceId);
            // verify required parameter 'serviceExecutionId' is not null or undefined
            assertParamExists('fetchService', 'serviceExecutionId', serviceExecutionId);
            const localVarPath = `/fetcher/fetchservice/{projectId}/{serviceId}/{serviceExecutionId}`
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'serviceId'}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${'serviceExecutionId'}}`, encodeURIComponent(String(serviceExecutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * FetcherTestControllerApi - functional programming interface
 * @export
 */
export const FetcherTestControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = FetcherTestControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async check2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.check2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchProject(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} projectId
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async fetchService(
            projectId: string,
            serviceId: string,
            serviceExecutionId: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.fetchService(projectId, serviceId, serviceExecutionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * FetcherTestControllerApi - factory interface
 * @export
 */
export const FetcherTestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FetcherTestControllerApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        check2(options?: any): AxiosPromise<void> {
            return localVarFp.check2(options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchProject(projectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.fetchProject(projectId, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} projectId
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        fetchService(projectId: string, serviceId: string, serviceExecutionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.fetchService(projectId, serviceId, serviceExecutionId, options).then(request => request(axios, basePath));
        },
    };
};

/**
 * FetcherTestControllerApi - interface
 * @export
 * @interface FetcherTestControllerApi
 */
export interface FetcherTestControllerApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FetcherTestControllerApiInterface
     */
    check2(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FetcherTestControllerApiInterface
     */
    fetchProject(projectId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} projectId
     * @param {string} serviceId
     * @param {string} serviceExecutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FetcherTestControllerApiInterface
     */
    fetchService(projectId: string, serviceId: string, serviceExecutionId: string, options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * FetcherTestControllerApi - object-oriented interface
 * @export
 * @class FetcherTestControllerApi
 * @extends {BaseAPI}
 */
export class FetcherTestControllerApi extends BaseAPI implements FetcherTestControllerApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FetcherTestControllerApi
     */
    public check2(options?: AxiosRequestConfig) {
        return FetcherTestControllerApiFp(this.configuration)
            .check2(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FetcherTestControllerApi
     */
    public fetchProject(projectId: string, options?: AxiosRequestConfig) {
        return FetcherTestControllerApiFp(this.configuration)
            .fetchProject(projectId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} projectId
     * @param {string} serviceId
     * @param {string} serviceExecutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FetcherTestControllerApi
     */
    public fetchService(projectId: string, serviceId: string, serviceExecutionId: string, options?: AxiosRequestConfig) {
        return FetcherTestControllerApiFp(this.configuration)
            .fetchService(projectId, serviceId, serviceExecutionId, options)
            .then(request => request(this.axios, this.basePath));
    }
}
