import { ReactComponent as Download } from '@assets/svg/it-download.svg';
import { ReactComponent as Search } from '@assets/svg/it-search.svg';
import { ReactComponent as Publish } from '@assets/svg/publish.svg';
import { ProvisioningPublishDatasetData } from '@features/project/beans/ProvisioningPublishDatasetData';
import { PopupType } from '@features/_shared/components/Popup/popup.item';
import { Table } from '@features/_shared/components/Table';
import { TextArea } from '@features/_shared/components/TextArea';
import { TextField } from '@features/_shared/components/TextField';
import { BehubIntegrationManager } from '@managers/behub-integration.manager';
import { FormManager } from '@managers/form.manager';
import { PopupManager } from '@managers/popup.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import { FormHelperUtil } from '@utils/form.helper.util';
import { FormatUtil } from '@utils/formaters.util';
import KeycloakUtil from '@utils/keycloak.util';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IntlContext } from 'react-intl';
import { InputFile, InputFileTypeEnum, OutputFile, Project } from '../../../api/entities';
import { ProvisioningPageEnum } from '../store/state';
import '../styles/provisioning.dataset.component.style.scss';
import { ProvisioningOriginEnum } from '../views/provisioning.view';

export interface IProvisioningDatasetComponentProps {
    isPublicatingProject?: boolean;
    inputs?: InputFile[];
    outputs?: OutputFile[];
    currentPage?: ProvisioningPageEnum;
    origin?: ProvisioningOriginEnum;
    steps?: Array<string>;
    selectedProject?: Project;
}

export enum BehubDirectoryTargetEnum {
    input = 'INPUT',
    output = 'OUTPUT',
}

export const ProvisioningDatasetComponent: React.FC<IProvisioningDatasetComponentProps> = ({
    isPublicatingProject,
    inputs = [],
    outputs = [],
    currentPage,
    origin,
    steps,
    selectedProject,
}) => {
    const translator = TranslatorManager.getInstance();
    const behubIntegrationManager = BehubIntegrationManager.getInstance();
    const popupManager = PopupManager.getInstance();
    const queueManager = QueueManager.getInstance();
    const [isInputModalOpen, setIsInputModalOpen] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [target, setTarget] = useState<BehubDirectoryTargetEnum | undefined>(undefined);
    const [selectedDataset, setSelectedDataset] = useState<string | undefined>(undefined);
    const intl = useContext(IntlContext);
    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator('provisioning.dataset', intl as any);

    const { errors, register, trigger, setValue, clearErrors, getValues } = FormManager.getInstance().buildFormProxy<ProvisioningPublishDatasetData>(
        ProvisioningPublishDatasetData,
        () => {},
    );

    const triggerValidation = (key: string, value: string) => {
        setValue(key, value);
        isSubmitted && trigger(key);
    };

    const handleOnPublishClick = async () => {
        let isValid = await trigger();

        setIsSubmitted(true);
        if (isValid && selectedDataset && selectedProject?.id && target) {
            await queueManager.queue(
                QueueKeyType.DEFAULT,
                behubIntegrationManager.publishDataset(selectedProject.id, target, selectedDataset, {
                    userEmail: KeycloakUtil.getEmail(),
                    firstName: KeycloakUtil.getFirstName(),
                    lastName: KeycloakUtil.getLastName(),
                    title: getValues('title'),
                    description: getValues('description'),
                }),
            );
            document.getElementById('publishDatasetModalClose')?.click();
            setIsInputModalOpen(false);
            popupManager.show({
                type: PopupType.Info,
                title: translator.get('provisioning.publication.thank.you.text'),
                description: translator.get('provisioning.publication.request.received.popup.description'),
                maxWidth: 'lg',
                stylingProp: 'popup-display',
                ctas: {
                    primary: {
                        label: translator.get('popup.menu.ok.title'),
                        className: 'popup-accept-button',
                        cta: async () => {
                            popupManager.hide();
                        },
                    },
                },
            });
        }
    };

    useEffect(() => {
        clearErrors();
        setIsSubmitted(true);
        setValue('title', '');
        setValue('description', '');
    }, [isInputModalOpen]);

    const inputCols =
        steps![0] === '/provisioning/dataset'
            ? [
                  translator.get('provisioning.table.header.name.text'),
                  translator.get('provisioning.input.table.header.source.text'),
                  translator.get('provisioning.table.header.size.text'),
                  translator.get('common.table.header.action.text'),
              ]
            : [
                  translator.get('provisioning.table.header.name.text'),
                  translator.get('provisioning.input.table.header.source.text'),
                  translator.get('provisioning.table.header.size.text'),
              ];

    const inputRows =
        inputs && Object.keys(inputs).length > 0
            ? Object.values(inputs)
                  .filter((item: InputFile) => item.type === InputFileTypeEnum.LOCAL)
                  .map((inputItem: InputFile | undefined, index) => {
                      return steps![0] === '/provisioning/dataset'
                          ? {
                                id: inputItem?.filename,
                                values: {
                                    name: inputItem?.filename,
                                    source: inputItem?.type,
                                    size: inputItem?.size ? FormatUtil.formatBytes(inputItem.size) : '0 Bytes',
                                    action: (
                                        <>
                                            <div className='d-flex justify-content-start provisioning-action-container'>
                                                {inputItem?.type !== InputFileTypeEnum.LOCAL ? (
                                                    <>
                                                        <button className='btn action-item'>
                                                            <Download className='icon' />
                                                        </button>
                                                        <button className='btn action-item'>
                                                            <Search className='icon' />
                                                        </button>
                                                    </>
                                                ) : (
                                                    !inputItem.behub && (
                                                        <button
                                                            className='btn action-item'
                                                            type='button'
                                                            data-bs-toggle='modal'
                                                            data-bs-target='#publishDatasetModal'
                                                            onClick={() => {
                                                                setIsInputModalOpen(true);
                                                                setSelectedDataset(inputItem.filename);
                                                                setTarget(BehubDirectoryTargetEnum.input);
                                                            }}>
                                                            <Publish className='icon' fill='#009138' />
                                                        </button>
                                                    )
                                                )}
                                            </div>
                                        </>
                                    ),
                                },
                            }
                          : {
                                id: inputItem?.filename,
                                values: {
                                    name: inputItem?.filename,
                                    source: inputItem?.type,
                                    size: inputItem?.size ? FormatUtil.formatBytes(inputItem.size) : '0 Bytes',
                                },
                            };
                  })
            : [];

    const outputCols =
        steps![0] === '/provisioning/dataset'
            ? [translator.get('provisioning.table.header.name.text'), '', translator.get('provisioning.table.header.size.text'), translator.get('common.table.header.action.text')]
            : [translator.get('provisioning.table.header.name.text'), '', translator.get('provisioning.table.header.size.text')];
    const outputRows =
        outputs && Object.keys(outputs).length > 0
            ? Object.values(outputs).map((outputItem: OutputFile | undefined, index) => {
                  return steps![0] === '/provisioning/dataset'
                      ? {
                            id: outputItem?.filename,
                            values: {
                                name: outputItem?.filename,
                                space: <div className='provisioning-space' />,
                                size: outputItem?.size ? FormatUtil.formatBytes(outputItem.size) : '0 Bytes',
                                action: (
                                    <div className='d-flex justify-content-start provisioning-action-container action-container-spaced'>
                                        {!outputItem?.behub && outputItem?.filename !== 'binder.log' && outputItem?.filename !== 'jupyter.log' && (
                                            <button
                                                className='btn action-item'
                                                type='button'
                                                data-bs-toggle='modal'
                                                data-bs-target='#publishDatasetModal'
                                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                    setIsInputModalOpen(true);
                                                    setSelectedDataset(outputItem?.filename);
                                                    setTarget(BehubDirectoryTargetEnum.output);
                                                }}
                                                aria-expanded='false'>
                                                <Publish className='icon' fill='#009138' />
                                            </button>
                                        )}
                                    </div>
                                ),
                            },
                        }
                      : {
                            id: outputItem?.filename,
                            values: {
                                name: outputItem?.filename,
                                space: <div className='provisioning-space' />,
                                size: outputItem?.size ? FormatUtil.formatBytes(outputItem.size) : '0 Bytes',
                            },
                        };
              })
            : [];

    return (
        <>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='row col-12  home-title-container '>
                        <h5 className='title-description col-12 mb-0'>{translator.get('provisioning.dataset.publication.description.part.one')}</h5>
                        <h3 className='secondary-title col-12 mt-3 mb-3'>{translator.get('provisioning.dataset.publication.description.part.two')}</h3>
                        <h5 className='title-description col-12'>{translator.get('provisioning.dataset.publication.description.part.three')}</h5>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <p className='input-output-header-container'>{translator.get('provisioning.input.header.text')}</p>
                    </div>
                    <div className='col-12'>
                        <div className='size-tab'>
                            <Table columns={inputCols as string[]} rows={inputRows || []} keyLabel='input' />
                        </div>
                    </div>
                </div>
                {isPublicatingProject && (
                    <div className='row'>
                        <div className='col-12'>
                            <div className='form-check'>
                                <input id='datasets' type='checkbox' className='focus--mouse' />
                                <label htmlFor='datasets'>{translator.get('provisioning.dataset.checkbox.text')}</label>
                            </div>
                        </div>
                    </div>
                )}
                <div className='row'>
                    <div className='col-12'>
                        <p className='input-output-header-container'>{translator.get('provisioning.output.header.text')}</p>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        <div className='size-tab'>
                            <Table columns={outputCols as string[]} rows={outputRows || []} keyLabel='output' />
                        </div>
                    </div>
                </div>
            </div>

            <div className='modal fade' role='dialog' id='publishDatasetModal' aria-labelledby='publishDatasetModalTitle' data-bs-backdrop='static' data-bs-keyboard='false'>
                <div className='modal-dialog modal-lg' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='modal-title h5 no_toc' id='publishDatasetModalTitle'>
                                {translator.get('provisioning.modal.publish.input.title')}
                            </h2>
                            <button
                                className='btn-close'
                                type='button'
                                data-bs-dismiss='modal'
                                aria-label='Close modal'
                                id='publishDatasetModalClose'
                                onClick={() => setIsInputModalOpen(false)}
                            />
                        </div>
                        <div className='modal-body mt-4'>
                            <div className='form-group'>
                                <label htmlFor='title' className='active d-flex flex-row align-items-center label-color'>
                                    {translator.get('provisioning.modal.publish.dataset.title')}
                                    <p className='requiredSign'>*</p>
                                </label>
                                <TextField
                                    type='text'
                                    id='tile'
                                    {...register('title', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('title', e.target.value) })}
                                    error={errors.title}
                                    errorMessage={errors.title && getMessageByFieldAndError('title', errors)}
                                    placeholder='Title'
                                />
                            </div>

                            <div className='form-group'>
                                <label htmlFor='description' className='active d-flex flex-row align-items-center label-color'>
                                    {translator.get('provisioning.modal.publish.dataset.description')}
                                </label>
                                <TextArea
                                    rows={3}
                                    id='description'
                                    {...register('description', { onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => triggerValidation('description', e.target.value) })}
                                    placeholder='Description'
                                    //onBlur={(e: React.FocusEvent<HTMLTextAreaElement, Element>) => handleOnBlur(e)}
                                />
                                {errors.description && (
                                    <small className='form-text error-message'>{translator.get(`form.project.create.description.${errors.description.type}`)}</small>
                                )}
                            </div>

                            <div className='d-flex justify-content-end mb-1'>
                                <button className='btn btn-primary text-white d-flex align-items-center' onClick={handleOnPublishClick}>
                                    {translator.get('provisioning.modal.publish.input.button')}
                                </button>
                            </div>
                        </div>
                        <div className='modal-footer'></div>
                    </div>
                </div>
            </div>
        </>
    );
};
