import {
    MinisiteResetExecutionHtml,
    MinisiteResetExecutionLogs,
    MinisiteSetEmail,
    MinisiteSetExecutionLogs,
    MinisiteSetOutputs,
    MinisiteSetSelectedExecution,
} from '@features/minisite/store/actions';
import { MinisiteState } from '@features/minisite/store/state';
import { StoreManager } from '@hypereact/state';
import { Slices } from '@store/state';
import {
    OutputFile,
    RequestFile,
    RequestServiceExecutionCreate,
    RequestServiceExecutionMinisiteCreate,
    RequestServiceUrlFileSelect,
    ResponseServiceExecution,
    ResponseServiceExecutionMinisite,
    ResponseServiceUrlFileSelect,
} from '../../api/entities';
import { IMinisiteApiService, MinisiteApiService } from '../services/minisite/minisite.api.service';

export interface IMinisiteManagerProps {
    create(id: string, requestServiceExecutionCreate: RequestServiceExecutionCreate): Promise<ResponseServiceExecutionMinisite>;
    getExecutionByServiceByIdAndId(jwtHeader: string, jwtPayload: string, jwtSignature: string): Promise<ResponseServiceExecutionMinisite>;
    launch(jwtHeader: string, jwtPayload: string, jwtSignature: string, email: string): Promise<void>;
    selectExternalUrlInput(
        jwtHeader: string,
        jwtPayload: string,
        jwtSignature: string,
        requestServiceUrlFileSelect: RequestServiceUrlFileSelect,
    ): Promise<ResponseServiceUrlFileSelect>;
    getOutputList(jwtHeader: string, jwtPayload: string, jwtSignature: string): Promise<void>;
    getLogs(jwtHeader: string, jwtPayload: string, jwtSignature: string): Promise<string>;
    downloadOutput(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile): Promise<File>;
    downloadInput(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile): Promise<File>;
}

export class MinisiteManager implements IMinisiteManagerProps {
    private static instance: MinisiteManager;
    private minisiteService: IMinisiteApiService;
    private storeManager: StoreManager;
    private constructor() {
        this.minisiteService = MinisiteApiService.getInstance(process.env.REACT_APP_MICROSERVICE_DASHBOARD_API_BASEPATH!);
        this.storeManager = StoreManager.getInstance();
    }

    static getInstance() {
        if (MinisiteManager.instance == null) {
            MinisiteManager.instance = new MinisiteManager();
        }
        return MinisiteManager.instance;
    }

    private getState(): MinisiteState {
        return this.storeManager.getState(Slices.Minisite) as MinisiteState;
    }

    async pollExecutionByServiceByIdAndId(jwtHeader: string, jwtPayload: string, jwtSignature: string): Promise<ResponseServiceExecutionMinisite> {
        const payloadBuffer = Buffer.from(jwtPayload, 'base64');
        const payloadJson = JSON.parse(payloadBuffer.toString());
        const response = await this.minisiteService.get(jwtHeader, jwtPayload, jwtSignature);

        if (payloadJson.execId === this.storeManager.getState(Slices.Minisite).execution.selectedExecution?.id) {
            this.storeManager.dispatch(new MinisiteSetSelectedExecution(response));
        }
        return response;
    }

    async getExecutionByServiceByIdAndId(jwtHeader: string, jwtPayload: string, jwtSignature: string): Promise<ResponseServiceExecutionMinisite> {
        const response = await this.minisiteService.get(jwtHeader, jwtPayload, jwtSignature);
        this.storeManager.dispatch(new MinisiteSetSelectedExecution(response));
        return response;
    }

    async launch(jwtHeader: string, jwtPayload: string, jwtSignature: string, email: string): Promise<void> {
        const response = await this.minisiteService.launch(jwtHeader, jwtPayload, jwtSignature, {
            timeout: 1200,
            jupyter_log_level: 'debug',
            user: {
                email: email,
            },
        });
        this.storeManager.dispatch(new MinisiteSetSelectedExecution(response));
    }

    async create(serviceId: string, requestServiceExecutionCreate: RequestServiceExecutionMinisiteCreate): Promise<ResponseServiceExecution> {
        const response = await this.minisiteService.create(serviceId, requestServiceExecutionCreate);
        this.storeManager.dispatch(new MinisiteSetSelectedExecution(response));
        return response;
    }

    async selectExternalUrlInput(
        jwtHeader: string,
        jwtPayload: string,
        jwtSignature: string,
        requestServiceUrlFileSelect: RequestServiceUrlFileSelect,
    ): Promise<ResponseServiceUrlFileSelect> {
        const response = this.minisiteService.selectExternalUrlInput(jwtHeader, jwtPayload, jwtSignature, requestServiceUrlFileSelect);
        return response;
    }

    async pollOutputList(jwtHeader: string, jwtPayload: string, jwtSignature: string): Promise<void> {
        const payloadBuffer = Buffer.from(jwtPayload, 'base64');
        const payloadJson = JSON.parse(payloadBuffer.toString());
        const response = await this.minisiteService.outputList(jwtHeader, jwtPayload, jwtSignature);
        const outputs = response.outputs && Object.values(response.outputs);
        if (payloadJson.execId === this.storeManager.getState(Slices.Minisite).execution.selectedExecution?.id) {
            response.outputs && this.storeManager.dispatch(new MinisiteSetOutputs(outputs as Array<OutputFile>));
        }
    }

    async getOutputList(jwtHeader: string, jwtPayload: string, jwtSignature: string): Promise<void> {
        const response = await this.minisiteService.outputList(jwtHeader, jwtPayload, jwtSignature);
        const outputs = response.outputs && Object.values(response.outputs);
        response.outputs && this.storeManager.dispatch(new MinisiteSetOutputs(outputs as Array<OutputFile>));
    }

    async pollLogs(jwtHeader: string, jwtPayload: string, jwtSignature: string): Promise<string> {
        const payloadBuffer = Buffer.from(jwtPayload, 'base64');
        const payloadJson = JSON.parse(payloadBuffer.toString());
        const response = await this.minisiteService.getLogs(jwtHeader, jwtPayload, jwtSignature);
        if (payloadJson.execId === this.storeManager.getState(Slices.Minisite).execution.selectedExecution?.id) {
            this.storeManager.dispatch(new MinisiteSetExecutionLogs(response));
        }
        return response;
    }

    async getLogs(jwtHeader: string, jwtPayload: string, jwtSignature: string): Promise<string> {
        const response = await this.minisiteService.getLogs(jwtHeader, jwtPayload, jwtSignature);
        this.storeManager.dispatch(new MinisiteSetExecutionLogs(response));
        return response;
    }

    resetOutputAndLogs() {
        this.storeManager.dispatch(new MinisiteResetExecutionHtml());
        this.storeManager.dispatch(new MinisiteResetExecutionLogs());
    }

    async downloadOutput(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile): Promise<File> {
        return await this.minisiteService.downloadOutput(jwtHeader, jwtPayload, jwtSignature, requestFile);
    }

    async downloadInput(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile): Promise<File> {
        return await this.minisiteService.downloadInput(jwtHeader, jwtPayload, jwtSignature, requestFile);
    }

    setEmail(email: string) {
        this.storeManager.dispatch(new MinisiteSetEmail(email));
    }

    async useDataset(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile): Promise<ResponseServiceExecutionMinisite> {
        const response = await this.minisiteService.useDataset(jwtHeader, jwtPayload, jwtSignature, requestFile);
        this.storeManager.dispatch(new MinisiteSetSelectedExecution(response));
        return response;
    }
}
