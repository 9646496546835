/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RequestBehubIntegrationPublishDataset } from '../entities';
// @ts-ignore
import { ResponseBehubIntegrationPublishDataset } from '../entities';
/**
 * BehubIntegrationControllerApi - axios parameter creator
 * @export
 */
export const BehubIntegrationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Publish an input on Data Portal.
         * @param {string} projectId
         * @param {string} target
         * @param {string} fileName
         * @param {RequestBehubIntegrationPublishDataset} requestBehubIntegrationPublishDataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishDataset: async (
            projectId: string,
            target: string,
            fileName: string,
            requestBehubIntegrationPublishDataset: RequestBehubIntegrationPublishDataset,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('publishDataset', 'projectId', projectId);
            // verify required parameter 'target' is not null or undefined
            assertParamExists('publishDataset', 'target', target);
            // verify required parameter 'fileName' is not null or undefined
            assertParamExists('publishDataset', 'fileName', fileName);
            // verify required parameter 'requestBehubIntegrationPublishDataset' is not null or undefined
            assertParamExists('publishDataset', 'requestBehubIntegrationPublishDataset', requestBehubIntegrationPublishDataset);
            const localVarPath = `/behub/publish/{projectId}/{target}/{fileName}`
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'target'}}`, encodeURIComponent(String(target)))
                .replace(`{${'fileName'}}`, encodeURIComponent(String(fileName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestBehubIntegrationPublishDataset, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BehubIntegrationControllerApi - functional programming interface
 * @export
 */
export const BehubIntegrationControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BehubIntegrationControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Publish an input on Data Portal.
         * @param {string} projectId
         * @param {string} target
         * @param {string} fileName
         * @param {RequestBehubIntegrationPublishDataset} requestBehubIntegrationPublishDataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publishDataset(
            projectId: string,
            target: string,
            fileName: string,
            requestBehubIntegrationPublishDataset: RequestBehubIntegrationPublishDataset,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseBehubIntegrationPublishDataset>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publishDataset(projectId, target, fileName, requestBehubIntegrationPublishDataset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BehubIntegrationControllerApi - factory interface
 * @export
 */
export const BehubIntegrationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BehubIntegrationControllerApiFp(configuration);
    return {
        /**
         * Publish an input on Data Portal.
         * @param {string} projectId
         * @param {string} target
         * @param {string} fileName
         * @param {RequestBehubIntegrationPublishDataset} requestBehubIntegrationPublishDataset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishDataset(
            projectId: string,
            target: string,
            fileName: string,
            requestBehubIntegrationPublishDataset: RequestBehubIntegrationPublishDataset,
            options?: any,
        ): AxiosPromise<ResponseBehubIntegrationPublishDataset> {
            return localVarFp.publishDataset(projectId, target, fileName, requestBehubIntegrationPublishDataset, options).then(request => request(axios, basePath));
        },
    };
};

/**
 * BehubIntegrationControllerApi - interface
 * @export
 * @interface BehubIntegrationControllerApi
 */
export interface BehubIntegrationControllerApiInterface {
    /**
     * Publish an input on Data Portal.
     * @param {string} projectId
     * @param {string} target
     * @param {string} fileName
     * @param {RequestBehubIntegrationPublishDataset} requestBehubIntegrationPublishDataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BehubIntegrationControllerApiInterface
     */
    publishDataset(
        projectId: string,
        target: string,
        fileName: string,
        requestBehubIntegrationPublishDataset: RequestBehubIntegrationPublishDataset,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseBehubIntegrationPublishDataset>;
}

/**
 * BehubIntegrationControllerApi - object-oriented interface
 * @export
 * @class BehubIntegrationControllerApi
 * @extends {BaseAPI}
 */
export class BehubIntegrationControllerApi extends BaseAPI implements BehubIntegrationControllerApiInterface {
    /**
     * Publish an input on Data Portal.
     * @param {string} projectId
     * @param {string} target
     * @param {string} fileName
     * @param {RequestBehubIntegrationPublishDataset} requestBehubIntegrationPublishDataset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BehubIntegrationControllerApi
     */
    public publishDataset(
        projectId: string,
        target: string,
        fileName: string,
        requestBehubIntegrationPublishDataset: RequestBehubIntegrationPublishDataset,
        options?: AxiosRequestConfig,
    ) {
        return BehubIntegrationControllerApiFp(this.configuration)
            .publishDataset(projectId, target, fileName, requestBehubIntegrationPublishDataset, options)
            .then(request => request(this.axios, this.basePath));
    }
}
