import { ReactComponent as Chat } from '@assets/svg/it-comment.svg';
import { ReactComponent as ProjectIcon } from '@assets/svg/it-folder.svg';
import { TextArea } from '@features/_shared/components/TextArea';
import { ProvisioningOriginEnum } from '@features/provisioning/views/provisioning.view';
import { TranslatorManager } from '@managers/TranslatorManager';
import { FormManager } from '@managers/form.manager';
import { NavigationManager } from '@managers/navigation.manager';
import { ReactComponent as Close } from '@assets/svg/it-close.svg';
import { ProjectManager } from '@managers/project.manager';
import { ProvisioningManager } from '@managers/provisioning.manager';
import { ServiceManager } from '@managers/service.manager';
import { Paths } from '@routes';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';
import { useEffect } from 'react';
import { BaseProjectTemplate, InputFileStatusEnum, Project, ServiceExecution, ServiceExecutionStatusEnum, WebService, WebServiceStatusEnum } from '../../../api';
import { ServiceRejectFormData } from '../beans/serviceRejectFormData';
import '../styles/service.detail.header.style.scss';

export interface ServiceDetailHeaderProps {
    service?: WebService;
    project?: Project;
    origin?: ProvisioningOriginEnum;
    execution?: ServiceExecution;
    templates?: Array<BaseProjectTemplate>;
    toBeApproved?: boolean;
    onStart?: () => void;
    onStop?: () => void;
    onApprove?: () => void;
    onReject?: () => void;
}
export const ServiceDetailHeader: React.FC<ServiceDetailHeaderProps> = ({ service, templates, project, execution, onStart, onStop, onApprove, onReject, ...props }) => {
    const translator = TranslatorManager.getInstance();
    const projectManager = ProjectManager.getInstance();
    const serviceManager = ServiceManager.getInstance();
    const navigationManager = NavigationManager.getInstance();

    useEffect(() => {
        service?.id_reference_project && projectManager.getProjectById(service?.id_reference_project);
    }, []);

    const handleClickClose = () => {
        const str = window.location.href;
        const start = '/service/';
        const end = '/execution';
        const parts = str.split(start);
        const substrings = parts[1].split(end);
        let serviceId = substrings[0];
        const id = service?.id ? service.id : serviceId;
        switch (props.origin) {
            case ProvisioningOriginEnum.SERVICE:
                navigationManager.navigateTo('/service');
                break;
            case ProvisioningOriginEnum.SERVICEDETAIL:
                ProvisioningManager.getInstance().setOrigin(ProvisioningOriginEnum.SERVICE);
                navigationManager.navigateTo(`/service/${id}` as Paths);
                break;
            case ProvisioningOriginEnum.HOMEPAGE:
                navigationManager.navigateTo('/home');
                break;
            default:
                navigationManager.navigateTo(`/service/${id}` as Paths);
        }
    };

    const handleProjectReferenceClick = () => {
        NavigationManager.getInstance().navigateTo(`/project/${project?.id}` as Paths);
    };

    const handleOnChange = (key: keyof WebService, value: string) => {
        serviceManager.saveValue(key, value);
    };

    const handleOnApproveClick = () => {
        onApprove?.();
    };

    const handleOnRejectClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const error = await trigger();
        if (!error) {
            e.preventDefault();
        } else {
            onReject?.();
            document.getElementById('serviceCreateModalBtnClose')?.click();
            reset();
        }
    };

    const handleModalClose = () => {
        clearErrors('rejectedReason');
        setValue('rejectedReason', '');
    };

    const templateName = templates?.find((item: BaseProjectTemplate) => item.id == service?.technology)?.name;

    const { register, clearErrors, setValue, trigger, reset, errors } = FormManager.getInstance().buildFormProxy<ServiceRejectFormData>(ServiceRejectFormData, () => {});

    return (
        <>
            <div className={`container-fluid header-container mb-3`}>
                <div className='row align-items-center'>
                    <div className='row d-flex justify-content-between'>
                        <div className='col-8 d-flex flex-row align-item-center header-flex'>
                            <div className='d-flex flex-row align-items-center'>
                                <div className='chip chip-simple bg-secondary m-1'>
                                    <span className='chip-label text-white mt-1'>{service?.status?.replace(/_/g, ' ')}</span>
                                </div>
                            </div>
                            {execution && (
                                <div className='d-flex flex-row align-items-center'>
                                    <p className='inline-text m-0 white-space-nowrap'>/ {execution?.run}</p>
                                    <div className=' d-md-flex chip chip-simple bg-secondary m-1'>
                                        <span className='chip-label text-white mt-1'>{execution?.status}</span>
                                    </div>
                                </div>
                            )}
                            {KeycloakUtil.isLoggedIn() && KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) && (
                                <p className='m-2 d-flex align-items-center'>{`${translator.get('header.entity.id')}: ${service?.id}`}</p>
                            )}
                            {NavigationManager.getInstance().getCurrentRoute() === `/service/${service?.id}` &&
                                service?.status === WebServiceStatusEnum.TO_BE_APPROVED &&
                                (KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) || KeycloakUtil.hasRole([KeycloakRoleEnum.reviewer])) && (
                                    <div className='d-flex flex-row align-items-center margin-approve-execution'>
                                        <button className='btn p-2 btn-primary text-white me-2 button-execution-container' onClick={handleOnApproveClick}>
                                            {translator.get('service.detail.header.approve.execution.button.text')}
                                        </button>
                                        <button className='btn p-2 btn-secondary button-execution-container' data-bs-toggle='modal' data-bs-target='#rejectServiceModal'>
                                            {translator.get('service.detail.header.reject.execution.button.text')}
                                        </button>
                                    </div>
                                )}
                        </div>
                        <div className='col-4 p-0 d-flex flex-flow justify-content-end'>
                            <div className='row m-1 headerDetail align-items-center'>
                                <div className='col p-0 height-button d-flex justify-content-end'>
                                    <button className='btn btn-primary text-white d-flex align-items-center btn-close-padding-header' onClick={handleClickClose}>
                                        <h6 className='button-close-text'>{translator.get('service.detail.header.closeProject.text')}</h6>
                                        <Close className='close-container' fill='white' />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row d-flex justify-content-between'>
                        <div className='col-6 align-center'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex flex-column'>
                                    <a
                                        className='m-0 d-flex align-items-center project-name-container text-header'
                                        onClick={handleProjectReferenceClick}
                                        style={{ cursor: 'pointer' }}>
                                        <ProjectIcon className='project-continer me-1' fill='#009102' />
                                        {project?.name}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-8 white-space-nowrap d-flex position-detail-element'>
                            <p className='m-0 created-by inline-text'>
                                <div className='row headerDetail align-items-center'>
                                    <div className='col p-0 width-element-header'>
                                        {project?.project_published && <span style={{ color: '#009133' }}>Published - </span>}
                                        <p className='inline-text ms-1 text-header-detail'>{translator.get('project.table.header.creator.text')}:</p>
                                        <p className='inline-text ms-1 text-header'>{service?.author?.name} </p>
                                    </div>
                                    <div className='col p-0 width-element-header'>
                                        <p className='inline-text ms-1 text-header-detail'>{translator.get('service.table.header.language.text')}:</p>
                                        <p className='inline-text ms-1 text-header'>{templateName ? templateName : ''} </p>
                                    </div>
                                    <div className='col p-0 width-element-header'>
                                        <p className='inline-text ms-1 text-header-detail'>{translator.get('service.table.header.version.text')}:</p>
                                        <p className='inline-text ms-1 text-header'>{service?.version} </p>
                                    </div>
                                </div>
                            </p>
                        </div>
                        {KeycloakUtil.isLoggedIn() && (
                            <div className='col-4 d-flex justify-content-end'>
                                <a
                                    href={`https://meet.jit.si/Lifewatch_BECODE_${project?.id}#userInfo.displayName="${KeycloakUtil.getName()}"&config.prejoinPageEnabled=false`}
                                    target='_blank'
                                    className='startChatroom d-flex flex-row align-items-center focus--mouse'>
                                    <p className='d-none d-md-block m-0'> {translator.get('common.start.chat.button.text')}</p>
                                    <Chat className='chat-container' fill='#009133' />
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='modal fade' role='dialog' id='rejectServiceModal' aria-labelledby='rejectNewServiceTitle' data-bs-backdrop='static' data-bs-keyboard='false'>
                <div className='modal-dialog' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='modal-title h5 no_toc' id='rejectNewServiceTitle'>
                                {translator.get('service.reject.service.modal.title')}
                            </h2>
                            <button
                                className='btn-close'
                                type='button'
                                data-bs-dismiss='modal'
                                aria-label='Close modal'
                                id='serviceCreateModalBtnClose'
                                onClick={handleModalClose}
                            />
                        </div>
                        <div className='modal-body'>
                            <div className='p-1'>{translator.get('service.reject.service.modal.description')}</div>
                            <div>
                                <TextArea
                                    className='textarea-description-container'
                                    id='rejectedReason'
                                    {...register('rejectedReason')}
                                    placeholder='Reason'
                                    rows={3}
                                    onBlur={(e: React.FocusEvent<HTMLTextAreaElement, Element>) => handleOnChange('rejected_reason', e.target.value)}
                                />
                                {errors.rejectedReason && (
                                    <small className='form-text error-message'>{translator.get(`form.service.reject.reason.${errors.rejectedReason.type}`)}</small>
                                )}
                            </div>
                            <div className='mt-3 mb-3 d-flex justify-content-end'>
                                <button
                                    className='btn p-2 btn-primary text-white me-2 button-execution-container'
                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnRejectClick(e)}>
                                    {translator.get('service.detail.header.reject.execution.button.text')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
