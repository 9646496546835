import { ReactComponent as Logo } from '@assets/svg/logo.svg';
import { User } from '@features/login/store/state';
import { ReduxConnect } from '@hypereact/state';
import { NavigationManager } from '@managers/navigation.manager';
import { RootState } from '@store/state';
import _ from 'lodash';
import React from 'react';
import LinkList from './components/linkList.component';
import './styles/sidebar.style.scss';
export interface ISideBarProps {
    isLogged?: boolean;
    user?: User;
    isIDEOpen?: boolean;
    currentRoute?: string;
}
@ReduxConnect((state: RootState) => {
    return {
        isIDEOpen: state.project.project.isIDEOpen,
        currentRoute: state.shared.currentRoute,
    };
})
class SideBar extends React.Component<ISideBarProps> {
    constructor(props: ISideBarProps) {
        super(props);
    }

    render() {
        return (
            <>
                <div className='sidebar-container'>
                    <div className='d-flex justify-content-center w-100'>
                        <a className='sidebar-logo d-flex justify-content-center' onClick={() => NavigationManager.getInstance().navigateTo('/home')}>
                            <Logo width={158} height={78.25} />
                        </a>
                    </div>

                    <LinkList isIDEOpen={this.props.isIDEOpen as boolean} route={this.props.currentRoute as string} />
                </div>
            </>
        );
    }
}

export default SideBar;
