/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InputCheck } from '../entities';
/**
 * DfsTestControllerApi - axios parameter creator
 * @export
 */
export const DfsTestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkDfs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/dfstest/checkdfs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} inputName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkProjectInputExists: async (projectId: string, inputName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('checkProjectInputExists', 'projectId', projectId);
            // verify required parameter 'inputName' is not null or undefined
            assertParamExists('checkProjectInputExists', 'inputName', inputName);
            const localVarPath = `/dfstest/cpie/{projectId}/{inputName}`
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'inputName'}}`, encodeURIComponent(String(inputName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {string} inputName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkServiceInputExists: async (serviceId: string, serviceExecutionId: string, inputName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('checkServiceInputExists', 'serviceId', serviceId);
            // verify required parameter 'serviceExecutionId' is not null or undefined
            assertParamExists('checkServiceInputExists', 'serviceExecutionId', serviceExecutionId);
            // verify required parameter 'inputName' is not null or undefined
            assertParamExists('checkServiceInputExists', 'inputName', inputName);
            const localVarPath = `/dfstest/csie/{serviceId}/{serviceExecutionId}/{inputName}`
                .replace(`{${'serviceId'}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${'serviceExecutionId'}}`, encodeURIComponent(String(serviceExecutionId)))
                .replace(`{${'inputName'}}`, encodeURIComponent(String(inputName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} recipe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanProject: async (projectId: string, recipe: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('cleanProject', 'projectId', projectId);
            // verify required parameter 'recipe' is not null or undefined
            assertParamExists('cleanProject', 'recipe', recipe);
            const localVarPath = `/dfstest/cleanproject/{projectId}/{recipe}`
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'recipe'}}`, encodeURIComponent(String(recipe)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {string} recipe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanService: async (serviceId: string, serviceExecutionId: string, recipe: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('cleanService', 'serviceId', serviceId);
            // verify required parameter 'serviceExecutionId' is not null or undefined
            assertParamExists('cleanService', 'serviceExecutionId', serviceExecutionId);
            // verify required parameter 'recipe' is not null or undefined
            assertParamExists('cleanService', 'recipe', recipe);
            const localVarPath = `/dfstest/cleanservice/{serviceId}/{serviceExecutionId}/{recipe}`
                .replace(`{${'serviceId'}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${'serviceExecutionId'}}`, encodeURIComponent(String(serviceExecutionId)))
                .replace(`{${'recipe'}}`, encodeURIComponent(String(recipe)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExecution: async (id: string, execId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteExecution', 'id', id);
            // verify required parameter 'execId' is not null or undefined
            assertParamExists('deleteExecution', 'execId', execId);
            const localVarPath = `/dfstest/delete/service/{id}/execution/{execId}`
                .replace(`{${'id'}}`, encodeURIComponent(String(id)))
                .replace(`{${'execId'}}`, encodeURIComponent(String(execId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectHard: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProjectHard', 'id', id);
            const localVarPath = `/dfstest/delete/projecthard/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectInput: async (projectId: string, filename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProjectInput', 'projectId', projectId);
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('deleteProjectInput', 'filename', filename);
            const localVarPath = `/dfstest/delete/input/{projectId}/{filename}`
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'filename'}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectOutput: async (projectId: string, filename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProjectOutput', 'projectId', projectId);
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('deleteProjectOutput', 'filename', filename);
            const localVarPath = `/dfstest/delete/output/{projectId}/{filename}`
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'filename'}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectSoft: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProjectSoft', 'id', id);
            const localVarPath = `/dfstest/delete/projectsoft/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteService: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteService', 'id', id);
            const localVarPath = `/dfstest/delete/service/{id}`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExcecutionId
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceInput: async (serviceId: string, serviceExcecutionId: string, filename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('deleteServiceInput', 'serviceId', serviceId);
            // verify required parameter 'serviceExcecutionId' is not null or undefined
            assertParamExists('deleteServiceInput', 'serviceExcecutionId', serviceExcecutionId);
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('deleteServiceInput', 'filename', filename);
            const localVarPath = `/dfstest/delete/input/{serviceId}/{serviceExcecutionId}/{filename}`
                .replace(`{${'serviceId'}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${'serviceExcecutionId'}}`, encodeURIComponent(String(serviceExcecutionId)))
                .replace(`{${'filename'}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExcecutionId
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceOutput: async (serviceId: string, serviceExcecutionId: string, filename: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('deleteServiceOutput', 'serviceId', serviceId);
            // verify required parameter 'serviceExcecutionId' is not null or undefined
            assertParamExists('deleteServiceOutput', 'serviceExcecutionId', serviceExcecutionId);
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('deleteServiceOutput', 'filename', filename);
            const localVarPath = `/dfstest/delete/output/{serviceId}/{serviceExcecutionId}/{filename}`
                .replace(`{${'serviceId'}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${'serviceExcecutionId'}}`, encodeURIComponent(String(serviceExcecutionId)))
                .replace(`{${'filename'}}`, encodeURIComponent(String(filename)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {string} inputName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputStreamForDataset: async (serviceId: string, serviceExecutionId: string, inputName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('getInputStreamForDataset', 'serviceId', serviceId);
            // verify required parameter 'serviceExecutionId' is not null or undefined
            assertParamExists('getInputStreamForDataset', 'serviceExecutionId', serviceExecutionId);
            // verify required parameter 'inputName' is not null or undefined
            assertParamExists('getInputStreamForDataset', 'inputName', inputName);
            const localVarPath = `/dfstest/osd/{serviceId}/{serviceExecutionId}/{inputName}`
                .replace(`{${'serviceId'}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${'serviceExecutionId'}}`, encodeURIComponent(String(serviceExecutionId)))
                .replace(`{${'inputName'}}`, encodeURIComponent(String(inputName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} inputName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputStreamForDataset1: async (projectId: string, inputName: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getInputStreamForDataset1', 'projectId', projectId);
            // verify required parameter 'inputName' is not null or undefined
            assertParamExists('getInputStreamForDataset1', 'inputName', inputName);
            const localVarPath = `/dfstest/opd/{projectId}/{inputName}`
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'inputName'}}`, encodeURIComponent(String(inputName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {string} inputName
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeSupportInputAvailable: async (
            projectId: string,
            inputName: string,
            serviceId: string,
            serviceExecutionId: string,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('makeSupportInputAvailable', 'projectId', projectId);
            // verify required parameter 'inputName' is not null or undefined
            assertParamExists('makeSupportInputAvailable', 'inputName', inputName);
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('makeSupportInputAvailable', 'serviceId', serviceId);
            // verify required parameter 'serviceExecutionId' is not null or undefined
            assertParamExists('makeSupportInputAvailable', 'serviceExecutionId', serviceExecutionId);
            const localVarPath = `/dfstest/msia/{projectId}/{inputName}/{serviceId}/{serviceExecutionId}`
                .replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)))
                .replace(`{${'inputName'}}`, encodeURIComponent(String(inputName)))
                .replace(`{${'serviceId'}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${'serviceExecutionId'}}`, encodeURIComponent(String(serviceExecutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepareProject: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('prepareProject', 'projectId', projectId);
            const localVarPath = `/dfstest/prepareproject/{projectId}`.replace(`{${'projectId'}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepareService: async (serviceId: string, serviceExecutionId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'serviceId' is not null or undefined
            assertParamExists('prepareService', 'serviceId', serviceId);
            // verify required parameter 'serviceExecutionId' is not null or undefined
            assertParamExists('prepareService', 'serviceExecutionId', serviceExecutionId);
            const localVarPath = `/dfstest/prepareservice/{serviceId}/{serviceExecutionId}`
                .replace(`{${'serviceId'}}`, encodeURIComponent(String(serviceId)))
                .replace(`{${'serviceExecutionId'}}`, encodeURIComponent(String(serviceExecutionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * DfsTestControllerApi - functional programming interface
 * @export
 */
export const DfsTestControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = DfsTestControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkDfs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkDfs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} projectId
         * @param {string} inputName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkProjectInputExists(
            projectId: string,
            inputName: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputCheck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkProjectInputExists(projectId, inputName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {string} inputName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkServiceInputExists(
            serviceId: string,
            serviceExecutionId: string,
            inputName: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InputCheck>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkServiceInputExists(serviceId, serviceExecutionId, inputName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} projectId
         * @param {string} recipe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cleanProject(projectId: string, recipe: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cleanProject(projectId, recipe, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {string} recipe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async cleanService(
            serviceId: string,
            serviceExecutionId: string,
            recipe: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.cleanService(serviceId, serviceExecutionId, recipe, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteExecution(id: string, execId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteExecution(id, execId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectHard(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectHard(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} projectId
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectInput(projectId: string, filename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectInput(projectId, filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} projectId
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectOutput(projectId: string, filename: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectOutput(projectId, filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectSoft(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectSoft(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteService(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteService(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExcecutionId
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteServiceInput(
            serviceId: string,
            serviceExcecutionId: string,
            filename: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteServiceInput(serviceId, serviceExcecutionId, filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExcecutionId
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteServiceOutput(
            serviceId: string,
            serviceExcecutionId: string,
            filename: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteServiceOutput(serviceId, serviceExcecutionId, filename, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {string} inputName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInputStreamForDataset(
            serviceId: string,
            serviceExecutionId: string,
            inputName: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInputStreamForDataset(serviceId, serviceExecutionId, inputName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} projectId
         * @param {string} inputName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInputStreamForDataset1(
            projectId: string,
            inputName: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInputStreamForDataset1(projectId, inputName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} projectId
         * @param {string} inputName
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async makeSupportInputAvailable(
            projectId: string,
            inputName: string,
            serviceId: string,
            serviceExecutionId: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.makeSupportInputAvailable(projectId, inputName, serviceId, serviceExecutionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prepareProject(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prepareProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prepareService(
            serviceId: string,
            serviceExecutionId: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prepareService(serviceId, serviceExecutionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * DfsTestControllerApi - factory interface
 * @export
 */
export const DfsTestControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DfsTestControllerApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkDfs(options?: any): AxiosPromise<void> {
            return localVarFp.checkDfs(options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} projectId
         * @param {string} inputName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkProjectInputExists(projectId: string, inputName: string, options?: any): AxiosPromise<InputCheck> {
            return localVarFp.checkProjectInputExists(projectId, inputName, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {string} inputName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkServiceInputExists(serviceId: string, serviceExecutionId: string, inputName: string, options?: any): AxiosPromise<InputCheck> {
            return localVarFp.checkServiceInputExists(serviceId, serviceExecutionId, inputName, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} projectId
         * @param {string} recipe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanProject(projectId: string, recipe: string, options?: any): AxiosPromise<void> {
            return localVarFp.cleanProject(projectId, recipe, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {string} recipe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanService(serviceId: string, serviceExecutionId: string, recipe: string, options?: any): AxiosPromise<void> {
            return localVarFp.cleanService(serviceId, serviceExecutionId, recipe, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {string} execId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteExecution(id: string, execId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteExecution(id, execId, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectHard(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectHard(id, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} projectId
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectInput(projectId: string, filename: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectInput(projectId, filename, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} projectId
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectOutput(projectId: string, filename: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectOutput(projectId, filename, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectSoft(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProjectSoft(id, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteService(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteService(id, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExcecutionId
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceInput(serviceId: string, serviceExcecutionId: string, filename: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteServiceInput(serviceId, serviceExcecutionId, filename, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExcecutionId
         * @param {string} filename
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteServiceOutput(serviceId: string, serviceExcecutionId: string, filename: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteServiceOutput(serviceId, serviceExcecutionId, filename, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {string} inputName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputStreamForDataset(serviceId: string, serviceExecutionId: string, inputName: string, options?: any): AxiosPromise<void> {
            return localVarFp.getInputStreamForDataset(serviceId, serviceExecutionId, inputName, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} projectId
         * @param {string} inputName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInputStreamForDataset1(projectId: string, inputName: string, options?: any): AxiosPromise<void> {
            return localVarFp.getInputStreamForDataset1(projectId, inputName, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} projectId
         * @param {string} inputName
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        makeSupportInputAvailable(projectId: string, inputName: string, serviceId: string, serviceExecutionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.makeSupportInputAvailable(projectId, inputName, serviceId, serviceExecutionId, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} projectId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepareProject(projectId: string, options?: any): AxiosPromise<void> {
            return localVarFp.prepareProject(projectId, options).then(request => request(axios, basePath));
        },
        /**
         *
         * @param {string} serviceId
         * @param {string} serviceExecutionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prepareService(serviceId: string, serviceExecutionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.prepareService(serviceId, serviceExecutionId, options).then(request => request(axios, basePath));
        },
    };
};

/**
 * DfsTestControllerApi - interface
 * @export
 * @interface DfsTestControllerApi
 */
export interface DfsTestControllerApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    checkDfs(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} projectId
     * @param {string} inputName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    checkProjectInputExists(projectId: string, inputName: string, options?: AxiosRequestConfig): AxiosPromise<InputCheck>;

    /**
     *
     * @param {string} serviceId
     * @param {string} serviceExecutionId
     * @param {string} inputName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    checkServiceInputExists(serviceId: string, serviceExecutionId: string, inputName: string, options?: AxiosRequestConfig): AxiosPromise<InputCheck>;

    /**
     *
     * @param {string} projectId
     * @param {string} recipe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    cleanProject(projectId: string, recipe: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} serviceId
     * @param {string} serviceExecutionId
     * @param {string} recipe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    cleanService(serviceId: string, serviceExecutionId: string, recipe: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    deleteExecution(id: string, execId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    deleteProjectHard(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} projectId
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    deleteProjectInput(projectId: string, filename: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} projectId
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    deleteProjectOutput(projectId: string, filename: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    deleteProjectSoft(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    deleteService(id: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} serviceId
     * @param {string} serviceExcecutionId
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    deleteServiceInput(serviceId: string, serviceExcecutionId: string, filename: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} serviceId
     * @param {string} serviceExcecutionId
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    deleteServiceOutput(serviceId: string, serviceExcecutionId: string, filename: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} serviceId
     * @param {string} serviceExecutionId
     * @param {string} inputName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    getInputStreamForDataset(serviceId: string, serviceExecutionId: string, inputName: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} projectId
     * @param {string} inputName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    getInputStreamForDataset1(projectId: string, inputName: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} projectId
     * @param {string} inputName
     * @param {string} serviceId
     * @param {string} serviceExecutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    makeSupportInputAvailable(projectId: string, inputName: string, serviceId: string, serviceExecutionId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    prepareProject(projectId: string, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     *
     * @param {string} serviceId
     * @param {string} serviceExecutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApiInterface
     */
    prepareService(serviceId: string, serviceExecutionId: string, options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * DfsTestControllerApi - object-oriented interface
 * @export
 * @class DfsTestControllerApi
 * @extends {BaseAPI}
 */
export class DfsTestControllerApi extends BaseAPI implements DfsTestControllerApiInterface {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public checkDfs(options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .checkDfs(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} projectId
     * @param {string} inputName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public checkProjectInputExists(projectId: string, inputName: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .checkProjectInputExists(projectId, inputName, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} serviceId
     * @param {string} serviceExecutionId
     * @param {string} inputName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public checkServiceInputExists(serviceId: string, serviceExecutionId: string, inputName: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .checkServiceInputExists(serviceId, serviceExecutionId, inputName, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} projectId
     * @param {string} recipe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public cleanProject(projectId: string, recipe: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .cleanProject(projectId, recipe, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} serviceId
     * @param {string} serviceExecutionId
     * @param {string} recipe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public cleanService(serviceId: string, serviceExecutionId: string, recipe: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .cleanService(serviceId, serviceExecutionId, recipe, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {string} execId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public deleteExecution(id: string, execId: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .deleteExecution(id, execId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public deleteProjectHard(id: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .deleteProjectHard(id, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} projectId
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public deleteProjectInput(projectId: string, filename: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .deleteProjectInput(projectId, filename, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} projectId
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public deleteProjectOutput(projectId: string, filename: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .deleteProjectOutput(projectId, filename, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public deleteProjectSoft(id: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .deleteProjectSoft(id, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public deleteService(id: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .deleteService(id, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} serviceId
     * @param {string} serviceExcecutionId
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public deleteServiceInput(serviceId: string, serviceExcecutionId: string, filename: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .deleteServiceInput(serviceId, serviceExcecutionId, filename, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} serviceId
     * @param {string} serviceExcecutionId
     * @param {string} filename
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public deleteServiceOutput(serviceId: string, serviceExcecutionId: string, filename: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .deleteServiceOutput(serviceId, serviceExcecutionId, filename, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} serviceId
     * @param {string} serviceExecutionId
     * @param {string} inputName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public getInputStreamForDataset(serviceId: string, serviceExecutionId: string, inputName: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .getInputStreamForDataset(serviceId, serviceExecutionId, inputName, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} projectId
     * @param {string} inputName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public getInputStreamForDataset1(projectId: string, inputName: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .getInputStreamForDataset1(projectId, inputName, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} projectId
     * @param {string} inputName
     * @param {string} serviceId
     * @param {string} serviceExecutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public makeSupportInputAvailable(projectId: string, inputName: string, serviceId: string, serviceExecutionId: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .makeSupportInputAvailable(projectId, inputName, serviceId, serviceExecutionId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} projectId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public prepareProject(projectId: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .prepareProject(projectId, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     *
     * @param {string} serviceId
     * @param {string} serviceExecutionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DfsTestControllerApi
     */
    public prepareService(serviceId: string, serviceExecutionId: string, options?: AxiosRequestConfig) {
        return DfsTestControllerApiFp(this.configuration)
            .prepareService(serviceId, serviceExecutionId, options)
            .then(request => request(this.axios, this.basePath));
    }
}
