import { ReactComponent as HideIdeButton } from '@assets/svg/arrows-left.svg';
import { ReactComponent as ArrowDown } from '@assets/svg/it-arrow-down-triangle.svg';
import { ReactComponent as Back } from '@assets/svg/it-arrow-left.svg';
import { ReactComponent as ArrowUp } from '@assets/svg/it-arrow-up-triangle.svg';
import { ReactComponent as MoreAction } from '@assets/svg/it-more-actions.svg';
import { ReactComponent as Play } from '@assets/svg/play.svg';
import { ReactComponent as Stop } from '@assets/svg/stop.svg';
import Breadcrumb from '@components/Breadcrumb/breadcrumb.component';
import Header from '@features/_shared/components/Header/header.component';
import { PopupType } from '@features/_shared/components/Popup/popup.item';
import { TextField } from '@features/_shared/components/TextField';
import { StyledTooltip } from '@features/_shared/components/Tooltip/Tooltip';
import AccordionComponent from '@features/project/components/project.detail.component';
import { ProvisioningPageEnum } from '@features/provisioning/store/state';
import { wizardPublicationDataset, wizardPublicationProject, wizardPublicationScript } from '@features/provisioning/utils/constant';
import { ProvisioningOriginEnum } from '@features/provisioning/views/provisioning.view';
import ServiceCreateFormView from '@features/service/components/service.create.form.component';
import { ServiceState } from '@features/service/store/state';
import { ReduxConnect, StoreManager } from '@hypereact/state';
import { TranslatorManager } from '@managers/TranslatorManager';
import { BackofficeManager } from '@managers/backoffice.manager';
import { NavigationManager } from '@managers/navigation.manager';
import { PopupManager } from '@managers/popup.manager';
import { ProjectManager } from '@managers/project.manager';
import { ProvisioningManager } from '@managers/provisioning.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { ServiceManager } from '@managers/service.manager';
import { Paths } from '@routes';
import { RootState } from '@store/state';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';
import { PollingUtil } from '@utils/polling.util';
import _ from 'lodash';
import React, { RefObject, createRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { BaseProjectTemplate, Project, ProjectStatusEnum, RequestProjectClone, RequestServiceCreate, User, WebService } from '../../../api/entities';
import { ProjectCloneFormView } from '../components/project.clone.form.components';
import '../components/styles/project.detail.style.scss';
import { ProjectResetPaginationAction, ProjectSetStatus } from '../state/actions';
import { MemberCreate, ProjectCreateUpdate } from '../state/state';

export interface IProjectDetailViewProps extends RouteComponentProps {
    isIDEOpen: boolean;
    project: Project;
    user: User;
    createUpdate: ProjectCreateUpdate;
    templates: Array<BaseProjectTemplate>;
    availableCategoryOptions?: string[];
    projectCreate: ProjectCreateUpdate;
    serviceCreateUpdate: WebService;
    newCollaborator?: MemberCreate;
    provenance: Array<Project>;
    service: ServiceState;
    origin: ProvisioningOriginEnum;
    configuration: { [key: string]: string | undefined };
}

export interface IProjectDetailViewState {
    isModalOpen: boolean;
    isAddingTimeout: boolean;
    timeoutValue: number;
    openedMenu: string | null;
}

@ReduxConnect((state: RootState) => {
    return {
        isIDEOpen: state.project.project.isIDEOpen,
        project: state.project.project.selectedProject,
        user: state.login,
        createUpdate: state.project.project.createUpdate,
        templates: state.project.template,
        availableCategoryOptions: state.project.project.categoryAvailableOptions,
        serviceCreateUpdate: state.service.service.createUpdate,
        newCollaborator: state.project.project.newCollaborator,
        provenance: state.project.project.provenance,
        service: state.service,
        origin: state.provisioning.origin,
        configuration: state.shared.configuration,
    };
})
export class ProjectDetailView extends React.Component<IProjectDetailViewProps, IProjectDetailViewState> {
    private projectManager: ProjectManager;
    private translator: TranslatorManager;
    private dropdownRef: RefObject<HTMLDivElement>;
    private provisioningManager: ProvisioningManager;
    private navigationManager: NavigationManager;
    private serviceManager: ServiceManager;
    private queueManager: QueueManager;
    private backofficeManager: BackofficeManager;
    private storeManager: StoreManager;
    private popupManager: PopupManager;

    constructor(props: IProjectDetailViewProps) {
        super(props);
        this.translator = TranslatorManager.getInstance();
        this.projectManager = ProjectManager.getInstance();
        this.translator = TranslatorManager.getInstance();
        this.serviceManager = ServiceManager.getInstance();
        this.queueManager = QueueManager.getInstance();
        this.storeManager = StoreManager.getInstance();
        this.provisioningManager = ProvisioningManager.getInstance();
        this.navigationManager = NavigationManager.getInstance();
        this.backofficeManager = BackofficeManager.getInstance();
        this.popupManager = PopupManager.getInstance();
        this.dropdownRef = createRef();
        this.manageIDE = this.manageIDE.bind(this);
        this.handleOpenClick = this.handleOpenClick.bind(this);
        this.handleStartClick = this.handleStartClick.bind(this);
        this.handleStopClick = this.handleStopClick.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleOnServiceChange = this.handleOnServiceChange.bind(this);
        this.handleCloseIdeClick = this.handleCloseIdeClick.bind(this);
        this.handleCloneProject = this.handleCloneProject.bind(this);
        this.handleClickOutsideDropdown = this.handleClickOutsideDropdown.bind(this);
        this.handleCreateService = this.handleCreateService.bind(this);
        this.handleModalClose = this.handleModalClose.bind(this);
        this.handlePublicationClick = this.handlePublicationClick.bind(this);
        this.handleHeaderCloseClick = this.handleHeaderCloseClick.bind(this);
        this.handleDropdownClose = this.handleDropdownClose.bind(this);

        this.state = {
            isModalOpen: false,
            isAddingTimeout: false,
            timeoutValue: 0,
            openedMenu: null,
        };
    }

    async componentDidMount() {
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.backofficeManager.getKeys({ startsWith: 'ui.project' }));
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.handleOnProjectDetailViewLoad());
        await this.queueManager.queue(QueueKeyType.POLLER, this.handleProjectDetailViewPoll());
        document.addEventListener('click', this.handleClickOutsideDropdown);
    }

    shouldComponentUpdate(nextProp: IProjectDetailViewProps, nextState: IProjectDetailViewState) {
        if (_.isEqual(nextProp, this.props) && _.isEqual(nextState, this.state)) {
            return false;
        } else {
            return true;
        }
    }

    componentWillUnmount() {
        PollingUtil.unregister('projectdetaildata');
        PollingUtil.unregister('projectdetailoutput');
        this.projectManager.manageIDE(false);
        document.removeEventListener('click', this.handleClickOutsideDropdown);
    }

    handleClickOutsideDropdown(event: any) {
        if (!(event.target.closest('.more-action-button') || event.target.closest('.publish-container'))) {
            this.setState({
                openedMenu: null,
                isAddingTimeout: false,
            });
        }
    }

    handleDropdownClose(actionMenu?: boolean) {
        let newDropdownMenuId = actionMenu ? `actions` : `moreAction`;
        this.state.openedMenu === newDropdownMenuId ? this.setState({ openedMenu: null }) : this.setState({ openedMenu: newDropdownMenuId });
    }

    async handleOnProjectDetailViewLoad() {
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(window.location.href.split('project/')[1]));
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getTemplateList());
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectCategories());
        KeycloakUtil.isLoggedIn() && this.props.project?.id && (await this.projectManager.getProvenanceTree(this.props.project?.id));
    }

    async handleProjectDetailViewPoll() {
        let validateProject = () => this.navigationManager.getCurrentRoute() === `/project/${this.props.project?.id}`;
        let validateOutputList = () =>
            this.navigationManager.getCurrentRoute() === `/project/${this.props.project?.id}` &&
            (this.props.project?.status === ProjectStatusEnum.RUNNING || this.props.project?.status === ProjectStatusEnum.LOADING);
        await PollingUtil.register(
            'projectdetaildata',
            async () => await this.projectManager.pollProjectById(this.props.project?.id as string),
            validateProject,
            10000,
            this.props.project?.id,
        );
        await PollingUtil.register(
            'projectdetailoutput',
            async () => await this.projectManager.pollOutputList(this.props.project?.id as string),
            validateOutputList,
            10000,
            this.props.project?.id,
        );
    }

    manageIDE(isOpen: boolean) {
        this.projectManager.manageIDE(isOpen);
    }

    handleOpenClick() {
        this.projectManager.manageIDE(true);
    }

    async handleStartClick() {
        let validateOutputList = () =>
            this.navigationManager.getCurrentRoute() === `/project/${this.props.project?.id}` &&
            (this.props.project?.status === ProjectStatusEnum.RUNNING || this.props.project?.status === ProjectStatusEnum.LOADING);
        const { id } = this.props.project;
        this.storeManager.dispatch(new ProjectSetStatus(ProjectStatusEnum.LOADING));
        await PollingUtil.register(
            'projectdetailoutput',
            async () => await this.projectManager.pollOutputList(this.props.project?.id as string),
            validateOutputList,
            10000,
            this.props.project?.id,
        );
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.start(id as string, this.state.timeoutValue));
    }

    async handleStopClick(force: boolean) {
        const { id } = this.props.project;
        this.storeManager.dispatch(new ProjectSetStatus(ProjectStatusEnum.STOPPING));
        id && (await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.stop(id, force)));
        this.projectManager.manageIDE(false);
        PollingUtil.unregister('projectdetailoutput');
    }

    handleCloseIdeClick() {
        this.projectManager.manageIDE(false);
    }

    async handleCloneProject() {
        const { name, version, description, categories, keywords } = this.props.createUpdate;
        const { id } = this.props.project;
        const username = KeycloakUtil.getName();
        const email = KeycloakUtil.getEmail();
        const request: RequestProjectClone = {
            source_project_id: id,
            name: name as string,
            version: version as string,
            author: {
                name: username,
                email: email,
            },
            description: description,
            categories: categories,
            keywords: keywords,
        };
        const response = await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.clone(request));
        response.id && (await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(response.id)));
        document.getElementById('modalBtnClose')?.click();
        this.navigationManager.navigateTo(`/project/${response.id}` as Paths);
    }

    handleOnChange(key: keyof ProjectCreateUpdate, value: string | number | boolean | Array<string>) {
        this.projectManager.saveValue(key, value);
        if (Array.isArray(value)) {
            this.projectManager.updateAvailableOptions(value);
        }
    }

    handleOnServiceChange(key: keyof WebService, value: string | number | boolean | Array<string>) {
        this.serviceManager.saveValue(key, value);
        if (Array.isArray(value)) {
            this.projectManager.updateAvailableOptions(value);
        }
    }

    handleModalClose() {
        this.setState({
            isModalOpen: false,
        });
        this.projectManager.resetCreateUpdate();
    }

    async handleCloneClick(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation();
        this.handleOnChange('description' as keyof ProjectCreateUpdate, this.props.project?.description as string);
        this.handleOnChange('categories' as keyof ProjectCreateUpdate, this.props.project?.categories as string[]);
        this.handleOnChange('keywords' as keyof ProjectCreateUpdate, this.props.project?.keywords as string[]);
        this.props.availableCategoryOptions &&
            this.projectManager.updateAvailableOptions(this.props.availableCategoryOptions.filter(category => this.props.project?.categories?.includes(category)));
        this.setState({
            isModalOpen: true,
        });
    }

    async handleCreateService() {
        const { technology, id } = this.props.project;
        const username = KeycloakUtil.getName();
        const email = KeycloakUtil.getEmail();
        const { name, version, description, keywords, categories } = this.props.service?.service?.createUpdate;
        const request: RequestServiceCreate = {
            name: name as string,
            idReferenceProject: id,
            version: version as string,
            technology: technology,
            author: {
                name: username,
                email: email,
            },
            description: description,
            categories: categories as string[],
            keywords: keywords,
        };
        const response = await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.create(request));
        const firstExecution = await this.queueManager.queue(
            QueueKeyType.DEFAULT,
            this.serviceManager.createExecution(response.id as string, {
                author: {
                    email: email,
                    name: username,
                },
            }),
        );
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.launch(response.id as string, firstExecution.id as string));
        document.getElementById('serviceCreateModalBtnClose')?.click();
        this.popupManager.show({
            type: PopupType.Info,
            title: this.translator.get('project.popup.service.created.title'),
            description: this.translator.get('project.popup.service.created.description'),
            maxWidth: 'xl',
            stylingProp: 'popup-display',
            ctas: {
                primary: {
                    label: this.translator.get('popup.menu.ok.title'),
                    className: 'popup-accept-button',
                    cta: async () => {
                        this.popupManager.hide();
                        this.navigationManager.navigateTo('/service');
                    },
                },
            },
        });
    }

    async handlePublicationClick(e: React.MouseEvent<HTMLHyperlinkElementUtils>, id: string, wizardPublication: string[], currentPage: ProvisioningPageEnum, index: number) {
        e.stopPropagation();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(id));
        this.provisioningManager.setSteps(wizardPublication);
        this.provisioningManager.setCurrentPage(currentPage);
        this.provisioningManager.setOrigin(ProvisioningOriginEnum.PROJECTDETAIL);
        this.navigationManager.navigateTo(wizardPublication[index ? index : 0] as Paths);
    }

    async handleDownloadRepoClick(e: React.MouseEvent<HTMLHyperlinkElementUtils>, projectId: string, zipname: string) {
        e.stopPropagation();
        const response = await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.downloadRepo(projectId, zipname));
        const href = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${zipname}.zip`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    handleHeaderCloseClick() {
        this.props.origin === ProvisioningOriginEnum.HOMEPAGE ? this.navigationManager.navigateTo('/home') : this.navigationManager.navigateTo('/project');
    }

    async handleCreateServiceClick(e: React.MouseEvent<HTMLElement>) {
        e.stopPropagation();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(e.currentTarget.id));
        this.handleOnServiceChange('categories' as keyof WebService, this.props.project.categories as string[]);
        this.handleOnServiceChange('keywords' as keyof WebService, this.props.project.keywords as any[]);
        this.handleOnServiceChange('description' as keyof WebService, this.props.project.description as string);
        this.setState({
            isModalOpen: true,
        });
    }

    async handleArchiveClick(e: React.MouseEvent<HTMLHyperlinkElementUtils>, projectId: string) {
        e.stopPropagation();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.archive(projectId));
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(projectId));
    }

    async handleDeleteProject(e: React.MouseEvent<HTMLHyperlinkElementUtils>, projectId: string) {
        PollingUtil.unregister('projectdetaildata');
        PollingUtil.unregister('projectdetailoutput');
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.delete(projectId));
        this.storeManager.dispatch(new ProjectResetPaginationAction());
        this.navigationManager.navigateTo('/project');
    }

    handleDeleteClick(e: React.MouseEvent<HTMLHyperlinkElementUtils>, projectId: string) {
        e.stopPropagation();
        this.popupManager.show({
            type: PopupType.Info,
            title: this.translator.get('project.popup.delete.project.title'),
            description: this.translator.get('project.popup.delete.project.description'),
            maxWidth: 'lg',
            stylingProp: 'popup-display',
            ctas: {
                primary: {
                    label: this.translator.get('project.popup.delete.project.button'),
                    className: 'popup-accept-button',
                    cta: () => this.handleDeleteProject(e, projectId),
                },
                secondary: {
                    label: this.translator.get('common.button.cancel.label'),
                    className: 'popup-accept-button',
                    cta: () => this.popupManager.hide(),
                },
            },
        });
    }

    async handleForceStopClick(e: any, projectId: string) {
        e.stopPropagation();
        this.popupManager.show({
            type: PopupType.Info,
            title: this.translator.get('project.popup.force.stop.title'),
            description: this.translator.get('project.popup.force.stop.description'),
            maxWidth: 'lg',
            stylingProp: 'popup-display',
            ctas: {
                primary: {
                    label: this.translator.get('project.popup.force.stop.button'),
                    className: 'popup-accept-button',
                    cta: async () => this.handleForceStop(projectId),
                },
                secondary: {
                    label: this.translator.get('common.button.cancel.label'),
                    className: 'popup-accept-button',
                    cta: () => this.popupManager.hide(),
                },
            },
        });
    }

    async handleForceStop(projectId: string) {
        this.storeManager.dispatch(new ProjectSetStatus(ProjectStatusEnum.STOPPING));
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.stop(projectId, true));
        this.projectManager.manageIDE(false);
        PollingUtil.unregister('projectdetailoutput');
    }

    render() {
        const { project, user, templates, serviceCreateUpdate, origin } = this.props;
        const gitUsername = KeycloakUtil.getName()?.replace(' ', '%20');
        const gitToken = KeycloakUtil.getGitlabToken();
        const gitUserId = KeycloakUtil.getGitlabUserId();
        return (
            <>
                <div className='header-title-container pb-0'>
                    <p className='header-title col-12 mb-4 mt-0'>{project?.name}</p>
                    <Breadcrumb secondaryPages={[this.translator.get('project.detail.breadcrumb.secondary.item')]} />
                </div>
                <Header project={project} templates={this.props.templates} handleClickClose={this.handleHeaderCloseClick} origin={origin} />
                <div className='container-fluid'>
                    <div className='row '>
                        {
                            <div className='col-12'>
                                <div className={`${this.props.isIDEOpen && 'container-gray mb-2 mb-lg-0'}`}>
                                    <div className='row'>
                                        <div className='col-5 side-control-container'>
                                            {this.props.isIDEOpen && (
                                                <div className='hideAccordionsButton container-gray align-items-center'>
                                                    <button className='btn btn-white sidePanelControl' onClick={this.handleCloseIdeClick}>
                                                        <HideIdeButton className='icon' />
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        {this.props.project?.status !== ProjectStatusEnum.TO_BE_ARCHIVED && this.props.project?.status !== ProjectStatusEnum.FORKING && (
                                            <div
                                                className={`col-7 d-flex project-commands-col side-control-container ${
                                                    this.props.isIDEOpen ? 'justify-content-start' : 'justify-content-end'
                                                }`}>
                                                {(this.props.project?.status === ProjectStatusEnum.LOADING || this.props.project?.status === ProjectStatusEnum.STOPPING) && (
                                                    <div className='d-flex align-items-center me-4'>
                                                        <div className='input-loader'></div>
                                                    </div>
                                                )}
                                                <div className={`${this.props.isIDEOpen ? 'projectCommandsContainer-small' : 'projectCommandsContainer'} d-flex flex-row`}>
                                                    {!this.props.isIDEOpen &&
                                                        !this.props.project?.script_published &&
                                                        this.props.project?.service_publish_date === null &&
                                                        KeycloakUtil.isLoggedIn() &&
                                                        (this.props.project?.author?.email === KeycloakUtil.getEmail() ||
                                                            KeycloakUtil.hasRole([KeycloakRoleEnum.reviewer]) ||
                                                            KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                                            project?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())) && (
                                                            <StyledTooltip trigger='hover' position='top' labelKey={this.translator.get('common.open.button.tooltip')}>
                                                                <div className='open-button-container'>
                                                                    <button
                                                                        className='projectCommandsButton btn btn-primary text-white'
                                                                        onClick={this.handleOpenClick}
                                                                        disabled={this.props.project?.status === ProjectStatusEnum.RUNNING ? false : true}>
                                                                        {this.translator.get('common.open.button.text')}
                                                                    </button>
                                                                </div>
                                                            </StyledTooltip>
                                                        )}
                                                    <div className={`${!this.props.isIDEOpen && 'projectCommandsButtonContainer'} d-flex flex-row align-items-center`}>
                                                        {KeycloakUtil.isLoggedIn() &&
                                                        (this.props.project?.author?.email === KeycloakUtil.getEmail() ||
                                                            KeycloakUtil.hasRole([KeycloakRoleEnum.reviewer]) ||
                                                            KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                                            project?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())) &&
                                                        !this.props.project?.script_published &&
                                                        this.props.project?.service_publish_date === null ? (
                                                            this.props.project?.status === ProjectStatusEnum.RUNNING ||
                                                            this.props.project?.status === ProjectStatusEnum.LOADING ||
                                                            this.props.project?.status === ProjectStatusEnum.STOPPING ? (
                                                                <button
                                                                    className='btn btn-outline-primary d-flex flex-row align-items-center button-icon-container start-stop-button'
                                                                    onClick={() => this.handleStopClick(false)}
                                                                    disabled={this.props.project?.status === ProjectStatusEnum.RUNNING ? false : true}>
                                                                    <span>{this.translator.get('common.stop.button.text')}</span>
                                                                    <Stop className='ms-2 icon-button' fill='##009138' />
                                                                </button>
                                                            ) : (
                                                                (this.props.project?.status === ProjectStatusEnum.READY ||
                                                                    this.props.project?.status === ProjectStatusEnum.STOPPED ||
                                                                    this.props.project?.status === ProjectStatusEnum.FAILED ||
                                                                    this.props.project?.status === ProjectStatusEnum.ARCHIVED) && (
                                                                    <StyledTooltip trigger='hover' position='top' labelKey={this.translator.get('common.start.button.tooltip')}>
                                                                        <div>
                                                                            <button
                                                                                className='btn btn-outline-primary d-flex flex-row align-items-center button-icon-container start-stop-button'
                                                                                onClick={this.handleStartClick}>
                                                                                <span>{this.translator.get('common.start.button.text')}</span>
                                                                                <Play className='ms-2 icon-button' />
                                                                            </button>
                                                                        </div>
                                                                    </StyledTooltip>
                                                                )
                                                            )
                                                        ) : (
                                                            <></>
                                                        )}

                                                        <div className='btn-group'>
                                                            {KeycloakUtil.isLoggedIn() &&
                                                                (this.props.project?.author?.email === KeycloakUtil.getEmail() ||
                                                                    KeycloakUtil.hasRole([KeycloakRoleEnum.reviewer]) ||
                                                                    KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                                                    project?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())) &&
                                                                !this.props.project?.script_published &&
                                                                this.props.project?.service_publish_date === null &&
                                                                (this.props.project?.status === ProjectStatusEnum.READY ||
                                                                    this.props.project?.status === ProjectStatusEnum.STOPPED ||
                                                                    this.props.project?.status === ProjectStatusEnum.LOADING ||
                                                                    this.props.project?.status === ProjectStatusEnum.RUNNING ||
                                                                    this.props.project?.status === ProjectStatusEnum.FAILED ||
                                                                    this.props.project?.status === ProjectStatusEnum.STOPPING ||
                                                                    this.props.project?.status === ProjectStatusEnum.ARCHIVED) && (
                                                                    <button
                                                                        className='btn btn-outline-primary d-flex flex-row button-icon-container more-action-button'
                                                                        data-bs-toggle='dropdown'
                                                                        aria-haspopup='true'
                                                                        aria-expanded='false'
                                                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                                            e.stopPropagation();
                                                                            this.handleDropdownClose(false);
                                                                        }}>
                                                                        <MoreAction className='more-action-icon-button' fill='#009102' />
                                                                    </button>
                                                                )}
                                                            <div
                                                                className={`dropdown-menu more-action-dropdown w-75 ${this.state.openedMenu === 'moreAction' && 'show'}`}
                                                                ref={this.dropdownRef}
                                                                id='moreAction'
                                                                tabIndex={1}>
                                                                {this.state.isAddingTimeout ? (
                                                                    <div className='timeout-input-container d-flex flex-column'>
                                                                        <div className=' d-flex flex-row align-items-end'>
                                                                            <div className='form-group mt-3 mb-2'>
                                                                                <label htmlFor='creator' className='active label-color'>
                                                                                    {this.translator.get('project.timeout.label')}
                                                                                </label>
                                                                                <TextField
                                                                                    type='number'
                                                                                    key={'timeout'}
                                                                                    placeholder={this.translator.get('project.timeout.placeholder')}
                                                                                    className='form-control'
                                                                                    defaultValue={this.state.timeoutValue !== 0 ? this.state.timeoutValue : 0}
                                                                                    onClick={(e: React.MouseEvent<HTMLInputElement>) => {
                                                                                        e.stopPropagation();
                                                                                    }}
                                                                                    onBlur={(e: any) =>
                                                                                        this.setState({
                                                                                            timeoutValue: e.target.value,
                                                                                        })
                                                                                    }
                                                                                    name='timeout'
                                                                                />
                                                                            </div>
                                                                            <div className='d-flex flex-column'>
                                                                                <button
                                                                                    className='btn pb-1'
                                                                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                                                        e.stopPropagation();
                                                                                        this.setState({
                                                                                            isAddingTimeout: false,
                                                                                            timeoutValue: 0,
                                                                                        });
                                                                                    }}>
                                                                                    <Back className='back-icon' />
                                                                                </button>
                                                                                <button
                                                                                    className='btn mb-1 btn btn-primary btn-save'
                                                                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                                                                                        e.stopPropagation();
                                                                                        this.setState({
                                                                                            isAddingTimeout: false,
                                                                                        });
                                                                                    }}>
                                                                                    {this.translator.get('project.detail.save.button.text')}
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className='link-list-wrapper'>
                                                                        <ul className='link-list'>
                                                                            {(this.props.project?.status === ProjectStatusEnum.LOADING ||
                                                                                this.props.project?.status === ProjectStatusEnum.RUNNING ||
                                                                                this.props.project?.status === ProjectStatusEnum.STOPPING) && (
                                                                                <li>
                                                                                    <a
                                                                                        className='dropdown-item list-item link-item'
                                                                                        onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                                                                                            this.handleForceStopClick(e, this.props.project?.id as string)
                                                                                        }>
                                                                                        <span>{this.translator.get('project.more.action.forced.stop.text')}</span>
                                                                                    </a>
                                                                                </li>
                                                                            )}
                                                                            {!(
                                                                                this.props.project?.status === ProjectStatusEnum.LOADING ||
                                                                                this.props.project?.status === ProjectStatusEnum.RUNNING
                                                                            ) && (
                                                                                <li>
                                                                                    <a
                                                                                        className='dropdown-item list-item set-timeout-container'
                                                                                        onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) => {
                                                                                            this.setState({
                                                                                                isAddingTimeout: true,
                                                                                            });
                                                                                            e.stopPropagation();
                                                                                        }}>
                                                                                        <span>{this.translator.get('project.more.action.set.timeout.text')}</span>
                                                                                    </a>
                                                                                </li>
                                                                            )}
                                                                        </ul>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {!this.props.isIDEOpen &&
                                                        (this.props.project?.project_published ||
                                                            this.props.project?.author?.email === KeycloakUtil.getEmail() ||
                                                            KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) && (
                                                            <>
                                                                <div className='btn-group  d-flex align-items-center'>
                                                                    <button
                                                                        className='btn btn-outline-primary publish-container d-flex flex-row'
                                                                        type='button'
                                                                        data-bs-toggle='dropdown'
                                                                        aria-controls='dropdown'
                                                                        aria-haspopup='true'
                                                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                                            e.stopPropagation();
                                                                            this.handleDropdownClose(true);
                                                                        }}
                                                                        aria-expanded='false'>
                                                                        {this.translator.get('common.button.action.label')}
                                                                        <ArrowDown className='arrow-down icon' fill='#009138' />
                                                                        <ArrowUp className='arrow-up icon' fill='#009138' />
                                                                    </button>
                                                                    <div className={`dropdown-menu ${this.state.openedMenu === 'actions' && 'show'}`} id='actions'>
                                                                        <div className='link-list-wrapper'>
                                                                            <ul className='link-list'>
                                                                                {!this.props.isIDEOpen &&
                                                                                    ((!this.props.project?.script_published &&
                                                                                        this.props.project?.service_publish_date === null &&
                                                                                        this.props.project?.status !== ProjectStatusEnum.RUNNING &&
                                                                                        this.props.project?.status !== ProjectStatusEnum.STOPPING &&
                                                                                        (this.props.project?.author?.email === KeycloakUtil.getEmail() ||
                                                                                            KeycloakUtil.hasRole([KeycloakRoleEnum.admin]))) ||
                                                                                        (this.props.project?.project_published && KeycloakUtil.isLoggedIn())) && (
                                                                                        <li>
                                                                                            <a
                                                                                                className='dropdown-item list-item link-item'
                                                                                                href='#'
                                                                                                data-bs-toggle='modal'
                                                                                                data-bs-target='#cloneNewProject'
                                                                                                onClick={(e: React.MouseEvent<HTMLElement>) => this.handleCloneClick(e)}>
                                                                                                <span>{this.translator.get('project.table.action.clone.project.text')}</span>
                                                                                            </a>
                                                                                        </li>
                                                                                    )}
                                                                                {(this.props.project?.author?.email === KeycloakUtil.getEmail() ||
                                                                                    KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) &&
                                                                                    this.props.project?.status !== ProjectStatusEnum.RUNNING &&
                                                                                    this.props.project?.status !== ProjectStatusEnum.STOPPING &&
                                                                                    !this.props.project?.service_publish_date &&
                                                                                    !this.props.project?.id_reference_service && (
                                                                                        <li>
                                                                                            <a
                                                                                                className='dropdown-item list-item link-item'
                                                                                                href='#'
                                                                                                data-bs-toggle='modal'
                                                                                                id={this.props.project?.id}
                                                                                                data-bs-target='#createServiceModal'
                                                                                                onClick={(e: React.MouseEvent<HTMLElement>) => {
                                                                                                    this.handleCreateServiceClick(e);
                                                                                                }}>
                                                                                                <span>{this.translator.get('project.table.action.create.service.text')}</span>
                                                                                            </a>
                                                                                        </li>
                                                                                    )}
                                                                                {(this.props.project?.author?.email === KeycloakUtil.getEmail() ||
                                                                                    KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) && (
                                                                                    <li>
                                                                                        <a
                                                                                            className='dropdown-item list-item link-item'
                                                                                            onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) =>
                                                                                                this.handlePublicationClick(
                                                                                                    e,
                                                                                                    this.props.project?.id as string,
                                                                                                    wizardPublicationDataset,
                                                                                                    ProvisioningPageEnum.DATASET,
                                                                                                    this.props.project?.provisioningPageIndex?.datasetIndex as number,
                                                                                                )
                                                                                            }>
                                                                                            <span>{this.translator.get('project.table.action.pubblication.dataset.text')}</span>
                                                                                        </a>
                                                                                    </li>
                                                                                )}
                                                                                {!this.props.project?.project_published &&
                                                                                    this.props.project?.status !== ProjectStatusEnum.RUNNING &&
                                                                                    this.props.project?.status !== ProjectStatusEnum.LOADING &&
                                                                                    this.props.project?.status !== ProjectStatusEnum.STOPPING &&
                                                                                    (this.props.project?.author?.email === KeycloakUtil.getEmail() ||
                                                                                        KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) && (
                                                                                        <li>
                                                                                            <a
                                                                                                className='dropdown-item list-item link-item'
                                                                                                onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) =>
                                                                                                    this.handlePublicationClick(
                                                                                                        e,
                                                                                                        this.props.project?.id as string,
                                                                                                        wizardPublicationProject,
                                                                                                        ProvisioningPageEnum.PROJECT,
                                                                                                        this.props.project?.provisioningPageIndex?.projectIndex as number,
                                                                                                    )
                                                                                                }>
                                                                                                <span>{this.translator.get('project.table.action.pubblication.project.text')}</span>
                                                                                            </a>
                                                                                        </li>
                                                                                    )}
                                                                                {(this.props.project?.author?.email === KeycloakUtil.getEmail() ||
                                                                                    KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) &&
                                                                                    this.props.project?.status !== ProjectStatusEnum.RUNNING &&
                                                                                    this.props.project?.status !== ProjectStatusEnum.LOADING &&
                                                                                    this.props.project?.status !== ProjectStatusEnum.STOPPING &&
                                                                                    !this.props.project?.script_published && (
                                                                                        <li>
                                                                                            <a
                                                                                                className='dropdown-item list-item link-item'
                                                                                                onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) =>
                                                                                                    this.handlePublicationClick(
                                                                                                        e,
                                                                                                        this.props.project?.id as string,
                                                                                                        wizardPublicationScript,
                                                                                                        ProvisioningPageEnum.SCRIPTS,
                                                                                                        this.props.project?.provisioningPageIndex?.scriptIndex as number,
                                                                                                    )
                                                                                                }>
                                                                                                <span>{this.translator.get('project.table.action.pubblication.script.text')}</span>
                                                                                            </a>
                                                                                        </li>
                                                                                    )}
                                                                                {(this.props.project?.project_published || this.props.project?.script_published) && (
                                                                                    <li>
                                                                                        <a
                                                                                            className='dropdown-item list-item link-item'
                                                                                            id={this.props.project?.id}
                                                                                            onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) =>
                                                                                                this.handleDownloadRepoClick(
                                                                                                    e,
                                                                                                    this.props.project?.id as string,
                                                                                                    this.props.project?.name as string,
                                                                                                )
                                                                                            }>
                                                                                            <span>{this.translator.get('project.table.action.download.project.text')}</span>
                                                                                        </a>
                                                                                    </li>
                                                                                )}
                                                                                {(this.props.project?.author?.email === KeycloakUtil.getEmail() ||
                                                                                    KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) &&
                                                                                    this.props.project?.status !== ProjectStatusEnum.ARCHIVED && (
                                                                                        <li>
                                                                                            <a
                                                                                                className='dropdown-item list-item link-item'
                                                                                                id={this.props.project?.id}
                                                                                                onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) =>
                                                                                                    this.handleArchiveClick(e, this.props.project?.id as string)
                                                                                                }>
                                                                                                <span>{this.translator.get('project.table.action.archive.project.text')}</span>
                                                                                            </a>
                                                                                        </li>
                                                                                    )}

                                                                                {(this.props.project?.author?.email === KeycloakUtil.getEmail() ||
                                                                                    KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) && (
                                                                                    <li>
                                                                                        <a
                                                                                            className='dropdown-item list-item link-item'
                                                                                            id={this.props.project?.id}
                                                                                            onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) =>
                                                                                                this.handleDeleteClick(e, this.props.project?.id as string)
                                                                                            }>
                                                                                            <span>{this.translator.get('project.table.action.delete.project.text')}</span>
                                                                                        </a>
                                                                                    </li>
                                                                                )}
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        }

                        <div className={`col-12 ${!this.props.isIDEOpen ? 'd-none' : ''}`}>
                            {this.props.project?.execution?.url && (
                                <iframe
                                    src={
                                        this.props.project?.author?.email === KeycloakUtil.getEmail() ||
                                        project?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())
                                            ? `${this.props.project?.execution?.url}/lab/tree/index.ipynb?token=${this.props.project?.execution?.token}&lfwbcd_git_username=${gitUsername}&lfwbcd_git_token=${gitToken}&lfwbcd_user_id=${gitUserId}&file-browser-path=/`
                                            : `${this.props.project?.execution?.url}/lab/tree/index.ipynb?token=${this.props.project?.execution?.token}&lfwbcd_git_username=${gitUsername}&lfwbcd_user_id=${gitUserId}&file-browser-path=/`
                                    }
                                    width='100%'
                                    height={700}
                                />
                            )}
                        </div>
                        <div className='col-12'>
                            <div className={`${this.props.isIDEOpen && 'd-lg-block pt-3 pb-3 container-gray'} accordionContainer`}>
                                <AccordionComponent
                                    isProjectRunning={this.props.isIDEOpen}
                                    createUpdate={this.props.createUpdate}
                                    project={this.props.project}
                                    user={this.props.user}
                                    onBlur={this.handleOnChange}
                                    newCollaborator={this.props.newCollaborator}
                                    provenance={this.props.provenance}
                                    availableCategoryOptions={this.props.availableCategoryOptions?.filter(
                                        (item: string) => item !== 'ALL' && !this.props.createUpdate.categories?.includes(item),
                                    )}
                                    templates={this.props.templates}
                                    configuration={this.props.configuration}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='modal fade scrollable' role='dialog' id='cloneNewProject' aria-labelledby='cloneNewProjectTitle' data-bs-backdrop='static' data-bs-keyboard='false'>
                    <div className='modal-dialog modal-xl' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h2 className='modal-title h5 no_toc' id='cloneNewProjectTitle'>
                                    {this.translator.get('project.modal.clone.title')}
                                </h2>
                                <button
                                    className='btn-close'
                                    type='button'
                                    data-bs-dismiss='modal'
                                    aria-label='Chiudi finestra modale'
                                    id='modalBtnClose'
                                    onClick={this.handleModalClose}></button>
                            </div>
                            <div className='modal-body'>
                                <ProjectCloneFormView
                                    user={user}
                                    project={this.props.project}
                                    onBlur={this.handleOnChange}
                                    onClone={this.handleCloneProject}
                                    isModalClosed={this.state.isModalOpen}
                                    templates={this.props.templates}
                                    availableCategoryOptions={this.props.availableCategoryOptions}
                                    projectCreate={this.props.createUpdate}
                                />
                            </div>
                            <div className='modal-footer modal-footer-shadow'></div>
                        </div>
                    </div>
                </div>

                <div
                    className='modal fade scrollable'
                    role='dialog'
                    id='createServiceModal'
                    aria-labelledby='createNewServiceTitle'
                    data-bs-backdrop='static'
                    data-bs-keyboard='false'>
                    <div className='modal-dialog modal-xl' role='document'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h2 className='modal-title h5 no_toc' id='createNewServiceTitle'>
                                    {this.translator.get('project.create.service.modal.title')}
                                </h2>
                                <button
                                    className='btn-close'
                                    type='button'
                                    data-bs-dismiss='modal'
                                    aria-label='Close modal'
                                    id='serviceCreateModalBtnClose'
                                    onClick={this.handleModalClose}
                                />
                            </div>
                            <div className='modal-body'>
                                <ServiceCreateFormView
                                    user={user}
                                    technologies={templates}
                                    onBlur={this.handleOnServiceChange}
                                    onChange={this.handleOnServiceChange}
                                    onCreate={this.handleCreateService}
                                    isModalClosed={this.state.isModalOpen}
                                    availableCategoryOptions={this.props.availableCategoryOptions?.filter((item: string) => item !== 'ALL')}
                                    project={this.props.project}
                                    serviceCreate={serviceCreateUpdate}
                                />
                            </div>
                            <div className='modal-footer modal-footer-shadow'></div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
