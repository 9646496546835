import { ITranslatorManager } from './FacadeTranslatorManager';

export class TranslatorManager {
    private static instance: TranslatorManager;

    private externalManager: ITranslatorManager | undefined;
    private labelPack: Record<string, string> | undefined;

    private constructor(translatorManager?: ITranslatorManager) {
        this.externalManager = translatorManager;
    }

    /**
     * get instance method for retrieving the singleton instance
     * translatorManager: instance of manager that implements translation logic (optional)
     * @returns the singleton instance
     */
    public static getInstance(translatorManager?: ITranslatorManager) {
        if (!this.instance) {
            if (translatorManager == null) {
                console.warn('missing translator manager in design system: please be sure to pass labels property in components or inject translator manager instance');
            }
            this.instance = new TranslatorManager(translatorManager);
        }
        return this.instance;
    }

    /**
     *
     * @param key
     * @param type
     * @param identifier
     * @param values
     * @returns
     */
    public get(key: string, type?: string, identifier?: string, values?: Record<string, string>): string {
        // check proper usage of labels or manager
        if (this.externalManager == null && this.labelPack == null) {
            console.error(`you forgot to inject a translator manager or a label pack prop in component ${type} with identifier ${identifier}`);
        } else if (this.externalManager != null && this.labelPack != null) {
            console.error('pay attention you are injecting labels that will be ignored because a manager has been used');
        }
        if (this.labelPack != null && values != null) {
            console.error('pay attention you are injecting labels thus the values are going to be ignored');
        }

        let keysToTranslate = identifier ? [`${identifier}.${type}.${key}`, `${type}.${key}`] : [key];

        let result: string = keysToTranslate[0];
        keysToTranslate.some(keyToTranslate => {
            const keyTranslated = this.externalManager ? this.externalManager.get(keyToTranslate, values) : this.labelPack?.[keyToTranslate];
            if (keyTranslated != null && keyTranslated !== keyToTranslate) {
                result = keyTranslated;
                return true;
            }
            return false;
        });

        return result;
    }

    public set(pack: Record<string, string>) {
        this.labelPack = pack;
    }
}
