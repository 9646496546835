/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// May contain unused imports in some cases
// @ts-ignore
import { InBoundMetrics } from './in-bound-metrics';
// May contain unused imports in some cases
// @ts-ignore
import { InBoundWarnings } from './in-bound-warnings';
// May contain unused imports in some cases
// @ts-ignore
import { InputFile } from './input-file';
// May contain unused imports in some cases
// @ts-ignore
import { Integrations } from './integrations';
// May contain unused imports in some cases
// @ts-ignore
import { OutputFile } from './output-file';
// May contain unused imports in some cases
// @ts-ignore
import { ProjectExecutionEnv } from './project-execution-env';
// May contain unused imports in some cases
// @ts-ignore
import { ProvisioningPageIndex } from './provisioning-page-index';
// May contain unused imports in some cases
// @ts-ignore
import { User } from './user';
// May contain unused imports in some cases
// @ts-ignore
import { VersionControl } from './version-control';

/**
 *
 * @export
 * @interface Project
 */
export interface Project {
    /**
     *
     * @type {string}
     * @memberof Project
     */
    id?: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    name?: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    version?: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    technology?: string;
    /**
     *
     * @type {User}
     * @memberof Project
     */
    author?: User;
    /**
     *
     * @type {Array<User>}
     * @memberof Project
     */
    collaborators?: Array<User>;
    /**
     *
     * @type {VersionControl}
     * @memberof Project
     */
    git?: VersionControl;
    /**
     *
     * @type {ProjectExecutionEnv}
     * @memberof Project
     */
    execution?: ProjectExecutionEnv;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    status?: ProjectStatusEnum;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    description?: string;
    /**
     *
     * @type {{ [key: string]: InputFile | undefined; }}
     * @memberof Project
     */
    inputs?: { [key: string]: InputFile | undefined };
    /**
     *
     * @type {{ [key: string]: OutputFile | undefined; }}
     * @memberof Project
     */
    outputs?: { [key: string]: OutputFile | undefined };
    /**
     *
     * @type {Array<string>}
     * @memberof Project
     */
    categories?: Array<string>;
    /**
     *
     * @type {InBoundMetrics}
     * @memberof Project
     */
    metrics?: InBoundMetrics;
    /**
     *
     * @type {Array<InBoundWarnings>}
     * @memberof Project
     */
    warnings?: Array<InBoundWarnings>;
    /**
     *
     * @type {Integrations}
     * @memberof Project
     */
    integrations?: Integrations;
    /**
     *
     * @type {Array<string>}
     * @memberof Project
     */
    keywords?: Array<string>;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    community?: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    helpdesk?: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    training?: string;
    /**
     *
     * @type {ProvisioningPageIndex}
     * @memberof Project
     */
    provisioningPageIndex?: ProvisioningPageIndex;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    created_at?: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    project_published_at?: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    script_published_at?: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    chat_url?: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    wiki_url?: string;
    /**
     *
     * @type {boolean}
     * @memberof Project
     */
    project_published?: boolean;
    /**
     *
     * @type {boolean}
     * @memberof Project
     */
    script_published?: boolean;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    id_reference_service?: string;
    /**
     *
     * @type {string}
     * @memberof Project
     */
    service_publish_date?: string;
}

export const ProjectStatusEnum = {
    ALL: 'ALL',
    READY: 'READY',
    RUNNING: 'RUNNING',
    STOPPED: 'STOPPED',
    LOADING: 'LOADING',
    STOPPING: 'STOPPING',
    TO_BE_ARCHIVED: 'TO_BE_ARCHIVED',
    TO_BE_DELETED: 'TO_BE_DELETED',
    ARCHIVED: 'ARCHIVED',
    DELETED: 'DELETED',
    FAILED: 'FAILED',
    FORKING: 'FORKING',
} as const;

export type ProjectStatusEnum = (typeof ProjectStatusEnum)[keyof typeof ProjectStatusEnum];
