import { IReduceableAction, ReduxAction } from '@hypereact/state';
import { Slices } from '@store/state';
import { SharedState } from './state';

//TODO: rename QueueTaskForLoader
@ReduxAction('QUEUE_TASK_FOR_LOADER', Slices.Shared)
export class QueueTaskForLoader implements IReduceableAction<SharedState> {
    constructor(public key?: string) {}
    reduce(state: SharedState) {
        const key: string = this.key || 'global';
        state.loading[key] = (state.loading[key] || 0) + 1;
        return state;
    }
}

//TODO: rename DequeueTaskForLoader
@ReduxAction('DEQUEUE_TASK_FOR_LOADER', Slices.Shared)
export class DequeueTaskForLoader implements IReduceableAction<SharedState> {
    constructor(public key?: string) {}
    reduce(state: SharedState) {
        const key: string = this.key || 'global';
        state.loading[key] = state.loading[key] - 1;
        return state;
    }
}

@ReduxAction('SHOW_POPUP', Slices.Shared)
export class ShowPopupAction implements IReduceableAction<SharedState> {
    reduce(state: SharedState) {
        state.popup.isVisible = true;
        return state;
    }
}

@ReduxAction('HIDE_POPUP', Slices.Shared)
export class HidePopupAction implements IReduceableAction<SharedState> {
    reduce(state: SharedState) {
        state.popup.isVisible = false;
        return state;
    }
}

@ReduxAction('SET_CURRENT_ROUTE', Slices.Shared)
export class SetCurrentRoute implements IReduceableAction<SharedState> {
    constructor(public route: string) {}
    reduce(state: SharedState) {
        state.currentRoute = this.route;
        return state;
    }
}

@ReduxAction('BACKOFFICE_GET_CONFIG_KEYS', Slices.Shared)
export class BackofficeSetKeys implements IReduceableAction<SharedState> {
    constructor(public keys: { [key: string]: string }) {}
    reduce(state: SharedState) {
        state.configuration = this.keys;
        return state;
    }
}
