import { ReactComponent as ArrowDown } from '@assets/svg/it-arrow-down-triangle.svg';
import { ReactComponent as ArrowUp } from '@assets/svg/it-arrow-up-triangle.svg';
import Breadcrumb from '@components/Breadcrumb/breadcrumb.component';
import { ListPaginationInterface } from '@features/_shared/components/Paginator/enums/ListPaginationInterface';
import { PopupType } from '@features/_shared/components/Popup/popup.item';
import { User } from '@features/login/store/state';
import { ProvisioningPageEnum } from '@features/provisioning/store/state';
import { wizardPublicationDataset, wizardPublicationService } from '@features/provisioning/utils/constant';
import { ProvisioningOriginEnum } from '@features/provisioning/views/provisioning.view';
import { AccordionContainer } from '@features/service/components/service.detail.component';
import { ServiceDetailHeader } from '@features/service/components/service.detail.header.component';
import { ReduxConnect, StoreManager } from '@hypereact/state';
import { TranslatorManager } from '@managers/TranslatorManager';
import { MinisiteManager } from '@managers/minisite.manager';
import { NavigationManager } from '@managers/navigation.manager';
import { PopupManager } from '@managers/popup.manager';
import { ProjectManager } from '@managers/project.manager';
import { ProvisioningManager } from '@managers/provisioning.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { ServiceManager } from '@managers/service.manager';
import { Paths } from '@routes';
import { RootState } from '@store/state';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';
import { PollingUtil } from '@utils/polling.util';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { BaseProjectTemplate, Project, ResponseServiceExecutionMinisite, WebService, WebServiceStatusEnum } from '../../../api';
import { ServiceResetPaginationAction } from '../store/actions';
import { ExecutionState } from '../store/state';
import '../styles/service.detail.style.scss';
export interface IServiceDetailViewProps {
    service: WebService;
    project: Project;
    user: User;
    templates: Array<BaseProjectTemplate>;
    execution: ExecutionState;
    createUpdate: WebService;
    executionsPagination: ListPaginationInterface;
    minisiteExecution: ResponseServiceExecutionMinisite;
    selectedProject: Project;
    origin: ProvisioningOriginEnum;
    availableCategoryOptions: string[];
}

export interface IServiceDetailViewState {
    isAddingTimeout: boolean;
    isStartedService: boolean;
}

@ReduxConnect((state: RootState) => {
    return {
        service: state.service.service.selectedService,
        user: state.login,
        templates: state.project.template,
        project: state.project.project.selectedProject,
        execution: state.service.execution,
        createUpdate: state.service.service.createUpdate,
        executionsPagination: state.service.execution.pagination,
        minisiteExecution: state.minisite.execution.selectedExecution,
        selectedProject: state.project.project.selectedProject,
        origin: state.provisioning.origin,
        availableCategoryOptions: state.service.service.categoryAvailableOptions,
    };
})
export class ServiceDetailView extends React.Component<IServiceDetailViewProps, IServiceDetailViewState> {
    private serviceManager: ServiceManager;
    private projectManager: ProjectManager;
    private minisiteManager: MinisiteManager;
    private navigationManager: NavigationManager;
    private translatorManager: TranslatorManager;
    private provisioningManager: ProvisioningManager;
    private popupManager: PopupManager;
    private queueManager: QueueManager;

    constructor(props: IServiceDetailViewProps) {
        super(props);
        this.serviceManager = ServiceManager.getInstance();
        this.projectManager = ProjectManager.getInstance();
        this.minisiteManager = MinisiteManager.getInstance();
        this.popupManager = PopupManager.getInstance();
        this.queueManager = QueueManager.getInstance();
        this.provisioningManager = ProvisioningManager.getInstance();
        this.navigationManager = NavigationManager.getInstance();
        this.translatorManager = TranslatorManager.getInstance();
        this.handleClickOutsideDropdown = this.handleClickOutsideDropdown.bind(this);
        this.handleTableRowClick = this.handleTableRowClick.bind(this);
        this.handleStartClick = this.handleStartClick.bind(this);
        this.handleOnApprove = this.handleOnApprove.bind(this);
        this.handleOnReject = this.handleOnReject.bind(this);
        this.handleOnChange = this.handleOnChange.bind(this);
        this.handleClickOutsideDropdown = this.handleClickOutsideDropdown.bind(this);

        this.state = {
            isStartedService: false,
            isAddingTimeout: false,
        };
    }

    async handleServiceDetailViewPoll() {
        const id = StoreManager.getInstance().getState('service').service?.selectedService?.id;
        let validateService = () => this.navigationManager.getCurrentRoute() === `/service/${id}`;
        await PollingUtil.register('servicedetaildata', async () => await this.serviceManager.pollServiceById(id as string), validateService, 10000, id);
        await PollingUtil.register(
            'servicedetailexec',
            async () => await this.serviceManager.pollExecutionList(id as string, this.props.executionsPagination?.page, this.props.executionsPagination?.size),
            validateService,
            10000,
            id,
        );
    }

    async handleViewOnLoad() {
        const id = window.location.href.split('service/')[1];
        const response = await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(id));
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getExecutionList(id, 0, 5));
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getTemplateList());
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceCategories());
        response.id_reference_project && (await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(response.id_reference_project)));
    }

    async componentDidMount() {
        const serviceCommitsDiff = await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.diffToProject(window.location.href.split('service/')[1] as string));
        if (serviceCommitsDiff.commits && serviceCommitsDiff.commits > 0) {
            this.popupManager.show({
                type: PopupType.Info,
                title: this.translatorManager.get('service.popup.diff.title'),
                description: this.translatorManager.get('service.popup.diff.description'),
                maxWidth: 'lg',
                stylingProp: 'popup-display',
                ctas: {
                    primary: {
                        label: this.translatorManager.get('service.popup.refresh.button'),
                        className: 'popup-accept-button',
                        cta: async () => {
                            this.popupManager.hide();
                            await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.refresh(window.location.href.split('service/')[1] as string));
                            await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(window.location.href.split('service/')[1] as string));
                        },
                    },
                },
            });
        } else if (serviceCommitsDiff.inputs && serviceCommitsDiff.inputs > 0) {
            this.popupManager.show({
                type: PopupType.Info,
                title: this.translatorManager.get('service.popup.diff.input.title'),
                description: this.translatorManager.get('service.popup.diff.input.description'),
                maxWidth: 'lg',
                stylingProp: 'popup-display',
                ctas: {
                    primary: {
                        label: this.translatorManager.get('service.popup.refresh.button'),
                        className: 'popup-accept-button',
                        cta: async () => {
                            this.popupManager.hide();
                            await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.refresh(window.location.href.split('service/')[1] as string));
                            await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(window.location.href.split('service/')[1] as string));
                        },
                    },
                },
            });
        }
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.handleViewOnLoad());
        await this.queueManager.queue(QueueKeyType.POLLER, this.handleServiceDetailViewPoll());
        document.addEventListener('click', this.handleClickOutsideDropdown);
    }

    shouldComponentUpdate(nextProp: IServiceDetailViewProps, nextState: IServiceDetailViewState) {
        const id = StoreManager.getInstance().getState('service').service?.selectedService?.id;
        if ((_.isEqual(nextProp, this.props) && _.isEqual(nextState, this.state)) || id != window.location.href.split('/service/')[1]) {
            return false;
        } else {
            return true;
        }
    }

    componentWillUnmount() {
        PollingUtil.unregister('servicedetaildata');
        PollingUtil.unregister('servicedetailexec');
        document.removeEventListener('click', this.handleClickOutsideDropdown);
    }

    handleClickOutsideDropdown(event: any) {
        if (!event.target.closest('.publish-container')) {
            this.setState({
                isAddingTimeout: false,
            });
        }

        this.setState({
            isAddingTimeout: false,
        });
    }

    async handleStartClick(e: any, serviceId: string) {
        e.stopPropagation();
        const serviceCommitsDiff = await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.diffToProject(serviceId as string));
        if (serviceCommitsDiff.commits && serviceCommitsDiff.commits > 0) {
            this.popupManager.show({
                type: PopupType.Info,
                title: this.translatorManager.get('service.popup.diff.title'),
                description: this.translatorManager.get('service.popup.diff.description'),
                maxWidth: 'lg',
                stylingProp: 'popup-display',
                ctas: {
                    primary: {
                        label: this.translatorManager.get('service.popup.refresh.button'),
                        className: 'popup-accept-button',
                        cta: async () => {
                            this.popupManager.hide();
                            await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.refresh(serviceId));
                        },
                    },
                },
            });
        } else if (serviceCommitsDiff.inputs && serviceCommitsDiff.inputs > 0) {
            this.popupManager.show({
                type: PopupType.Info,
                title: this.translatorManager.get('service.popup.diff.input.title'),
                description: this.translatorManager.get('service.popup.diff.input.description'),
                maxWidth: 'lg',
                stylingProp: 'popup-display',
                ctas: {
                    primary: {
                        label: this.translatorManager.get('service.popup.refresh.button'),
                        className: 'popup-accept-button',
                        cta: async () => {
                            this.popupManager.hide();
                            await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.refresh(serviceId));
                        },
                    },
                },
            });
        } else {
            const response = await this.queueManager.queue(
                QueueKeyType.DEFAULT,
                this.serviceManager.createExecution(serviceId, {
                    author: {
                        name: KeycloakUtil.getName(),
                        email: KeycloakUtil.getEmail(),
                    },
                }),
            );
            await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(serviceId));
            await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.launch(serviceId, response.id as string));
            this.navigationManager.navigateTo(`/service/${serviceId}` as Paths);
        }
    }

    async handleProjectButtonClick(e: any) {
        e.stopPropagation();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(e.target.id));
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(this.props.service?.id_reference_project as string));
        this.navigationManager.navigateTo(`/project/${this.props.service?.id_reference_project}` as Paths);
    }

    async handleMinisiteExecutionCreate(e: any, serviceId: string) {
        e.stopPropagation();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.minisiteManager.create(serviceId, {}));
        const token = this.props.minisiteExecution.data as string;
        let [header, payload, signature] = token.split('.');
        this.navigationManager.navigateTo(`/minisite/${payload}/${signature}/${header}` as Paths);
    }

    async handleTableRowClick(execId: string) {
        const { id } = this.props.service;
        const response = await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.findExecution(id as string, execId));
        this.provisioningManager.setOrigin(ProvisioningOriginEnum.SERVICEDETAIL);
        this.navigationManager.navigateTo(`${this.props.service?.id}/execution/${response.id}` as Paths);
    }

    async handlePublicationDatasetClick(e: React.MouseEvent<HTMLHyperlinkElementUtils>, id: string) {
        e.stopPropagation();
        this.projectManager.resetSelectedProject();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(id));
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.getProjectById(this.props.service?.id_reference_project as string));
        this.provisioningManager.setSteps(wizardPublicationDataset);
        this.provisioningManager.setCurrentPage(ProvisioningPageEnum.DATASET);
        this.provisioningManager.setOrigin(ProvisioningOriginEnum.SERVICEDETAIL);
        let index = this.props.selectedProject.provisioningPageIndex?.datasetIndex;
        this.navigationManager.navigateTo(`${wizardPublicationDataset[index ? index : 0]}` as Paths);
    }

    async handlePublicationServiceClick(e: React.MouseEvent<HTMLHyperlinkElementUtils>, id: string) {
        e.stopPropagation();
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(id));
        this.provisioningManager.setSteps(wizardPublicationService);
        this.navigationManager.navigateTo(`${wizardPublicationService[0]}` as Paths);
        this.provisioningManager.setCurrentPage(ProvisioningPageEnum.SERVICE);
        this.provisioningManager.setOrigin(ProvisioningOriginEnum.SERVICEDETAIL);
        let index = this.props.selectedProject.provisioningPageIndex?.serviceIndex;
        this.navigationManager.navigateTo(wizardPublicationService[index ? index : 0] as Paths);
    }

    async handleDownloadRepoClick(e: React.MouseEvent<HTMLHyperlinkElementUtils>, projectId: string, zipname: string) {
        e.stopPropagation();
        const response = await this.queueManager.queue(QueueKeyType.DEFAULT, this.projectManager.downloadRepo(projectId, zipname));
        const href = URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', `${zipname}.zip`); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    }

    async handleDeleteService(e: React.MouseEvent<HTMLHyperlinkElementUtils>, serviceId: string) {
        PollingUtil.unregister('servicedetaildata');
        PollingUtil.unregister('servicedetailexec');
        await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.delete(serviceId));
        StoreManager.getInstance().dispatch(new ServiceResetPaginationAction());
        this.navigationManager.navigateTo('/service');
    }

    handleOnChange(key: keyof WebService, value: string | number | boolean | Array<string>) {
        this.serviceManager.saveValue(key, value);
        if (Array.isArray(value)) {
            this.serviceManager.updateAvailableOptions(value);
        }
    }

    handleDeleteClick(e: React.MouseEvent<HTMLHyperlinkElementUtils>, serviceId: string) {
        e.stopPropagation();
        this.popupManager.show({
            type: PopupType.Info,
            title: this.translatorManager.get('service.popup.delete.service.title'),
            description: this.translatorManager.get('service.popup.delete.service.description'),
            maxWidth: 'lg',
            stylingProp: 'popup-display',
            ctas: {
                primary: {
                    label: this.translatorManager.get('service.popup.delete.service.button'),
                    className: 'popup-accept-button',
                    cta: () => this.handleDeleteService(e, serviceId),
                },
                secondary: {
                    label: this.translatorManager.get('common.button.cancel.label'),
                    className: 'popup-accept-button',
                    cta: () => this.popupManager.hide(),
                },
            },
        });
    }

    async handleOnApprove() {
        const { service } = this.props;
        service?.id &&
            (await this.queueManager.queue(
                QueueKeyType.DEFAULT,
                this.serviceManager.approve(service?.id, {
                    approve_at: moment().toISOString(),
                    user: {
                        name: KeycloakUtil.getName(),
                        email: KeycloakUtil.getEmail(),
                    },
                }),
            ));
        service?.id && (await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(service?.id)));
    }

    async handleOnReject() {
        const { service } = this.props;
        const { rejected_reason } = this.props.createUpdate;
        service?.id &&
            (await this.queueManager.queue(
                QueueKeyType.DEFAULT,
                this.serviceManager.reject(service?.id, {
                    rejected_at: moment().toISOString(),
                    user: {
                        name: KeycloakUtil.getName(),
                        email: KeycloakUtil.getEmail(),
                    },
                    reason: rejected_reason,
                }),
            ));
        service?.id && (await this.queueManager.queue(QueueKeyType.DEFAULT, this.serviceManager.getServiceById(service?.id)));
    }

    render() {
        const { service, user, templates, project, execution, createUpdate, executionsPagination, origin } = this.props;
        const isLogged = KeycloakUtil.isLoggedIn();

        return (
            <>
                <div className='header-title-container pb-0'>
                    <p className='header-title col-12 mb-4 mt-0'>{service?.name}</p>
                    <Breadcrumb secondaryPages={[this.translatorManager.get('service.detail.breadcrumb.secondary.item')]} />
                </div>
                <ServiceDetailHeader service={service} templates={templates} project={project} origin={origin} onApprove={this.handleOnApprove} onReject={this.handleOnReject} />
                <div className='container-fluid'>
                    <div className='action-container action-container-spacing'>
                        {service?.status === WebServiceStatusEnum.PUBLISHED && (
                            <button
                                id='minisite-execution-btn'
                                className='btn btn-outline-primary table-button d-flex align-items-center action-item'
                                onClick={(e: any) => this.handleMinisiteExecutionCreate(e, service?.id as string)}>
                                {this.translatorManager.get('service.detail.button.create.minisite.text')}
                            </button>
                        )}
                        {/* {isLogged && (
                            <>
                                <button
                                    id={service?.id}
                                    disabled={this.props.service?.status === WebServiceStatusEnum.DRAFT}
                                    className='btn btn-outline-primary table-button d-flex align-items-center action-item'
                                    onClick={(e: any) => this.handleStartClick(e, service?.id as string)}>
                                    {this.translatorManager.get('common.start.button.text')} <Play className='table-icon-button ms-2' />
                                </button>
                            </>
                        )} */}
                        <div className='btn-group'>
                            {((!service?.published && (service?.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin]))) ||
                                service?.published) && (
                                <button
                                    className='btn btn-outline-primary publish-container d-flex flex-row'
                                    type='button'
                                    data-bs-toggle='dropdown'
                                    aria-controls='dropdown'
                                    aria-haspopup='true'
                                    onClick={(e: React.MouseEvent<HTMLElement>) => {
                                        e.stopPropagation();
                                    }}
                                    aria-expanded='false'>
                                    {this.translatorManager.get('common.button.action.label')}
                                    <ArrowDown className='arrow-down icon' fill='#009138' />
                                    <ArrowUp className='arrow-up icon' fill='#009138' />
                                </button>
                            )}
                            <div className='dropdown-menu'>
                                <div className='link-list-wrapper'>
                                    <ul className='link-list'>
                                        {(service?.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) && (
                                            <li>
                                                <a
                                                    className='dropdown-item list-item link-item'
                                                    onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) => service?.id && this.handlePublicationDatasetClick(e, service?.id)}>
                                                    <span>{this.translatorManager.get('service.table.action.pubblication.dataset.text')}</span>
                                                </a>
                                            </li>
                                        )}
                                        {(service?.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) &&
                                            !service?.published &&
                                            service?.status === WebServiceStatusEnum.TO_BE_PUBLISHED && (
                                                <li>
                                                    <a
                                                        className='dropdown-item list-item link-item'
                                                        onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) =>
                                                            service?.id && this.handlePublicationServiceClick(e, service?.id)
                                                        }>
                                                        <span>{this.translatorManager.get('service.table.action.pubblication.service.text')}</span>
                                                    </a>
                                                </li>
                                            )}
                                        {service?.published && (
                                            <li>
                                                <a
                                                    className='dropdown-item list-item link-item'
                                                    id={service?.id}
                                                    onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) =>
                                                        this.handleDownloadRepoClick(e, service?.id_reference_project as string, service.name as string)
                                                    }>
                                                    <span>{this.translatorManager.get('service.table.action.download.project.text')}</span>
                                                </a>
                                            </li>
                                        )}
                                        {(service?.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) &&
                                            service?.status !== WebServiceStatusEnum.TO_BE_APPROVED && (
                                                <li>
                                                    <a
                                                        className='dropdown-item list-item link-item'
                                                        id={service?.id}
                                                        onClick={(e: React.MouseEvent<HTMLHyperlinkElementUtils>) => this.handleDeleteClick(e, service?.id as string)}>
                                                        <span>{this.translatorManager.get('service.table.action.delete.service.text')}</span>
                                                    </a>
                                                </li>
                                            )}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12'>
                            <div className={`pb-3 accordionContainer ${'accordion-container-closed-ide'}`}>
                                <AccordionContainer
                                    service={service}
                                    execution={execution}
                                    user={user}
                                    createUpdate={createUpdate}
                                    onBlur={this.handleOnChange}
                                    onRowClick={this.handleTableRowClick}
                                    executionsPagination={executionsPagination}
                                    availableCategoryOptions={this.props.availableCategoryOptions?.filter(
                                        (item: string) => item !== 'ALL' && !this.props.createUpdate.categories?.includes(item),
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
