import { ProjectControllerApi } from '../../../api/controllers/project-controller-api';

export interface IProjectApiClient {
    project: ProjectControllerApi;
}

export class ProjectApiClients {
    private static instance: IProjectApiClient;

    static getInstance(basePath: string): IProjectApiClient {
        if (ProjectApiClients.instance == null) {
            ProjectApiClients.instance = {
                project: new ProjectControllerApi(undefined, basePath),
            };
        }
        return ProjectApiClients.instance;
    }
}
