import { ProjectUpdateInputsStatus, ProjectUpdateOutputsStatus } from '@features/project/state/actions';
import { StoreManager } from '@hypereact/state';
import { RequestBehubIntegrationPublishDataset } from 'src/api/entities/request-behub-integration-publish-dataset';
import { ResponseBehubIntegrationPublishDataset } from 'src/api/entities/response-behub-integration-publish-dataset';
import { BehubIntegrationApiService, IBehubIntegrationApiService } from '../services/behub/behub-integration.api.service';

export interface IBehubIntegrationManager {
    publishDataset(
        projectId: string,
        target: 'INPUT' | 'OUTPUT',
        fileName: string,
        requestBehubIntegrationPublishDataset: RequestBehubIntegrationPublishDataset,
    ): Promise<ResponseBehubIntegrationPublishDataset>;
}

export class BehubIntegrationManager implements IBehubIntegrationManager {
    private static instance: BehubIntegrationManager;
    private behubIntegrationService: IBehubIntegrationApiService;
    private storeManager: StoreManager;

    private constructor() {
        this.storeManager = StoreManager.getInstance();
        this.behubIntegrationService = BehubIntegrationApiService.getInstance(process.env.REACT_APP_MICROSERVICE_DASHBOARD_API_BASEPATH!);
    }

    static getInstance() {
        if (BehubIntegrationManager.instance == null) {
            BehubIntegrationManager.instance = new BehubIntegrationManager();
        }
        return BehubIntegrationManager.instance;
    }

    async publishDataset(
        projectId: string,
        target: 'INPUT' | 'OUTPUT',
        fileName: string,
        requestBehubIntegrationPublishDataset: RequestBehubIntegrationPublishDataset,
    ): Promise<ResponseBehubIntegrationPublishDataset> {
        const response = await this.behubIntegrationService.publishDataset(projectId, target, fileName, requestBehubIntegrationPublishDataset);
        target === 'INPUT' && this.storeManager.dispatch(new ProjectUpdateInputsStatus(fileName, response));
        return response;
    }
}
