export interface ShellState {
    navigation: NavigationState;
}

export interface NavigationState {
    page: number;
    size: number;
    total?: number;
}

export const initialShellState: ShellState = {
    navigation: {
        page: 0,
        size: 10,
        total: undefined,
    },
};

export interface SharedState {
    loading: {
        global: number;
        [key: string]: number;
    };
    sidebar: {
        isOpen: boolean;
        currentItemId: number;
        currentSubItemId: number | null;
    };
    popup: {
        isVisible: boolean;
    };
    toast: {
        isVisible: boolean;
    };
    navbar: null;
    currentRoute?: string;
    configuration: {
        [key: string]: string | undefined;
    };
}

export const initialSharedState: SharedState = {
    loading: {
        global: 0,
    },
    sidebar: {
        isOpen: true,
        currentItemId: 0,
        currentSubItemId: null,
    },
    popup: {
        isVisible: false,
    },
    toast: {
        isVisible: false,
    },
    navbar: null,
    currentRoute: undefined,
    configuration: {},
};
