import { InputFile, OutputFile, ResponseServiceExecutionMinisite } from '../../../api';

export interface InputState {
    inputs: Array<InputFile>;
    selectedInput?: InputFile;
}

export interface OutputState {
    outputs: Array<OutputFile>;
    selectedOutput?: OutputFile;
}

export interface ExecutionMinisiteState {
    executions: Array<ResponseServiceExecutionMinisite>;
    selectedExecution?: ResponseServiceExecutionMinisite;
    // pagination: ListPaginationInterface;
    log?: string;
    html?: string;
    email: string | undefined;
}

export interface MinisiteState {
    execution: ExecutionMinisiteState;
    input: InputState;
    output: OutputState;
}

export const initialMinisiteState: MinisiteState = {
    execution: {
        executions: [],
        selectedExecution: undefined,
        // pagination: {
        //     page: 0,
        //     size: 5,
        // },
        email: undefined,
    },
    input: {
        inputs: [],
    },
    output: {
        outputs: [],
    },
};
