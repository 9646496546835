import React from 'react';
import { WebService } from 'src/api/entities/web-service';
import './styles/minisite.header.scss';

export interface IMiniSiteHeaderProps {
    service: WebService;
}

export const MiniSiteHeader: React.FC<IMiniSiteHeaderProps> = ({ service }) => {
    //const { minisite_title, subtitle, logo, landscape } = service;

    return (
        <React.Fragment>
            <div className='container-fluid'>
                <div
                    className={`row d-flex ${service?.landscape ? 'background-container' : 'background-container-no-image'}`}
                    style={service?.landscape ? { backgroundImage: `url(${service?.landscape})` } : {}}>
                    <div className='d-flex flex-column justify-content-center container mb-0'>
                        <p className={`header-title ${service?.landscape ? 'minisite-title-header' : 'minisite-title-no-bg'}`}>{service?.minisite_title}</p>
                        <p className={`title-description ${service?.landscape ? 'minisite-title-header' : 'minisite-title-no-bg'}`}>{service?.subtitle}</p>
                    </div>
                </div>
            </div>
            <div className='home-title-container mb-0'>
                <h5 className='title-description col-12'>{service?.description}</h5>
            </div>
            <div className='d-flex justify-content-center mt-2'>
                {service?.logo ? <img className='minisite-logo-container' src={service?.logo} alt='logo'></img> : <div className='col-md-4'></div>}
            </div>
        </React.Fragment>
    );
};
