/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BranchRequest } from '../entities';
// @ts-ignore
import { GitLabUserRequest } from '../entities';
// @ts-ignore
import { GitLabUserResponse } from '../entities';
// @ts-ignore
import { ProjectResponseBody } from '../entities';
// @ts-ignore
import { ProjectsTemplate } from '../entities';
// @ts-ignore
import { TokenResponse } from '../entities';
// @ts-ignore
import { User } from '../entities';
/**
 * GitIntegrationControllerApi - axios parameter creator
 * @export
 */
export const GitIntegrationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Changes the author as role in a project repository
         * @param {string} gitProjectName
         * @param {User} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser: async (gitProjectName: string, user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gitProjectName' is not null or undefined
            assertParamExists('addUser', 'gitProjectName', gitProjectName);
            // verify required parameter 'user' is not null or undefined
            assertParamExists('addUser', 'user', user);
            const localVarPath = `/git/addUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gitProjectName !== undefined) {
                localVarQueryParameter['gitProjectName'] = gitProjectName;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a branch inside the repository in the programs group
         * @param {BranchRequest} branchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branch: async (branchRequest: BranchRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'branchRequest' is not null or undefined
            assertParamExists('branch', 'branchRequest', branchRequest);
            const localVarPath = `/git/createBranch`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(branchRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Check if a specific user can access or consume the API
         * @param {GitLabUserRequest} gitLabUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        check1: async (gitLabUserRequest: GitLabUserRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gitLabUserRequest' is not null or undefined
            assertParamExists('check1', 'gitLabUserRequest', gitLabUserRequest);
            const localVarPath = `/git/checkGroups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(gitLabUserRequest, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a new repository in the \"programs\" group
         * @param {string} gitTemplateId
         * @param {string} gitProjectName
         * @param {string} description
         * @param {User} author
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1: async (gitTemplateId: string, gitProjectName: string, description: string, author: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gitTemplateId' is not null or undefined
            assertParamExists('create1', 'gitTemplateId', gitTemplateId);
            // verify required parameter 'gitProjectName' is not null or undefined
            assertParamExists('create1', 'gitProjectName', gitProjectName);
            // verify required parameter 'description' is not null or undefined
            assertParamExists('create1', 'description', description);
            // verify required parameter 'author' is not null or undefined
            assertParamExists('create1', 'author', author);
            const localVarPath = `/git/createProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gitTemplateId !== undefined) {
                localVarQueryParameter['gitTemplateId'] = gitTemplateId;
            }

            if (gitProjectName !== undefined) {
                localVarQueryParameter['gitProjectName'] = gitProjectName;
            }

            if (description !== undefined) {
                localVarQueryParameter['description'] = description;
            }

            if (author !== undefined) {
                localVarQueryParameter['author'] = author;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Returns the list of project templates needed for further technology selection in project creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/git/checkTemplates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Ensures that a user exists in GitLab
         * @param {User} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pokeUser: async (user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'user' is not null or undefined
            assertParamExists('pokeUser', 'user', user);
            const localVarPath = `/git/pokeUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Changes the author as role in a project repository
         * @param {string} gitProjectName
         * @param {User} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUser1: async (gitProjectName: string, user: User, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'gitProjectName' is not null or undefined
            assertParamExists('removeUser1', 'gitProjectName', gitProjectName);
            // verify required parameter 'user' is not null or undefined
            assertParamExists('removeUser1', 'user', user);
            const localVarPath = `/git/removeUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (gitProjectName !== undefined) {
                localVarQueryParameter['gitProjectName'] = gitProjectName;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(user, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * GitIntegrationControllerApi - functional programming interface
 * @export
 */
export const GitIntegrationControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = GitIntegrationControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Changes the author as role in a project repository
         * @param {string} gitProjectName
         * @param {User} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addUser(gitProjectName: string, user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GitLabUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addUser(gitProjectName, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a branch inside the repository in the programs group
         * @param {BranchRequest} branchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async branch(branchRequest: BranchRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.branch(branchRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Check if a specific user can access or consume the API
         * @param {GitLabUserRequest} gitLabUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async check1(gitLabUserRequest: GitLabUserRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.check1(gitLabUserRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a new repository in the \"programs\" group
         * @param {string} gitTemplateId
         * @param {string} gitProjectName
         * @param {string} description
         * @param {User} author
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async create1(
            gitTemplateId: string,
            gitProjectName: string,
            description: string,
            author: User,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectResponseBody>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.create1(gitTemplateId, gitProjectName, description, author, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Returns the list of project templates needed for further technology selection in project creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplates(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: ProjectsTemplate | undefined }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplates(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Ensures that a user exists in GitLab
         * @param {User} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pokeUser(user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pokeUser(user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Changes the author as role in a project repository
         * @param {string} gitProjectName
         * @param {User} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async removeUser1(gitProjectName: string, user: User, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.removeUser1(gitProjectName, user, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * GitIntegrationControllerApi - factory interface
 * @export
 */
export const GitIntegrationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GitIntegrationControllerApiFp(configuration);
    return {
        /**
         * Changes the author as role in a project repository
         * @param {string} gitProjectName
         * @param {User} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addUser(gitProjectName: string, user: User, options?: any): AxiosPromise<GitLabUserResponse> {
            return localVarFp.addUser(gitProjectName, user, options).then(request => request(axios, basePath));
        },
        /**
         * Create a branch inside the repository in the programs group
         * @param {BranchRequest} branchRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        branch(branchRequest: BranchRequest, options?: any): AxiosPromise<void> {
            return localVarFp.branch(branchRequest, options).then(request => request(axios, basePath));
        },
        /**
         * Check if a specific user can access or consume the API
         * @param {GitLabUserRequest} gitLabUserRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        check1(gitLabUserRequest: GitLabUserRequest, options?: any): AxiosPromise<void> {
            return localVarFp.check1(gitLabUserRequest, options).then(request => request(axios, basePath));
        },
        /**
         * Creates a new repository in the \"programs\" group
         * @param {string} gitTemplateId
         * @param {string} gitProjectName
         * @param {string} description
         * @param {User} author
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        create1(gitTemplateId: string, gitProjectName: string, description: string, author: User, options?: any): AxiosPromise<ProjectResponseBody> {
            return localVarFp.create1(gitTemplateId, gitProjectName, description, author, options).then(request => request(axios, basePath));
        },
        /**
         * Returns the list of project templates needed for further technology selection in project creation
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplates(options?: any): AxiosPromise<{ [key: string]: ProjectsTemplate | undefined }> {
            return localVarFp.getTemplates(options).then(request => request(axios, basePath));
        },
        /**
         * Ensures that a user exists in GitLab
         * @param {User} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pokeUser(user: User, options?: any): AxiosPromise<TokenResponse> {
            return localVarFp.pokeUser(user, options).then(request => request(axios, basePath));
        },
        /**
         * Changes the author as role in a project repository
         * @param {string} gitProjectName
         * @param {User} user
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUser1(gitProjectName: string, user: User, options?: any): AxiosPromise<void> {
            return localVarFp.removeUser1(gitProjectName, user, options).then(request => request(axios, basePath));
        },
    };
};

/**
 * GitIntegrationControllerApi - interface
 * @export
 * @interface GitIntegrationControllerApi
 */
export interface GitIntegrationControllerApiInterface {
    /**
     * Changes the author as role in a project repository
     * @param {string} gitProjectName
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitIntegrationControllerApiInterface
     */
    addUser(gitProjectName: string, user: User, options?: AxiosRequestConfig): AxiosPromise<GitLabUserResponse>;

    /**
     * Create a branch inside the repository in the programs group
     * @param {BranchRequest} branchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitIntegrationControllerApiInterface
     */
    branch(branchRequest: BranchRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Check if a specific user can access or consume the API
     * @param {GitLabUserRequest} gitLabUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitIntegrationControllerApiInterface
     */
    check1(gitLabUserRequest: GitLabUserRequest, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Creates a new repository in the \"programs\" group
     * @param {string} gitTemplateId
     * @param {string} gitProjectName
     * @param {string} description
     * @param {User} author
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitIntegrationControllerApiInterface
     */
    create1(gitTemplateId: string, gitProjectName: string, description: string, author: User, options?: AxiosRequestConfig): AxiosPromise<ProjectResponseBody>;

    /**
     * Returns the list of project templates needed for further technology selection in project creation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitIntegrationControllerApiInterface
     */
    getTemplates(options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: ProjectsTemplate | undefined }>;

    /**
     * Ensures that a user exists in GitLab
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitIntegrationControllerApiInterface
     */
    pokeUser(user: User, options?: AxiosRequestConfig): AxiosPromise<TokenResponse>;

    /**
     * Changes the author as role in a project repository
     * @param {string} gitProjectName
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitIntegrationControllerApiInterface
     */
    removeUser1(gitProjectName: string, user: User, options?: AxiosRequestConfig): AxiosPromise<void>;
}

/**
 * GitIntegrationControllerApi - object-oriented interface
 * @export
 * @class GitIntegrationControllerApi
 * @extends {BaseAPI}
 */
export class GitIntegrationControllerApi extends BaseAPI implements GitIntegrationControllerApiInterface {
    /**
     * Changes the author as role in a project repository
     * @param {string} gitProjectName
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitIntegrationControllerApi
     */
    public addUser(gitProjectName: string, user: User, options?: AxiosRequestConfig) {
        return GitIntegrationControllerApiFp(this.configuration)
            .addUser(gitProjectName, user, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Create a branch inside the repository in the programs group
     * @param {BranchRequest} branchRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitIntegrationControllerApi
     */
    public branch(branchRequest: BranchRequest, options?: AxiosRequestConfig) {
        return GitIntegrationControllerApiFp(this.configuration)
            .branch(branchRequest, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Check if a specific user can access or consume the API
     * @param {GitLabUserRequest} gitLabUserRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitIntegrationControllerApi
     */
    public check1(gitLabUserRequest: GitLabUserRequest, options?: AxiosRequestConfig) {
        return GitIntegrationControllerApiFp(this.configuration)
            .check1(gitLabUserRequest, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Creates a new repository in the \"programs\" group
     * @param {string} gitTemplateId
     * @param {string} gitProjectName
     * @param {string} description
     * @param {User} author
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitIntegrationControllerApi
     */
    public create1(gitTemplateId: string, gitProjectName: string, description: string, author: User, options?: AxiosRequestConfig) {
        return GitIntegrationControllerApiFp(this.configuration)
            .create1(gitTemplateId, gitProjectName, description, author, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Returns the list of project templates needed for further technology selection in project creation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitIntegrationControllerApi
     */
    public getTemplates(options?: AxiosRequestConfig) {
        return GitIntegrationControllerApiFp(this.configuration)
            .getTemplates(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Ensures that a user exists in GitLab
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitIntegrationControllerApi
     */
    public pokeUser(user: User, options?: AxiosRequestConfig) {
        return GitIntegrationControllerApiFp(this.configuration)
            .pokeUser(user, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Changes the author as role in a project repository
     * @param {string} gitProjectName
     * @param {User} user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GitIntegrationControllerApi
     */
    public removeUser1(gitProjectName: string, user: User, options?: AxiosRequestConfig) {
        return GitIntegrationControllerApiFp(this.configuration)
            .removeUser1(gitProjectName, user, options)
            .then(request => request(this.axios, this.basePath));
    }
}
