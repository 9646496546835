import { TextField } from '@components/TextField';
import { ListPaginationInterface } from '@features/_shared/components/Paginator/enums/ListPaginationInterface';
import { SelectMultipleComponent } from '@features/_shared/components/SelectMultiple/SelectMultiple.component';
import { FormManager } from '@managers/form.manager';
import { ProjectManager } from '@managers/project.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import React from 'react';
import { ProjectSearchFilter, ProjectSearchFilterStatusEnum } from '../../../api/entities';
import { ProjectFilterbarData } from '../beans/projectFilterbarData';
import './styles/project.filterbar.style.scss';

export interface IFilterBarProps {
    filters?: ProjectSearchFilter;
    onFilterChange?: () => void;
    clearFilters?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    availableCategoryOptions?: string[];
    pagination?: ListPaginationInterface;
}

const FilterBarProjectView: React.FC<IFilterBarProps> = ({ onFilterChange, filters, clearFilters, availableCategoryOptions, pagination }) => {
    const translator = TranslatorManager.getInstance();
    const projectManager = ProjectManager.getInstance();

    const handleFiltersChange = async (e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) => {
        await projectManager.setFilters(e.target.name as keyof ProjectSearchFilter, e.target.value);
        await onFilterChange?.();
    };

    const handleClearFilters = async (e: React.MouseEvent<HTMLButtonElement>) => {
        await projectManager.resetFilters();
        clearFilters?.(e);
        setValue('name', '');
        setValue('creatorName', '');
        setValue('status', ProjectSearchFilterStatusEnum.ALL);
        setValue('keywords', '');
    };

    const handleCategoryChange = async (newValues: Array<string>) => {
        await projectManager.setFilters('categories' as keyof ProjectSearchFilter, String(newValues));
        await onFilterChange?.();
    };

    const handleUnselectAll = async (e?: React.MouseEvent<HTMLButtonElement>) => {
        e && e.preventDefault();
        await projectManager.setFilters('categories' as keyof ProjectSearchFilter, 'ALL');
        await onFilterChange?.();
        projectManager.resetCategoryFilter();
        await projectManager.getProjectCategories();
    };

    const { setValue, register } = FormManager.getInstance().buildFormProxy<ProjectFilterbarData>(ProjectFilterbarData, () => {});

    return (
        <>
            <div className='container-fluid mb-5'>
                <div className='row mb-4'>
                    <p className='filter-title'>{translator.get('common.filterbar.title')}</p>
                </div>
                <div className='row row-cols-12'>
                    <div className='col-lg-2 col-sm-12 col-md-2'>
                        <div className='form-group filter-input-container'>
                            <label htmlFor='nameFilter' className='active label-color'>
                                {translator.get('project.filterbar.name.label')}
                            </label>
                            <TextField
                                isSearchable={true}
                                {...register('name')}
                                id={'name'}
                                className='filter-border'
                                placeholder={translator.get('service.filterbar.search.placeholder')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFiltersChange(e)}
                            />
                        </div>
                    </div>
                    <div className='col-lg-2 col-sm-12 col-md-2'>
                        <div className='form-group filter-input-container'>
                            <label htmlFor='creatorFilter' className='active label-color'>
                                {translator.get('project.filterbar.creator.label')}
                            </label>
                            <TextField
                                isSearchable={true}
                                {...register('creatorName')}
                                className='filter-border'
                                placeholder={translator.get('service.filterbar.search.placeholder')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFiltersChange(e)}
                            />
                        </div>
                    </div>

                    <div className='col-lg-2 col-sm-12 col-md-2'>
                        <div className='form-group filter-input-container'>
                            <label htmlFor='keywordSearch' className='active label-color'>
                                {translator.get('project.filterbar.keywords.label')}
                            </label>
                            <TextField
                                isSearchable={true}
                                {...register('keywords')}
                                className='filter-border'
                                placeholder={translator.get('service.filterbar.search.placeholder')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFiltersChange(e)}
                            />
                        </div>
                    </div>
                    <div className='col-lg-2 col-sm-12 col-md-2'>
                        <div id='category'>
                            <div className='select-wrapper service-filterbar-center'>
                                <label htmlFor='category' className='active label-color'>
                                    {translator.get('service.filterbar.category.label')}
                                </label>

                                <SelectMultipleComponent
                                    placeholder={translator.get('service.filterbar.category.placeholder')}
                                    options={availableCategoryOptions as string[]}
                                    value={filters?.categories?.split(',') as string[]}
                                    {...register('categories')}
                                    onChange={handleCategoryChange}
                                    availableValues={availableCategoryOptions as Array<string>}
                                    handleUnselectAll={handleUnselectAll}
                                    isFilterbar={true}
                                    isDeleteShow={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-sm-12 col-md-2'>
                        <div className='select-wrapper filter-input-container'>
                            <label htmlFor='statusFilter' className='active label-color'>
                                {translator.get('project.filterbar.status.label')}
                            </label>
                            <select className='select-container' {...register('status')} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleFiltersChange(e)}>
                                {Object.keys(ProjectSearchFilterStatusEnum)
                                    .filter(
                                        item =>
                                            item !== ProjectSearchFilterStatusEnum.TO_BE_DELETED &&
                                            item !== ProjectSearchFilterStatusEnum.STOPPED &&
                                            item !== ProjectSearchFilterStatusEnum.DELETED,
                                    )
                                    .map((item, index) => {
                                        return (
                                            <option value={item} selected={item === ProjectSearchFilterStatusEnum.ALL} key={index}>
                                                {item.replace(/_/g, ' ')}
                                            </option>
                                        );
                                    })}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-4 col-sm-12 col-md-4'>
                        <button className='btn btn-primary text-white' onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClearFilters?.(e)}>
                            {translator.get('common.clear.all.filters.button.text')}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
export default FilterBarProjectView;
