import { HomeSetStatistics } from '@features/home/state/actions';
import { StoreManager } from '@hypereact/state';
import { HomepageApiService, IHomepageApiService } from '../services/homepage/homepage.api.service';

export interface IHomepageManagerProps {
    getStatistics(): Promise<void>;
}

export class HomepageManager implements IHomepageManagerProps {
    private static instance: HomepageManager;
    private homepageService: IHomepageApiService;
    private storeManager: StoreManager;

    private constructor() {
        this.homepageService = HomepageApiService.getInstance(process.env.REACT_APP_MICROSERVICE_DASHBOARD_API_BASEPATH!);
        this.storeManager = StoreManager.getInstance();
    }

    static getInstance() {
        if (HomepageManager.instance == null) {
            HomepageManager.instance = new HomepageManager();
        }
        return HomepageManager.instance;
    }

    async getStatistics(): Promise<void> {
        const response = await this.homepageService.getStatistics();
        this.storeManager.dispatch(new HomeSetStatistics(response));
    }
}
