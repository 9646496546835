import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
    url: document.querySelector('script[id="injected_keycloak_server_url"]') && document.querySelector('script[id="injected_keycloak_server_url"]').textContent.toString(),
    realm: document.querySelector('script[id="injected_keycloak_realm"]') && document.querySelector('script[id="injected_keycloak_realm"]').textContent.toString(),
    clientId: document.querySelector('script[id="injected_keycloak_client_name"]') && document.querySelector('script[id="injected_keycloak_client_name"]').textContent.toString(),
    publicClient: true,
});

export default keycloak;
