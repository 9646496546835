/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RequestBelindaIntegrationUpdate } from '../entities';
// @ts-ignore
import { ResponseBelindaIntegrationUpdate } from '../entities';
/**
 * BelindaIntegrationControllerApi - axios parameter creator
 * @export
 */
export const BelindaIntegrationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Update the status of the resource so that it is aligned with that of belinda.
         * @param {string} apiKey
         * @param {RequestBelindaIntegrationUpdate} requestBelindaIntegrationUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        belindaUpdate: async (apiKey: string, requestBelindaIntegrationUpdate: RequestBelindaIntegrationUpdate, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiKey' is not null or undefined
            assertParamExists('belindaUpdate', 'apiKey', apiKey);
            // verify required parameter 'requestBelindaIntegrationUpdate' is not null or undefined
            assertParamExists('belindaUpdate', 'requestBelindaIntegrationUpdate', requestBelindaIntegrationUpdate);
            const localVarPath = `/integration/belinda/update`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (apiKey != null) {
                localVarHeaderParameter['api-key'] = String(apiKey);
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestBelindaIntegrationUpdate, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * BelindaIntegrationControllerApi - functional programming interface
 * @export
 */
export const BelindaIntegrationControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BelindaIntegrationControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Update the status of the resource so that it is aligned with that of belinda.
         * @param {string} apiKey
         * @param {RequestBelindaIntegrationUpdate} requestBelindaIntegrationUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async belindaUpdate(
            apiKey: string,
            requestBelindaIntegrationUpdate: RequestBelindaIntegrationUpdate,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseBelindaIntegrationUpdate>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.belindaUpdate(apiKey, requestBelindaIntegrationUpdate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * BelindaIntegrationControllerApi - factory interface
 * @export
 */
export const BelindaIntegrationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BelindaIntegrationControllerApiFp(configuration);
    return {
        /**
         * Update the status of the resource so that it is aligned with that of belinda.
         * @param {string} apiKey
         * @param {RequestBelindaIntegrationUpdate} requestBelindaIntegrationUpdate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        belindaUpdate(apiKey: string, requestBelindaIntegrationUpdate: RequestBelindaIntegrationUpdate, options?: any): AxiosPromise<ResponseBelindaIntegrationUpdate> {
            return localVarFp.belindaUpdate(apiKey, requestBelindaIntegrationUpdate, options).then(request => request(axios, basePath));
        },
    };
};

/**
 * BelindaIntegrationControllerApi - interface
 * @export
 * @interface BelindaIntegrationControllerApi
 */
export interface BelindaIntegrationControllerApiInterface {
    /**
     * Update the status of the resource so that it is aligned with that of belinda.
     * @param {string} apiKey
     * @param {RequestBelindaIntegrationUpdate} requestBelindaIntegrationUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BelindaIntegrationControllerApiInterface
     */
    belindaUpdate(apiKey: string, requestBelindaIntegrationUpdate: RequestBelindaIntegrationUpdate, options?: AxiosRequestConfig): AxiosPromise<ResponseBelindaIntegrationUpdate>;
}

/**
 * BelindaIntegrationControllerApi - object-oriented interface
 * @export
 * @class BelindaIntegrationControllerApi
 * @extends {BaseAPI}
 */
export class BelindaIntegrationControllerApi extends BaseAPI implements BelindaIntegrationControllerApiInterface {
    /**
     * Update the status of the resource so that it is aligned with that of belinda.
     * @param {string} apiKey
     * @param {RequestBelindaIntegrationUpdate} requestBelindaIntegrationUpdate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BelindaIntegrationControllerApi
     */
    public belindaUpdate(apiKey: string, requestBelindaIntegrationUpdate: RequestBelindaIntegrationUpdate, options?: AxiosRequestConfig) {
        return BelindaIntegrationControllerApiFp(this.configuration)
            .belindaUpdate(apiKey, requestBelindaIntegrationUpdate, options)
            .then(request => request(this.axios, this.basePath));
    }
}
