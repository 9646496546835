import { IsNotEmpty, Matches } from 'class-validator';

export class ServiceCreateFormData {
    @Matches(/^[a-zA-Z]+[a-zA-Z0-9\-_]*[a-zA-Z0-9]{1}$/)
    @IsNotEmpty()
    name: string;

    @Matches(/^(?!.*[-_.]{2,})(?!.*\.{2,})(?!.*_{2,})(?!.*-{2,})(?!.*[-_.]{1}[-_.]+)[a-zA-Z0-9]+[a-zA-Z0-9\-_\.]*[a-zA-Z0-9]$/)
    @IsNotEmpty()
    version: string;

    @Matches(/^(?!\s)[^!@#$%^&*()_+={}[\]|\\:;"'<,>.?/~`].*/)
    @IsNotEmpty()
    description: string;

    categories: Array<string>;

    @Matches(/^(?!\s)[^!@#$%^&*()_+={}[\]|\\:;"'<,>.?/~`].*/)
    @IsNotEmpty()
    keyword: Array<string>;
}
