import { ReactComponent as Close } from '@assets/svg/it-close.svg';
import { TextField } from '@components/TextField';
import { SelectMultipleComponent } from '@features/_shared/components/SelectMultiple/SelectMultiple.component';
import { TextArea } from '@features/_shared/components/TextArea';
import { StyledTooltip } from '@features/_shared/components/Tooltip/Tooltip';
import { TranslatorManager } from '@managers/TranslatorManager';
import { FormManager } from '@managers/form.manager';
import { ProjectManager } from '@managers/project.manager';
import { QueueManager } from '@managers/queue.manager';
import { FormHelperUtil } from '@utils/form.helper.util';
import KeycloakUtil from '@utils/keycloak.util';
import { useContext, useEffect, useState } from 'react';
import { IntlContext } from 'react-intl';
import { BaseProjectTemplate, User } from '../../../api/entities';
import { ProjectCreateFormData } from '../beans/projectCreateFormData';
import { ProjectCreateUpdate } from '../state/state';
import './styles/project.create.style.scss';

export interface ICreateFormProjectViewProps {
    user: User;
    technologies: Array<BaseProjectTemplate>;
    onBlur?: (key: keyof ProjectCreateUpdate, value: string | number | boolean | Array<string>) => void;
    onChange?: (key: keyof ProjectCreateUpdate, value: string | number | boolean) => void;
    onCreate?: () => void;
    isModalClosed?: boolean;
    availableCategoryOptions?: string[];
    projectCreate: ProjectCreateUpdate;
}

const CreateFormProjectView: React.FC<ICreateFormProjectViewProps> = ({ technologies, isModalClosed, onBlur, onCreate, availableCategoryOptions, projectCreate }) => {
    const translator = TranslatorManager.getInstance();
    const intl = useContext(IntlContext);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator('project.create', intl as any);
    const username = KeycloakUtil.getName();
    const projectManager = ProjectManager.getInstance();
    const queueManager = QueueManager.getInstance();

    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        let error = await trigger(['name', 'version', 'technology', 'description']);
        //needs to be called after trigger , otherwise the values will be reset
        const isCategoriesAndKeywordsValidated = validateCategoriesAndKeywords();
        error = error && isCategoriesAndKeywordsValidated;

        setIsSubmitted(true);
        if (error) {
            onCreate?.();
            reset();
            setIsSubmitted(false);
        } else {
            e.preventDefault();
        }
    };

    const validateCategoriesAndKeywords = () => {
        const isCategories = projectCreate.categories && projectCreate.categories?.length > 0 ? true : false;
        const isKeywords = projectCreate.keywords && projectCreate.keywords.length > 0 ? true : false;

        if (isCategories || isKeywords) {
            if (isCategories) {
                clearErrors('categories');
            }
            if (isKeywords) {
                clearErrors('keyword');
            }
        }
        if (!isCategories || !isKeywords) {
            if (!isCategories) {
                setError('categories', { type: 'isNotEmpty' });
            }
            if (!isKeywords) {
                setError('keyword', { type: 'isNotEmpty' });
            }
        }
        return (isCategories && isKeywords) as boolean;
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
        onBlur?.(e.target.name as keyof ProjectCreateUpdate, e.target.value);
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        onBlur?.(e.target.name as keyof ProjectCreateUpdate, e.target.value);
    };

    const handleOnAddKeywordButtonClick = async () => {
        const currentKeyword = getValues('keyword');
        const noError = (await trigger('keyword')) && !projectCreate?.keywords?.find((item: string) => item.toLowerCase() === currentKeyword.toLowerCase());
        if (currentKeyword !== '' && noError) {
            const updatedKeywords = projectCreate.keywords ? [...(projectCreate.keywords as string[]), currentKeyword] : [currentKeyword];
            onBlur?.('keywords' as keyof ProjectCreateUpdate, updatedKeywords);
            setValue('keyword', undefined);
            clearErrors('keyword');
        } else if (projectCreate?.keywords?.find((item: string) => item.toLowerCase() === currentKeyword.toLowerCase())) {
            setError('keyword', { type: 'keywordlrdxst' });
        }
    };

    const handleOnKeywordRemoveClick = (keywordToBeRemoved: string) => {
        if (projectCreate.keywords) {
            const updatedKeywords: any[] = projectCreate.keywords.filter(currentKeyword => currentKeyword !== keywordToBeRemoved);
            onBlur?.('keywords' as keyof ProjectCreateUpdate, updatedKeywords);
        }
    };

    const triggerValidation = (key: string, value: string) => {
        setValue(key, value);
        (isSubmitted || key === 'keyword') && trigger(key);
    };

    useEffect(() => {
        clearErrors(['name', 'version', 'technology', 'categories', 'description', 'keyword']);
        const fieldNames = ['name', 'version', 'technology', 'categories'];
        fieldNames.map((item: string) => setValue(item, ''));
        setValue('description', undefined);
        setIsSubmitted(false);
    }, [isModalClosed]);

    const handleCategoryChange = async (newValues: Array<string>) => {
        onBlur?.('categories' as keyof ProjectCreateUpdate, newValues);
        if (newValues.length > 0) {
            clearErrors('categories');
        } else {
            setError('categories', { type: 'isNotEmpty' });
        }
    };

    const handleUnselectAll = async (e?: React.MouseEvent<HTMLButtonElement>) => {
        e && e.preventDefault();
        projectManager.resetCategoryCreate();
        await projectManager.getProjectCategories();
    };

    const { errors, register, trigger, setValue, reset, clearErrors, setError, getValues } = FormManager.getInstance().buildFormProxy<ProjectCreateFormData>(
        ProjectCreateFormData,
        onSubmit,
    );
    return (
        <div className='container-fluid mt-4'>
            <div className='row'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='title' className='active d-flex flex-row align-items-center label-color'>
                            {translator.get('project.modal.create.new.title.label')}
                            <p className='requiredSign'>*</p>
                            <StyledTooltip trigger='hover' position='right' labelKey={translator.get('project.modal.create.title.tooltip')}>
                                <div className='tooltip-icon-container'>?</div>
                            </StyledTooltip>
                        </label>
                        <TextField
                            type='text'
                            placeholder='Title'
                            id='name'
                            maxLength={40}
                            {...register('name', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('name', e.target.value) })}
                            error={errors.name}
                            errorMessage={errors.name && getMessageByFieldAndError('name', errors)}
                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                        />
                    </div>
                </div>
            </div>
            <div className='row pt-3'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='version' className='active d-flex flex-row align-items-center label-color'>
                            {translator.get('project.modal.create.version.label')}
                            <p className='requiredSign'>*</p>
                            <StyledTooltip trigger='hover' position='right' labelKey={translator.get('project.modal.create.version.tooltip')}>
                                <div className='tooltip-icon-container'>?</div>
                            </StyledTooltip>
                        </label>
                        <TextField
                            type='text'
                            id='version'
                            maxLength={20}
                            {...register('version', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('version', e.target.value) })}
                            error={errors.version}
                            errorMessage={errors.version && getMessageByFieldAndError('version', errors)}
                            placeholder='Version'
                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                        />
                    </div>
                </div>
            </div>
            <div className='row pt-3'>
                <div className='col-12'>
                    <div className='form-group'>
                        <div className='select-wrapper'>
                            <label htmlFor='technology' className='active d-flex flex-row align-items-center label-color'>
                                {translator.get('project.modal.create.language.label')}
                                <p className='requiredSign'>*</p>
                                <StyledTooltip trigger='hover' position='right' labelKey={translator.get('project.modal.create.technology.tooltip')}>
                                    <div className='tooltip-icon-container'>?</div>
                                </StyledTooltip>
                            </label>
                            <select
                                {...register('technology', {
                                    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                                        handleOnChange(e);
                                        triggerValidation('technology', e.target.value);
                                    },
                                })}
                                className='select-container'>
                                <option value='' disabled selected key={0}>
                                    {translator.get('project.modal.create.language.placeholder')}
                                </option>
                                {technologies.map((item: BaseProjectTemplate) => {
                                    return (
                                        <option value={`${item.id}`} key={item.id}>
                                            {item.name}
                                        </option>
                                    );
                                })}
                            </select>
                            {errors.technology && <small className='form-text error-message'>{translator.get(`form.project.create.language.${errors.technology.type}`)}</small>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row pt-3'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='creator' className='active label-color'>
                            {translator.get('project.modal.create.creator.label')}
                        </label>
                        <TextField id='creator' value={username} name='creator' readOnly />
                    </div>
                </div>
            </div>
            <div className='row pt-3'>
                <div className='col-12 category-index'>
                    <div className='form-group'>
                        <div className='select-wrapper'>
                            <label htmlFor='categories' className='active d-flex flex-row align-items-center label-color'>
                                {translator.get('project.modal.create.category.label')}
                                <p className='requiredSign'>*</p>
                                <StyledTooltip trigger='hover' position='right' labelKey={translator.get('project.modal.create.category.tooltip')}>
                                    <div className='tooltip-icon-container'>?</div>
                                </StyledTooltip>
                            </label>
                            <div>
                                <SelectMultipleComponent
                                    placeholder={translator.get('service.filterbar.category.placeholder')}
                                    options={availableCategoryOptions as string[]}
                                    value={projectCreate.categories}
                                    {...register('categories')}
                                    onChange={handleCategoryChange}
                                    availableValues={availableCategoryOptions as Array<string>}
                                    handleUnselectAll={handleUnselectAll}
                                />
                            </div>
                            {errors.categories && <small className='form-text error-message'>{translator.get(`form.project.create.category.${errors.categories.type}`)}</small>}
                        </div>
                    </div>
                </div>
            </div>
            <div className='row pt-3 mb-3'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='description' className='active d-flex flex-row align-items-center label-color'>
                            {translator.get('project.modal.create.abstract.label')}
                            <p className='requiredSign'>*</p>
                            <StyledTooltip trigger='hover' position='right' labelKey={translator.get('project.modal.create.abstract.tooltip')}>
                                <div className='tooltip-icon-container'>?</div>
                            </StyledTooltip>
                        </label>
                        <TextArea
                            rows={3}
                            id='description'
                            {...register('description', { onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => triggerValidation('description', e.target.value) })}
                            placeholder={translator.get('project.modal.create.abstract.placeholder')}
                            onBlur={(e: React.FocusEvent<HTMLTextAreaElement, Element>) => handleOnBlur(e)}
                        />
                        {errors.description && <small className='form-text error-message'>{translator.get(`form.project.create.description.${errors.description.type}`)}</small>}
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-12'>
                    <div className='form-group'>
                        <label htmlFor='keywords' className='active d-flex flex-row align-items-center label-color'>
                            {translator.get('project.modal.create.new.keywords.label')}
                            <p className='requiredSign'>*</p>
                            <StyledTooltip trigger='hover' position='right' labelKey={translator.get('project.modal.create.keywords.tooltip')}>
                                <div className='tooltip-icon-container'>?</div>
                            </StyledTooltip>
                        </label>
                        {projectCreate.keywords &&
                            projectCreate.keywords.map((keyword, index) => {
                                return (
                                    <div className='row' key={index}>
                                        <div className='col-12'>
                                            <div className='keyword-container pl-3'>
                                                {keyword}
                                                <button
                                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnKeywordRemoveClick(keyword)}
                                                    className='btn btn-primary text-white btn-remove'>
                                                    <div className='d-flex'>
                                                        <Close className='icon' fill='white' />
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        <TextField
                            type='text'
                            placeholder='Keywords'
                            id='keyword'
                            {...register('keyword', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('keyword', e.target.value) })}
                            error={errors.keyword}
                            errorMessage={errors.keyword && getMessageByFieldAndError('keyword', errors)}
                        />
                        <div className='col-12 mb-5 mt-2'>
                            <button onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnAddKeywordButtonClick()} className='btn btn-primary text-white'>
                                {translator.get('project.modal.create.add.keyword.button.text')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='row mb-4'>
                <h6 className='additional-info'>{translator.get('project.modal.create.additional.metadata.title')}</h6>
                <div className='modal-additional-info-container d-flex justify-content-end'>
                    <button
                        className='btn btn-outline-secondary button-plus'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#plus'
                        aria-expanded='true'
                        aria-controls='plus'>
                        <Plus className='plus-container icon' />
                        <Minus className='minus-container icon' />
                    </button>
                </div>
                <div id='plus' className='collapse' role='region' aria-labelledby='heading1c'>
                    <div className='row mt-4'>
                        <div className='form-group mt-3'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <label htmlFor='metadata' className='active metadata label-color'>
                                        {translator.get('project.modal.create.metadata.label')}
                                    </label>
                                </div>
                            </div>
                            <TextField type='text' id='metadata' placeholder='Keywords' name='metadata' />
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-12'>
                            <div className='form-group'>
                                <label htmlFor='value' className='active label-color'>
                                    {translator.get('project.modal.create.value.label')}
                                </label>
                                <TextArea
                                    rows={3}
                                    id='value'
                                    name='metadataValue'
                                    placeholder='Lorem ipsum'
                                    onBlur={(e: React.FocusEvent<HTMLTextAreaElement, Element>) => handleOnBlur(e)}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <h6 className='metadata-list-header'>{translator.get('project.modal.create.metadata.table.title')}</h6>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('project.modal.create.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('project.modal.create.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('project.modal.create.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                        <div className='container-info-metadata'>
                            <div className='info-metadata'>
                                <th scope='row'>{translator.get('project.modal.create.metadata.table.header')}</th>
                            </div>
                            <div className='info-value'>
                                <td>Value</td>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className='row'>
                <div className='col-12 mb-3'>
                    <button onClick={(e: React.MouseEvent<HTMLButtonElement>) => onSubmit(e)} className='btn btn-primary w-100 text-white'>
                        {translator.get('project.modal.create.submit.button.text')}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default CreateFormProjectView;
