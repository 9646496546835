import { PopupItemProps } from '@features/_shared/components/Popup/popup.item';

/**
 * Notification Manager as common abstract class for the types (e.g. PopupManager, ToastManager)
 */
export abstract class NotificationManager {
    /**
     * Shows the notification in function of the input notification model
     * @param notificationModel the notification model
     */
    abstract show(notificationModel: PopupItemProps): void;
    /**
     * Hides the notification
     */
    abstract hide(): void;
}
