import { StyledTooltip } from '@components/Tooltip/Tooltip';
import { TextField } from '@features/_shared/components/TextField';
import { LoginState } from '@features/login/store/state';
import { TranslatorManager } from '@managers/TranslatorManager';
import { FormManager } from '@managers/form.manager';
import { ProjectManager } from '@managers/project.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { FormHelperUtil } from '@utils/form.helper.util';
import KeycloakUtil from '@utils/keycloak.util';
import React, { useContext, useEffect, useState } from 'react';
import { IntlContext } from 'react-intl';
import { Project } from 'src/api';
import { ProjectInputUploadData } from '../beans/ProjectInputUploadData';

export interface BehubInputUploadComponentProps {
    project: Project;
    user: LoginState;
    isClosed: boolean;
    onUpload(): void;
    onRedirect(): void;
}

export const BehubInputUploadComponent: React.FC<BehubInputUploadComponentProps> = ({ project, user, isClosed, onUpload, onRedirect }) => {
    const translator = TranslatorManager.getInstance();
    const projectManager = ProjectManager.getInstance();
    const [showUrlInput, setShowUrlInput] = useState<boolean>(false);
    const [isUploadEnabled, setIsUploadEnabled] = useState<boolean>(false);
    const [fileName, setFileName] = useState<string>('');
    const [url, setURL] = useState<string>('');
    const [persistence, setPersistence] = useState<boolean>(false);
    const username = KeycloakUtil.getName();
    const email = KeycloakUtil.getEmail();
    const element = document.querySelector('script[id="injected-integration-behub-select"]');
    const iframeURL = element?.textContent!;

    const intl = useContext(IntlContext);
    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator('project.input', intl as any);

    const { register, getValues, setValue, errors, setError, clearErrors } = FormManager.getInstance().buildFormProxy<ProjectInputUploadData>(ProjectInputUploadData, () => {}, {
        filename: fileName ?? '',
    });

    (window as any).handleDatasetDownload = () => {
        const params: string = (document.getElementById('behubIframe') as any).contentWindow.location.href;
        setURL(params.split('url=')[1]);
        const name = params.split('filename=')[1].split('&url')[0];
        setValue('filename', name);
        setIsUploadEnabled(true);
        setShowUrlInput(true);
        onRedirect?.();
    };

    useEffect(() => {
        if (isClosed) {
            setValue('filename', '');
            setValue('url', '');
            setValue('persistence', false);
            setShowUrlInput(false);
        }
    }, [isClosed]);

    const handleSelectUrlInput = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const filenameValue = getValues('filename');
        if (filenameValue !== '') {
            const fileName: string = getValues('filename');
            const isPersistent = getValues('persistence');
            setFileName(fileName);
            setPersistence(isPersistent);
            onUpload?.();
            await QueueManager.getInstance().queue(
                QueueKeyType.DEFAULT,
                projectManager.createInput(project.id as string, {
                    type: 'URL',
                    filename: fileName,
                    author: {
                        name: username,
                        email: email,
                    },
                    persistent: persistence,
                }),
            );
            try {
                await projectManager.selectExternalUrlInput(project.id as string, {
                    file_url: url,
                    file_name: fileName,
                });
                setIsUploadEnabled(false);
                project.id && (await projectManager.getProjectById(project.id));
                clearErrors();
            } catch (e) {
                project.id && (await projectManager.removeInput(project.id, { name: fileName }));
            }
        } else {
            setError('filename', { type: 'isNotEmpty' });
            e.preventDefault();
        }
    };

    const triggerValidation = (key: string, value: string) => {
        let filenameValue = value;
        setValue(key, value);
        if (filenameValue !== '') {
            clearErrors(key);
            setIsUploadEnabled(true);
        } else {
            setError(key, { type: 'isNotEmpty' });
            setIsUploadEnabled(false);
        }
    };

    const enableUpload = (e: any) => {
        e.target.value ? setIsUploadEnabled(true) : setIsUploadEnabled(false);
    };

    return (
        <>
            {showUrlInput && (
                <div>
                    <div className='form-group' style={{ marginBottom: '1rem' }}>
                        <label htmlFor='filename' className='active d-flex flex-row align-items-center label-color'>
                            {translator.get('project.modal.upload.input.filename.title')}
                        </label>
                        <TextField
                            type='text'
                            error={errors.filename}
                            onPaste={(e: any) => enableUpload(e)}
                            errorMessage={errors.filename && getMessageByFieldAndError('filename', errors)}
                            id='filename'
                            {...register('filename', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('filename', e.target.value) })}
                            placeholder='Filename'
                        />
                    </div>
                    <div className='form-check' style={{ marginLeft: 0 }}>
                        <input id='persistence' type='checkbox' {...register('persistence')} className='focus--mouse' />
                        <label className='label-color d-flex flex-row align-items-center' htmlFor='persistence'>
                            {translator.get('project.modal.upload.input.persistence.title')}
                            <StyledTooltip trigger='hover' position='right' labelKey={translator.get('project.modal.upload.input.persistence.tooltip')}>
                                <div className='tooltip-icon-container'>?</div>
                            </StyledTooltip>
                        </label>
                    </div>
                    <div className='d-flex justify-content-end'>
                        <button className='btn btn-primary text-white d-flex align-items-center' disabled={!isUploadEnabled} onClick={handleSelectUrlInput}>
                            {translator.get('project.modal.upload.input.upload.button')}
                        </button>
                    </div>
                </div>
            )}
            {!showUrlInput && <iframe src={iframeURL} className='behub-iframe' id='behubIframe' />}
        </>
    );
};
