import { SelectMultipleComponent } from '@features/_shared/components/SelectMultiple/SelectMultiple.component';
import { TextField } from '@features/_shared/components/TextField';
import { TranslatorManager } from '@managers/TranslatorManager';
import { FormManager } from '@managers/form.manager';
import { ServiceManager } from '@managers/service.manager';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import React from 'react';
import { WebServiceSearchFilter, WebServiceSearchFilterStatusEnum } from '../../../api';
import { ServiceFilterbarData } from '../beans/serviceFlterbarData';
import '../styles/service.filterbar.style.scss';
export interface FilterBarServiceProps {
    filters?: WebServiceSearchFilter;
    onFilterChange?: () => void;
    clearFilters?: (e: React.MouseEvent<HTMLButtonElement>) => void;
    availableCategoryOptions?: string[];
}

const FilterBarServiceView: React.FC<FilterBarServiceProps> = ({ onFilterChange, filters, clearFilters, availableCategoryOptions }) => {
    const translator = TranslatorManager.getInstance();
    const serviceManager = ServiceManager.getInstance();

    const handleFiltersChange = async (e: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>) => {
        await serviceManager.setFilters(e.target.name as keyof WebServiceSearchFilter, e.target.value);
        await onFilterChange?.();
    };

    const handleApprovedFilterChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        await serviceManager.setFilters(e.target.name as keyof WebServiceSearchFilter, e.target.checked);
        await onFilterChange?.();
    };

    const handleClearFilters = async (e: React.MouseEvent<HTMLButtonElement>) => {
        await serviceManager.resetFilters();
        clearFilters?.(e);
        setValue('name', '');
        setValue('creatorName', '');
        setValue('status', WebServiceSearchFilterStatusEnum.ALL);
        setValue('keywords', '');
    };

    const handleCategoryChange = async (newValues: Array<string>) => {
        await serviceManager.setFilters('categories' as keyof WebServiceSearchFilter, String(newValues));
        await onFilterChange?.();
    };

    const handleUnselectAll = async (e?: React.MouseEvent<HTMLButtonElement>) => {
        e && e.preventDefault();
        await serviceManager.setFilters('categories' as keyof WebServiceSearchFilter, 'ALL');

        await onFilterChange?.();
        serviceManager.resetCategoryFilter();
    };

    const { setValue, register } = FormManager.getInstance().buildFormProxy<ServiceFilterbarData>(ServiceFilterbarData, () => {});

    return (
        <>
            <div className='container-fluid mb-5'>
                <div className='row mb-4'>
                    <p className='filter-title'>{translator.get('common.filterbar.title')}</p>
                </div>
                <div className='row row-cols-12'>
                    <div className='col-lg-2 col-sm-12 col-md-2'>
                        <div className='form-group filter-input-container'>
                            <label htmlFor='name' className='active label-color'>
                                {translator.get('service.filterbar.name.label')}
                            </label>
                            <TextField
                                isSearchable={true}
                                {...register('name')}
                                className='filter-border'
                                id='name'
                                placeholder={translator.get('service.filterbar.search.placeholder')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFiltersChange(e)}
                            />
                        </div>
                    </div>
                    <div className='col-lg-2 col-sm-12 col-md-2'>
                        <div className='form-group filter-input-container'>
                            <label htmlFor='applicant' className='active label-color'>
                                {translator.get('service.filterbar.applicant.label')}
                            </label>
                            <TextField
                                className='filter-border'
                                placeholder={translator.get('service.filterbar.search.placeholder')}
                                {...register('creatorName')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFiltersChange(e)}
                                isSearchable={true}
                            />
                        </div>
                    </div>
                    <div className='col-lg-2 col-sm-12 col-md-2'>
                        <div className='form-group filter-input-container'>
                            <label htmlFor='keywordSearch' className='active label-color'>
                                {translator.get('project.filterbar.keywords.label')}
                            </label>
                            <TextField
                                isSearchable={true}
                                {...register('keywords')}
                                className='filter-border'
                                placeholder={translator.get('service.filterbar.search.placeholder')}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFiltersChange(e)}
                            />
                        </div>
                    </div>
                    <div className='col-lg-2 col-sm-12 col-md-2'>
                        <div id='category'>
                            <div className='select-wrapper service-filterbar-center'>
                                <label htmlFor='category' className='active label-color'>
                                    {translator.get('service.filterbar.category.label')}
                                </label>
                                <SelectMultipleComponent
                                    placeholder={translator.get('service.filterbar.category.placeholder')}
                                    options={availableCategoryOptions as string[]}
                                    value={filters?.categories?.split(',') as string[]}
                                    {...register('categories')}
                                    onChange={handleCategoryChange}
                                    availableValues={availableCategoryOptions as Array<string>}
                                    handleUnselectAll={handleUnselectAll}
                                    isFilterbar={true}
                                    isDeleteShow={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-2 col-sm-12 col-md-2'>
                        <div id='status'>
                            <div className='select-wrapper mb-3'>
                                <label htmlFor='status' className='active label-color'>
                                    {translator.get('service.filterbar.status.label')}
                                </label>
                                <select {...register('status')} className='select-container' onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleFiltersChange(e)}>
                                    {Object.keys(WebServiceSearchFilterStatusEnum)
                                        .filter(item => item !== WebServiceSearchFilterStatusEnum.TO_BE_DELETED && item !== WebServiceSearchFilterStatusEnum.DELETED)
                                        .map((item, index) => {
                                            return (
                                                <option value={item} selected={item === WebServiceSearchFilterStatusEnum.ALL} key={index}>
                                                    {item.replace(/_/g, ' ')}
                                                </option>
                                            );
                                        })}
                                </select>
                            </div>
                        </div>
                    </div>

                    {(KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) || KeycloakUtil.hasRole([KeycloakRoleEnum.reviewer])) && (
                        <div className='col-lg-2 col-sm-12 col-md-2'>
                            <div className='form-check filter-input-container ms-0'>
                                <input
                                    name='approved'
                                    id='approved'
                                    type='checkbox'
                                    className='form-control focus--mouse'
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleApprovedFilterChange(e)}
                                />
                                <label className='label-color' htmlFor='approved'>
                                    {translator.get('service.filterbar.toBeApproved.label')}
                                </label>
                            </div>
                        </div>
                    )}
                    <div className='col-lg-4 col-sm-12 col-md-4'>
                        <button className='btn btn-primary text-white' onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClearFilters?.(e)}>
                            {translator.get('common.clear.all.filters.button.text')}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
export default FilterBarServiceView;
