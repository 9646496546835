import { ReactComponent as Edit } from '@assets/svg/edit-regular.svg';
import { ReactComponent as Close } from '@assets/svg/it-close.svg';
import { ReactComponent as Download } from '@assets/svg/it-download.svg';
import { ReactComponent as Publish } from '@assets/svg/publish.svg';
import { ReactComponent as Delete } from '@assets/svg/trash-alt-solid.svg';
import { ReactComponent as AddUser } from '@assets/svg/user-plus-solid.svg';
import { Table } from '@components/Table';
import { TextArea } from '@features/_shared/components/TextArea';
import { TextField } from '@features/_shared/components/TextField';
import { LoginState } from '@features/login/store/state';
import { ProvisioningPageEnum } from '@features/provisioning/store/state';
import { wizardPublicationDataset } from '@features/provisioning/utils/constant';
import { ProvisioningOriginEnum } from '@features/provisioning/views/provisioning.view';
import { TranslatorManager } from '@managers/TranslatorManager';
import { FormManager } from '@managers/form.manager';
import { NavigationManager } from '@managers/navigation.manager';
import { ProjectManager } from '@managers/project.manager';
import { ProvisioningManager } from '@managers/provisioning.manager';
import { QueueKeyType, QueueManager } from '@managers/queue.manager';
import { Paths } from '@routes';
import { FormHelperUtil } from '@utils/form.helper.util';
import { FormatUtil } from '@utils/formaters.util';
import KeycloakUtil, { KeycloakRoleEnum } from '@utils/keycloak.util';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IntlContext } from 'react-intl';
import {
    BaseProjectTemplate,
    InBoundMetrics,
    InBoundWarnings,
    InputFile,
    InputFileStatusEnum,
    InputFileTypeEnum,
    OutputFile,
    Project,
    ProjectStatusEnum,
    RequestProjectAddUser,
    RequestProjectUpdate,
    User,
    WebService,
} from '../../../api/entities';
import { ProjectDetailInfoData } from '../beans/projectDetailInfoData';
import { MemberCreate, ProjectCreateUpdate } from '../state/state';
import { ProjectAddTeamMemberComponent } from './project.add.team.member.component';
import { BehubInputUploadComponent } from './project.behub.input.upload.component';
import { LocalInputUploadComponent } from './project.local.input.upload.component';
import ProjectProvenanceComponent from './project.provenance.component';
import './styles/project.detail.component.style.scss';
import { SelectMultipleComponent } from '@features/_shared/components/SelectMultiple/SelectMultiple.component';

export interface AccordionContainerProps {
    isProjectRunning?: boolean;
    createUpdate: ProjectCreateUpdate;
    project?: Project;
    user?: User;
    service?: WebService;
    newCollaborator?: MemberCreate;
    provenance?: Array<Project>;
    templates?: Array<BaseProjectTemplate>;
    availableCategoryOptions?: string[];
    onBlur?: (key: keyof ProjectCreateUpdate, value: string | number | boolean | Array<string>) => void;
    configuration: { [key: string]: string | undefined };
}

export interface Statistics {
    [key: string]: string;
}

export enum SaveInfoTypeEnum {
    ABSTRACT = 'ABSTRACT',
    KEYWORDS = 'KEYWORDS',
    CATEGORY = 'CATEGORY',
    METADATA = 'METADATA',
    TRAINING_ROOM = 'TRAINING_ROOM',
    WORKING_GROUP = 'WORKING_GROUP',
    HELPDESK = 'HELPDESK',
}

const AccordionContainer: React.FC<AccordionContainerProps> = ({
    isProjectRunning,
    onBlur,
    availableCategoryOptions,
    createUpdate,
    project,
    user,
    newCollaborator,
    provenance,
    templates,
    configuration,
}) => {
    const projectManager = ProjectManager.getInstance();
    const translator = TranslatorManager.getInstance();
    const navigationManager = NavigationManager.getInstance();
    const provisioningManager = ProvisioningManager.getInstance();
    const queueManager = QueueManager.getInstance();

    const intl = useContext(IntlContext);
    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator('project.detail', intl as any);
    var ref = useRef<HTMLDivElement>(null);
    let warningsRef = useRef<HTMLDivElement>(null);

    const [isAddingMember, setIsAddingMember] = useState<boolean>(false);
    const [isEditingInfoAbstract, setIsEditingInfoAbstract] = useState<boolean>(false);
    const [isEditingInfoCategory, setIsEditingInfoCategory] = useState<boolean>(false);
    const [isEditingInfoKeywords, setIsEditingInfoKeywords] = useState<boolean>(false);
    const [isEditingInfoMetadata, setIsEditingInfoMetadata] = useState<boolean>(false);
    const [isEditingInfoTrainingRoom, setIsEditingInfoTrainingRoom] = useState<boolean>(false);
    const [isEditingInfoWorkingGroup, setIsEditingInfoWorkingGroup] = useState<boolean>(false);
    const [isEditingInfoHelpdesk, setIsEditingInfoHelpdesk] = useState<boolean>(false);

    const [isInputModalOpen, setIsInputModalOpen] = useState<boolean>(false);
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const [isIframeClosed, setIsIframeClosed] = useState<boolean>(true);

    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        //needed for the form manager
    };

    const scrollToBottom = () => {
        ref.current && ref.current.scrollTo({ top: ref.current.offsetTop + ref.current.offsetHeight, behavior: 'smooth' });
    };

    const handleWarningsScroll = () => warningsRef.current && warningsRef.current.scrollTo({ top: warningsRef.current.scrollHeight, behavior: 'smooth' });

    useEffect(() => {
        scrollToBottom();
        handleWarningsScroll();
    }, [project]);

    const handleOnSubmit = async (e: React.MouseEvent<HTMLButtonElement>, type: SaveInfoTypeEnum) => {
        let error = true;
        switch (type) {
            case SaveInfoTypeEnum.ABSTRACT: {
                error = await trigger('description');
                break;
            }
            case SaveInfoTypeEnum.KEYWORDS: {
                const isKeywordsValidated = validateKeywords();
                error = error && isKeywordsValidated;
                break;
            }
            case SaveInfoTypeEnum.METADATA: {
                //TODO: To be implemented
                break;
            }
            case SaveInfoTypeEnum.CATEGORY: {
                const isCategoryValidated = validateCategory();
                error = error && isCategoryValidated;
                break;
            }
            case SaveInfoTypeEnum.TRAINING_ROOM: {
                error = await trigger('training');
                break;
            }
            case SaveInfoTypeEnum.WORKING_GROUP: {
                error = await trigger('community');
                break;
            }
            case SaveInfoTypeEnum.HELPDESK: {
                error = await trigger('helpdesk');
                break;
            }
        }

        setIsSubmitted(true);
        if (error) {
            await queueManager.queue(QueueKeyType.DEFAULT, handleSaveInfo(type));
            reset();
            setIsSubmitted(false);
        } else {
            e.preventDefault();
        }
    };

    const validateKeywords = () => {
        const isKeywords = createUpdate?.keywords && createUpdate?.keywords.length > 0 ? true : false;

        if (isKeywords) {
            clearErrors('keyword');
        } else {
            setError('keyword', { type: 'isNotEmpty' });
        }
        return isKeywords as boolean;
    };

    const validateCategory = () => {
        const isCategory = createUpdate?.categories && createUpdate?.categories.length > 0 ? true : false;

        if (isCategory) {
            clearErrors('categories');
        } else {
            setError('categories', { type: 'isNotEmpty' });
        }
        return isCategory as boolean;
    };

    const handleCategoryChange = async (newValues: Array<string>) => {
        onBlur?.('categories' as keyof ProjectCreateUpdate, newValues);
        if (newValues.length > 0) {
            clearErrors('categories');
        } else {
            setError('categories', { type: 'isNotEmpty' });
        }
    };

    const handleOpenMember = (isOpen: boolean) => {
        setIsAddingMember(isOpen);
    };

    const handleOpenEditInfoAbstract = (isOpen: boolean) => {
        setIsEditingInfoAbstract(isOpen);
    };

    const handleOpenEditInfoCategory = (isOpen: boolean) => {
        setIsEditingInfoCategory(isOpen);
    };

    const handleOpenEditInfoKeywords = (isOpen: boolean) => {
        setIsEditingInfoKeywords(isOpen);
    };

    const handleOpenEditInfoMetadata = (isOpen: boolean) => {
        setIsEditingInfoMetadata(isOpen);
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
        projectManager.saveValue(e.target.name as keyof ProjectCreateUpdate, e.target.value);
    };

    const handleAddMemberOnBlur = (e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
        projectManager.saveNewMemberValue(e.target.name as keyof MemberCreate, e.target.value);
    };

    const handleSaveMember = async (e: React.MouseEvent<HTMLButtonElement>) => {
        const { collaboratorEmail, collaboratorName } = newCollaborator as MemberCreate;
        e.stopPropagation();
        const requestProjectAddUser = { email: collaboratorEmail, name: collaboratorName } as RequestProjectAddUser;
        project && (await queueManager.queue(QueueKeyType.DEFAULT, projectManager.addNewMember(project, requestProjectAddUser)));
        project?.id && (await queueManager.queue(QueueKeyType.DEFAULT, projectManager.getProjectById(project?.id)));
        projectManager.resetNewCollaborator();
    };

    const handleDeleteMember = async (e: React.MouseEvent<HTMLButtonElement>, memberEmail: string) => {
        const collaborator = project?.collaborators?.find(currentCollaborator => currentCollaborator.email === memberEmail);
        const member = { ...collaborator };
        project && (await queueManager.queue(QueueKeyType.DEFAULT, projectManager.deleteMember(project, member)));
        await queueManager.queue(QueueKeyType.DEFAULT, projectManager.getProjectById(project?.id as string));
    };

    const handleSaveInfo = async (type: SaveInfoTypeEnum, e?: React.MouseEvent<HTMLButtonElement>) => {
        e && e.stopPropagation();
        let projectUpdateRequest: RequestProjectUpdate = {};
        switch (type) {
            case SaveInfoTypeEnum.ABSTRACT: {
                projectUpdateRequest = { description: createUpdate?.description };
                handleOpenEditInfoAbstract(false);
                break;
            }
            case SaveInfoTypeEnum.KEYWORDS: {
                projectUpdateRequest = { keywords: createUpdate?.keywords };
                handleOpenEditInfoKeywords(false);

                break;
            }
            case SaveInfoTypeEnum.CATEGORY: {
                projectUpdateRequest = { categories: createUpdate?.categories };
                handleOpenEditInfoCategory(false);
                break;
            }
            case SaveInfoTypeEnum.METADATA: {
                handleOpenEditInfoMetadata(false);

                //TODO: To be implemented
                break;
            }

            case SaveInfoTypeEnum.TRAINING_ROOM: {
                projectUpdateRequest = { training: createUpdate?.training ? createUpdate?.training : '' };
                setIsEditingInfoTrainingRoom(false);
                break;
            }

            case SaveInfoTypeEnum.WORKING_GROUP: {
                projectUpdateRequest = { community: createUpdate?.community ? createUpdate?.community : '' };
                setIsEditingInfoWorkingGroup(false);
                break;
            }

            case SaveInfoTypeEnum.HELPDESK: {
                projectUpdateRequest = { helpdesk: createUpdate?.helpdesk ? createUpdate.helpdesk : '' };
                setIsEditingInfoHelpdesk(false);
                break;
            }
        }
        project?.id && (await projectManager.update(project?.id, projectUpdateRequest));
        handleOpenEditInfoAbstract(false);
    };

    const handleOnDelete = async (fileName: string) => {
        project?.id && (await queueManager.queue(QueueKeyType.DEFAULT, projectManager.removeInput(project?.id, { name: fileName })));
    };

    const handleUploadFromURL = () => {
        document.getElementById('urlUploadModalClose')?.click();
    };

    const handleUploadFromLocal = () => {
        document.getElementById('localUploadModalClose')?.click();
    };

    const handleInputDowload = async (projectId: string, fileName: string) => {
        const response = await queueManager.queue(QueueKeyType.DEFAULT, projectManager.downloadInput(projectId, { name: fileName }));
        const href = URL.createObjectURL(new Blob([response], { type: 'application/octet-stream' }));
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    };

    const handlePublicationDatasetClick = async (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
        e.stopPropagation();
        await queueManager.queue(QueueKeyType.DEFAULT, projectManager.getProjectById(id));
        provisioningManager.setSteps(wizardPublicationDataset);
        provisioningManager.setCurrentPage(ProvisioningPageEnum.DATASET);
        provisioningManager.setOrigin(ProvisioningOriginEnum.PROJECT);
        navigationManager.navigateTo(wizardPublicationDataset[project?.provisioningPageIndex?.datasetIndex ? project?.provisioningPageIndex?.datasetIndex : 0] as Paths);
    };

    const handleUnselectAll = async (e?: React.MouseEvent<HTMLButtonElement>) => {
        e && e.preventDefault();
        projectManager.resetCategoryCreate();
        await projectManager.getProjectCategories();
    };

    const handleOutputDowload = async (projectId: string, fileName: string) => {
        const response = await queueManager.queue(QueueKeyType.DEFAULT, projectManager.downloadOutput(projectId, { name: fileName }));
        const href = URL.createObjectURL(new Blob([response], { type: 'application/*' }));
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
    };

    const handleOnKeywordRemoveClick = (keywordToBeRemoved: string) => {
        if (createUpdate?.keywords) {
            const updatedKeywords: any[] = createUpdate?.keywords.filter(currentKeyword => currentKeyword !== keywordToBeRemoved);
            projectManager.saveValue('keywords' as keyof ProjectCreateUpdate, updatedKeywords);
        }
    };

    const handleOnAddKeywordButtonClick = async () => {
        const currentKeyword = getValues('keyword');
        const noError = (await trigger('keyword')) && !createUpdate?.keywords?.find((item: string) => item.toLowerCase() === currentKeyword.toLowerCase());
        if (currentKeyword !== '' && noError) {
            const updatedKeywords = createUpdate?.keywords ? [...(createUpdate.keywords as string[]), currentKeyword] : [currentKeyword];
            projectManager.saveValue('keywords' as keyof ProjectCreateUpdate, updatedKeywords);
            setValue('keyword', undefined);
            clearErrors('keyword');
        } else if (createUpdate?.keywords?.find((item: string) => item.toLowerCase() === currentKeyword.toLowerCase())) {
            setError('keyword', { type: 'keywordlrdxst' });
        }
    };

    const triggerValidation = (key: string, value: string) => {
        setValue(key, value);
        (isSubmitted || key === 'keyword') && trigger(key);
    };

    const formatWarningValues = (key: string | undefined, value: number | undefined) => {
        switch (key) {
            case 'CPU':
                return `${value} %`;
            case 'MEMORY':
                return FormatUtil.formatBytes(Number(value));
            case 'STORAGE':
                return FormatUtil.formatBytes(Number(value));
        }
    };

    const getIconBasedOnStatusAndSize = (status: InputFileStatusEnum, size: number, total: number) => {
        let percentage = (((size as number) / (total as number)) * 100).toFixed(2);
        if (status === InputFileStatusEnum.READY && size > 0) {
            return size ? FormatUtil.formatBytes(size) : '0 Bytes';
        } else if (status === InputFileStatusEnum.WAIT || (status === InputFileStatusEnum.PROGRESS && size === 0)) {
            return (
                <div className='d-flex align-items-center'>
                    <div className='input-loader'></div>
                    <div className='project-loader-percentage'>{'0.0%'}</div>
                </div>
            );
        } else if (Number(percentage) > 0) {
            return (
                <div className='d-flex align-items-center'>
                    <div className='input-loader'></div>
                    <div className='project-loader-percentage'>{percentage + '%'}</div>
                </div>
            );
        }
    };

    const inputColumns = [
        translator.get('project.detail.table.header.name.text'),
        ...(!isProjectRunning ? [translator.get('project.detail.table.header.source.text')] : []),
        translator.get('project.detail.table.header.size.text'),
        ...(!isProjectRunning ? [translator.get('project.detail.table.header.date.text')] : []),
        ...(!isProjectRunning ? [translator.get('project.detail.table.header.author.text')] : []),
        translator.get('common.table.header.action.text'),
    ];

    const inputRows =
        project?.inputs && Object.keys(project?.inputs).length > 0
            ? Object.values(project?.inputs).map((item: InputFile | undefined, index) => {
                  return {
                      id: index,
                      values: {
                          name: item?.filename,
                          ...(!isProjectRunning && { source: item?.type }),
                          size: getIconBasedOnStatusAndSize(item?.status as InputFileStatusEnum, item?.size as number, item?.total as number),
                          ...(!isProjectRunning && {
                              date: moment
                                  .utc(item?.created_at as string)
                                  .local()
                                  .format('DD/MM/YYYY HH:mm'),
                          }),
                          ...(!isProjectRunning && { author: `${item?.created_by?.name} (${item?.created_by?.email})` }),
                          action: (
                              <div className='d-flex flex-row '>
                                  <button className='btn action-item' onClick={() => handleInputDowload(project?.id as string, item?.filename as string)}>
                                      <Download className='icon' fill='#009138' />
                                  </button>
                                  {
                                      <button
                                          className={`btn action-item ${
                                              KeycloakUtil.isLoggedIn() &&
                                              (project?.author?.email === KeycloakUtil.getEmail() ||
                                                  KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                                  project?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())) &&
                                              !project?.script_published &&
                                              project?.service_publish_date === null &&
                                              !item?.behub &&
                                              item?.type === InputFileTypeEnum.LOCAL
                                                  ? ''
                                                  : 'hidden'
                                          }`}
                                          onClick={(e: React.MouseEvent<HTMLButtonElement>) => handlePublicationDatasetClick(e, project?.id as string)}>
                                          <Publish className='icon' fill='#009138' />
                                      </button>
                                  }
                                  {KeycloakUtil.isLoggedIn() &&
                                      (project?.author?.email === KeycloakUtil.getEmail() ||
                                          KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                          project?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())) &&
                                      !project?.script_published &&
                                      project?.service_publish_date === null && (
                                          <button className='btn action-item' onClick={() => item?.filename && handleOnDelete(item?.filename)}>
                                              <Delete fill='#009138' width={14} />
                                          </button>
                                      )}
                              </div>
                          ),
                      },
                  };
              })
            : [];

    const outputColumns = [
        translator.get('project.detail.table.header.name.text'),
        translator.get('project.detail.table.header.size.text'),
        translator.get('project.detail.table.header.date.text'),
        translator.get('common.table.header.action.text'),
    ];

    const outputRows =
        project?.outputs && Object.keys(project?.outputs).length > 0
            ? Object.values(project?.outputs).map((item: OutputFile | undefined, index) => {
                  return {
                      id: index,
                      values: {
                          name: item?.filename,
                          size: item?.size ? FormatUtil.formatBytes(item.size) : '0 Bytes',
                          date: moment
                              .utc(item?.updated_at as string)
                              .local()
                              .format('DD/MM/YYYY HH:mm'),
                          action: (
                              <div className='d-flex flex-row '>
                                  <button className='btn'>
                                      <Download className='icon' onClick={() => handleOutputDowload(project?.id as string, item?.filename as string)} fill='#009138' />
                                  </button>
                                  {KeycloakUtil.isLoggedIn() &&
                                      !project?.project_published &&
                                      !item?.behub &&
                                      item?.filename !== 'binder.log' &&
                                      item?.filename !== 'jupyter.log' && (
                                          <button
                                              className='btn action-item'
                                              onClick={(e: React.MouseEvent<HTMLButtonElement>) => handlePublicationDatasetClick(e, project?.id as string)}>
                                              <Publish className='icon' fill='#009138' />
                                          </button>
                                      )}
                              </div>
                          ),
                      },
                  };
              })
            : [];

    const statisticsColumns = [translator.get('project.detail.statistics.table.header.title.text'), translator.get('project.detail.statistics.table.header.value.text')];
    const statisticsLabel: Statistics = {
        cpu_avg: translator.get('project.detail.statistics.table.cpu.average.text'),
        cpu_max: translator.get('project.detail.statistics.table.cpu.max.text'),
        memory_avg: translator.get('project.detail.statistics.table.memory.average.text'),
        memory_max: translator.get('project.detail.statistics.table.memory.max.text'),
        storage_file_count: translator.get('project.detail.statistics.table.storage.total.file.text'),
        storage_total_size: translator.get('project.detail.statistics.table.storage.total.size.text'),
        execution_time: translator.get('project.detail.statistics.table.execution.time.text'),
    };

    const isStatisticTableField = (key: string) => {
        return key !== 'cells' && key !== 'timestamp' && key !== 'started' && key !== 'finished' && key !== 'id' && key !== 'instanceId' && key !== 'executionId';
    };

    const getStatisticValue = (key: string, value: string) => {
        if (key.includes('cpu')) {
            return FormatUtil.roundNumberAfterComma(Number(value), 2) + ' %';
        } else if (key.includes('memory') || key === 'storage_total_size') {
            return FormatUtil.formatBytes(Number(value));
        } else if (key === 'execution_time') {
            return moment.utc(moment.duration(value).asMilliseconds()).format('H[h] m[m] s[s]');
        } else {
            return value;
        }
    };

    const statisticsRows =
        project?.metrics && Object.keys(project?.metrics).length > 0
            ? Object.keys(project?.metrics)
                  .filter(metricKey => isStatisticTableField(metricKey))
                  .map((item: string, index) => {
                      if (configuration[item.replace('project_', '')] && configuration[item.replace('project_', '')] === 'true') {
                          return {
                              id: index,
                              values: {
                                  title: statisticsLabel[item as keyof Statistics],
                                  value: project.metrics && getStatisticValue(item, project.metrics[item as keyof InBoundMetrics] as string),
                              },
                          };
                      }
                  })
            : [];

    const warningColumns = [
        translator.get('project.detail.warning.table.header.warning.text'),
        translator.get('project.detail.warning.table.header.type.text'),
        translator.get('project.detail.warning.table.header.timestamp.text'),
    ];

    const warningRows =
        project?.warnings && Object.keys(project?.warnings).length > 0
            ? project?.warnings.map((item: InBoundWarnings | undefined, index) => {
                  return {
                      id: index,
                      values: {
                          warning: formatWarningValues(item?.type, item?.value),
                          type: item?.type,
                          timestamp: moment
                              .utc(item?.timestamp as string)
                              .local()
                              .format('DD/MM/YYYY HH:mm'),
                      },
                  };
              })
            : [];

    const showTeamActions = () => {
        return (
            KeycloakUtil.isLoggedIn() &&
            (project?.author?.email === KeycloakUtil.getEmail() || KeycloakUtil.hasRole([KeycloakRoleEnum.admin])) &&
            !project?.script_published &&
            project?.service_publish_date === null
        );
    };

    const teamColumns = [
        translator.get('project.detail.table.header.name.text'),
        translator.get('project.detail.table.header.user.email.text'),
        ...(!isProjectRunning ? [translator.get('project.detail.table.header.date.text'), showTeamActions() && translator.get('common.table.header.action.text')] : []),
    ];

    const teamRows = project?.collaborators?.map((item: User, index) => {
        return {
            id: index,
            values: {
                name: item.name,
                email: item.email,
                ...(!isProjectRunning && {
                    date: moment
                        .utc(item.created_at as string)
                        .local()
                        .format('DD/MM/YYYY HH:mm'),
                    ...(showTeamActions() && {
                        action: (
                            <button className='btn d-flex' onClick={(e: React.MouseEvent<HTMLButtonElement>) => item.email && handleDeleteMember(e, item.email)}>
                                <Delete fill='#009138' width={14} />
                            </button>
                        ),
                    }),
                }),
            },
        };
    });

    const { errors, register, trigger, setValue, reset, clearErrors, setError, getValues } = FormManager.getInstance().buildFormProxy<ProjectDetailInfoData>(
        ProjectDetailInfoData,
        onSubmit,
    );
    return (
        <>
            <div className='container-fluid'>
                {!isProjectRunning && (
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='accordion accordion-background-active border bg-white' id='accordion'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            className='accordion-button border'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#info'
                                            aria-expanded='true'
                                            aria-controls='info'>
                                            {translator.get('project.detail.info.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div id='info' className='size-tab collapse show' role='region' aria-labelledby='heading1c'>
                                        <div className='accordion-body'>
                                            <h6 className='d-flex flex-row align-items-center'>
                                                {translator.get('project.detail.info.accordion.abstract.title')}
                                                <p className='requiredSign'>*</p>
                                                {KeycloakUtil.isLoggedIn() &&
                                                    (project?.author?.email === KeycloakUtil.getEmail() ||
                                                        KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                                        project?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())) &&
                                                    !project?.script_published &&
                                                    project?.service_publish_date === null &&
                                                    project?.status !== ProjectStatusEnum.FORKING &&
                                                    (isEditingInfoAbstract ? (
                                                        <button
                                                            className='btn btn-primary btn-save'
                                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnSubmit(e, SaveInfoTypeEnum.ABSTRACT)}>
                                                            {translator.get('project.detail.save.button.text')}
                                                        </button>
                                                    ) : (
                                                        <button className='btn' onClick={() => handleOpenEditInfoAbstract(true)}>
                                                            <Edit className=' icon-edit' width={10} fill='#009138' />
                                                        </button>
                                                    ))}
                                            </h6>
                                            {isEditingInfoAbstract ? (
                                                <div>
                                                    <div className='form-group grow-wrap'>
                                                        <TextArea
                                                            isAutoSize={true}
                                                            id='description'
                                                            {...register('description', {
                                                                onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => triggerValidation('description', e.target.value),
                                                            })}
                                                            defaultValue={project?.description}
                                                            placeholder={translator.get('project.modal.create.abstract.placeholder')}
                                                            onBlur={(e: React.FocusEvent<HTMLTextAreaElement, Element>) => handleOnBlur(e)}
                                                        />
                                                        {errors.description && (
                                                            <small className='form-text error-message'>
                                                                {translator.get(`form.project.create.description.${errors.description.type}`)}
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <p>{project?.description}</p>
                                                </>
                                            )}

                                            <h6 className='d-flex flex-row align-items-center'>
                                                {translator.get('project.detail.info.accordion.keyword.title')}
                                                <p className='requiredSign'>*</p>

                                                {KeycloakUtil.isLoggedIn() &&
                                                    (project?.author?.email === KeycloakUtil.getEmail() ||
                                                        KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                                        project?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())) &&
                                                    !project?.script_published &&
                                                    project?.service_publish_date === null &&
                                                    project?.status !== ProjectStatusEnum.FORKING &&
                                                    (isEditingInfoKeywords ? (
                                                        <button
                                                            className='btn btn-primary btn-save'
                                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnSubmit(e, SaveInfoTypeEnum.KEYWORDS)}>
                                                            {translator.get('project.detail.save.button.text')}
                                                        </button>
                                                    ) : (
                                                        <button className='btn' onClick={() => handleOpenEditInfoKeywords(true)}>
                                                            <Edit className=' icon-edit' width={10} fill='#009138' />
                                                        </button>
                                                    ))}
                                            </h6>
                                            {isEditingInfoKeywords ? (
                                                <div>
                                                    <div className='form-group grow-wrap mb-1'>
                                                        <div className='row'>
                                                            <div className='col-12'>
                                                                <div className='form-group mb-0'>
                                                                    <div className='mt-1'>
                                                                        {createUpdate?.keywords &&
                                                                            createUpdate?.keywords.map(keyword => {
                                                                                return (
                                                                                    <div className='row'>
                                                                                        <div className='col-12'>
                                                                                            <div className='keyword-container pl-3'>
                                                                                                {keyword}
                                                                                                <button
                                                                                                    onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                                                                                                        handleOnKeywordRemoveClick(keyword)
                                                                                                    }
                                                                                                    className='btn btn-primary text-white btn-remove'>
                                                                                                    <div className='d-flex'>
                                                                                                        <Close className='icon' fill='white' />
                                                                                                    </div>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            })}
                                                                        <TextField
                                                                            type='text'
                                                                            placeholder='Keywords'
                                                                            id='keyword'
                                                                            {...register('keyword', {
                                                                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('keyword', e.target.value),
                                                                            })}
                                                                            error={errors.keyword}
                                                                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                                                                            errorMessage={errors.keyword && getMessageByFieldAndError('keyword', errors)}
                                                                        />

                                                                        <div className='col-12 mb-2 mt-2'>
                                                                            <button
                                                                                onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnAddKeywordButtonClick()}
                                                                                className='btn btn-primary text-white'>
                                                                                {translator.get('project.modal.create.add.keyword.button.text')}
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <p>
                                                        {project?.keywords &&
                                                            project?.keywords.map((keyword, index) => {
                                                                return (
                                                                    <>
                                                                        {keyword}
                                                                        {project.keywords && index < project.keywords.length - 1 ? ', ' : ''}
                                                                    </>
                                                                );
                                                            })}
                                                    </p>
                                                </>
                                            )}
                                            <h6 className='d-flex flex-row align-items-center'>
                                                {translator.get('project.detail.info.accordion.category.title')}
                                                <p className='requiredSign'>*</p>

                                                {KeycloakUtil.isLoggedIn() &&
                                                    (project?.author?.email === KeycloakUtil.getEmail() ||
                                                        KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                                        project?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())) &&
                                                    !project?.script_published &&
                                                    project?.service_publish_date === null &&
                                                    project?.status !== ProjectStatusEnum.FORKING &&
                                                    (isEditingInfoCategory ? (
                                                        <button
                                                            className='btn btn-primary btn-save'
                                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnSubmit(e, SaveInfoTypeEnum.CATEGORY)}>
                                                            {translator.get('project.detail.save.button.text')}
                                                        </button>
                                                    ) : (
                                                        <button className='btn' onClick={() => handleOpenEditInfoCategory(true)}>
                                                            <Edit className=' icon-edit' width={10} fill='#009138' />
                                                        </button>
                                                    ))}
                                            </h6>
                                            {isEditingInfoCategory ? (
                                                <div>
                                                    <div className='form-group grow-wrap mb-1'>
                                                        <div className='row pt-3'>
                                                            <div className='col-12 category-index'>
                                                                <div className='form-group'>
                                                                    <div className='select-wrapper'>
                                                                        <div>
                                                                            <SelectMultipleComponent
                                                                                placeholder={translator.get('service.filterbar.category.placeholder')}
                                                                                options={availableCategoryOptions as string[]}
                                                                                value={createUpdate.categories}
                                                                                {...register('categories')}
                                                                                onChange={handleCategoryChange}
                                                                                availableValues={availableCategoryOptions?.filter(option => option !== 'ALL') as Array<string>}
                                                                                handleUnselectAll={handleUnselectAll}
                                                                            />
                                                                        </div>
                                                                        {errors.categories && (
                                                                            <small className='form-text error-message'>
                                                                                {translator.get(`form.project.create.category.${errors.categories.type}`)}
                                                                            </small>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <p>
                                                        {project?.categories &&
                                                            project?.categories.map((categories, index) => {
                                                                return (
                                                                    <>
                                                                        {categories}
                                                                        {project.categories && index < project.categories.length - 1 ? ', ' : ''}
                                                                    </>
                                                                );
                                                            })}
                                                    </p>
                                                </>
                                            )}
                                            <h6 className={` mt-2 ${!project?.training ? 'mb-4' : ''}`}>
                                                {translator.get('project.detail.accordion.info.training.text')}
                                                {KeycloakUtil.isLoggedIn() &&
                                                    (project?.author?.email === KeycloakUtil.getEmail() ||
                                                        KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                                        project?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())) &&
                                                    !project?.script_published &&
                                                    project?.service_publish_date === null &&
                                                    project?.status !== ProjectStatusEnum.FORKING &&
                                                    (isEditingInfoTrainingRoom ? (
                                                        <button
                                                            className='btn btn-primary btn-save'
                                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnSubmit(e, SaveInfoTypeEnum.TRAINING_ROOM)}>
                                                            {translator.get('project.detail.save.button.text')}
                                                        </button>
                                                    ) : (
                                                        <button className='btn' onClick={() => setIsEditingInfoTrainingRoom(true)}>
                                                            <Edit className=' icon-edit' width={10} fill='#009138' />
                                                        </button>
                                                    ))}
                                            </h6>
                                            {isEditingInfoTrainingRoom ? (
                                                <div>
                                                    <div className='form-group grow-wrap'>
                                                        <TextField
                                                            id='training'
                                                            type='text'
                                                            {...register('training', {
                                                                onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => triggerValidation('training', e.target.value),
                                                            })}
                                                            defaultValue={project?.training}
                                                            error={errors.training}
                                                            errorMessage={errors.training && getMessageByFieldAndError('training', errors)}
                                                            placeholder={translator.get('project.detail.accordion.info.training.text')}
                                                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <a className={!project?.training ? 'pt-4' : ''} href={project?.training} target='_blank' rel='noreferrer'>
                                                        {project?.training}
                                                    </a>
                                                </>
                                            )}
                                            <h6 className={` mt-2 ${!project?.community ? 'mb-4' : ''}`}>
                                                {translator.get('project.detail.accordion.info.working.group.text')}
                                                {KeycloakUtil.isLoggedIn() &&
                                                    (project?.author?.email === KeycloakUtil.getEmail() ||
                                                        KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                                        project?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())) &&
                                                    !project?.script_published &&
                                                    project?.service_publish_date === null &&
                                                    project?.status !== ProjectStatusEnum.FORKING &&
                                                    (isEditingInfoWorkingGroup ? (
                                                        <button
                                                            className='btn btn-primary btn-save'
                                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnSubmit(e, SaveInfoTypeEnum.WORKING_GROUP)}>
                                                            {translator.get('project.detail.save.button.text')}
                                                        </button>
                                                    ) : (
                                                        <button className='btn' onClick={() => setIsEditingInfoWorkingGroup(true)}>
                                                            <Edit className=' icon-edit' width={10} fill='#009138' />
                                                        </button>
                                                    ))}
                                            </h6>
                                            {isEditingInfoWorkingGroup ? (
                                                <div>
                                                    <div className='form-group grow-wrap'>
                                                        <TextField
                                                            id='community'
                                                            type='text'
                                                            {...register('community', {
                                                                onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => triggerValidation('community', e.target.value),
                                                            })}
                                                            defaultValue={project?.community}
                                                            error={errors.community}
                                                            errorMessage={errors.community && getMessageByFieldAndError('community', errors)}
                                                            placeholder={translator.get('project.detail.accordion.info.working.group.text')}
                                                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <a className={!project?.community ? 'pt-4' : ''} href={project?.community} target='_blank' rel='noreferrer'>
                                                        {project?.community}
                                                    </a>
                                                </>
                                            )}
                                            <h6 className={` mt-2 ${!project?.helpdesk ? 'mb-4' : ''}`}>
                                                {translator.get('project.detail.accordion.info.helpdesk.text')}
                                                {KeycloakUtil.isLoggedIn() &&
                                                    (project?.author?.email === KeycloakUtil.getEmail() ||
                                                        KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) ||
                                                        project?.collaborators?.find(currentCollaborator => currentCollaborator.email === KeycloakUtil.getEmail())) &&
                                                    !project?.script_published &&
                                                    project?.service_publish_date === null &&
                                                    project?.status !== ProjectStatusEnum.FORKING &&
                                                    (isEditingInfoHelpdesk ? (
                                                        <button
                                                            className='btn btn-primary btn-save'
                                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnSubmit(e, SaveInfoTypeEnum.HELPDESK)}>
                                                            {translator.get('project.detail.save.button.text')}
                                                        </button>
                                                    ) : (
                                                        <button className='btn' onClick={() => setIsEditingInfoHelpdesk(true)}>
                                                            <Edit className=' icon-edit' width={10} fill='#009138' />
                                                        </button>
                                                    ))}
                                            </h6>
                                            {isEditingInfoHelpdesk ? (
                                                <div>
                                                    <div className='form-group grow-wrap'>
                                                        <TextField
                                                            id='helpdesk'
                                                            type='text'
                                                            {...register('helpdesk', {
                                                                onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => triggerValidation('helpdesk', e.target.value),
                                                            })}
                                                            defaultValue={project?.helpdesk}
                                                            error={errors.helpdesk}
                                                            errorMessage={errors.helpdesk && getMessageByFieldAndError('helpdesk', errors)}
                                                            placeholder={translator.get('project.detail.accordion.info.helpdesk.text')}
                                                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                                                        />
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <a className={!project?.helpdesk ? 'pt-4' : ''} href={project?.helpdesk} target='_blank' rel='noreferrer'>
                                                        {project?.helpdesk}
                                                    </a>
                                                </>
                                            )}
                                            {/*<h6 className='mt-2'>
                                                {translator.get('project.detail.info.accordion.abstract.metadata.title')}
                                                {KeycloakUtil.isLoggedIn() &&
                                                    !project?.project_published &&
                                                    (isEditingInfoMetadata ? (
                                                        <button
                                                            className='btn btn-primary btn-save'
                                                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleOnSubmit(e, SaveInfoTypeEnum.METADATA)}>
                                                            {translator.get('project.detail.save.button.text')}
                                                        </button>
                                                    ) : (
                                                            <button className='btn' onClick={() => handleOpenEditInfoMetadata(true)}>
                                                                <Edit className=' icon-edit' width={10} fill='#009138' />
                                                            </button>
                                                        ))}
                                            </h6>

                                             <div className='container-info-metadata'>
                                                <div className='info-metadata'>
                                                    <th scope='row'>{translator.get('project.detail.info.accordion.abstract.metadata.table.header')}</th>
                                                </div>
                                                <div className='info-value'>
                                                    <td>Value</td>
                                                </div>
                                            </div>
                                            <div className='container-info-metadata'>
                                                <div className='info-metadata'>
                                                    <th scope='row'>{translator.get('project.detail.info.accordion.abstract.metadata.table.header')}</th>
                                                </div>
                                                <div className='info-value'>
                                                    <td>Value</td>
                                                </div>
                                            </div>
                                            <div className='container-info-metadata'>
                                                <div className='info-metadata'>
                                                    <th scope='row'>{translator.get('project.detail.info.accordion.abstract.metadata.table.header')}</th>
                                                </div>
                                                <div className='info-value'>
                                                    <td>Value</td>
                                                </div>
                                            </div>
                                            <div className='container-info-metadata'>
                                                <div className='info-metadata'>
                                                    <th scope='row'>{translator.get('project.detail.info.accordion.abstract.metadata.table.header')}</th>
                                                </div>
                                                <div className='info-value'>
                                                    <td>Value</td>
                                                </div>
                                            </div> */}
                                        </div>
                                        <div className='creator-container'>
                                            <div className='d-flex creator-container-info-accordion'>
                                                <div className='creator-label-container'>Creator: </div>
                                                <div className='creator-content-container'>{`${project?.author?.name} (${project?.author?.email})`}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {KeycloakUtil.isLoggedIn() && project?.status !== ProjectStatusEnum.FORKING && (
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='accordion accordion-background-active border bg-white' id='accordion'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            className='accordion-button border collapsed'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#input'
                                            aria-expanded='false'
                                            aria-controls='input'>
                                            {translator.get('project.detail.input.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div id='input' className='collapse size-tab' role='region' aria-labelledby='input'>
                                        <div className='accordion-body input-project-size'>
                                            <Table columns={inputColumns as Array<string>} rows={inputRows as any} keyLabel='input' />
                                            {KeycloakUtil.isLoggedIn() && !project?.script_published && project?.service_publish_date === null && (
                                                <div className='inputAccordion p-3'>
                                                    <p className='label-green'>{translator.get('project.detail.input.accordion.paragraph.title')}</p>
                                                    <p>{translator.get('project.detail.input.accordion.paragraph.text')}</p>
                                                    <div className='containerInputButton'>
                                                        <button
                                                            className='btn btn-primary btnInput btnInputLocal'
                                                            data-bs-toggle='modal'
                                                            data-bs-target='#localUploadModal'
                                                            onClick={() => setIsInputModalOpen(true)}>
                                                            {translator.get('project.detail.accordion.input.local.button.text')}
                                                        </button>
                                                        <button
                                                            className='btn btn-primary btnInput btnInputLocal'
                                                            data-bs-toggle='modal'
                                                            data-bs-target='#urlUploadModal'
                                                            onClick={() => {
                                                                setIsInputModalOpen(true);
                                                                setIsIframeClosed(false);
                                                            }}>
                                                            {translator.get('project.detail.accordion.input.data.portal.button.text')}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {KeycloakUtil.isLoggedIn() && project?.status !== ProjectStatusEnum.FORKING && (
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='accordion accordion-background-active border bg-white' id='accordion'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            className='accordion-button border collapsed'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#output'
                                            aria-expanded='false'
                                            aria-controls='output'>
                                            {translator.get('project.detail.output.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div id='output' className='collapse output-accordion-body size-tab' role='region' aria-labelledby='output' ref={ref}>
                                        <div className='accordion-body'>
                                            <Table columns={outputColumns as Array<string>} rows={outputRows as any} keyLabel='output' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {KeycloakUtil.isLoggedIn() && project?.status !== ProjectStatusEnum.FORKING && (
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='accordion accordion-background-active border bg-white' id='accordion'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            className='accordion-button border collapsed'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#Warnings'
                                            aria-expanded='false'
                                            aria-controls='Warnings'
                                            onClick={e => {
                                                e.preventDefault();
                                                handleWarningsScroll();
                                            }}>
                                            {translator.get('project.detail.warnings.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div id='Warnings' className='collapse size-tab' role='region' aria-labelledby='heading1c'>
                                        <div className='accordion-body log-size' ref={warningsRef}>
                                            <Table columns={warningColumns} rows={warningRows} keyLabel='warning' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {KeycloakUtil.isLoggedIn() && project?.status !== ProjectStatusEnum.FORKING && (
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='accordion accordion-background-active border bg-white' id='accordion'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            className='accordion-button border collapsed'
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#Team'
                                            aria-expanded='false'
                                            aria-controls='Team'>
                                            {translator.get('project.detail.team.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div id='Team' className='collapse size-tab' role='region' aria-labelledby='heading1c'>
                                        <div className='accordion-body pb-0'>
                                            <div className='creatorDiv'>
                                                <div className='creator-container-team'>
                                                    <div className='d-flex'>
                                                        <div className='creator-label-container'>Creator: </div>
                                                        <div className='creator-content-container'>{`${project?.author?.name} (${project?.author?.email})`}</div>
                                                    </div>
                                                    {KeycloakUtil.isLoggedIn() &&
                                                        !project?.script_published &&
                                                        project?.service_publish_date === null &&
                                                        (KeycloakUtil.hasRole([KeycloakRoleEnum.admin]) || project?.author?.email === KeycloakUtil.getEmail()) &&
                                                        !isProjectRunning && (
                                                            <>
                                                                {isAddingMember ? (
                                                                    <>
                                                                        <ProjectAddTeamMemberComponent
                                                                            handleOpenMember={handleOpenMember}
                                                                            handleOnBlur={handleAddMemberOnBlur}
                                                                            handleSaveMember={handleSaveMember}
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <button className='btn margin-button-add-team' onClick={() => handleOpenMember(true)}>
                                                                        <AddUser className='plus-container' fill='#009138' />
                                                                    </button>
                                                                )}
                                                            </>
                                                        )}
                                                </div>
                                                <Table columns={teamColumns as Array<string>} rows={teamRows!} keyLabel='team' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {KeycloakUtil.isLoggedIn() && project?.status !== ProjectStatusEnum.FORKING && !isProjectRunning && (
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='accordion accordion-background-active border bg-white' id='accordion'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            className={`accordion-button border ${!isProjectRunning && 'collapsed'}`}
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#Statistics'
                                            aria-expanded={isProjectRunning ? 'true' : 'false'}
                                            aria-controls='Statistics'>
                                            {translator.get('project.detail.statistics.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div id='Statistics' className={`collapse size-tab ${isProjectRunning && 'show'}`} role='region' aria-labelledby='heading1c'>
                                        {' '}
                                        <div className='accordion-body'>
                                            <p className='accordion-header mb-1'>{translator.get('project.detail.statistics.table.header.text')}</p>

                                            <Table columns={statisticsColumns} rows={statisticsRows} keyLabel='statistics' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {KeycloakUtil.isLoggedIn() && project?.status !== ProjectStatusEnum.FORKING && !isProjectRunning && (
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='accordion accordion-background-active border bg-white' id='accordion'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            className={`accordion-button border ${!isProjectRunning && 'collapsed'}`}
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#Provenance'
                                            aria-expanded={isProjectRunning ? 'true' : 'false'}
                                            aria-controls='Provenance'>
                                            {translator.get('project.detail.provenance.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div id='Provenance' className={`collapse ${isProjectRunning && 'show'}`} role='region' aria-labelledby='heading1c'>
                                        <div className='accordion-body' style={{ overflow: 'auto' }}>
                                            {provenance ? (
                                                <ProjectProvenanceComponent treeData={provenance} templates={templates} />
                                            ) : (
                                                <div>{translator.get('project.detail.provenance.accordion.no.children.text')}</div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {project?.integrations?.belindaMetadata?.embed_url && project?.integrations?.belindaMetadata?.status === 'PUBLISHED' && (
                    <div className='row mb-3'>
                        <div className='col'>
                            <div className='accordion accordion-background-active border bg-white' id='accordion'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header ' id='heading1c'>
                                        <button
                                            className={`accordion-button border collapsed`}
                                            type='button'
                                            data-bs-toggle='collapse'
                                            data-bs-target='#BelindaMetadata'
                                            aria-expanded='false'
                                            aria-controls='BelindaMetadata'>
                                            {translator.get('project.detail.belinda.metadata.accordion.header.button.text')}
                                        </button>
                                    </h2>
                                    <div id='BelindaMetadata' className={`collapse`} role='region' aria-labelledby='heading1c'>
                                        <div className='accordion-body' style={{ height: 800 }}>
                                            <iframe src={project?.integrations?.belindaMetadata?.embed_url} width={'100%'} height='100%' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <div className='modal fade' role='dialog' id='urlUploadModal' aria-labelledby='urlUploadModalTitle' data-bs-backdrop='static' data-bs-keyboard='false'>
                <div className={`modal-dialog ${!isIframeClosed ? 'modal-xl' : 'modal-md'}`} role='document'>
                    <div className='modal-content' style={{ height: !isIframeClosed ? 800 : 'auto' }}>
                        <div className='modal-header'>
                            <h2 className='modal-title h5 no_toc' id='urlUploadModalTitle'>
                                {translator.get('project.modal.upload.input.title')}
                            </h2>
                            <button
                                className='btn-close'
                                type='button'
                                data-bs-dismiss='modal'
                                aria-label='Close modal'
                                id='urlUploadModalClose'
                                onClick={() => setIsInputModalOpen(false)}
                            />
                        </div>
                        <div className='modal-body mt-4'>
                            <BehubInputUploadComponent
                                user={user as LoginState}
                                project={project as Project}
                                isClosed={isInputModalOpen}
                                onUpload={handleUploadFromURL}
                                onRedirect={() => setIsIframeClosed(true)}
                            />
                        </div>
                        <div className='modal-footer'></div>
                    </div>
                </div>
            </div>

            <div className='modal fade' role='dialog' id='localUploadModal' aria-labelledby='localUploadModalTitle' data-bs-backdrop='static' data-bs-keyboard='false'>
                <div className='modal-dialog' role='document'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='modal-title h5 no_toc' id='localUploadModalTitle'>
                                {translator.get('project.modal.upload.input.title')}
                            </h2>
                            <button
                                className='btn-close'
                                type='button'
                                data-bs-dismiss='modal'
                                aria-label='Close modal'
                                id='localUploadModalClose'
                                onClick={() => setIsInputModalOpen(false)}
                            />
                        </div>
                        <div className='modal-body mt-4'>
                            <LocalInputUploadComponent user={user as LoginState} project={project as Project} isClosed={isInputModalOpen} onUpload={handleUploadFromLocal} />
                        </div>
                        <div className='modal-footer'></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AccordionContainer;
