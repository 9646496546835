import React from 'react';
import { TextareaHTMLAttributes, useEffect, useRef, useState } from 'react';
import './style/Textarea.style.scss';
interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
    isAutoSize?: boolean;
}

const TextArea: React.FC<ITextAreaProps> = React.forwardRef(({ isAutoSize, onChange, name, defaultValue, value, ...props }, ref) => {
    return (
        <>
            <textarea
                {...props}
                ref={ref as any}
                name={name}
                defaultValue={defaultValue}
                value={value}
                className={`textarea-container ${props.className}`}
                onChange={e => {
                    onChange?.(e);
                    //autosize();
                }}
            />
        </>
    );
});

export default TextArea;
