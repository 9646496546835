/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import {
    DUMMY_BASE_URL,
    assertParamExists,
    setApiKeyToObject,
    setBasicAuthToObject,
    setBearerAuthToObject,
    setOAuthToObject,
    setSearchParams,
    serializeDataIfNeeded,
    toPathString,
    createRequestFunction,
} from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { RequestFile } from '../entities';
// @ts-ignore
import { RequestGenericStart } from '../entities';
// @ts-ignore
import { RequestServiceExecutionMinisiteCreate } from '../entities';
// @ts-ignore
import { RequestServiceUrlFileSelect } from '../entities';
// @ts-ignore
import { ResponseServiceExecutionMinisite } from '../entities';
// @ts-ignore
import { ResponseServiceListOutput } from '../entities';
// @ts-ignore
import { ResponseServiceUrlFileSelect } from '../entities';
/**
 * MinisiteControllerApi - axios parameter creator
 * @export
 */
export const MinisiteControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates an execution
         * @param {string} id
         * @param {RequestServiceExecutionMinisiteCreate} requestServiceExecutionMinisiteCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExecutionMinisite: async (
            id: string,
            requestServiceExecutionMinisiteCreate: RequestServiceExecutionMinisiteCreate,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('createExecutionMinisite', 'id', id);
            // verify required parameter 'requestServiceExecutionMinisiteCreate' is not null or undefined
            assertParamExists('createExecutionMinisite', 'requestServiceExecutionMinisiteCreate', requestServiceExecutionMinisiteCreate);
            const localVarPath = `/minisite/{id}/execution/create`.replace(`{${'id'}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestServiceExecutionMinisiteCreate, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Download a specific input
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInput2: async (jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jwtHeader' is not null or undefined
            assertParamExists('downloadInput2', 'jwtHeader', jwtHeader);
            // verify required parameter 'jwtPayload' is not null or undefined
            assertParamExists('downloadInput2', 'jwtPayload', jwtPayload);
            // verify required parameter 'jwtSignature' is not null or undefined
            assertParamExists('downloadInput2', 'jwtSignature', jwtSignature);
            // verify required parameter 'requestFile' is not null or undefined
            assertParamExists('downloadInput2', 'requestFile', requestFile);
            const localVarPath = `/minisite/execution/input/download/{jwt_payload}/{jwt_signature}/{jwt_header}`
                .replace(`{${'jwt_header'}}`, encodeURIComponent(String(jwtHeader)))
                .replace(`{${'jwt_payload'}}`, encodeURIComponent(String(jwtPayload)))
                .replace(`{${'jwt_signature'}}`, encodeURIComponent(String(jwtSignature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestFile, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Downloads the log file as html
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadLog1: async (jwtHeader: string, jwtPayload: string, jwtSignature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jwtHeader' is not null or undefined
            assertParamExists('downloadLog1', 'jwtHeader', jwtHeader);
            // verify required parameter 'jwtPayload' is not null or undefined
            assertParamExists('downloadLog1', 'jwtPayload', jwtPayload);
            // verify required parameter 'jwtSignature' is not null or undefined
            assertParamExists('downloadLog1', 'jwtSignature', jwtSignature);
            const localVarPath = `/minisite/execution/log/download/{jwt_payload}/{jwt_signature}/{jwt_header}`
                .replace(`{${'jwt_header'}}`, encodeURIComponent(String(jwtHeader)))
                .replace(`{${'jwt_payload'}}`, encodeURIComponent(String(jwtPayload)))
                .replace(`{${'jwt_signature'}}`, encodeURIComponent(String(jwtSignature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Downloads specific output file
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOutput2: async (jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jwtHeader' is not null or undefined
            assertParamExists('downloadOutput2', 'jwtHeader', jwtHeader);
            // verify required parameter 'jwtPayload' is not null or undefined
            assertParamExists('downloadOutput2', 'jwtPayload', jwtPayload);
            // verify required parameter 'jwtSignature' is not null or undefined
            assertParamExists('downloadOutput2', 'jwtSignature', jwtSignature);
            // verify required parameter 'requestFile' is not null or undefined
            assertParamExists('downloadOutput2', 'requestFile', requestFile);
            const localVarPath = `/minisite/execution/output/download/{jwt_payload}/{jwt_signature}/{jwt_header}`
                .replace(`{${'jwt_header'}}`, encodeURIComponent(String(jwtHeader)))
                .replace(`{${'jwt_payload'}}`, encodeURIComponent(String(jwtPayload)))
                .replace(`{${'jwt_signature'}}`, encodeURIComponent(String(jwtSignature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestFile, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Obtains detailed information about the service execution minisite
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get2: async (jwtHeader: string, jwtPayload: string, jwtSignature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jwtHeader' is not null or undefined
            assertParamExists('get2', 'jwtHeader', jwtHeader);
            // verify required parameter 'jwtPayload' is not null or undefined
            assertParamExists('get2', 'jwtPayload', jwtPayload);
            // verify required parameter 'jwtSignature' is not null or undefined
            assertParamExists('get2', 'jwtSignature', jwtSignature);
            const localVarPath = `/minisite/execution/{jwt_payload}/{jwt_signature}/{jwt_header}`
                .replace(`{${'jwt_header'}}`, encodeURIComponent(String(jwtHeader)))
                .replace(`{${'jwt_payload'}}`, encodeURIComponent(String(jwtPayload)))
                .replace(`{${'jwt_signature'}}`, encodeURIComponent(String(jwtSignature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Launch an execution
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {RequestGenericStart} requestGenericStart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launch1: async (
            jwtHeader: string,
            jwtPayload: string,
            jwtSignature: string,
            requestGenericStart: RequestGenericStart,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'jwtHeader' is not null or undefined
            assertParamExists('launch1', 'jwtHeader', jwtHeader);
            // verify required parameter 'jwtPayload' is not null or undefined
            assertParamExists('launch1', 'jwtPayload', jwtPayload);
            // verify required parameter 'jwtSignature' is not null or undefined
            assertParamExists('launch1', 'jwtSignature', jwtSignature);
            // verify required parameter 'requestGenericStart' is not null or undefined
            assertParamExists('launch1', 'requestGenericStart', requestGenericStart);
            const localVarPath = `/minisite/execution/run/{jwt_payload}/{jwt_signature}/{jwt_header}`
                .replace(`{${'jwt_header'}}`, encodeURIComponent(String(jwtHeader)))
                .replace(`{${'jwt_payload'}}`, encodeURIComponent(String(jwtPayload)))
                .replace(`{${'jwt_signature'}}`, encodeURIComponent(String(jwtSignature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestGenericStart, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Downloads specific output file
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outputs: async (jwtHeader: string, jwtPayload: string, jwtSignature: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jwtHeader' is not null or undefined
            assertParamExists('outputs', 'jwtHeader', jwtHeader);
            // verify required parameter 'jwtPayload' is not null or undefined
            assertParamExists('outputs', 'jwtPayload', jwtPayload);
            // verify required parameter 'jwtSignature' is not null or undefined
            assertParamExists('outputs', 'jwtSignature', jwtSignature);
            const localVarPath = `/minisite/execution/outputs/{jwt_payload}/{jwt_signature}/{jwt_header}`
                .replace(`{${'jwt_header'}}`, encodeURIComponent(String(jwtHeader)))
                .replace(`{${'jwt_payload'}}`, encodeURIComponent(String(jwtPayload)))
                .replace(`{${'jwt_signature'}}`, encodeURIComponent(String(jwtSignature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Checks and selects a specific input from external URL
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {RequestServiceUrlFileSelect} requestServiceUrlFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectExternalUrlInput3: async (
            jwtHeader: string,
            jwtPayload: string,
            jwtSignature: string,
            requestServiceUrlFileSelect: RequestServiceUrlFileSelect,
            options: AxiosRequestConfig = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'jwtHeader' is not null or undefined
            assertParamExists('selectExternalUrlInput3', 'jwtHeader', jwtHeader);
            // verify required parameter 'jwtPayload' is not null or undefined
            assertParamExists('selectExternalUrlInput3', 'jwtPayload', jwtPayload);
            // verify required parameter 'jwtSignature' is not null or undefined
            assertParamExists('selectExternalUrlInput3', 'jwtSignature', jwtSignature);
            // verify required parameter 'requestServiceUrlFileSelect' is not null or undefined
            assertParamExists('selectExternalUrlInput3', 'requestServiceUrlFileSelect', requestServiceUrlFileSelect);
            const localVarPath = `/minisite/execution/input/url/{jwt_payload}/{jwt_signature}/{jwt_header}`
                .replace(`{${'jwt_header'}}`, encodeURIComponent(String(jwtHeader)))
                .replace(`{${'jwt_payload'}}`, encodeURIComponent(String(jwtPayload)))
                .replace(`{${'jwt_signature'}}`, encodeURIComponent(String(jwtSignature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestServiceUrlFileSelect, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/minisite/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput1: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/minisite/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput10: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/minisite/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput11: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/minisite/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput2: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/minisite/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput3: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/minisite/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput4: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/minisite/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'HEAD', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput5: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/minisite/input/**`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput6: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/minisite/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput7: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/minisite/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput8: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/minisite/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput9: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/minisite/input`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Use already uploaded project dataset for this slot
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useDataset: async (jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'jwtHeader' is not null or undefined
            assertParamExists('useDataset', 'jwtHeader', jwtHeader);
            // verify required parameter 'jwtPayload' is not null or undefined
            assertParamExists('useDataset', 'jwtPayload', jwtPayload);
            // verify required parameter 'jwtSignature' is not null or undefined
            assertParamExists('useDataset', 'jwtSignature', jwtSignature);
            // verify required parameter 'requestFile' is not null or undefined
            assertParamExists('useDataset', 'requestFile', requestFile);
            const localVarPath = `/minisite/execution/input/dataset/{jwt_payload}/{jwt_signature}/{jwt_header}`
                .replace(`{${'jwt_header'}}`, encodeURIComponent(String(jwtHeader)))
                .replace(`{${'jwt_payload'}}`, encodeURIComponent(String(jwtPayload)))
                .replace(`{${'jwt_signature'}}`, encodeURIComponent(String(jwtSignature)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
            localVarRequestOptions.data = serializeDataIfNeeded(requestFile, localVarRequestOptions, configuration);

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};

/**
 * MinisiteControllerApi - functional programming interface
 * @export
 */
export const MinisiteControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = MinisiteControllerApiAxiosParamCreator(configuration);
    return {
        /**
         * Creates an execution
         * @param {string} id
         * @param {RequestServiceExecutionMinisiteCreate} requestServiceExecutionMinisiteCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createExecutionMinisite(
            id: string,
            requestServiceExecutionMinisiteCreate: RequestServiceExecutionMinisiteCreate,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceExecutionMinisite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createExecutionMinisite(id, requestServiceExecutionMinisiteCreate, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Download a specific input
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadInput2(
            jwtHeader: string,
            jwtPayload: string,
            jwtSignature: string,
            requestFile: RequestFile,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadInput2(jwtHeader, jwtPayload, jwtSignature, requestFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Downloads the log file as html
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadLog1(
            jwtHeader: string,
            jwtPayload: string,
            jwtSignature: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadLog1(jwtHeader, jwtPayload, jwtSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Downloads specific output file
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadOutput2(
            jwtHeader: string,
            jwtPayload: string,
            jwtSignature: string,
            requestFile: RequestFile,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadOutput2(jwtHeader, jwtPayload, jwtSignature, requestFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Obtains detailed information about the service execution minisite
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async get2(
            jwtHeader: string,
            jwtPayload: string,
            jwtSignature: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceExecutionMinisite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.get2(jwtHeader, jwtPayload, jwtSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Launch an execution
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {RequestGenericStart} requestGenericStart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async launch1(
            jwtHeader: string,
            jwtPayload: string,
            jwtSignature: string,
            requestGenericStart: RequestGenericStart,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceExecutionMinisite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.launch1(jwtHeader, jwtPayload, jwtSignature, requestGenericStart, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Downloads specific output file
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async outputs(
            jwtHeader: string,
            jwtPayload: string,
            jwtSignature: string,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceListOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.outputs(jwtHeader, jwtPayload, jwtSignature, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Checks and selects a specific input from external URL
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {RequestServiceUrlFileSelect} requestServiceUrlFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async selectExternalUrlInput3(
            jwtHeader: string,
            jwtPayload: string,
            jwtSignature: string,
            requestServiceUrlFileSelect: RequestServiceUrlFileSelect,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceUrlFileSelect>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.selectExternalUrlInput3(jwtHeader, jwtPayload, jwtSignature, requestServiceUrlFileSelect, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput1(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput1(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput10(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput10(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput11(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput11(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput2(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput2(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput3(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput3(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput4(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput4(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput5(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput5(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput6(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput6(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput7(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput7(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput8(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput8(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadInput9(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadInput9(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Use already uploaded project dataset for this slot
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async useDataset(
            jwtHeader: string,
            jwtPayload: string,
            jwtSignature: string,
            requestFile: RequestFile,
            options?: AxiosRequestConfig,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ResponseServiceExecutionMinisite>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.useDataset(jwtHeader, jwtPayload, jwtSignature, requestFile, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

/**
 * MinisiteControllerApi - factory interface
 * @export
 */
export const MinisiteControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MinisiteControllerApiFp(configuration);
    return {
        /**
         * Creates an execution
         * @param {string} id
         * @param {RequestServiceExecutionMinisiteCreate} requestServiceExecutionMinisiteCreate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createExecutionMinisite(
            id: string,
            requestServiceExecutionMinisiteCreate: RequestServiceExecutionMinisiteCreate,
            options?: any,
        ): AxiosPromise<ResponseServiceExecutionMinisite> {
            return localVarFp.createExecutionMinisite(id, requestServiceExecutionMinisiteCreate, options).then(request => request(axios, basePath));
        },
        /**
         * Download a specific input
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadInput2(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile, options?: any): AxiosPromise<File> {
            return localVarFp.downloadInput2(jwtHeader, jwtPayload, jwtSignature, requestFile, options).then(request => request(axios, basePath));
        },
        /**
         * Downloads the log file as html
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadLog1(jwtHeader: string, jwtPayload: string, jwtSignature: string, options?: any): AxiosPromise<string> {
            return localVarFp.downloadLog1(jwtHeader, jwtPayload, jwtSignature, options).then(request => request(axios, basePath));
        },
        /**
         * Downloads specific output file
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadOutput2(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile, options?: any): AxiosPromise<File> {
            return localVarFp.downloadOutput2(jwtHeader, jwtPayload, jwtSignature, requestFile, options).then(request => request(axios, basePath));
        },
        /**
         * Obtains detailed information about the service execution minisite
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        get2(jwtHeader: string, jwtPayload: string, jwtSignature: string, options?: any): AxiosPromise<ResponseServiceExecutionMinisite> {
            return localVarFp.get2(jwtHeader, jwtPayload, jwtSignature, options).then(request => request(axios, basePath));
        },
        /**
         * Launch an execution
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {RequestGenericStart} requestGenericStart
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        launch1(
            jwtHeader: string,
            jwtPayload: string,
            jwtSignature: string,
            requestGenericStart: RequestGenericStart,
            options?: any,
        ): AxiosPromise<ResponseServiceExecutionMinisite> {
            return localVarFp.launch1(jwtHeader, jwtPayload, jwtSignature, requestGenericStart, options).then(request => request(axios, basePath));
        },
        /**
         * Downloads specific output file
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        outputs(jwtHeader: string, jwtPayload: string, jwtSignature: string, options?: any): AxiosPromise<ResponseServiceListOutput> {
            return localVarFp.outputs(jwtHeader, jwtPayload, jwtSignature, options).then(request => request(axios, basePath));
        },
        /**
         * Checks and selects a specific input from external URL
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {RequestServiceUrlFileSelect} requestServiceUrlFileSelect
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        selectExternalUrlInput3(
            jwtHeader: string,
            jwtPayload: string,
            jwtSignature: string,
            requestServiceUrlFileSelect: RequestServiceUrlFileSelect,
            options?: any,
        ): AxiosPromise<ResponseServiceUrlFileSelect> {
            return localVarFp.selectExternalUrlInput3(jwtHeader, jwtPayload, jwtSignature, requestServiceUrlFileSelect, options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput1(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput1(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput10(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput10(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput11(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput11(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput2(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput2(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput3(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput3(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput4(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput4(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput5(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput5(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput6(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput6(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput7(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput7(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput8(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput8(options).then(request => request(axios, basePath));
        },
        /**
         * Receives a local dataset (temporary) of input with resume/largefile input
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadInput9(options?: any): AxiosPromise<void> {
            return localVarFp.uploadInput9(options).then(request => request(axios, basePath));
        },
        /**
         * Use already uploaded project dataset for this slot
         * @param {string} jwtHeader
         * @param {string} jwtPayload
         * @param {string} jwtSignature
         * @param {RequestFile} requestFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        useDataset(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile, options?: any): AxiosPromise<ResponseServiceExecutionMinisite> {
            return localVarFp.useDataset(jwtHeader, jwtPayload, jwtSignature, requestFile, options).then(request => request(axios, basePath));
        },
    };
};

/**
 * MinisiteControllerApi - interface
 * @export
 * @interface MinisiteControllerApi
 */
export interface MinisiteControllerApiInterface {
    /**
     * Creates an execution
     * @param {string} id
     * @param {RequestServiceExecutionMinisiteCreate} requestServiceExecutionMinisiteCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    createExecutionMinisite(
        id: string,
        requestServiceExecutionMinisiteCreate: RequestServiceExecutionMinisiteCreate,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseServiceExecutionMinisite>;

    /**
     * Download a specific input
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    downloadInput2(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * Downloads the log file as html
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    downloadLog1(jwtHeader: string, jwtPayload: string, jwtSignature: string, options?: AxiosRequestConfig): AxiosPromise<string>;

    /**
     * Downloads specific output file
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    downloadOutput2(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile, options?: AxiosRequestConfig): AxiosPromise<File>;

    /**
     * Obtains detailed information about the service execution minisite
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    get2(jwtHeader: string, jwtPayload: string, jwtSignature: string, options?: AxiosRequestConfig): AxiosPromise<ResponseServiceExecutionMinisite>;

    /**
     * Launch an execution
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {RequestGenericStart} requestGenericStart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    launch1(
        jwtHeader: string,
        jwtPayload: string,
        jwtSignature: string,
        requestGenericStart: RequestGenericStart,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseServiceExecutionMinisite>;

    /**
     * Downloads specific output file
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    outputs(jwtHeader: string, jwtPayload: string, jwtSignature: string, options?: AxiosRequestConfig): AxiosPromise<ResponseServiceListOutput>;

    /**
     * Checks and selects a specific input from external URL
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {RequestServiceUrlFileSelect} requestServiceUrlFileSelect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    selectExternalUrlInput3(
        jwtHeader: string,
        jwtPayload: string,
        jwtSignature: string,
        requestServiceUrlFileSelect: RequestServiceUrlFileSelect,
        options?: AxiosRequestConfig,
    ): AxiosPromise<ResponseServiceUrlFileSelect>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    uploadInput(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    uploadInput1(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    uploadInput10(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    uploadInput11(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    uploadInput2(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    uploadInput3(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    uploadInput4(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    uploadInput5(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    uploadInput6(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    uploadInput7(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    uploadInput8(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    uploadInput9(options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * Use already uploaded project dataset for this slot
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApiInterface
     */
    useDataset(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile, options?: AxiosRequestConfig): AxiosPromise<ResponseServiceExecutionMinisite>;
}

/**
 * MinisiteControllerApi - object-oriented interface
 * @export
 * @class MinisiteControllerApi
 * @extends {BaseAPI}
 */
export class MinisiteControllerApi extends BaseAPI implements MinisiteControllerApiInterface {
    /**
     * Creates an execution
     * @param {string} id
     * @param {RequestServiceExecutionMinisiteCreate} requestServiceExecutionMinisiteCreate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public createExecutionMinisite(id: string, requestServiceExecutionMinisiteCreate: RequestServiceExecutionMinisiteCreate, options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .createExecutionMinisite(id, requestServiceExecutionMinisiteCreate, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Download a specific input
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public downloadInput2(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile, options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .downloadInput2(jwtHeader, jwtPayload, jwtSignature, requestFile, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Downloads the log file as html
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public downloadLog1(jwtHeader: string, jwtPayload: string, jwtSignature: string, options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .downloadLog1(jwtHeader, jwtPayload, jwtSignature, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Downloads specific output file
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public downloadOutput2(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile, options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .downloadOutput2(jwtHeader, jwtPayload, jwtSignature, requestFile, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Obtains detailed information about the service execution minisite
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public get2(jwtHeader: string, jwtPayload: string, jwtSignature: string, options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .get2(jwtHeader, jwtPayload, jwtSignature, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Launch an execution
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {RequestGenericStart} requestGenericStart
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public launch1(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestGenericStart: RequestGenericStart, options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .launch1(jwtHeader, jwtPayload, jwtSignature, requestGenericStart, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Downloads specific output file
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public outputs(jwtHeader: string, jwtPayload: string, jwtSignature: string, options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .outputs(jwtHeader, jwtPayload, jwtSignature, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Checks and selects a specific input from external URL
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {RequestServiceUrlFileSelect} requestServiceUrlFileSelect
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public selectExternalUrlInput3(
        jwtHeader: string,
        jwtPayload: string,
        jwtSignature: string,
        requestServiceUrlFileSelect: RequestServiceUrlFileSelect,
        options?: AxiosRequestConfig,
    ) {
        return MinisiteControllerApiFp(this.configuration)
            .selectExternalUrlInput3(jwtHeader, jwtPayload, jwtSignature, requestServiceUrlFileSelect, options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public uploadInput(options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .uploadInput(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public uploadInput1(options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .uploadInput1(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public uploadInput10(options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .uploadInput10(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public uploadInput11(options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .uploadInput11(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public uploadInput2(options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .uploadInput2(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public uploadInput3(options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .uploadInput3(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public uploadInput4(options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .uploadInput4(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public uploadInput5(options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .uploadInput5(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public uploadInput6(options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .uploadInput6(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public uploadInput7(options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .uploadInput7(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public uploadInput8(options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .uploadInput8(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Receives a local dataset (temporary) of input with resume/largefile input
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public uploadInput9(options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .uploadInput9(options)
            .then(request => request(this.axios, this.basePath));
    }

    /**
     * Use already uploaded project dataset for this slot
     * @param {string} jwtHeader
     * @param {string} jwtPayload
     * @param {string} jwtSignature
     * @param {RequestFile} requestFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MinisiteControllerApi
     */
    public useDataset(jwtHeader: string, jwtPayload: string, jwtSignature: string, requestFile: RequestFile, options?: AxiosRequestConfig) {
        return MinisiteControllerApiFp(this.configuration)
            .useDataset(jwtHeader, jwtPayload, jwtSignature, requestFile, options)
            .then(request => request(this.axios, this.basePath));
    }
}
