import { TokenResponse, User } from '../../../api/entities';
import { GitIntegrationApiClients, IGitIntegrationApiClient } from './git-integration.api.client';

export interface IGitIntegrationApiService {
    pokeUser(user: User): Promise<TokenResponse>;
}

export class GitIntegrationApiService implements IGitIntegrationApiService {
    private static instance: GitIntegrationApiService;
    private api: IGitIntegrationApiClient;

    private constructor(basePath: string) {
        this.api = GitIntegrationApiClients.getInstance(basePath);
    }

    static getInstance(basePath: string) {
        if (GitIntegrationApiService.instance == null) {
            GitIntegrationApiService.instance = new GitIntegrationApiService(basePath);
        }
        return GitIntegrationApiService.instance;
    }

    async pokeUser(user: User): Promise<TokenResponse> {
        return (await this.api.git.pokeUser(user)).data;
    }
}
