import { HomeState } from '@features/home/state/state';
import { LoginState } from '@features/login/store/state';
import { MinisiteState } from '@features/minisite/store/state';
import { ProjectState } from '@features/project/state/state';
import { ProvisioningState } from '@features/provisioning/store/state';
import { ServiceState } from '@features/service/store/state';
import { SharedState, ShellState } from '@features/_shared/store/state';

export enum Slices {
    Login = 'login',
    Project = 'project',
    Service = 'service',
    Provisioning = 'provisioning',
    Shell = 'shell',
    Shared = 'shared',
    Home = 'home',
    Minisite = 'minisite',
}

export interface RootState {
    [Slices.Login]: LoginState;
    [Slices.Project]: ProjectState;
    [Slices.Service]: ServiceState;
    [Slices.Provisioning]: ProvisioningState;
    [Slices.Shell]: ShellState;
    [Slices.Shared]: SharedState;
    [Slices.Home]: HomeState;
    [Slices.Minisite]: MinisiteState;
}
