import { TranslatorManager } from './TranslatorManager';

export interface ITranslatorManager {
    get(key: string, values?: Record<string, string>): string;
}

export class DesignSystemTranslatorManager {
    /**
     * get instance method for retrieving the singleton instance
     * translatorManager: instance of manager that implements translation logic (optional)
     * @returns the singleton instance
     */
    public static getInstance(translatorManager?: ITranslatorManager) {
        return TranslatorManager.getInstance(translatorManager);
    }
}
