import { PersistentReduceableReducer } from '@hypereact/state';
import { MinisiteState } from './state';

export class MinisiteReducer extends PersistentReduceableReducer<MinisiteState> {
    dehydrate(state: MinisiteState): MinisiteState | null {
        return state;
    }

    rehydrate(state: MinisiteState, data: MinisiteState): MinisiteState {
        return {
            ...state,
            ...data,
        };
    }
}
