import {
    BaseResponse,
    RequestApprove,
    RequestBodyGenericStopProcess,
    RequestFile,
    RequestGenericStart,
    RequestProjectPublish,
    RequestReject,
    RequestServiceCreate,
    RequestServiceExecutionCreate,
    RequestServiceFileCreate,
    RequestServicePublish,
    RequestServiceUpdate,
    RequestServiceUrlFileSelect,
    ResponseCoreServiceExecutionLaunch,
    ResponseProjectListOutput,
    ResponseServiceDiffToProject,
    ResponseServiceExecution,
    ResponseServiceExecutionPaginated,
    ResponseServiceFileCreate,
    ResponseServiceListOutput,
    ResponseServiceSearch,
    ResponseServiceUpdate,
    ResponseServiceUrlFileSelect,
    WebService,
    WebServiceSearchRequest,
} from '../../../api/entities';
import { IServiceApiClient, ServiceApiClients } from './service.api.client';

export interface IServiceApiService {
    search(serviceSearchRequest: WebServiceSearchRequest): Promise<ResponseServiceSearch>;
    create(requestServiceCreate: RequestServiceCreate): Promise<WebService>;
    getServiceById(id: string): Promise<WebService>;
    createExecution(id: string, requestServiceExecutionCreate: RequestServiceExecutionCreate): Promise<ResponseServiceExecution>;
    launch(id: string, execId: string, requestGenericStart: RequestGenericStart): Promise<ResponseCoreServiceExecutionLaunch>;
    stop(id: string, execId: string, requestServiceStopExecution: RequestBodyGenericStopProcess): Promise<BaseResponse>;
    findExecution(id: string, execId: string): Promise<ResponseServiceExecution>;
    executions(id: string, page?: number, size?: number): Promise<ResponseServiceExecutionPaginated>;
    selectExternalUrlInput(id: string, execId: string, requestServiceUrlFileSelect: RequestServiceUrlFileSelect): Promise<ResponseServiceUrlFileSelect>;
    createInput(id: string, execId: string, requestServiceFileCreate: RequestServiceFileCreate): Promise<ResponseServiceFileCreate>;
    update(id: string, requestServiceUpdate: RequestServiceUpdate): Promise<ResponseServiceUpdate>;
    outputList(id: string, execId: string): Promise<ResponseServiceListOutput>;
    publishService(id: string, requestPublish: RequestProjectPublish): Promise<WebService>;
    getLogs(serviceId: string, executionId: string): Promise<string>;
    getHtml(serviceId: string, executionId: string): Promise<string>;
    reject(id: string, requestReject: RequestReject): Promise<WebService>;
    downloadOutput(id: string, execId: string, requestFile: RequestFile): Promise<File>;
    downloadInput(id: string, execId: string, requestFile: RequestFile): Promise<File>;
    refresh(id: string): Promise<WebService>;
    diffToProject(id: string): Promise<ResponseServiceDiffToProject>;
    updatePublicationData(id: string, requestServicePublish: RequestServicePublish): Promise<WebService>;
    delete(id: string): Promise<void>;
    approve(id: string, requestApprove: RequestApprove): Promise<WebService>;
}

export class ServiceApiService implements IServiceApiService {
    private static instance: ServiceApiService;
    private api: IServiceApiClient;

    private constructor(basePath: string) {
        this.api = ServiceApiClients.getInstance(basePath);
    }

    static getInstance(basePath: string) {
        if (ServiceApiService.instance == null) {
            ServiceApiService.instance = new ServiceApiService(basePath);
        }
        return ServiceApiService.instance;
    }

    async search(serviceSearchRequest: WebServiceSearchRequest): Promise<ResponseServiceSearch> {
        return (await this.api.service.search(serviceSearchRequest)).data;
    }

    async create(requestServiceCreate: RequestServiceCreate): Promise<WebService> {
        return (await this.api.service.create(requestServiceCreate)).data;
    }

    async getServiceById(id: string): Promise<WebService> {
        return (await this.api.service.get(id)).data;
    }

    async createExecution(id: string, requestServiceExecutionCreate: RequestServiceExecutionCreate): Promise<ResponseServiceExecution> {
        return (await this.api.service.createExecution(id, requestServiceExecutionCreate)).data;
    }

    async launch(id: string, execId: string, requestGenericStart: RequestGenericStart): Promise<ResponseCoreServiceExecutionLaunch> {
        return (await this.api.service.launch(id, execId, requestGenericStart)).data;
    }

    async stop(id: string, execId: string, requestServiceStopExecution: RequestBodyGenericStopProcess): Promise<BaseResponse> {
        return (await this.api.service.stop(id, execId, requestServiceStopExecution)).data;
    }

    async findExecution(id: string, execId: string): Promise<ResponseServiceExecution> {
        return (await this.api.service.findExecution(id, execId)).data;
    }

    async executions(id: string, page?: number, size?: number): Promise<ResponseServiceExecutionPaginated> {
        return (await this.api.service.executions(id, page, size)).data;
    }

    async selectExternalUrlInput(id: string, execId: string, requestServiceUrlFileSelect: RequestServiceUrlFileSelect): Promise<ResponseServiceUrlFileSelect> {
        return (await this.api.service.selectExternalUrlInput1(id, execId, requestServiceUrlFileSelect)).data;
    }

    async createInput(id: string, execId: string, requestServiceFileCreate: RequestServiceFileCreate): Promise<ResponseServiceFileCreate> {
        return (await this.api.service.createInput(id, execId, requestServiceFileCreate)).data;
    }

    async update(id: string, requestServiceUpdate: RequestServiceUpdate): Promise<ResponseServiceUpdate> {
        return (await this.api.service.update(id, requestServiceUpdate)).data;
    }

    async outputList(id: string, execId: string): Promise<ResponseProjectListOutput> {
        return (await this.api.service.outputList(id, execId)).data;
    }

    async publishService(id: string, requestPublish: RequestProjectPublish): Promise<WebService> {
        return (await this.api.service.publish(id, requestPublish)).data;
    }

    async getLogs(serviceId: string, executionId: string): Promise<string> {
        return (await this.api.service.downloadLog(serviceId, executionId)).data;
    }

    async getHtml(serviceId: string, executionId: string): Promise<string> {
        return (await this.api.service.downloadHtml(serviceId, executionId)).data;
    }

    async reject(id: string, requestReject: RequestReject): Promise<WebService> {
        return (await this.api.service.reject(id, requestReject)).data;
    }

    async downloadOutput(id: string, execId: string, requestFile: RequestFile): Promise<File> {
        return (await this.api.service.downloadOutput(id, execId, requestFile, { responseType: 'blob' })).data;
    }

    async downloadInput(id: string, execId: string, requestFile: RequestFile): Promise<File> {
        return (await this.api.service.downloadInput(id, execId, requestFile, { responseType: 'blob' })).data;
    }

    async refresh(id: string): Promise<WebService> {
        return (await this.api.service.refresh(id)).data;
    }

    async diffToProject(id: string): Promise<ResponseServiceDiffToProject> {
        return (await this.api.service.diffToProject(id)).data;
    }

    async updatePublicationData(id: string, requestServicePublish: RequestServicePublish): Promise<WebService> {
        return (await this.api.service.updatePublicationData(id, requestServicePublish)).data;
    }

    async delete(id: string): Promise<void> {
        return (await this.api.service._delete(id)).data;
    }

    async approve(id: string, requestApprove: RequestApprove): Promise<WebService> {
        return (await this.api.service.approve(id, requestApprove)).data;
    }
}
