import { TextField } from '@features/_shared/components/TextField';
import { FormManager } from '@managers/form.manager';
import { TranslatorManager } from '@managers/TranslatorManager';
import { FormHelperUtil } from '@utils/form.helper.util';
import { useContext, useState } from 'react';
import { IntlContext } from 'react-intl';
import { ProjectAddTeamMember } from '../beans/projectAddTeamMember';
import '../components/styles/project.add.team.member.component.style.scss';
export interface IProjectAddTeamMemberComponentProps {
    handleOnBlur: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
    handleSaveMember: (e: React.MouseEvent<HTMLButtonElement>) => void;
    handleOpenMember: (isOpen: boolean) => void;
}

export const ProjectAddTeamMemberComponent: React.FC<IProjectAddTeamMemberComponentProps> = ({ handleOnBlur, handleSaveMember, handleOpenMember }) => {
    const translator = TranslatorManager.getInstance();
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
    const intl = useContext(IntlContext);
    const getMessageByFieldAndError = FormHelperUtil.getFormFieldErrorTranslator('project.add.member', intl as any);

    const onSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        let error = await trigger();
        setIsSubmitted(true);
        if (error) {
            handleSaveMember(e);
            reset();
            handleOpenMember(false);
        } else {
            e.preventDefault();
        }
    };

    const triggerValidation = (key: string, value: string) => {
        setValue(key, value);
        isSubmitted && trigger(key);
    };

    const { errors, register, trigger, setValue, reset } = FormManager.getInstance().buildFormProxy<ProjectAddTeamMember>(ProjectAddTeamMember, onSubmit);
    return (
        <>
            <div className='d-flex flex-row justify-content-start wrap-member-container'>
                <div className='form-group mt-2 mb-0 member-container'>
                    <div className='member-text-field'>
                        <TextField
                            type='email'
                            {...register('collaboratorEmail', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('collaboratorEmail', e.target.value) })}
                            error={errors.collaboratorEmail}
                            className='form-control'
                            errorMessage={errors.collaboratorEmail && getMessageByFieldAndError('collaboratorEmail', errors)}
                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                            placeholder={translator.get('project.detail.team.accordion.new.member.placeholder')}
                        />
                    </div>
                    <div className='member-text-field'>
                        <TextField
                            type='text'
                            {...register('collaboratorName', { onChange: (e: React.ChangeEvent<HTMLInputElement>) => triggerValidation('collaboratorName', e.target.value) })}
                            error={errors.collaboratorName}
                            className='form-control'
                            errorMessage={errors.collaboratorName && getMessageByFieldAndError('collaboratorName', errors)}
                            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => handleOnBlur(e)}
                            placeholder={translator.get('project.detail.team.accordion.new.member.name.placeholder')}
                        />
                    </div>
                </div>
                <button className='btn btn-primary btn-save btn-save-margin float-end ' onClick={(e: React.MouseEvent<HTMLButtonElement>) => onSubmit(e)}>
                    {translator.get('project.detail.save.button.text')}
                </button>
            </div>
        </>
    );
};
