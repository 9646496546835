import { HomeSetProjects } from '@features/home/state/actions';
import { SetPokeUser } from '@features/login/store/actions';
import {
    ProjectResetCategoryFilters,
    ProjectResetCreateUpdate,
    ProjectResetCreateUpdateCategory,
    ProjectResetFilters,
    ProjectResetNewCollaborator,
    ProjectResetPaginationAction,
    ProjectResetSelectedProject,
    ProjectSetCategories,
    ProjectSetFilters,
    ProjectSetIsIDEOpened,
    ProjectSetNewMemberValue,
    ProjectSetOutputs,
    ProjectSetPaginationAction,
    ProjectSetProjects,
    ProjectSetProvenance,
    ProjectSetSelectedProject,
    ProjectSetTemplates,
    ProjectSetValue,
    ProjectUpdateCategoryAvailableOptions,
} from '@features/project/state/actions';
import { MemberCreate, ProjectCreateUpdate, ProjectState } from '@features/project/state/state';
import { ServiceSetCreateUpdate } from '@features/service/store/actions';
import { ListPaginationInterface } from '@features/_shared/components/Paginator/enums/ListPaginationInterface';
import { StoreManager } from '@hypereact/state';
import { Slices } from '@store/state';
import {
    Project,
    ProjectSearchFilter,
    ProjectSearchRequest,
    RequestFile,
    RequestProjectAddUser,
    RequestProjectClone,
    RequestProjectCreate,
    RequestProjectFileCreate,
    RequestProjectPublish,
    RequestProjectRemoveUser,
    RequestProjectUpdate,
    RequestProjectUrlFileSelect,
    RequestScriptPublish,
    RequestUpdateProvisioning,
    ResponseProjectFileCreate,
    ResponseProjectPublish,
    ResponseProjectSearch,
    ResponseProjectUrlFileSelect,
    User,
} from '../../api/entities';
import { GitIntegrationApiService, IGitIntegrationApiService } from '../../api/mocks/git-integration/git-integration.api.service';
import { IProjectApiService, ProjectApiService } from '../services/projects/project.api.service';
import { AxiosPromise } from 'axios';

export interface IProjectManagerProps {
    search(request: ProjectSearchRequest): Promise<ResponseProjectSearch>;
    getProjectById(id: string): Promise<Project>;
    start(projectId: string, timeout?: number): void;
    addNewMember(project: Project, newMember: RequestProjectAddUser): void;
    setPagination(page: number, size: number, total: number): Promise<void>;
}

export class ProjectManager implements IProjectManagerProps {
    private static instance: ProjectManager;
    private projectService: IProjectApiService;
    private gitIntegration: IGitIntegrationApiService;
    private storeManager: StoreManager;
    private constructor() {
        this.projectService = ProjectApiService.getInstance(process.env.REACT_APP_MICROSERVICE_DASHBOARD_API_BASEPATH!);
        this.gitIntegration = GitIntegrationApiService.getInstance(process.env.REACT_APP_MICROSERVICE_DASHBOARD_API_BASEPATH!);
        this.storeManager = StoreManager.getInstance();
    }

    static getInstance() {
        if (ProjectManager.instance == null) {
            ProjectManager.instance = new ProjectManager();
        }
        return ProjectManager.instance;
    }

    private getState(): ProjectState {
        return this.storeManager.getState(Slices.Project) as ProjectState;
    }

    async search(request: ProjectSearchRequest): Promise<ResponseProjectSearch> {
        const response = await this.projectService.search(request);
        this.storeManager.dispatch(new ProjectSetPaginationAction(response.page?.page as number, response.page?.size as number, response.page?.total as number));
        this.storeManager.dispatch(new ProjectSetProjects(response.projects as Array<Project>));
        return response;
    }

    async homeSearch(): Promise<ResponseProjectSearch> {
        const response = await this.projectService.search({
            size: 5,
            page: 0,
            filter: { published: true },
            sort: { project_published_at: 'DESC' },
        });
        this.storeManager.dispatch(new HomeSetProjects(response.projects as Array<Project>));
        return response;
    }

    async pollProjectById(id: string): Promise<Project> {
        const response = await this.projectService.getProjectById(id);
        if (id === this.storeManager.getState(Slices.Project).project.selectedProject.id) {
            this.storeManager.dispatch(new ProjectSetSelectedProject(response));
        }
        return response;
    }

    async getProjectById(id: string): Promise<Project> {
        const response = await this.projectService.getProjectById(id);
        this.storeManager.dispatch(new ProjectSetSelectedProject(response));
        return response;
    }

    async start(projectId: string, timeout?: number): Promise<void> {
        await this.projectService.start(projectId, {
            timeout: timeout,
            jupyter_log_level: 'debug',
        });
    }

    async stop(projectId: string, force: boolean): Promise<void> {
        await this.projectService.stop(projectId, { force: force });
        await this.projectService.outputList(projectId);
    }

    async create(request: RequestProjectCreate): Promise<void> {
        await this.projectService.create(request);
        await this.search({
            size: 10,
            page: 0,
            filter: {},
        });
        this.storeManager.dispatch(new ProjectResetCreateUpdate());
    }

    async clone(request: RequestProjectClone): Promise<Project> {
        const response = await this.projectService.clone(request);
        await this.search({
            size: 10,
            page: 0,
            filter: {},
        });
        this.storeManager.dispatch(new ProjectSetSelectedProject(response));
        this.storeManager.dispatch(new ProjectResetCreateUpdate());
        return response;
    }

    async getTemplateList(): Promise<void> {
        const response = await this.projectService.getProjectTemplates();
        this.storeManager.dispatch(new ProjectSetTemplates(response.projectTemplates || []));
    }

    manageIDE(isOpen: boolean): void {
        this.storeManager.dispatch(new ProjectSetIsIDEOpened(isOpen));
    }

    setFilters(key: keyof ProjectSearchFilter, value: string | Array<string>) {
        this.storeManager.dispatch(new ProjectSetFilters(key, value !== '' ? value : undefined));
        this.storeManager.dispatch(new ProjectUpdateCategoryAvailableOptions(value));
    }

    updateAvailableOptions(value: Array<string>) {
        this.storeManager.dispatch(new ProjectUpdateCategoryAvailableOptions(value));
    }

    saveValue(key: keyof ProjectCreateUpdate, value: string | string[] | number | boolean | Array<string>): void {
        this.storeManager.dispatch(new ProjectSetValue(key, value));
    }

    saveNewMemberValue(key: keyof MemberCreate, value: string | number | boolean): void {
        this.storeManager.dispatch(new ProjectSetNewMemberValue(key, value));
    }

    resetCreateUpdate() {
        this.storeManager.dispatch(new ProjectResetCreateUpdate());
    }

    resetNewCollaborator() {
        this.storeManager.dispatch(new ProjectResetNewCollaborator());
    }

    async addNewMember(project: Project, newMember: RequestProjectAddUser) {
        project.id && (await this.projectService.addNewMember(project.id, newMember));
    }
    async deleteMember(project: Project, member: RequestProjectRemoveUser) {
        project.id && (await this.projectService.removeMember(project.id, member));
    }

    resetFilters() {
        this.storeManager.dispatch(new ProjectResetFilters());
    }

    resetPagination() {
        this.storeManager.dispatch(new ProjectResetPaginationAction());
    }

    resetCategoryFilter() {
        this.storeManager.dispatch(new ProjectResetCategoryFilters());
    }

    resetCategoryCreate() {
        this.storeManager.dispatch(new ProjectResetCreateUpdateCategory());
    }

    resetSelectedProject() {
        this.storeManager.dispatch(new ProjectResetSelectedProject());
    }

    //TODO to be removed
    async pokeUser(user: User) {
        const response = await this.gitIntegration.pokeUser(user);
        this.storeManager.dispatch(new SetPokeUser(response));
    }

    async createInput(id: string, requestProjectFileCreate: RequestProjectFileCreate): Promise<ResponseProjectFileCreate> {
        const response = await this.projectService.createInput(id, requestProjectFileCreate);
        return response;
    }

    async selectExternalUrlInput(id: string, requestProjectUrlFileSelect: RequestProjectUrlFileSelect): Promise<ResponseProjectUrlFileSelect> {
        const response = this.projectService.selectExternalUrlInput(id, requestProjectUrlFileSelect);
        return response;
    }

    async pollOutputList(id: string): Promise<void> {
        const response = await this.projectService.outputList(id);
        if (id === this.storeManager.getState(Slices.Project).project.selectedProject.id) {
            this.storeManager.dispatch(new ProjectSetOutputs(Object.values(response.outputs as any)));
        }
    }

    async getOutputList(id: string): Promise<void> {
        const response = await this.projectService.outputList(id);
        if (id === this.storeManager.getState(Slices.Project).project.selectedProject.id) {
            this.storeManager.dispatch(new ProjectSetOutputs(Object.values(response.outputs as any)));
        }
    }

    async setPagination(page: number, size: number, total: number): Promise<void> {
        this.storeManager.dispatch(new ProjectSetPaginationAction(page, size, total));
        const projectState = this.getState();

        const pagination: ListPaginationInterface = projectState.project.pagination;
        const query = projectState.project.query;
        const order = projectState.project.order ? { [projectState.project.order.by]: projectState?.project.order.direction } : undefined;
        const filters: ProjectSearchFilter = projectState.project.filters;
        const projectSearchRequest: ProjectSearchRequest = { page: pagination.page, size: pagination.size, query: query, sort: order, filter: filters };
        await this.search(projectSearchRequest);
    }

    async update(projectId: string, requestProjectUpdate: RequestProjectUpdate): Promise<void> {
        await this.projectService.update(projectId, requestProjectUpdate);
        const response = await this.projectService.getProjectById(projectId);
        this.storeManager.dispatch(new ProjectSetSelectedProject(response));
    }

    async updateProvisioning(id: string, requestUpdateProvisioning: RequestUpdateProvisioning): Promise<void> {
        await this.projectService.updateProvisioning(id, requestUpdateProvisioning);
        const response = await this.projectService.getProjectById(id);
        this.storeManager.dispatch(new ProjectSetSelectedProject(response));
    }

    async downloadInput(id: string, requestFile: RequestFile): Promise<File> {
        return this.projectService.downloadInput(id, requestFile);
    }

    async removeInput(id: string, requestFile: RequestFile): Promise<void> {
        await this.projectService.removeInput(id, requestFile);
        await this.getProjectById(id);
    }

    async downloadOutput(id: string, requestFile: RequestFile): Promise<File> {
        return this.projectService.downloadOutput(id, requestFile);
    }

    async publishProject(id: string, requestPublish: RequestProjectPublish): Promise<ResponseProjectPublish> {
        return this.projectService.publishProject(id, requestPublish);
    }

    async publishScript(id: string, requestScriptPublish: RequestScriptPublish): Promise<void> {
        await this.projectService.publishScript(id, requestScriptPublish);
    }

    async getProvenanceTree(id: string): Promise<void> {
        const response = await this.projectService.getProjectsByParentId(id);
        this.storeManager.dispatch(new ProjectSetProvenance(response));
    }

    setServiceCreateUpdate(project: Project): void {
        this.storeManager.dispatch(new ServiceSetCreateUpdate(project));
    }

    async getProjectCategories(): Promise<void> {
        const response = await this.projectService.getProjectCategories();
        response.categories && this.storeManager.dispatch(new ProjectSetCategories(response.categories));
    }

    async downloadRepo(projectId: string, zipname: string): Promise<AxiosPromise<File>> {
        return this.projectService.downloadRepo(projectId, zipname);
    }

    async archive(id: string): Promise<void> {
        return await this.projectService.archive(id);
    }

    async delete(id: string): Promise<void> {
        return await this.projectService.delete(id);
    }
}
