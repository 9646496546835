import { createBrowserHistory, History } from 'history';
import type { Paths } from '@routes';
import { StoreManager } from '@hypereact/state';
import { SetCurrentRoute } from '@features/_shared/store/actions';

export interface IState {
    id?: string | number;
}

export interface INavigationManager {
    getHistory(): History<unknown> | null;
    getCurrentRoute(): string | undefined;
    navigateTo(path: string, state?: unknown): void;
    replace(path: string): void;
}

export class NavigationManager implements INavigationManager {
    private static instance: NavigationManager;

    private history: History<unknown> = createBrowserHistory({
        basename: process.env.REACT_APP_ROUTER_BASEHREF,
    });

    public static getInstance() {
        if (NavigationManager.instance == null) {
            NavigationManager.instance = new NavigationManager();
        }
        return NavigationManager.instance;
    }

    getHistory() {
        return this.history as History<unknown>;
    }

    navigateTo(path: Paths, state?: IState) {
        this.history?.push(path, state);
        StoreManager.getInstance().dispatch(new SetCurrentRoute(path));
    }

    replace(path: Paths, state?: IState) {
        this.history?.replace(path, state);
    }

    getCurrentRoute() {
        return this.history?.location.pathname;
    }
}
