export const wizardPublicationDataset = ['/provisioning/dataset'];
export const wizardPublicationProject = ['/provisioning/projectData', '/provisioning/dataset', '/provisioning/requirements', '/provisioning/publication'];
export const wizardPublicationService = [
    '/provisioning/serviceData',
    '/provisioning/templates',
    '/provisioning/requirements',
    '/provisioning/metadata',
    '/provisioning/publication',
];
export const wizardPublicationScript = ['/provisioning/projectData', '/provisioning/requirements', '/provisioning/metadata', '/provisioning/publication'];
